import {Settings} from 'lucide-react';
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext';
import {getSubmissionTypeText} from '../../../utils/get-submission-test.js';
import ConfirmationModal from '../../ConfirmationModal';
import DueTimeDisplay from '../../DueTimeDisplay.js';
import CloneModal from './TCloneModal';
import Modal from './TModal';
import MoreButton from '../TMore';
import {MoreHorizontal, Copy, Share2, Pencil, Trash2} from 'lucide-react';

function TAssignments(props) {
    const [assignmentName, setAssignmentName] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [dueTime, setDueTime] = useState('');
    const [timezone, setTimezone] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState();
    const [studentSubmitted, setStudentSubmitted] = useState('');
    const [format, setFormat] = useState(null); // New state for essay/problem set
    const [className, setClassName] = useState('');
    const [assignments, setAssignments] = useState([]);
    const {user} = useAuth();
    const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
    const [cloneAssignmentId, setCloneAssignmentId] = useState(null);
    const [cloneAssignmentName, setCloneAssignmentName] = useState('');
    const [originalCloneName, setOriginalCloneName] = useState('');
    const [cloneDueDate, setCloneDueDate] = useState('');
    const [cloneDueTime, setCloneDueTime] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
    const [cloneSubmissionType, setCloneSubmissionType] = useState('');
    const [classOpen, setClassOpen] = useState(false);
    const [code, setCode] = useState('');
    const [cloneClassId, setCloneClassId] = useState(props.class_id);
    const [userClasses, setUserClasses] = useState([]);
    const [isGroupSubmission, setIsGroupSubmission] = useState(false);
    const [shareCode, setShareCode] = useState('');
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [cloneAssignmentType, setCloneAssignmentType] = useState(null);
    const [isCloneEssay, setIsCloneEssay] = useState(false);
    const [isCloneFlexible, setIsCloneFlexible] = useState(false);
    const [isOpenToManage, setIsOpenToManage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleShareAssignment = async assignmentId => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignmentId}/share-code/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setShareCode(response.data.share_code);
            setIsShareModalOpen(true);
        } catch (error) {
            setErrorMessage(
                error.response?.data?.error || 'An error occurred while getting the share code.'
            );
        }
    };

    const handleRenameSubmit = async (assignmentId, newName) => {
        // console.log('Renaming assignment:', assignmentId, 'to:', newName);

        if (newName.trim() === '') {
            setErrorMessage('Assignment name cannot be empty.');
            return;
        }

        if (newName.length > 70) {
            setErrorMessage('Assignment name must not exceed 70 characters.');
            return;
        }

        try {
            await gradeApi.patch({
                path: `/api/assignment/${assignmentId}/rename/`,
                body: {title: newName},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            getAssignments(); // Refresh the assignments list
            setErrorMessage(''); // Clear any existing error messages
        } catch (error) {
            setErrorMessage(
                error.response?.data?.error || 'An error occurred while renaming the assignment.'
            );
        }
    };
    const getUserClasses = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/users/${user.user.id}/courses-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setUserClasses(response.data.courses);
            // console.log(response);
        } catch (error) {
            console.error('Error fetching user classes:', error);
        }
    };

    const handleOutsideClick = (e, ref) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setIsModalOpen(false);
        }
    };

    const confirmCloneAssignment = async () => {
        try {
            if (cloneAssignmentName.length > 70) {
                setErrorMessage('Assignment name must not exceed 70 characters.');
                return;
            }

            if (cloneSubmissionType == 'student' && (!cloneDueDate || !cloneDueTime)) {
                setErrorMessage('Please set both due date and time for student submissions');
                return;
            }

            // Get the original assignment details first
            const detailsResponse = await gradeApi.get({
                path: `/api/assignment/${cloneAssignmentId}/share-details/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const {type} = detailsResponse.data;

            // Create new assignment
            const response = await gradeApi.post({
                path: `/api/v2/courses/${cloneClassId}/assignment`,
                body: {
                    title: cloneAssignmentName,
                    is_exam: cloneSubmissionType === 'teacher',
                    is_group_task: isGroupSubmission,
                    type: type,
                    due_date: cloneSubmissionType == 'teacher' ? '9999-9-20' : cloneDueDate.trim(),
                    due_time: cloneSubmissionType == 'teacher' ? '09:09' : cloneDueTime.trim()
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            // Get and save draft data based on type
            let draftResponse;
            if (type === 'flexible_problem_set') {
                draftResponse = await gradeApi.get({
                    path: `/api/v2/assignments/${cloneAssignmentId}/flexible-problem-set/draft`,
                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                });

                await gradeApi.post({
                    path: `/api/v2/assignments/${response.data.id}/flexible-problem-set/draft`,
                    body: draftResponse.data,
                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                });
            } else {
                const draftPath = type === 'essay' ? 'essay' : 'problem-set';
                draftResponse = await gradeApi.get({
                    path: `/api/assignment/${cloneAssignmentId}/${draftPath}/load-draft/`,
                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                });

                if (type === 'essay') {
                    const {criteria, instructions, gradingInstructions, showRubric} = JSON.parse(
                        draftResponse.data.draft_data
                    );
                    await gradeApi.post({
                        path: `/api/assignment/${response.data.id}/essay/save-draft/`,
                        body: {
                            criteria,
                            instructions,
                            editableInstructions: instructions,
                            gradingInstructions,
                            showRubric
                        },
                        config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                    });
                } else {
                    const draft = draftResponse.data;
                    console.log(draftResponse);
                    await gradeApi.post({
                        path: `/api/assignment/${response.data.id}/problem-set/save-draft/`,
                        body: {
                            examPaper: draft.examPaper,
                            bubbleSheet: draft.bubbleSheet,
                            instructions: draft.instructions,
                            editableInstructions: draft.instructions,
                            gradingInstructions: draft.gradingInstructions,
                            feedbackInstructions: draft.feedbackInstructions || 'detailed'
                        },
                        config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                    });
                }
            }

            getAssignments();
            setIsCloneModalOpen(false);
        } catch (error) {
            setErrorMessage(error.response?.data?.error || 'An error occurred. Please try again.');
        }
    };

    // Update handleCloneAssignment to properly initialize date/time
    const handleCloneAssignment = async assignmentId => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignmentId}/share-details/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            const assignmentDetails = response.data;
            const type = assignmentDetails.type;

            setCloneAssignmentId(assignmentId);
            setCloneAssignmentName(`Copy of ${assignmentDetails.title}`);
            setFormat(type);
            setCloneAssignmentType(type);
            setIsCloneEssay(type === 'essay');
            setIsCloneFlexible(type === 'flexible_problem_set');

            const truncatedTitle =
                assignmentDetails.title.length > 20
                    ? assignmentDetails.title.substring(0, 20) + '...'
                    : assignmentDetails.title;
            setOriginalCloneName(truncatedTitle);

            setCloneDueDate('');
            setCloneDueTime('');

            const submissionType =
                type !== 'pdf_problem_set' ? '' : assignmentDetails.is_exam ? 'teacher' : 'student';

            setCloneSubmissionType(submissionType);
            setCloneClassId(props.class_id);
            setErrorMessage('');
            setIsCloneModalOpen(true);
        } catch (error) {
            setErrorMessage(error.response?.data?.error || 'Failed to fetch assignment details');
        }
    };
    // create a ref for the modal
    const modalRef = useRef();

    // dismissal tracking
    useEffect(() => {
        const handleClickOutside = event => {
            handleOutsideClick(event, modalRef);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef]);

    const getAssignments = async () => {
        setIsLoading(true);
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${props.class_id}/teacher-assignments-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log(response);
            setAssignments(response.data.assignments);
            setClassName(response.data.class_name);
            setClassOpen(response.data.class_open);
            setCode(response.data.class_code);
            setIsOpenToManage(response.data.isOpenToManage);
            // console.log(response.data.class_open);
            setTimezone(response.data.timezone);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.response.data.error || 'An error occurred. Please try again.');
        }
    };

    useEffect(() => {
        getAssignments();
        getUserClasses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();

        // console.log(assignmentName.length)
        // Check assignment name length
        if (assignmentName.length > 70) {
            setErrorMessage('Assignment name must not exceed 70 characters.');
            return;
        }

        try {
            await gradeApi.post({
                path: `/api/v2/courses/${props.class_id}/assignment`,
                body: {
                    title: assignmentName,
                    is_exam: studentSubmitted === 'teacher',
                    is_group_task: isGroupSubmission,
                    type: format,
                    due_date: studentSubmitted == 'student' ? dueDate : '9999-9-20',
                    due_time: studentSubmitted == 'student' ? dueTime : '16:13'
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log(response);
            getAssignments();
            setIsModalOpen(false);
        } catch (error) {
            // console.log(error);
            setErrorMessage(error.response?.data?.error || 'An error occurred. Please try again.');
        }
    };

    const handleDeleteAssignment = assignmentId => {
        setSelectedAssignmentId(assignmentId);
        setIsConfirmationModalOpen(true);
    };

    const confirmDeleteAssignment = async () => {
        try {
            await gradeApi.delete({
                path: `/api/courses/${props.class_id}/assignment/${selectedAssignmentId}/`,
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            getAssignments();
            setIsConfirmationModalOpen(false);
        } catch (error) {
            setErrorMessage(error.response.data.error);
        }
    };

    const actuallyChangeClassOpen = async value => {
        // console.log(value);
        try {
            await gradeApi.post({
                path: `/api/courses/${props.class_id}/open/`,
                body: {openValue: value},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
        } catch (error) {
            setErrorMessage(error);
        }
    };

    const handleChangeClassOpen = event => {
        const newValue = event.target.checked;
        setClassOpen(newValue);
        actuallyChangeClassOpen(newValue);
    };
    const AssignmentCard = ({
                                id,
                                title,
                                num_submissions,
                                num_students,
                                due_date,
                                due_time,
                                timezone,
                                is_exam,
                                type,
                                is_group_task,
                                is_assignment_published,
                                is_grades_published,
                                handleRenameSubmit,
                                handleCloneAssignment,
                                handleDeleteAssignment,
                                handleShareAssignment,
                                classId,
                                isOpenToManage
                            }) => {
        const [showOptions, setShowOptions] = useState(false);
        const [isEditing, setIsEditing] = useState(false);
        const [newName, setNewName] = useState(title);
        const dropdownRef = useRef(null);
        let kwd = "";

        // Add effect to handle clicks outside the dropdown
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setShowOptions(false);
                }
            };

            // Add event listener if dropdown is open
            if (showOptions) {
                document.addEventListener('mousedown', handleClickOutside);
            }

            // Clean up the event listener
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [showOptions]);

        if (type == 'byob_pdf_problem_set') {
            kwd = 'create';
        } else {
            kwd = 'tsubmissions';
        }

        const handleCardClick = (e) => {
            if (e.target.closest('.more-options-area')) {
                e.preventDefault();
                return;
            }
            if (isEditing) {
                e.preventDefault();
            }
        };

        const handleRenameClick = () => {
            setIsEditing(true);
            setNewName(title);
            setShowOptions(false);
        };

        const handleSaveClick = async () => {
            await handleRenameSubmit(id, newName);
            setIsEditing(false);
        };

        const getSubmissionStatus = () => {
            if (is_group_task) {
                return {
                    count: `${num_submissions}`,
                    label: 'groups submitted'
                };
            }
            return {
                count: `${num_submissions}/${num_students}`,
                label: 'submitted'
            };
        };

        const getStatusIndicatorClass = () => {
            if (is_grades_published) {
                return 'bg-orange-200';
            }
            if (is_assignment_published) {
                return 'bg-gray-300';
            }
            return 'bg-transparent';
        };


        return (

            <Link to={`/tclasses/${props.class_id}/tassignments/${id}/${kwd}`} onClick={handleCardClick}>
                <div
                    className="relative bg-white rounded-3xl p-6 transition-all duration-300 hover:shadow-lg border border-gray-200 hover:border-orange-200 overflow-visible">
                    <div className="flex flex-col space-y-4">
                        <div className="flex justify-between items-start">
                            <div className="space-y-1">
                                {isEditing ? (
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="text"
                                            value={newName}
                                            className="p-2 border rounded-lg focus:ring-2 focus:ring-orange-200 focus:border-orange-200 outline-none"
                                            onChange={(e) => setNewName(e.target.value)}
                                        />
                                        <button
                                            className="px-3 py-1 bg-orange-200 rounded-full text-sm hover:bg-orange-300 transition-colors"
                                            onClick={handleSaveClick}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className="px-3 py-1 bg-gray-200 rounded-full text-sm hover:bg-gray-300 transition-colors"
                                            onClick={() => setIsEditing(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                ) : (
                                    <h3 className="font-medium text-gray-900 line-clamp-2">{title}</h3>
                                )}
                                <div className="space-y-1">
                                    <p className="text-sm text-gray-500">
                                        {is_exam ? 'No due date' : (
                                            <DueTimeDisplay dueTime={due_time} dueDate={due_date} timezone={timezone}/>
                                        )}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        {type === 'essay' ? 'Essay' :
                                            type === 'flexible_problem_set' ? 'Online Assignment' :
                                                type === 'pdf_problem_set' ? `PDF Problem Set${is_exam ? ' (Teacher)' : ' (Student)'}` :
                                                    type === 'byob_pdf_problem_set' ? `PDF Problem Set${is_exam ? ' (Teacher)' : ' (Student)'}` :
                                                        'Assignment'}
                                    </p>
                                </div>
                            </div>

                            {isOpenToManage && (
                                <div className="relative more-options-area">
                                    <button
                                        className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowOptions(!showOptions);
                                        }}
                                    >
                                        <MoreHorizontal className="w-5 h-5 text-gray-500"/>
                                    </button>

                                    {showOptions && (
                                        <div
                                            ref={dropdownRef}
                                            className="fixed transform translate-x-[-90%] mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-100 py-1 z-50"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <div className="max-h-60 overflow-y-auto">
                                                {/* 
                                                <button 
                                                className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-orange-50 flex items-center space-x-2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleCloneAssignment(id);
                                                }}
                                                >
                                                <Copy className="w-4 h-4 text-gray-500"/>
                                                <span>Clone Assignment</span>
                                                </button>
                                                
                                                <button
                                                    className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-orange-50 flex items-center space-x-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleShareAssignment(id);
                                                    }}
                                                >
                                                    <Share2 className="w-4 h-4 text-gray-500"/>
                                                    <span>Share Assignment</span>
                                                </button>
                                                */}
                                                <button
                                                    className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-orange-50 flex items-center space-x-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleRenameClick();
                                                    }}
                                                >
                                                    <Pencil className="w-4 h-4 text-gray-500"/>
                                                    <span>Rename</span>
                                                </button>
                                                <button
                                                    className="w-full px-4 py-2 text-sm text-left text-red-600 hover:bg-red-50 flex items-center space-x-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleDeleteAssignment(id);
                                                    }}
                                                >
                                                    <Trash2 className="w-4 h-4 text-red-500"/>
                                                    <span>Delete</span>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="flex justify-between items-center">
                            <div className="flex items-center space-x-2">
                                <span className="text-sm font-medium text-gray-900">
                                    {getSubmissionStatus().count}
                                </span>
                                <span className="text-sm text-gray-500">
                                    {getSubmissionStatus().label}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`absolute bottom-0 right-0 w-8 h-8 rounded-tl-3xl rounded-br-3xl ${getStatusIndicatorClass()}`}
                    />
                </div>
            </Link>
        );
    };


    const AssignmentList = ({assignments, isOpenToManage}) => {
        const sortedAssignments = assignments?.sort((a, b) => b.id - a.id);

        return (
            <div className="container mx-auto relative pb-16">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 min-w-20">
                    {/* New Assignment Card */}
                    <button
                        className="relative bg-white rounded-3xl p-6 transition-all duration-300 hover:shadow-lg border-2 border-dashed border-gray-200 hover:border-orange-200 group flex flex-col items-center justify-center"
                        onClick={() => setIsModalOpen(true)}
                    >
                        <div className="flex flex-col items-center space-y-3">
                            <div className="rounded-full bg-orange-50 p-3 group-hover:bg-orange-100 transition-colors">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-orange-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 4v16m8-8H4"
                                    />
                                </svg>
                            </div>
                            <span className="font-medium text-gray-600 group-hover:text-gray-900 transition-colors">
                                Create Assignment
                            </span>
                        </div>
                    </button>

                    {/* Existing Assignments */}
                    {sortedAssignments && sortedAssignments.length > 0 ? (
                        sortedAssignments.map(value => (
                            <AssignmentCard
                                key={value.id}
                                id={value.id}
                                isOpenToManage={isOpenToManage}
                                title={value.title}
                                num_submissions={value.num_submissions}
                                num_students={value.course.num_students}
                                due_date={value.due_date}
                                due_time={value.due_time}
                                timezone={value.timezone}
                                is_exam={value.is_exam}
                                type={value.type}
                                is_group_task={value.is_group_task}
                                is_assignment_published={value.is_assignment_published}
                                is_grades_published={value.is_grades_published}
                                setErrorMessage={setErrorMessage}
                                handleRenameSubmit={handleRenameSubmit}
                                handleDeleteAssignment={handleDeleteAssignment}
                                handleShareAssignment={handleShareAssignment}
                                handleCloneAssignment={handleCloneAssignment}
                            />
                        ))
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
        );
    };

    const AssignmentRow = ({assignmentInfo, isOpenToManage}) => {
        const [myBool, setmyBool] = React.useState(true);

        function toggleBool() {
            setmyBool(!myBool);
        }

        return (
            <div className="hover:border rounded-full py-0.5 px-3 transition-all duration-100 desktop:hover:shadow-sm">
                <div className="grid grid-cols-5 align-middle">
                    <div className="classes-table-content">
                        <Link
                            to={`/tclasses/${props.class_id}/tassignments/${assignmentInfo.id}/tsubmissions`}
                            className="classes-table-content"
                        >
                            {assignmentInfo.title}
                        </Link>
                    </div>
                    <div className="classes-table-content">
                        {assignmentInfo.type === 'essay'
                            ? 'Essay'
                            : assignmentInfo.is_exam
                                ? 'Teacher'
                                : 'Student'}
                    </div>
                    <div className="classes-table-content">
                        {assignmentInfo.is_exam ? 'Teacher' : 'Student'}
                    </div>
                    <DueTimeDisplay
                        dueTime={assignmentInfo.due_time}
                        dueDate={assignmentInfo.due_date}
                        timezone={assignmentInfo.timezone}
                    />
                    <div className="classes-table-content text-right">
                        {assignmentInfo.num_submissions}/{assignmentInfo.course.num_students}
                    </div>
                    <div className="classes-table-content text-right flex justify-end p-2">
                        <MoreButton
                            isOpenToManage={isOpenToManage}
                            myBool={myBool}
                            toggleBool={toggleBool}
                            id={assignmentInfo.id}
                        />
                    </div>
                </div>
            </div>
        );
    };

    // ClassTable component
    const AssignmentTable = ({assignments, isOpenToManage}) => {
        const sortedAssignments = assignments?.sort((a, b) => b.id - a.id);
        return (
            <div className="min-w-full">
                <div className="grid grid-cols-5 gap-4 mb-6 p-2 font-bold border-b border-black">
                    <div className="classes-column-header">Assignment</div>
                    <div className="classes-column-header">Submission Type</div>
                    <div className="classes-column-header">Due Date</div>
                    <div className="classes-column-header text-right">Submissions</div>
                    <div className="classes-column-header text-right">Actions</div>
                </div>
                {sortedAssignments ? (
                    <div>
                        {sortedAssignments.map(value, i => (
                            <AssignmentRow
                                key={`${i} + ${Math.random()}`}
                                assignmentInfo={value}
                                isOpenToManage={isOpenToManage}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="text-center">No Assignments available</div>
                )}
            </div>
        );
    };

    const [cardView, setCardView] = useState(true); // Changed to camelCase to follow convention

    // Corrected typo in function name
    function toggleCardView() {
        setCardView(!cardView);
    }

    // Your changeView function remains unchanged
    const changeView = ({assignments, isOpenToManage}) => {
        // console.log(assignments);
        return cardView ? (
            <AssignmentList assignments={assignments} isOpenToManage={isOpenToManage}/>
        ) : (
            <AssignmentTable assignments={assignments} isOpenToManage={isOpenToManage}/>
        );
    };

    const [dateFocused, setDateFocused] = useState(false);
    const [timeFocused, setTimeFocused] = useState(false);
    return (
        <div id="HomeTeacher" className="flex flex-row p-8 justify-start h-screen">
            <div id="Main-Content-container" className="flex flex-col w-full relative">
                {/* Sticky header - now matching content width */}
                <div className="fixed top-8 bg-white z-50" style={{width: 'calc(56%)'}}>
                    <div id="Overview" className="flex flex-col space-y-2">
                        <div className="flex flex-row justify-between items-center">
                            <div className="flex flex-row items-center">
                                <Link to="/tclasses">
                                    <span className="text-black text-xl font-medium">My Classes</span>
                                </Link>
                                <Link to="/tclasses">
                                    <div className="size-6 flex items-center justify-center">
                                        <button>
                                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                                        </button>
                                    </div>
                                </Link>
                                <h1 className="text-black text-xl font-medium ml-4">
                                    {className ? (
                                        className.length > 15
                                            ? `${className.substring(0, 15)}...`
                                            : className
                                    ) : ''}
                                </h1>
                            </div>

                            <div className="flex items-center space-x-4">
                                {!isLoading &&
                                    (isOpenToManage ? (
                                        <span
                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-red-100 text-red-800">
                                            Lead Instructor
                                        </span>
                                    ) : (
                                        <span
                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-green-100 text-green-800">
                                            Co-Instructor
                                        </span>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Scrollable content */}
                <div className="mt-28 overflow-y-auto hide-scrollbar">
                    {changeView({assignments, isOpenToManage})}
                </div>

                {isCloneModalOpen && (
                    <CloneModal
                        isOpen={isCloneModalOpen}
                        assignmentName={cloneAssignmentName}
                        setAssignmentName={setCloneAssignmentName}
                        dueDate={cloneDueDate}
                        dueTime={cloneDueTime}
                        setDueTime={setCloneDueTime}
                        timezone={timezone}
                        setDueDate={setCloneDueDate}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        originalCloneName={originalCloneName}
                        submissionType={cloneSubmissionType}
                        setSubmissionType={setCloneSubmissionType}
                        isEssay={isCloneEssay} // Use the new state variable
                        isFlexible={isCloneFlexible} // Use the new state variable
                        cloneClassId={cloneClassId}
                        setCloneClassId={setCloneClassId}
                        userClasses={userClasses}
                        isGroupSubmission={isGroupSubmission}
                        setIsGroupSubmission={setIsGroupSubmission}
                        onClose={() => setIsCloneModalOpen(false)}
                        onConfirm={confirmCloneAssignment}
                    />
                )}
                {isModalOpen && (
                    <Modal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        handleSubmit={handleSubmit}
                        assignmentName={assignmentName}
                        setAssignmentName={setAssignmentName}
                        format={format}
                        setFormat={setFormat}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        dueTime={dueTime}
                        timezone={timezone}
                        setDueTime={setDueTime}
                        studentSubmitted={studentSubmitted}
                        setStudentSubmitted={setStudentSubmitted}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        dateFocused={dateFocused}
                        setDateFocused={setDateFocused}
                        timeFocused={timeFocused}
                        setTimeFocused={setTimeFocused}
                        modalRef={modalRef}
                        isGroupSubmission={isGroupSubmission}
                        setIsGroupSubmission={setIsGroupSubmission}
                        handleCloneAssignment={handleCloneAssignment}
                    />
                )}
                <ConfirmationModal
                    isOpen={isConfirmationModalOpen}
                    message="Are you sure you want to delete this assignment? This action cannot be undone."
                    onClose={() => setIsConfirmationModalOpen(false)}
                    onConfirm={confirmDeleteAssignment}
                />
                <ConfirmationModal
                    isOpen={isShareModalOpen}
                    message={
                        <div className="space-y-4">
                            <h2 className="text-lg font-semibold">Assignment Sharing</h2>
                            <p>
                                Share this assignment with a peer. To access this assignment, they should open
                                GradeWiz on their end, click "New Assignment," select "Import Shared Assignment" and
                                enter the code below:
                            </p>
                            <div className="bg-gray-100 p-4 rounded-md text-center">
                                <span className="text-xl font-mono font-semibold">{shareCode}</span>
                            </div>
                        </div>
                    }
                    showCancelButton={false}
                    onClose={() => setIsShareModalOpen(false)}
                    onConfirm={() => setIsShareModalOpen(false)}
                />
            </div>
        </div>
    );
}

export default TAssignments;
