import React from 'react';
import {Link} from 'react-router-dom';
import {
    ArrowLeft,
    ChevronLeft,
    ChevronRight,
    Check
} from "lucide-react";

const BatchNavigation = ({
                             showTopBar,
                             currentBatchIndex,
                             currentQuestion,
                             allStudents,
                             data,
                             isDropdownOpen,
                             setIsDropdownOpen,
                             dropdownRef,
                             setIsBatchInputFocused,
                             navigate,
                             class_id,
                             assignment_id,
                             students,
                             allCurrentBatchViewed,
                             markingBatchAsViewed,
                             markBatchAsViewed,
                             navigateToBatch,
                             setCurrentQuestion,
                             setCurrentBatchIndex,
                             setGradingInstructions,
                             setLastUsedInstructions,
                             isRegrading
                         }) => {
    // State to track the input value separately from the actual batch index
    const [batchInputValue, setBatchInputValue] = React.useState((currentBatchIndex + 1).toString());
    
    // Update the input value when the currentBatchIndex changes (e.g., from arrow navigation)
    React.useEffect(() => {
        setBatchInputValue((currentBatchIndex + 1).toString());
    }, [currentBatchIndex]);
    
    return (
        <div
            className={`fixed top-0 left-0 right-0 z-50 bg-white transition-all duration-300 ${
                showTopBar ? 'translate-y-0' : 'translate-y-[-100%]'
            }`}
        >
            <div className="border-b">
                <div className="flex items-center py-7 px-10">
                    <div className="flex items-center space-x-2">
                        <Link
                            to={`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions`}
                            className="text-black text-lg font-medium p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                        >
                            <ArrowLeft/>
                        </Link>
                    </div>

                    <div className="ml-auto flex items-center space-x-4">
                        {/* Regrading Status Indicator */}
                        {isRegrading && (
                            <div className="flex items-center px-3 py-1.5 bg-orange-100 text-orange-800 rounded-full">
                                <div className="w-2.5 h-2.5 bg-orange-500 rounded-full mr-2 animate-pulse"></div>
                                <span className="text-sm font-medium">Regrading in progress</span>
                            </div>
                        )}

                        {/* Custom Subquestion Dropdown */}
                        <div className="relative">
                            <div ref={dropdownRef} className="custom-dropdown">
                                <button
                                    className="text-gray-600 text-l bg-white hover:bg-gray-200 hover:text-gray-800 font-medium flex items-center justify-between border border-black rounded-full p-2 pr-3 min-w-[200px]"
                                    onClick={() => setIsDropdownOpen(prev => !prev)}
                                >
                  <span className="truncate">
                    {currentQuestion ?
                        data.examPaper
                            .flatMap(q => q.subQuestions)
                            .find(sq => sq.id === currentQuestion)
                            ? (() => {
                                const allSubQuestions = data.examPaper.flatMap(q => q.subQuestions);
                                const selectedSubQuestion = allSubQuestions.find(sq => sq.id === currentQuestion);
                                const qIndex = data.examPaper.findIndex(q =>
                                    q.subQuestions.some(sq => sq.id === currentQuestion)
                                );
                                const sqIndex = data.examPaper[qIndex]?.subQuestions.findIndex(sq =>
                                    sq.id === currentQuestion
                                );
                                return `${qIndex + 1}${String.fromCharCode(97 + sqIndex)}) ${selectedSubQuestion?.question_text?.substring(0, 30) || ''}`;
                            })()
                            : 'Select Subquestion'
                        : 'Select Subquestion'
                    }
                  </span>
                                    <ChevronRight
                                        size={16}
                                        className={`ml-2 transition-transform duration-200 ${isDropdownOpen ? 'rotate-90' : ''}`}
                                    />
                                </button>

                                {isDropdownOpen && (
                                    <div
                                        className="absolute z-50 mt-1 w-80 max-h-96 overflow-y-auto bg-white border border-gray-200 rounded-lg shadow-lg">
                                        <div className="p-2 sticky top-0 bg-white border-b border-gray-100">
                                            <div className="text-sm font-medium text-gray-500">Select Subquestion</div>
                                        </div>
                                        <div className="py-1">
                                            {data.examPaper.map((question, qIndex) => (
                                                <React.Fragment key={`question-${qIndex}`}>
                                                    <div
                                                        className="px-3 py-2 text-xs font-semibold text-gray-500 bg-gray-50">
                                                        Question {qIndex + 1}
                                                    </div>
                                                    {question.subQuestions.map((subQuestion, sqIndex) => (
                                                        <div
                                                            key={subQuestion.id}
                                                            className={`px-3 py-2 cursor-pointer hover:bg-gray-100 ${currentQuestion === subQuestion.id ? 'bg-orange-50 text-orange-700' : ''}`}
                                                            onClick={() => {
                                                                const newSubQuestionId = subQuestion.id;
                                                                const selectedSubQuestion = subQuestion;

                                                                // Update the URL with the new subquestion ID
                                                                navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions/group/${currentBatchIndex}`, {
                                                                    state: {
                                                                        students: students,
                                                                        allStudents: allStudents,
                                                                        initialSubQuestion: newSubQuestionId
                                                                    },
                                                                    replace: true // Replace current history entry instead of adding a new one
                                                                });

                                                                setCurrentQuestion(newSubQuestionId);
                                                                setCurrentBatchIndex(0); // Reset to first batch when changing subquestion
                                                                setIsDropdownOpen(false); // Close dropdown after selection

                                                                // Set instructions if available, otherwise clear them
                                                                if (selectedSubQuestion?.regrade_instructions) {
                                                                    setLastUsedInstructions(selectedSubQuestion.regrade_instructions);
                                                                    setGradingInstructions(selectedSubQuestion.regrade_instructions);
                                                                } else {
                                                                    // Only clear gradingInstructions to match arrow navigation behavior
                                                                    setGradingInstructions('');
                                                                }
                                                            }}
                                                        >
                                                            <div className="flex items-center">
                                                                <div
                                                                    className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-gray-100 mr-2 text-xs font-medium">
                                                                    {`${qIndex + 1}${String.fromCharCode(97 + sqIndex)}`}
                                                                </div>
                                                                <div className="flex-1 truncate">
                                                                    {subQuestion.question_text?.substring(0, 50) || ''}
                                                                    {subQuestion.question_text?.length > 50 ? '...' : ''}
                                                                </div>
                                                                {currentQuestion === subQuestion.id && (
                                                                    <Check size={16} className="ml-2 text-orange-500"/>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex items-center space-x-2 ml-auto">
                            <span>Batch </span>
                            <div className="relative inline-block">
                                <input 
                                    type="text" 
                                    className="w-12 h-8 text-center border rounded-md focus:ring-2 focus:ring-amber-500 focus:border-amber-500 outline-none" 
                                    value={batchInputValue} 
                                    onFocus={(e) => {
                                        // Show top bar when input is focused
                                        setIsBatchInputFocused(true);
                                        setIsDropdownOpen(false);
                                        
                                        // Move cursor to the end after a brief delay to ensure it works
                                        setTimeout(() => {
                                            const valueLength = e.target.value.length;
                                            e.target.setSelectionRange(valueLength, valueLength);
                                        }, 0);
                                    }}
                                    onChange={(e) => {
                                        // Allow any input during typing, including empty string
                                        const value = e.target.value;
                                        setBatchInputValue(value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            // Process the input value when Enter is pressed
                                            const maxBatches = Math.ceil(allStudents.length / 10);
                                            let value = batchInputValue.trim();
                                            
                                            // Handle empty input - default to current batch
                                            if (value === '') {
                                                setBatchInputValue((currentBatchIndex + 1).toString());
                                                e.target.blur();
                                                return;
                                            }
                                            
                                            // Parse the input value
                                            let newBatchIndex = parseInt(value) - 1;
                                            
                                            // Handle invalid input
                                            if (isNaN(newBatchIndex) || newBatchIndex < 0) {
                                                newBatchIndex = 0; // Default to first batch
                                            } else if (newBatchIndex >= maxBatches) {
                                                newBatchIndex = maxBatches - 1; // Cap at max batch
                                            }
                                            
                                            // Update the input value to reflect the validated batch number
                                            const newValue = (newBatchIndex + 1).toString();
                                            setBatchInputValue(newValue);
                                            
                                            // Move cursor to the end
                                            setTimeout(() => {
                                                const valueLength = e.target.value.length;
                                                e.target.setSelectionRange(valueLength, valueLength);
                                            }, 0);
                                            
                                            // Only navigate if the batch is different
                                            if (newBatchIndex !== currentBatchIndex) {
                                                // Jump to the specified batch
                                                navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions/group/${newBatchIndex}`, {
                                                    state: {
                                                        students: students,
                                                        allStudents: allStudents,
                                                        initialSubQuestion: currentQuestion,
                                                        jumpToBatch: true
                                                    },
                                                    replace: true
                                                });
                                                setCurrentBatchIndex(newBatchIndex);
                                            }
                                            
                                            e.target.blur();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        // Reset the batch input focused state
                                        setIsBatchInputFocused(false);
                                        
                                        // Ensure valid value on blur
                                        const maxBatches = Math.ceil(allStudents.length / 10);
                                        let value = batchInputValue.trim();
                                        
                                        // If empty, set to current batch
                                        if (value === '') {
                                            setBatchInputValue((currentBatchIndex + 1).toString());
                                            return;
                                        }
                                        
                                        // Parse and validate
                                        let newBatchIndex = parseInt(value) - 1;
                                        let newValue;
                                        
                                        if (isNaN(newBatchIndex) || newBatchIndex < 0) {
                                            newValue = "1"; // Default to first batch
                                            newBatchIndex = 0;
                                        } else if (newBatchIndex >= maxBatches) {
                                            newValue = maxBatches.toString(); // Cap at max batch
                                            newBatchIndex = maxBatches - 1;
                                        } else {
                                            newValue = (newBatchIndex + 1).toString();
                                        }
                                        
                                        setBatchInputValue(newValue);
                                        
                                        // Navigate to the new batch if it's different
                                        if (newBatchIndex !== currentBatchIndex) {
                                            navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions/group/${newBatchIndex}`, {
                                                state: {
                                                    students: students,
                                                    allStudents: allStudents,
                                                    initialSubQuestion: currentQuestion,
                                                    jumpToBatch: true
                                                },
                                                replace: true
                                            });
                                            setCurrentBatchIndex(newBatchIndex);
                                        }
                                    }}
                                />
                            </div>
                            <span>of {Math.ceil(allStudents.length / 10)}</span>
                            <span className="text-gray-500">({allStudents.length} submissions)</span>
                        </div>

                        <div className="flex items-center space-x-2">
                            <button
                                className={`flex items-center space-x-2 py-1.5 px-3 rounded-lg transition-all duration-200 ${
                                    markingBatchAsViewed ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-gray-100'
                                } ${allCurrentBatchViewed ? 'text-green-600' : 'text-gray-600'}`}
                                disabled={markingBatchAsViewed || allCurrentBatchViewed}
                                onClick={markBatchAsViewed}
                            >
                                <div className={`flex items-center justify-center w-5 h-5 rounded-full ${
                                    allCurrentBatchViewed ? 'bg-green-100' : 'bg-gray-100'
                                }`}>
                                    {allCurrentBatchViewed ? (
                                        <Check size={12} className="text-green-600"/>
                                    ) : (
                                        <span className="text-xs text-gray-500">✓</span>
                                    )}
                                </div>
                                <span className="text-sm">
                  Mark as viewed
                                    {markingBatchAsViewed && (
                                        <span className="ml-1 inline-block">
                      <span className="w-3 h-3 rounded-full animate-pulse bg-gray-400 inline-block"></span>
                    </span>
                                    )}
                </span>
                            </button>
                        </div>

                        <div className="flex items-center space-x-1">
                            <button
                                disabled={currentBatchIndex === 0}
                                className={`text-gray-600 bg-black/10 hover:bg-gray-200 font-medium flex items-center rounded-full p-1 ${currentBatchIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                                onClick={() => navigateToBatch('prev')}
                            >
                                <ChevronLeft size={16}/>
                            </button>
                            <button
                                disabled={currentBatchIndex >= Math.ceil(allStudents.length / 10) - 1}
                                className={`text-gray-600 bg-black/10 hover:bg-gray-200 font-medium flex items-center rounded-full p-1 ${currentBatchIndex >= Math.ceil(allStudents.length / 10) - 1 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                                onClick={() => navigateToBatch('next')}
                            >
                                <ChevronRight size={16}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BatchNavigation;