import {Star, Trash2} from "lucide-react";
import React from "react";
import {InlineMath} from 'react-katex';
import OptionsList from './OptionsList';
import {default as formatQuestionId} from './formatQuestionId';
import EditableText from './EditableText';

const SubQuestions = ({
                          subquestions,
                          questionId,
                          onSubquestionTextChange,
                          onSubquestionSolutionChange,
                          onSubMCQOptionTextChange,
                          onSubMCQOptionCorrectChange,
                          onAddSubMCQOption,
                          onDeleteSubMCQOption,
                          onDeleteSubquestion,
                          onSubquestionTypeChange,
                      }) => {

    return (
        <div className="subquestions">
            {subquestions.map((sub, subIndex) => {
                return (
                    <div
                        key={sub.letter}
                        className={`subquestion ${sub.type}`}
                        style={{"--sub-animation-delay": `${subIndex * 0.1}s`}}
                    >
                        <div className="subquestion-header">
                            <div className="letter-badge">
                                {formatQuestionId(questionId, sub.letter)}
                            </div>
                            <div 
                                className="type-badge"
                                onClick={() => {
                                    // Toggle between mcq and freeform
                                    const newType = sub.type === "mcq" ? "freeform" : "mcq";
                                    // The parent component should have a handler for type changes
                                    if (typeof onSubquestionTypeChange === 'function') {
                                        onSubquestionTypeChange(subIndex, newType);
                                    }
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {sub.type === "mcq" ? "Multiple Choice" : "Free Response"}
                            </div>
                            <div className="subquestion-actions">
                                <button
                                    className="delete-btn"
                                    aria-label="Delete subquestion"
                                    onClick={() => onDeleteSubquestion && onDeleteSubquestion(subIndex)}
                                >
                                    <div className="icon-wrapper">
                                        <Trash2 className="w-4 h-4"/>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div className="subquestion-content">
                            <div className="question-text">
                                <EditableText
                                    text={sub.text}
                                    className="question-text-content"
                                    placeholder="Enter subquestion text..."
                                    multiline={true}
                                    onSave={(newText) => onSubquestionTextChange && onSubquestionTextChange(subIndex, newText)}
                                />
                            </div>

                            {sub.type === "mcq" && (
                                <div className="mcq-options-container">
                                    <OptionsList
                                        options={sub.options || sub.mcqOptions || []}
                                        correctAnswer={sub.correctAnswer || (sub.mcqOptions ?
                                            sub.mcqOptions
                                                .map((opt, idx) => opt.isCorrect ? String.fromCharCode(65 + idx) : null)
                                                .filter(Boolean) : [])}
                                        subIndex={subIndex}
                                        onOptionTextChange={onSubMCQOptionTextChange}
                                        onOptionCorrectChange={onSubMCQOptionCorrectChange}
                                        onAddOption={() => onAddSubMCQOption && onAddSubMCQOption(subIndex)}
                                        onDeleteOption={(subIdx, optionIndex) => onDeleteSubMCQOption && onDeleteSubMCQOption(subIndex, optionIndex)}
                                    />
                                </div>
                            )}

                            {sub.type === "freeform" && (
                                <div
                                    className="solution-wrapper"
                                    id={`solution-${questionId}-${subIndex}`}
                                >
                                    <div className="solution">
                                        <div className="solution-header">
                                            <Star className="w-4 h-4"/>
                                            <span>Solution</span>
                                        </div>
                                        <div className="solution-content">
                                            <EditableText
                                                text={sub.solution}
                                                className="solution-content-text"
                                                placeholder="Enter solution..."
                                                multiline={true}
                                                onSave={(newSolution) => onSubquestionSolutionChange && onSubquestionSolutionChange(subIndex, newSolution)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SubQuestions;