import {FieldArray} from 'formik';
import {default as React} from 'react';
import {SQuestionType} from './SQuestionType';

export function SubQuestion({subQuestions, fieldName, disabledStyle, isDisabled}) {
    return (
        <FieldArray name={fieldName}>
            {() => (
                <div>
                    {subQuestions.map((subQuestion, optionIndex) => (
                        <SQuestionType
                            key={`${subQuestion.id}-${optionIndex}`}
                            questionPoint={`${String.fromCharCode(subQuestion.position ? 96 + subQuestion.position : 97 + optionIndex)}) ${subQuestion.question}`}
                            disabledStyle={disabledStyle}
                            isDisabled={isDisabled}
                            index={optionIndex}
                            question={subQuestion}
                            fieldName={fieldName}
                            isSubQuestion={true}
                        />
                    ))}
                </div>
            )}
        </FieldArray>
    );
}
