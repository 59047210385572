import React, {useState} from "react";
import {FileQuestion, Plus, Trash2} from "lucide-react";
import {InlineMath} from 'react-katex';
import SubQuestions from './Subquestions';
import OptionsList from './OptionsList';
import {default as formatQuestionId} from './formatQuestionId';
import EditableText from './EditableText';

const QuestionCard = ({
                          id,
                          question,
                          extractedQuestions,
                          setExtractedQuestions,
                          uploadQuestions
                      }) => {

    const [editedQuestion, setEditedQuestion] = useState(question);
    console.log('Initial question data:', question, 'Edited question:', editedQuestion);


    const handleSave = (updatedQuestion) => {
        const updatedExtractedQuestions = {
            ...extractedQuestions,
            [id]: updatedQuestion
        };
        setExtractedQuestions(updatedExtractedQuestions);
        console.log("uploading questions");
        uploadQuestions(updatedExtractedQuestions);
    };

    const handleQuestionTextChange = (newText) => {
        const updatedQuestion = {
            ...editedQuestion,
            questionText: newText
        };
        setEditedQuestion(updatedQuestion);
        handleSave(updatedQuestion);
    };


    const handleSubMCQOptionChange = (subIndex, optionIndex, isCorrect) => {
        console.log('Changing MCQ option correctness:', subIndex, optionIndex, isCorrect);
        console.log('Current options:', editedQuestion.subquestions[subIndex].options);

        const updatedSubquestions = [...editedQuestion.subquestions];
        const subquestion = updatedSubquestions[subIndex];

        // Make sure options is an array
        if (!Array.isArray(subquestion.options)) {
            console.log('Options is not an array');
            return;
        }

        try {
            // We're using simple string options now, so we directly work with the correctAnswer array
            const letter = String.fromCharCode(65 + optionIndex);
            let correctAnswer = Array.isArray(subquestion.correctAnswer) ? [...subquestion.correctAnswer] : [];

            if (isCorrect && !correctAnswer.includes(letter)) {
                correctAnswer.push(letter);
            } else if (!isCorrect && correctAnswer.includes(letter)) {
                correctAnswer = correctAnswer.filter(ans => ans !== letter);
            }

            updatedSubquestions[subIndex] = {
                ...subquestion,
                correctAnswer
            };

            const updatedQuestion = {
                ...editedQuestion,
                subquestions: updatedSubquestions
            };
            setEditedQuestion(updatedQuestion);
            handleSave(updatedQuestion);
        } catch (error) {
            console.error('Error updating MCQ option:', error);
        }
    };

    const handleSubMCQOptionTextChange = (subIndex, optionIndex, text) => {
        console.log('Updating MCQ option text:', subIndex, optionIndex, text);

        const updatedSubquestions = [...editedQuestion.subquestions];
        const subquestion = updatedSubquestions[subIndex];

        if (Array.isArray(subquestion.options)) {
            // Simple, direct update - just replace the string at the given index
            const updatedOptions = [...subquestion.options];
            updatedOptions[optionIndex] = text;
            
            updatedSubquestions[subIndex] = {
                ...subquestion,
                options: updatedOptions
            };
        }

        console.log('Updated subquestion:', updatedSubquestions[subIndex]);

        const updatedQuestion = {
            ...editedQuestion,
            subquestions: updatedSubquestions
        };

        setEditedQuestion(updatedQuestion);
        handleSave(updatedQuestion);
    };

    const handleAddSubMCQOption = (subIndex) => {
        console.log('Adding MCQ option to subquestion', subIndex);

        const updatedSubquestions = [...editedQuestion.subquestions];
        const subquestion = updatedSubquestions[subIndex];

        // Check if options array exists and has valid format
        if (Array.isArray(subquestion.options) && subquestion.options.length > 0) {
            const option = subquestion.options[0];

            // Convert existing options to strings and add a new empty string option
            console.log('Adding new option as a string');

            // First convert any existing options to strings if they're not already
            const standardizedOptions = subquestion.options.map(opt => {
                if (typeof opt === 'string') {
                    return opt;
                }
                if (typeof opt === 'object' && opt !== null) {
                    if (opt.hasOwnProperty('text')) {
                        return opt.text;
                    }
                    if (opt.hasOwnProperty('0')) {
                        return opt['0'];
                    }
                }
                return '';
            });

            // Add a new empty option
            updatedSubquestions[subIndex] = {
                ...subquestion,
                options: [...standardizedOptions, '']
            };
        } else {
            // Initialize with simple string format
            console.log('Initializing options array with simple string format');
            updatedSubquestions[subIndex] = {
                ...subquestion,
                options: ['Option A', 'Option B']
            };
        }

        // Save the updated question
        const updatedQuestion = {
            ...editedQuestion,
            subquestions: updatedSubquestions
        };

        setEditedQuestion(updatedQuestion);
        handleSave(updatedQuestion);
    };

    const handleDeleteSubMCQOption = (subIndex, optionIndex) => {
        console.log('Deleting MCQ option:', subIndex, optionIndex);
        console.log('Current options:', editedQuestion.subquestions[subIndex].options);

        // Make deep copy to ensure we're not working with references
        const updatedSubquestions = JSON.parse(JSON.stringify(editedQuestion.subquestions));
        const subquestion = updatedSubquestions[subIndex];

        if (subquestion.options && Array.isArray(subquestion.options)) {
            // Make sure there are more than 2 options before allowing deletion
            if (subquestion.options.length <= 2) {
                console.log('Minimum 2 options required, not deleting');
                return;
            }

            // Remove option at exact index
            console.log('Removing option at index:', optionIndex);
            const updatedOptions = [...subquestion.options];
            updatedOptions.splice(optionIndex, 1);

            console.log('Options before deletion:', subquestion.options);
            console.log('Options after deletion:', updatedOptions);

            // Update the subquestion with new options
            updatedSubquestions[subIndex] = {
                ...subquestion,
                options: updatedOptions
            };

            // Create the updated question object
            const updatedQuestion = {
                ...editedQuestion,
                subquestions: updatedSubquestions
            };

            // Save changes
            console.log('Final updated question:', updatedQuestion);
            setEditedQuestion(updatedQuestion);
            handleSave(updatedQuestion);
        }
    };

    const handleMCQOptionChange = (optionIndex, isCorrect) => {
        console.log(optionIndex, isCorrect);
        console.log('Changing MCQ option correctness:', optionIndex, isCorrect);

        // Ensure options exists
        if (!editedQuestion.options || !Array.isArray(editedQuestion.options)) {
            console.log('options does not exist or is not an array');
            return;
        }

        // Generate the letter for this option (A, B, C, etc.)
        const letter = String.fromCharCode(65 + optionIndex);
        
        // Update correctAnswer array to reflect the current selection
        let correctAnswer = Array.isArray(editedQuestion.correctAnswer) ? 
            [...editedQuestion.correctAnswer] : [];
            
        if (isCorrect && !correctAnswer.includes(letter)) {
            // Add this letter to correct answers
            correctAnswer.push(letter);
        } else if (!isCorrect && correctAnswer.includes(letter)) {
            // Remove this letter from correct answers
            correctAnswer = correctAnswer.filter(ans => ans !== letter);
        }
        
        console.log('Updated correctAnswer array:', correctAnswer);

        // Save the updated question with the modified correctAnswer
        const updatedQuestion = {
            ...editedQuestion,
            correctAnswer
        };

        setEditedQuestion(updatedQuestion);
        handleSave(updatedQuestion);
    };

    const handleMCQOptionTextChange = (optionIndex, text) => {
        console.log('Changing MCQ option text:', optionIndex, text);

        // Ensure options exists
        if (!editedQuestion.options || !Array.isArray(editedQuestion.options)) {
            console.log('options does not exist or is not an array');
            return;
        }

        // Simply replace the string at the specified index
        const updatedOptions = [...editedQuestion.options];
        updatedOptions[optionIndex] = text;

        console.log('Updated options after text change:', updatedOptions);

        // Save the updated question with the modified option text
        const updatedQuestion = {
            ...editedQuestion,
            options: updatedOptions
        };

        setEditedQuestion(updatedQuestion);
        handleSave(updatedQuestion);
    };

    const handleAddMCQOption = () => {
        console.log('Adding MCQ option, current options:', editedQuestion.options);
        // Ensure options exists
        if (!editedQuestion.options) {
            console.log('options does not exist, initializing');
            const updatedQuestion = {
                ...editedQuestion,
                options: ['Option A', 'Option B'],
                correctAnswer: ['A'] // Initialize with first option as correct
            };
            setEditedQuestion(updatedQuestion);
            handleSave(updatedQuestion);
            return;
        }

        const updatedOptions = [...(editedQuestion.options || [])];
        updatedOptions.push('');
        console.log('Updated options:', updatedOptions);
        const updatedQuestion = {
            ...editedQuestion,
            options: updatedOptions
        };
        setEditedQuestion(updatedQuestion);
        handleSave(updatedQuestion);
    };

    const handleDeleteMCQOption = (optionIndex) => {
        // Ensure we have more than 2 options before allowing deletion
        if (!editedQuestion.options || editedQuestion.options.length <= 2) {
            return;
        }

        const updatedOptions = [...editedQuestion.options];
        updatedOptions.splice(optionIndex, 1);
        const updatedQuestion = {
            ...editedQuestion,
            options: updatedOptions
        };
        setEditedQuestion(updatedQuestion);
        handleSave(updatedQuestion);
    };

    // New function to add a subquestion
    const handleAddSubquestion = () => {
        const newSubquestions = [...editedQuestion.subquestions];
        const nextLetter = String.fromCharCode(97 + newSubquestions.length);

        newSubquestions.push({
            letter: nextLetter,
            text: '',
            type: 'freeform',
            solution: '',
            options: [],
            correctAnswer: null
        });

        setEditedQuestion({
            ...editedQuestion,
            subquestions: newSubquestions
        });
    };

    // New function to delete a subquestion
    const handleDeleteSubquestion = (subIndex) => {
        const newSubquestions = [...editedQuestion.subquestions];

        // Remove the subquestion at the specified index
        newSubquestions.splice(subIndex, 1);

        // Update the letters for all subsequent subquestions
        for (let i = subIndex; i < newSubquestions.length; i++) {
            newSubquestions[i].letter = String.fromCharCode(97 + i);
        }

        setEditedQuestion({
            ...editedQuestion,
            subquestions: newSubquestions
        });
    };

    // Function to change subquestion type (e.g., from freeform to MCQ)
    const handleSubquestionTypeChange = (subIndex, newType) => {
        const newSubquestions = [...editedQuestion.subquestions];
        newSubquestions[subIndex].type = newType;

        // Initialize options if changing to MCQ type - using simple strings
        if (newType === 'mcq' && (!newSubquestions[subIndex].options || newSubquestions[subIndex].options.length === 0)) {
            newSubquestions[subIndex].options = ['Option A', 'Option B'];
            // Initialize correctAnswer to track which options are correct
            newSubquestions[subIndex].correctAnswer = ['A'];
        }

        setEditedQuestion({
            ...editedQuestion,
            subquestions: newSubquestions
        });
    };

    // Function to toggle multiple correct answers for a subquestion
    const toggleSubMultipleCorrect = (subIndex) => {
        const newSubquestions = [...editedQuestion.subquestions];
        const subquestion = newSubquestions[subIndex];

        subquestion.allowMultipleCorrect = !subquestion.allowMultipleCorrect;

        // If turning off multiple correct and multiple are selected, keep only the first correct one
        if (!subquestion.allowMultipleCorrect) {
            const correctIndices = subquestion.options
                .map((opt, idx) => opt.isCorrect ? idx : -1)
                .filter(idx => idx !== -1);

            if (correctIndices.length > 1) {
                subquestion.options = subquestion.options.map((opt, idx) => ({
                    ...opt,
                    isCorrect: idx === correctIndices[0]
                }));
            }
        }

        setEditedQuestion({
            ...editedQuestion,
            subquestions: newSubquestions
        });
    };

    // New function to delete the entire question
    const handleDeleteQuestion = () => {
        // Make a copy of the current questions
        const updatedExtractedQuestions = {...extractedQuestions};
        // Delete the current question
        delete updatedExtractedQuestions[id];
        
        // Set the updated questions without renumbering
        setExtractedQuestions(updatedExtractedQuestions);
        uploadQuestions(updatedExtractedQuestions);
    };

    const QuestionText = ({text, onSave}) => {
        return (
            <div className="question-text-wrapper">
                <FileQuestion className="question-icon"/>
                <EditableText
                    text={text}
                    className="question-text"
                    placeholder="Enter question text..."
                    multiline={true}
                    onSave={onSave}
                />
            </div>
        );
    };

    return (
        <div
            id={`question-${id}`}
            className="question-card"
        >
            <div className="question-header">
                <div className="header-content">
                    <h3>
                        <span className="question-number">{formatQuestionId(id)}</span>
                    </h3>
                    <div className="action-buttons">
                        <button
                            className="delete-btn"
                            aria-label="Delete question"
                            onClick={handleDeleteQuestion}
                        >
                            <div className="icon-wrapper">
                                <Trash2 className="w-4 h-4"/>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div className="question-content">
                <QuestionText
                    text={editedQuestion.questionText}
                    onSave={handleQuestionTextChange}
                />
                {editedQuestion.type === "mcq" && (
                    <OptionsList
                        options={editedQuestion.options || []}
                        correctAnswer={editedQuestion.options
                            ?.map((opt, idx) => opt.isCorrect ? String.fromCharCode(65 + idx) : null)
                            ?.filter(Boolean) || []}
                        onOptionTextChange={handleMCQOptionTextChange}
                        onOptionCorrectChange={handleMCQOptionChange}
                        onAddOption={handleAddMCQOption}
                        onDeleteOption={handleDeleteMCQOption}
                    />
                )}
                {editedQuestion.subquestions && (
                    <>
                        <SubQuestions
                            subquestions={editedQuestion.subquestions}
                            questionId={id}
                            onSubquestionTextChange={(subIndex, newText) => {
                                const updatedSubquestions = [...editedQuestion.subquestions];
                                updatedSubquestions[subIndex].text = newText;
                                const updatedQuestion = {
                                    ...editedQuestion,
                                    subquestions: updatedSubquestions
                                };
                                setEditedQuestion(updatedQuestion);
                                handleSave(updatedQuestion);
                            }}
                            onSubquestionSolutionChange={(subIndex, newSolution) => {
                                const updatedSubquestions = [...editedQuestion.subquestions];
                                updatedSubquestions[subIndex].solution = newSolution;
                                const updatedQuestion = {
                                    ...editedQuestion,
                                    subquestions: updatedSubquestions
                                };
                                setEditedQuestion(updatedQuestion);
                                handleSave(updatedQuestion);
                            }}
                            onSubquestionTypeChange={handleSubquestionTypeChange}
                            onSubMCQOptionTextChange={handleSubMCQOptionTextChange}
                            onSubMCQOptionCorrectChange={handleSubMCQOptionChange}
                            onAddSubMCQOption={handleAddSubMCQOption}
                            onDeleteSubMCQOption={handleDeleteSubMCQOption}
                            onToggleSubMultipleCorrect={toggleSubMultipleCorrect}
                            onDeleteSubquestion={handleDeleteSubquestion}
                        />
                        <button
                            className="add-subquestion-btn"
                            onClick={handleAddSubquestion}
                        >
                            <Plus className="w-4 h-4"/>
                            <span>Add Subquestion</span>
                        </button>
                    </>
                )}
            </div>
        </div>
    );

};

export default QuestionCard;