import React, { useState, useEffect } from 'react';
import { gradeApi } from '../../../api';
import { useAuth } from '../../../auth/AuthContext';
import { SQuestionType } from '../../Student/SUnpublishedFlexibleAssignment/SQuestionType';
import { FormikProvider, Form, useFormik } from 'formik';

// Standalone component for flexible assignments that doesn't use Redux
const StandaloneFlexibleAssignment = ({ 
    studentId, 
    assignmentId, 
    questionId = null 
}) => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submissionData, setSubmissionData] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [completionStatus, setCompletionStatus] = useState({});
    // No need for sidebar or focus mode in the standalone component

    // Fetch submission data directly
    useEffect(() => {
        const fetchSubmissionData = async () => {
            if (!studentId || !assignmentId) {return;}
            
            setLoading(true);
            setError(null);
            
            try {
                const path = questionId
                    ? `/api/v2/assignments/${assignmentId}/submission/${questionId}/flexible-problem-set`
                    : `/api/v2/assignments/${assignmentId}/submission/flexible-problem-set`;
                
                console.log(`Fetching data for student: ${studentId}, assignment: ${assignmentId}`);
                
                const response = await gradeApi.get({
                    path,
                    config: {
                        headers: { Authorization: `Bearer ${user.session_token}` },
                        params: { studentId }
                    }
                });
                
                if (response.data) {
                    setSubmissionData(response.data);
                    
                    // Calculate completion status if examPaper exists
                    if (response.data.examPaper) {
                        const status = {};
                        response.data.examPaper.forEach((question, index) => {
                            status[index] = isQuestionCompleted(question);
                        });
                        
                        setCompletionStatus(status);
                        
                        // If questionId is provided, find and set the current question index
                        if (questionId) {
                            const questionIndex = response.data.examPaper.findIndex(q => q.id === questionId);
                            if (questionIndex !== -1) {
                                setCurrentQuestionIndex(questionIndex);
                            }
                        }
                    } else {
                        setError('No exam paper data available');
                    }
                } else {
                    setError('No submission data available');
                }
            } catch (err) {
                console.error('Error fetching submission data:', err);
                setError('Failed to load submission data');
            } finally {
                setLoading(false);
            }
        };
        
        fetchSubmissionData();
    }, [studentId, assignmentId, questionId, user.session_token]);

    // Check if a question is completed
    const isQuestionCompleted = (question) => {
        if (!question) {return false;}
        
        if (question.questionType === 'text') {
            return !!question.answerText?.trim();
        } else if (question.questionType === 'mcq') {
            return !!question.answerMcqOptionId;
        } else if (question.questionType === 'image') {
            return !!question.answerImageS3Key;
        }
        
        // Check subquestions if they exist
        if (question.subQuestions && question.subQuestions.length > 0) {
            const completedSubQuestions = question.subQuestions.filter(subQ => isQuestionCompleted(subQ));
            return completedSubQuestions.length === question.subQuestions.length;
        }
        
        return false;
    };
    
    // Calculate total completed questions
    const calculateTotalCompleted = () => {
        if (!submissionData.examPaper) {return 0;}
        return Object.values(completionStatus).filter(status => status).length;
    };

    // Navigate to a specific question
    const navigateToQuestion = (index) => {
        setCurrentQuestionIndex(index);
    };
    
    // Setup formik (needed for SQuestionType)
    const formik = useFormik({
        initialValues: {},
        onSubmit: () => {}
    });

    if (loading) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <div className="text-center">
                    <div className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full animate-spin mx-auto"></div>
                    <p className="mt-3 text-gray-600">Loading submission for student {studentId}...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <div className="text-center p-4">
                    <p className="text-red-500 font-medium">Error: {error}</p>
                    <p className="text-gray-600 mt-2">Please try refreshing the page.</p>
                </div>
            </div>
        );
    }

    if (!submissionData.examPaper || submissionData.examPaper.length === 0) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <div className="text-center p-4">
                    <p className="text-gray-600">No submission data available for this student.</p>
                </div>
            </div>
        );
    }
    
    const examPaper = submissionData.examPaper;
    const totalCompleted = calculateTotalCompleted();
    const currentQuestion = examPaper[currentQuestionIndex];

    // Render subquestions if any
    const renderSubQuestions = (subQuestions) => {
        if (!subQuestions || subQuestions.length === 0) {return null;}
        
        return (
            <div className="pl-8 mt-4">
                {subQuestions.map((subQuestion, subIndex) => (
                    <div key={`sub-${subQuestion.id}-${subIndex}`} className="mb-4">
                        <SQuestionType
                            question={subQuestion}
                            questionPoint={subQuestion.question}
                            fieldName="examPaper"
                            index={subIndex}
                            isDisabled={true}
                            disabledStyle="opacity-80"
                            isSubQuestion={true}
                        />
                    </div>
                ))}
            </div>
        );
    };

    // Simple question navigation
    const QuestionNav = () => (
        <div className="flex items-center justify-center mb-4 space-x-2">
            {examPaper.map((_, index) => (
                <button
                    key={index}
                    onClick={() => navigateToQuestion(index)}
                    className={`w-8 h-8 rounded-full flex items-center justify-center ${currentQuestionIndex === index ? 'bg-orange-500 text-white' : 'bg-gray-100 text-gray-800'}`}
                >
                    {index + 1}
                </button>
            ))}
        </div>
    );

    return (
        <FormikProvider value={formik}>
            <Form>
                <div className="flex flex-col h-full bg-white relative">
                    <div className="px-5 py-4">
                        {/* Simple question navigation */}
                        <QuestionNav />
                        
                        {/* Render current question using SQuestionType */}
                        <div>
                            <SQuestionType
                                question={examPaper[currentQuestionIndex]}
                                questionPoint={examPaper[currentQuestionIndex].question}
                                fieldName="examPaper"
                                index={currentQuestionIndex}
                                isDisabled={true}
                                disabledStyle="opacity-80"
                                isSubQuestion={false}
                            />
                            
                            {/* Render subquestions if any */}
                            {renderSubQuestions(examPaper[currentQuestionIndex].subQuestions)}
                        </div>
                    </div>
                </div>
            </Form>
        </FormikProvider>
    );
};

export default StandaloneFlexibleAssignment;
