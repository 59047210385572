import React, { useEffect } from "react";

const useStateLogger = ({ isDrawMode, isDrawing, currentBox, startPoint }) => {
  // Debug logging for state changes
  useEffect(() => {
    console.log("Drawing Mode:", isDrawMode);
  }, [isDrawMode]);

  useEffect(() => {
    console.log("Is Drawing:", isDrawing);
  }, [isDrawing]);

  useEffect(() => {
    console.log("Current Box:", currentBox);
  }, [currentBox]);

  useEffect(() => {
    console.log("Start Point:", startPoint);
  }, [startPoint]);
};

export default useStateLogger;
