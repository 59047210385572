import React from 'react';
import {X, Download} from 'lucide-react';
import './styling/PDFPreviewModal.scss';

const PDFPreviewModal = ({isOpen, onClose, pdfUrl}) => {
    if (!isOpen) {
        console.log('not open');
        return null;
    }

    return (
        <div className="pdf-preview-modal">
            <div className="modal-overlay" onClick={onClose}/>
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Generated PDF Preview</h2>
                    <div className="modal-actions">
                        <a
                            href={pdfUrl}
                            download="generated-template.pdf"
                            className="download-btn"
                        >
                            <Download size={16}/>
                            Download
                        </a>
                        <button className="close-btn" onClick={onClose}>
                            <X size={20}/>
                        </button>
                    </div>
                </div>
                <div className="modal-body">
                    <iframe
                        src={pdfUrl}
                        title="PDF Preview"
                        className="pdf-preview"
                    />
                </div>
            </div>
        </div>
    );
};

export default PDFPreviewModal; 