// import { getDocument } from "pdfjs-dist/build/pdf";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// const pdfjsLib = require("pdfjs-dist/build/pdf");
// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// export const pdfToImage = async ({
//   file,
//   scale = 2,
//   pageNumber = 1,
// }) => {
//   const pdf = await getDocument(file).promise;
//   const totalPages = pdf.numPages;
//   if (pageNumber > totalPages || pageNumber < 1) {
//     console.log('page number out of bounds');
//     pageNumber = 1;
//   }

//   const page = await pdf.getPage(pageNumber);
//   const viewport = page.getViewport({ scale }); // Adjust scale as needed
//   const canvas = document.createElement("canvas");
//   const context = canvas.getContext("2d");
//   canvas.width = viewport.width;
//   canvas.height = viewport.height;

//   await page.render({ canvasContext: context, viewport }).promise;

//   // Convert canvas to data URL
//   const imgDataUrl = canvas.toDataURL("image/png");

//   return imgDataUrl;
// };

// export default pdfToImage;

import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';

// Configure the worker source
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

// Updated pdfToImage function to render all pages for scrolling
export const pdfToImage = async ({
                                     file,
                                     scale = 1.5,
                                     container = null,
                                     onProgress = null,
                                     onComplete = null
                                 }) => {
    try {

        console.log('pdfToImage called');
        console.log('pdfToImag called');

        if (!container) {
            throw new Error("Container element is required for scrollable PDF");
        }
        console.log('container:', container);
        console.log('container:', container);

        // Clear the container
        container.innerHTML = '';
        container.classList.add('pdf-scroll-container');

        // Load the PDF document
        const pdf = await pdfjsLib.getDocument(file).promise;
        const totalPages = pdf.numPages;

        // Set height to auto for scrolling
        container.style.overflowY = 'auto';
        container.style.height = '100%';

        // Create a div to hold all the pages
        const pagesContainer = document.createElement('div');
        pagesContainer.classList.add('pdf-pages-container');
        container.appendChild(pagesContainer);

        // Create a loading indicator
        const loadingIndicator = document.createElement('div');
        loadingIndicator.classList.add('pdf-loading-indicator');
        loadingIndicator.textContent = 'Loading pages...';
        container.appendChild(loadingIndicator);

        // Process each page
        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
            // Create page container
            const pageContainer = document.createElement('div');
            pageContainer.classList.add('pdf-page');
            pageContainer.setAttribute('data-page-number', pageNum);
            pagesContainer.appendChild(pageContainer);

            // Get the page
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({scale});

            // Create canvas for this page
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            // Add canvas to page container
            pageContainer.appendChild(canvas);

            // Add page number indicator
            const pageIndicator = document.createElement('div');
            pageIndicator.classList.add('pdf-page-number');
            pageIndicator.textContent = `Page ${pageNum}`;
            pageContainer.appendChild(pageIndicator);

            // Render page to canvas
            await page.render({canvasContext: context, viewport}).promise;

            // Update progress if callback provided
            if (onProgress && typeof onProgress === 'function') {
                onProgress(pageNum, totalPages);
            }
        }

        // Remove loading indicator
        container.removeChild(loadingIndicator);

        // Call complete callback if provided
        if (onComplete && typeof onComplete === 'function') {
            onComplete(totalPages);
        }

        return totalPages;
    } catch (error) {
        console.error("Error converting PDF to scrollable images:", error);
        throw error;
    }
};

// Keep the original function for backward compatibility
export const pdfToSingleImage = async ({
                                           file,
                                           scale = 2,
                                           pageNumber = 1,
                                       }) => {
    try {
        const pdf = await pdfjsLib.getDocument(file).promise;
        const totalPages = pdf.numPages;

        // Validate page number
        const validPageNumber = pageNumber > totalPages || pageNumber < 1
            ? 1
            : pageNumber;

        const page = await pdf.getPage(validPageNumber);
        const viewport = page.getViewport({scale});

        // Create canvas
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Render page to canvas
        await page.render({canvasContext: context, viewport}).promise;

        // Convert canvas to data URL
        return canvas.toDataURL("image/png");
    } catch (error) {
        console.error("Error converting PDF to image:", error);
        throw error;
    }
};

export default pdfToImage;