import {Viewer} from '@react-pdf-viewer/core';
import {toolbarPlugin} from '@react-pdf-viewer/toolbar';
import React, {useEffect, useRef, useState} from 'react';
import {pdfjs} from 'react-pdf';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

// Set worker path using the recommended approach
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

export const PDFView = ({url, initialPage = 0, onPageChange, className}) => {
    // Use a state to track if the component is mounted
    const [isMounted, setIsMounted] = useState(false);

    // State to track if we need to reload the viewer
    const [reloadTrigger, setReloadTrigger] = useState(0);

    // Create a persistent viewer key to force remounting when needed
    const viewerKey = useRef(`pdf-viewer-${Date.now()}`).current + `-${reloadTrigger}`;

    // Always create toolbar plugin (consistent hook ordering)
    const toolbarPluginInstance = useRef(toolbarPlugin()).current;
    const {Toolbar, renderDefaultToolbar} = toolbarPluginInstance;

    // Reference to store the current document
    const documentRef = useRef(null);

    // Reference to any active loading task
    const loadingTaskRef = useRef(null);

    // Track visibility changes
    const wasHiddenRef = useRef(false);

    // PDF.js options
    const options = {
        cMapUrl: 'https://unpkg.com/pdfjs-dist@3.11.174/cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'https://unpkg.com/pdfjs-dist@3.11.174/standard_fonts/',
        disableAutoFetch: false, // Changed to improve loading performance
        disableTelemetry: true,
        ignoreErrors: true,
    };

    // Enhanced cleanup function with direct worker destruction
    const cleanupDocument = () => {
        try {
            // First properly destroy the loading task and its worker
            if (loadingTaskRef.current) {
                try {
                    // Directly destroy the worker first - this is the key fix
                    if (loadingTaskRef.current._worker && !loadingTaskRef.current._worker.destroyed) {
                        loadingTaskRef.current._worker.destroy();
                    }

                    // Then destroy the loading task
                    if (!loadingTaskRef.current.destroyed) {
                        loadingTaskRef.current.destroy();
                    }
                } catch (e) {
                    console.error('Error destroying PDF loading task:', e);
                }
                loadingTaskRef.current = null;
            }

            if (documentRef.current) {
                // Try to cleanup the document
                if (documentRef.current.cleanup && typeof documentRef.current.cleanup === 'function') {
                    try {
                        documentRef.current.cleanup();
                    } catch (e) {
                        console.error('Error cleaning up PDF document:', e);
                    }
                }

                // Try to destroy the document
                if (documentRef.current.destroy && typeof documentRef.current.destroy === 'function') {
                    try {
                        documentRef.current.destroy();
                    } catch (e) {
                        console.error('Error destroying PDF document:', e);
                    }
                }

                // Clear the reference
                documentRef.current = null;
            }
        } catch (e) {
            console.error('Document cleanup error:', e);
        }
    };

    // Before unloading the page - ensure PDF is cleaned up
    const handleBeforeUnload = () => {
        cleanupDocument();
    };

    // Handle page visibility changes
    const handleVisibilityChange = () => {
        if (document.hidden) {
            // Page is being hidden - mark this state
            wasHiddenRef.current = true;
        } else if (wasHiddenRef.current) {
            // Page was hidden and is now visible again - reload if needed
            wasHiddenRef.current = false;

            // Check if we need to recover from worker termination
            if (documentRef.current === null && url) {
                // Force remount of the viewer component
                setReloadTrigger(prev => prev + 1);
            }
        }
    };

    // Clean up on unmount and handle navigation
    useEffect(() => {
        // Set mounted flag
        setIsMounted(true);

        // Add event listeners for navigation and visibility
        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            // Component is unmounting
            setIsMounted(false);

            // Remove event listeners
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener('visibilitychange', handleVisibilityChange);

            // Clean up document
            cleanupDocument();
        };
    }, []);

    // Create a new effect specifically for URL changes
    useEffect(() => {
        if (url && isMounted) {
            // When URL changes, clean up the previous document
            cleanupDocument();
        }
    }, [url, isMounted]);

    // Error handler with recovery mechanism
    const handleError = (error) => {
        console.error('PDF viewer error:', error);

        if (error.message && error.message.includes('Worker was terminated')) {
            // Force remount of the viewer component to recover
            setTimeout(() => {
                if (isMounted) {
                    cleanupDocument();
                    setReloadTrigger(prev => prev + 1);
                }
            }, 0);
        }
    };

    // Transform function to hide unwanted toolbar buttons
    const transform = (slot) => ({
        ...slot,
        SwitchTheme: () => <></>,
        Open: () => <></>,
        Print: () => <></>
    });

    // Handler for document load
    const handleDocumentLoad = (document) => {
        // Only store reference if component is mounted
        if (isMounted) {
            // Store reference to document for cleanup
            documentRef.current = document;
        }
    };

    // Handler for page changes - defined consistently
    const handlePageChange = (pageIndex) => {
        if (onPageChange && isMounted) {
            onPageChange(pageIndex + 1);
        }
    };

    // If no URL, render placeholder but maintain hook structure
    if (!url) {
        return (
            <div className={`w-full h-full flex flex-col ${className || ''}`}>
                <div className="flex items-center justify-center h-full">
                    <div className="text-gray-500">PDF preview not available</div>
                </div>
            </div>
        );
    }

    return (
        <div className={`w-full h-full flex flex-col ${className || ''}`}>
            <div className="bg-gray-100 border-b border-gray-200 p-1">
                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
            </div>
            <div className="flex-1 relative">
                <div className="absolute inset-0">
                    <Viewer
                        key={viewerKey} // Force remount when needed
                        fileUrl={url}
                        options={options}
                        plugins={[toolbarPluginInstance]}
                        defaultScale={1}
                        initialPage={initialPage - 1}
                        renderError={(error) => (
                            <div className="flex items-center justify-center h-full">
                                <div className="text-center text-red-600">
                                    <p>Failed to load PDF</p>
                                    <p className="text-xs mt-2 text-gray-500">{error.message}</p>
                                    <button
                                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                        onClick={() => setReloadTrigger(prev => prev + 1)}
                                    >
                                        Retry
                                    </button>
                                </div>
                            </div>
                        )}
                        renderLoader={(percentages) => (
                            <div className="flex items-center justify-center h-full">
                                <div className="text-center">
                                    <div
                                        className="w-8 h-8 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin mx-auto"></div>
                                    <p className="mt-2">Loading {Math.round(percentages.loading * 100)}%</p>
                                </div>
                            </div>
                        )}
                        onPageChange={handlePageChange}
                        onDocumentLoad={handleDocumentLoad}
                        onError={handleError}
                    />
                </div>
            </div>
        </div>
    );
};