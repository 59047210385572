import {Field, FieldArray, useFormikContext} from 'formik';
import {Loader2, ImagePlus} from 'lucide-react';
import React, {useState} from 'react';
import DragAndDropUpload from '../../DragDropQuestion.js';
import ErrorMessage from '../ErrorMessage.js';
import RichTextField from '../TPset/RichTextField.js';
import {getFieldName} from '../TPset/utils.js';
import LaTeXPreviewField from '../../LaTeXPreviewField';

export const TQuestionType = ({
                                  index,
                                  fieldName,
                                  rubricQuestionType,
                                  disabledStyle,
                                  isLoading,
                                  isPublished,
                                  imageS3Key,
                                  rubricItems,
                                  handleImageUpload,
                                  mcqOptions,
                                  submitForm,
                                  setFieldValue,
                                  onCopyRubricItems,
                                  onPasteRubricItems,
                                  aiRubricError,
                                  mcqCheckboxId,
                                  copiedRubricItems,
                                  handleAIRubricClick,
                                  isDisabled,
                                  questionSizing,
                                  isPdfSet = false,
                                  imageSize,
                                  isAnswerUploadVisible,
                                  setIsAnswerUploadVisible,
                                  handleAnswerImageUpload,
                                  answerImageS3Key,
                                  handleVisualSolutionUpload,
                                  isEditMode = false,
                              }) => {
    const [isProcessingVisualSolution, setIsProcessingVisualSolution] = useState(false);
    const [isImageUploadVisible, setIsImageUploadVisible] = useState(false);

    return (
        <>
            <div className="flex flex-col md:flex-row justify-between items-start mt-4">
                <div className="w-full">
                    <LaTeXPreviewField
                        name={getFieldName(index, 'question', fieldName)}
                        disabled={isPublished}
                        placeholder="Question Text"
                        className={`sexy-border rounded-lg mb-4 focus:ring-0 focus:sexy-border focus:rounded-lg w-full ${disabledStyle}`}
                        rows={6}
                        onBlur={submitForm}
                    />

                    <span className="text-red-800 text-xs">
            <ErrorMessage name={getFieldName(index, 'question', fieldName)}/>
          </span>
                    <div className="flex flex-col w-full mb-2">
                        <button
                            type="button"
                            className="flex items-center text-sm"
                            onClick={() => setIsImageUploadVisible(!isImageUploadVisible)}
                        >
                            <img
                                src="/assets/back_arrow.png"
                                className={`size-5 transform ${isImageUploadVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                alt="Answer box image upload"
                            />
                            Optional: Attach image to question
                        </button>
                    </div>
                    {isImageUploadVisible && (<div>
                            {!imageS3Key && (
                                <div className="w-full">
                                    <DragAndDropUpload handleImageUpload={handleImageUpload} isPublished={isPublished}/>
                                </div>
                            )}
                            {imageS3Key && (
                                <div className="relative mb-4 mt-2 flex items-center">
                                    <img
                                        src={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${imageS3Key}`}
                                        alt="Sub-question"
                                        className="w-20 h-20 object-cover"
                                    />
                                    <button
                                        type="button"
                                        disabled={isPublished}
                                        onClick={() => {
                                            setFieldValue(getFieldName(index, 'imageUrl', fieldName), null);
                                            setFieldValue(getFieldName(index, 'imageS3Key', fieldName), null);

                                            setTimeout(async () => {
                                                await submitForm();
                                            }, 500);
                                        }}
                                    >
                                        <img src="/assets/trash.png" className="size-4 ml-2" alt="Delete sub-question"/>
                                    </button>
                                </div>
                            )}
                            {isPdfSet && imageS3Key && (
                                <div className="flex items-center mb-4">
                                    <div className="mr-3 text-sm">Image size:</div>
                                    <div className="input-entry-box font-normal px-2 w-14">
                                        <Field
                                            name={getFieldName(index, 'imageSize', fieldName)}
                                            disabled={isPublished}
                                            type="number"
                                            min="1"
                                            max="40"
                                            className="actual-input text-black"
                                            onBlur={submitForm}
                                        />
                                    </div>
                                    <div className="ml-2">%</div>
                                </div>
                            )}
                            {isPdfSet && imageS3Key && (
                                <div className="flex items-center">
                                    <div className="flex gap-2">
                                        {[
                                            {label: 'S', value: 5},
                                            {label: 'M', value: 15},
                                            {label: 'L', value: 30}
                                        ].map(({label, value}) => (
                                            <button
                                                key={label}
                                                type="button"
                                                className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 ${
                                                    imageSize === value
                                                        ? 'bg-orange-50 border-orange-200 hover:bg-orange-100'
                                                        : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                                }`}
                                                disabled={isPublished}
                                                onClick={() => {
                                                    setFieldValue(getFieldName(index, 'imageSize', fieldName), value);
                                                    setTimeout(async () => {
                                                        await submitForm();
                                                    }, 500);
                                                }}
                                            >
                                                {label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {isPdfSet && !isDisabled && (
                        <>
                            <div className="flex flex-col w-full mb-2 mt-8">
                                <button
                                    type="button"
                                    className="flex items-center text-sm"
                                    onClick={() => setIsAnswerUploadVisible(!isAnswerUploadVisible)}
                                >
                                    <img
                                        src="/assets/back_arrow.png"
                                        className={`size-5 transform ${isAnswerUploadVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                        alt="Answer box image upload"
                                    />
                                    Optional: Put image inside answer box
                                </button>
                            </div>
                            {isAnswerUploadVisible && (
                                <div className="mt-1 w-full bg-white rounded-lg">
                                    <div className="mt-1">
                                        <DragAndDropUpload
                                            handleImageUpload={handleAnswerImageUpload}
                                            isPublished={isPublished}
                                        />
                                    </div>
                                    {answerImageS3Key && (
                                        <div className="flex items-center mt-4 p-3 bg-white rounded-md shadow-sm">
                                            <img
                                                src={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${answerImageS3Key}`}
                                                alt="Sub-question Answer image"
                                                className="w-20 h-20 object-cover rounded mr-3"
                                            />
                                            <button
                                                disabled={isPublished}
                                                className="p-1"
                                                onClick={() => {
                                                    setFieldValue(getFieldName(index, 'answerImageUrl', fieldName), null);
                                                    setFieldValue(getFieldName(index, 'answerImageS3Key', fieldName), null);

                                                    setTimeout(async () => {
                                                        await submitForm();
                                                    }, 500);
                                                }}
                                            >
                                                <img src="/assets/trash.png" className="size-4"
                                                     alt="Delete answer image"/>
                                            </button>
                                        </div>
                                    )}
                                    {answerImageS3Key && (
                                        <div className="mt-4 p-3 bg-white rounded-md shadow-sm">
                                            <div className="mb-2 text-sm font-medium">Answer box image size:</div>
                                            <div className="flex items-center">
                                                <div className="input-entry-box font-normal px-2 w-20 mr-2">
                                                    <Field
                                                        name={getFieldName(index, 'answerImageSize', fieldName)}
                                                        disabled={isPublished}
                                                        type="number"
                                                        min="1"
                                                        max="100"
                                                        className="actual-input text-black w-full"
                                                        onBlur={submitForm}
                                                    />
                                                </div>
                                                <span>%</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="flex flex-col w-full md:w-[450px] md:ml-8">
                    {rubricQuestionType && isPdfSet && !isDisabled && (
                        <div className="flex flex-col mb-4">
                            <div className="text-sm font-medium mb-2">Answer box size</div>
                            <div className="flex items-center gap-4">
                                <div className="flex gap-2">
                                    {[
                                        {label: 'S', value: 10},
                                        {label: 'M', value: 30},
                                        {label: 'L', value: 60}
                                    ].map(({label, value}) => (
                                        <button
                                            key={label}
                                            type="button"
                                            className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 ${
                                                questionSizing === value
                                                    ? 'bg-orange-50 border-orange-200 hover:bg-orange-100'
                                                    : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                            }`}
                                            disabled={isPublished}
                                            onClick={() => {
                                                setFieldValue(getFieldName(index, 'questionSizing', fieldName), value);
                                                setTimeout(async () => {
                                                    await submitForm();
                                                }, 500);
                                            }}
                                        >
                                            {label}
                                        </button>
                                    ))}
                                </div>
                                <div className="flex items-center">
                                    <div className={`input-entry-box font-normal px-2 w-14 ${disabledStyle}`}>
                                        <Field
                                            name={getFieldName(index, 'questionSizing', fieldName)}
                                            disabled={isPublished}
                                            type="number"
                                            min="1"
                                            max="100"
                                            className={`actual-input text-black ${disabledStyle}`}
                                            onBlur={submitForm}
                                        />
                                    </div>
                                    <span className="ml-2">%</span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col space-y-2">
                        {rubricQuestionType
                            ? !isDisabled && (
                            <div className="flex flex-col">
                                <div className="flex items-center gap-2">
                                    <div className="text-sm font-medium mb-2">Rubric Items</div>
                                    <div className="flex justify-end mb-2">
                                        {isLoading && (
                                            <div className="flex items-center mr-3">
                                                <Loader2 className="h-4 w-4 animate-spin text-gray-500"/>
                                                <span className="ml-2 text-sm text-gray-500">Generating...</span>
                                            </div>
                                        )}
                                        <button
                                            type="button"
                                            disabled={isPublished || isLoading || isDisabled || isEditMode}
                                            className={`mr-2 px-2 py-1 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-300 text-black ${isLoading || isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            onClick={() => onCopyRubricItems(rubricItems)}
                                        >
                                            Copy
                                        </button>
                                        <button
                                            type="button"
                                            disabled={isPublished || !copiedRubricItems || isLoading || isDisabled || isEditMode}
                                            className={`px-2 py-1 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black ${isLoading || isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            onClick={onPasteRubricItems}
                                        >
                                            Paste
                                        </button>

                                        <button
                                            type="button"
                                            disabled={isPublished || isLoading || isDisabled || isEditMode}
                                            className={`ml-2 p-1 bg-white rounded-full opacity-60 border-2 ${isLoading || isDisabled || isEditMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            onClick={handleAIRubricClick}
                                        >
                                            <img src="/assets/magic-wand.png" alt="AI Rubric" className="w-6 h-6"/>
                                        </button>
                                    </div>
                                </div>
                                {aiRubricError && (
                                    <div className="text-red-500 text-sm mt-2 mb-2">{aiRubricError}</div>
                                )}
                                <FieldArray name={getFieldName(index, 'rubricItems', fieldName)}>
                                    {({remove, push}) => {
                                        const rubricFieldName = getFieldName(index, 'rubricItems', fieldName);
                                        return (
                                            <div className="flex flex-col space-y-2">
                                                {rubricItems.map((rubricItem, rubricIndex) => (
                                                    <div key={rubricIndex} className="flex items-start mb-2">
                                                        <div
                                                            className={`size-4 flex-shrink-0 flex items-center justify-center mr-3 ${disabledStyle}`}
                                                        >
                                                            <button
                                                                type="button"
                                                                className={`${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                                disabled={isPublished || isDisabled || isEditMode}
                                                                onClick={() => remove(rubricIndex)}
                                                            >
                                                                <img
                                                                    src="/assets/trash.png"
                                                                    className="size-4"
                                                                    alt="Delete rubric item"
                                                                />
                                                            </button>
                                                        </div>
                                                        <div className="flex-1 flex flex-col">
                                                            <div className={`w-full ${disabledStyle}`}>
                                                                <LaTeXPreviewField
                                                                    name={getFieldName(rubricIndex, 'descriptor', rubricFieldName)}
                                                                    placeholder="Description"
                                                                    disabled={isPublished || isLoading || isDisabled || isEditMode}
                                                                    className={`w-full max-h-20 overflow-y-auto resize-none border border-gray-300 rounded-md px-2 py-1 focus:border-black focus:ring-0 text-black ${disabledStyle} ${isLoading || isDisabled | isEditMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                                    rows={2}
                                                                    isEditMode={true}
                                                                    onBlur={submitForm}
                                                                />

                                                                <span className="text-red-800 text-xs">
                                      <ErrorMessage
                                          name={getFieldName(
                                              rubricIndex,
                                              'descriptor',
                                              rubricFieldName
                                          )}
                                      />
                                    </span>
                                                            </div>
                                                        </div>
                                                        <div className={`ml-2 w-16 ${disabledStyle}`}>
                                                            <Field
                                                                name={getFieldName(rubricIndex, 'value', rubricFieldName)}
                                                                type="number"
                                                                placeholder="Pts"
                                                                min="0"
                                                                disabled={isPublished || isLoading || isDisabled || isEditMode}
                                                                className={`w-full px-2 py-1 text-black box-border appearance-none ${disabledStyle} border border-gray-300 rounded-md focus:border-black focus:ring-0 ${isLoading || isDisabled || isEditMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                                onBlur={submitForm}
                                                            />
                                                            <span className="text-red-800 text-xs">
                                    <ErrorMessage
                                        name={getFieldName(rubricIndex, 'value', rubricFieldName)}
                                    />
                                  </span>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div
                                                    className={`flex items-center mt-4 ml-8 ${disabledStyle} ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                >
                                                    <button
                                                        disabled={isPublished || isDisabled || isEditMode}
                                                        onClick={() => {
                                                            push({descriptor: '', value: ''});
                                                        }}
                                                    >
                                                        <div
                                                            className={`size-4 flex items-center justify-center ${disabledStyle}`}
                                                        >
                                                            <img
                                                                src="/assets/add-circle.png"
                                                                className="size-4"
                                                                alt="Add rubric item"
                                                            />
                                                        </div>
                                                    </button>
                                                    <div className={`ml-5 text-sm ${disabledStyle}`}>Add Rubric Item
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }}
                                </FieldArray>
                            </div>
                        )
                            : !isDisabled && (
                            <div className="flex flex-col">
                                <div className="text-sm font-medium mb-2">Question Weight</div>
                                <div
                                    className={`input-entry-box font-normal px-2 w-16 relative ${disabledStyle}`}
                                >
                                    <Field
                                        name={getFieldName(index, 'weight', fieldName)}
                                        type="number"
                                        disabled={isPublished || isDisabled || isEditMode}
                                        min="1"
                                        max="99"
                                        className={`actual-input text-black w-12 ${disabledStyle} ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`} // Set width here
                                        onBlur={submitForm}
                                    />
                                    <span className="text-red-800 text-xs absolute bottom-[-20px] left-0 w-max">
                        <ErrorMessage name={getFieldName(index, 'weight', fieldName)}/>
                      </span>
                                </div>
                                <div className="text-sm font-medium mt-4 mb-2">Multiple Choice Options</div>
                                <FieldArray name={getFieldName(index, 'mcqOptions', fieldName)}>
                                    {({remove, push}) => {
                                        const optionFieldName = getFieldName(index, 'mcqOptions', fieldName);
                                        return (
                                            <div>
                                                {mcqOptions.map((option, optionIndex) => (
                                                    <div key={optionIndex} className="flex items-center mb-8 relative">
                                                        <div
                                                            className="mr-2">{String.fromCharCode(65 + optionIndex)}</div>
                                                        <LaTeXPreviewField
                                                            name={getFieldName(optionIndex, 'text', optionFieldName)}
                                                            type="text"
                                                            placeholder={`Option ${String.fromCharCode(65 + optionIndex)}`}
                                                            disabled={isPublished || isDisabled || isEditMode}
                                                            className={`sexy-border rounded px-1 py-0.5 mr-2 w-40 ${disabledStyle} ${isDisabled || isEditMode ? 'opacity-50 cursor-not-allowed' : ''}`} // Adjust width here
                                                            onBlur={submitForm}
                                                        />
                                                        <span className="text-red-800 text-xs absolute bottom-[-35px]">
                                  <ErrorMessage
                                      name={getFieldName(optionIndex, 'text', optionFieldName)}
                                  />
                                </span>
                                                        <div className="relative inline-block mr-2">
                                                            <Field
                                                                name={getFieldName(optionIndex, 'isCorrect', optionFieldName)}
                                                                id={`${mcqCheckboxId}-${optionIndex}`}
                                                                type="checkbox"
                                                                disabled={isPublished || isDisabled || isEditMode}
                                                                className={`sr-only peer ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                                onChange={e => {
                                                                    const isChecked = e.target.checked;
                                                                    mcqOptions.forEach((_, idx) => {
                                                                        setFieldValue(
                                                                            getFieldName(idx, 'isCorrect', optionFieldName),
                                                                            false
                                                                        );
                                                                    });
                                                                    if (isChecked) {
                                                                        setFieldValue(
                                                                            getFieldName(optionIndex, 'isCorrect', optionFieldName),
                                                                            true
                                                                        );
                                                                    }
                                                                    setTimeout(async () => {
                                                                        await submitForm();
                                                                    }, 500);
                                                                }}
                                                            />
                                                            <label
                                                                htmlFor={`${mcqCheckboxId}-${optionIndex}`}
                                                                className={`${
                                                                    isPublished || isDisabled || isEditMode
                                                                        ? 'cursor-not-allowed opacity-50'
                                                                        : 'cursor-pointer'
                                                                } w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out`}
                                                            ></label>
                                                            <span className="text-red-800 text-xs">
                                    <ErrorMessage
                                        name={getFieldName(optionIndex, 'isCorrect', optionFieldName)}
                                    />
                                  </span>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            disabled={isPublished || isDisabled || isEditMode}
                                                            className={`${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                            onClick={() => {
                                                                remove(optionIndex);
                                                                setTimeout(async () => {
                                                                    await submitForm();
                                                                }, 500);
                                                            }}
                                                        >
                                                            <img
                                                                src="/assets/trash.png"
                                                                className="size-4"
                                                                alt="Delete option"
                                                            />
                                                        </button>
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    disabled={isPublished || isDisabled || isEditMode}
                                                    className={`less-sexy-button w-40 h-9 mt-2 ${disabledStyle} ${isDisabled || isEditMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    onClick={() => {
                                                        push({
                                                            option: String.fromCharCode(65 + mcqOptions.length),
                                                            text: '',
                                                            isCorrect: mcqOptions.length === 0
                                                        });
                                                    }}
                                                >
                                                    Add Option
                                                </button>
                                            </div>
                                        );
                                    }}
                                </FieldArray>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full">
                {rubricQuestionType && !isDisabled && (
                    <div className="w-full mt-4 relative">
                        <div className="mb-2 text-sm font-medium">Solution (and Grading Instructions)</div>
                        <div className="relative">
                            {isLoading && (
                                <div className="absolute -top-6 right-0 flex items-center">
                                    <Loader2 className="h-4 w-4 animate-spin text-gray-500"/>
                                    <span className="ml-2 text-sm text-gray-500">Processing...</span>
                                </div>
                            )}
                            <LaTeXPreviewField
                                name={getFieldName(index, 'solution', fieldName)}
                                disabled={isPublished || isDisabled || isLoading || isEditMode}
                                placeholder="Solution"
                                className={`sexy-border rounded-lg mb-4 focus:ring-0 focus:sexy-border focus:rounded-lg w-full ${disabledStyle} ${isDisabled || isLoading || isEditMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                                rows={6}
                                onBlur={submitForm}
                            />

                            <div className="absolute bottom-6 right-2">
                                <label
                                    className={`flex items-center cursor-pointer ${isPublished || isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        disabled={isPublished || isDisabled || isLoading || isEditMode}
                                        className="hidden"
                                        onChange={handleVisualSolutionUpload}
                                    />
                                    <div
                                        className="flex items-center px-3 py-1.5 text-sm border border-gray-300 rounded-full hover:border-gray-400 bg-white">
                                        <ImagePlus className="h-4 w-4 mr-2"/>
                                        <span>Visual Solution Upload</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <span className="text-red-800 text-xs">
           <ErrorMessage name={getFieldName(index, 'solution', fieldName)}/>
       </span>
                    </div>
                )}
            </div>
        </>
    );
};
