import React, {useState} from 'react';
import {Calendar, MessageSquare, CheckCircle, AlertCircle, Send, FileText, HelpCircle, BookOpen} from 'lucide-react';
import SideBar from '../components/SideBar';
import {useAuth} from '../auth/AuthContext';
import '../components/V2PDF/src/app/template/page.scss';

const SupportPage = () => {
    const {user} = useAuth();
    const [formStatus, setFormStatus] = useState({success: false, error: false});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());

        try {
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...data,
                    access_key: '9932eb13-3d42-4b3f-80f8-91dbe1b390f3',
                    from_name: 'GradeWiz Website Contact',
                }),
            });

            if (response.ok) {
                setFormStatus({success: true, error: false});
                e.target.reset();
            } else {
                setFormStatus({success: false, error: true});
            }
        } catch (error) {
            setFormStatus({success: false, error: true});
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="template-editor h-screen flex flex-col bg-white">
            <div className="flex flex-row h-full overflow-hidden">
                <div className="flex-shrink-0">
                    <SideBar activePage="Support"/>
                </div>

                <div className="split-container bg-white" style={{ height: 'auto', minHeight: 'calc(100vh - 73px)' }}>
                    <div className="viewer-panel overflow-y-auto" style={{ overflow: 'visible', height: 'auto' }}>
                        {/* Expanded white area above header with maintained text position */}
                        <div className="bg-white pt-16">
                            <div
                                className="page-header p-6 flex justify-between items-center border-b border-[#e2e8f0] bg-white sticky top-0 z-10">
                                <div>
                                    <h1 className="text-[1.125rem] font-semibold text-[#1e293b] tracking-tight">Support
                                        and Feedback</h1>
                                    <p className="text-[0.875rem] text-[#64748b] mt-1">Get help or share your thoughts
                                        with us</p>
                                </div>
                                <a
                                    href="https://calendly.com/maxbohun/gradewiz"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-flex items-center px-4 py-2 rounded-md border border-[#e4b17a] bg-white
                                        text-[0.875rem] font-medium text-[#e4b17a] transition-all duration-300
                                        hover:bg-[#fdf8f3] hover:shadow-md hover:-translate-y-[1px]"
                                >
                                    <Calendar className="w-4 h-4 mr-2 stroke-[#e4b17a]"/>
                                    Schedule a Call
                                </a>
                            </div>

                            <div className="p-6">
                                <div
                                    className="bg-white border border-[rgba(226,232,240,0.8)] rounded-xl shadow-sm p-6 mb-6 transition-all hover:shadow-md">
                                    <div className="mx-auto">
                                        <div className="panel-header mb-5 flex items-center gap-3">
                                            <MessageSquare className="w-5 h-5 text-[#e4b17a]"/>
                                            <h2 className="text-[1rem] font-semibold text-[#1e293b] tracking-tight">Contact
                                                Us</h2>
                                        </div>

                                        <form className="space-y-5" onSubmit={handleSubmit}>
                                            <input
                                                type="hidden"
                                                name="access_key"
                                                value="9932eb13-3d42-4b3f-80f8-91dbe1b390f3"
                                            />
                                            <input
                                                type="hidden"
                                                name="from_name"
                                                value="GradeWiz Website Contact"
                                            />

                                            <div>
                                                <label
                                                    htmlFor="title"
                                                    className="block text-[0.875rem] font-medium text-[#475569] mb-2"
                                                >
                                                    Title
                                                </label>
                                                <input
                                                    type="text"
                                                    id="title"
                                                    name="title"
                                                    value={user?.user?.is_teacher ? "Teacher" : "Student"}
                                                    readOnly
                                                    className="w-full px-4 py-2 border border-[#e2e8f0] rounded-lg bg-[#f8fafc] text-[#1e293b]"
                                                />
                                            </div>

                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label
                                                        htmlFor="firstName"
                                                        className="block text-[0.875rem] font-medium text-[#475569] mb-2"
                                                    >
                                                        First name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="firstName"
                                                        name="firstName"
                                                        value={user?.user?.first_name || ""}
                                                        readOnly
                                                        className="w-full px-4 py-2 border border-[#e2e8f0] rounded-lg bg-[#f8fafc] text-[#1e293b]"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="lastName"
                                                        className="block text-[0.875rem] font-medium text-[#475569] mb-2"
                                                    >
                                                        Last name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="lastName"
                                                        name="lastName"
                                                        value={user?.user?.last_name || ""}
                                                        readOnly
                                                        className="w-full px-4 py-2 border border-[#e2e8f0] rounded-lg bg-[#f8fafc] text-[#1e293b]"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label
                                                    htmlFor="email"
                                                    className="block text-[0.875rem] font-medium text-[#475569] mb-2"
                                                >
                                                    Email Address
                                                </label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={user?.user?.email || ""}
                                                    readOnly
                                                    className="w-full px-4 py-2 border border-[#e2e8f0] rounded-lg bg-[#f8fafc] text-[#1e293b]"
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    htmlFor="message"
                                                    className="block text-[0.875rem] font-medium text-[#475569] mb-2"
                                                >
                                                    Message
                                                </label>
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    required
                                                    rows={5}
                                                    className="w-full px-4 py-2 border border-[#e2e8f0] rounded-lg focus:outline-none focus:border-[#e4b17a] focus:ring-2 focus:ring-[rgba(228,177,122,0.2)] transition-all duration-200 resize-none"
                                                    placeholder="Your message here"
                                                />
                                            </div>

                                            <button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="flex items-center justify-center w-full px-5 py-3 rounded-lg border border-[#e4b17a]
                                                    bg-[#e4b17a] text-white font-medium text-[0.875rem] transition-all duration-300
                                                    hover:shadow-md hover:-translate-y-[1px]
                                                    disabled:bg-[#cbd5e1] disabled:border-[#cbd5e1] disabled:text-white disabled:cursor-not-allowed"
                                            >
                                                {isSubmitting ? (
                                                    <>
                                                        <span className="animate-pulse mr-2">Sending</span>
                                                        <div
                                                            className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"
                                                        ></div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Send className="w-4 h-4 mr-2 stroke-[2.5px]"/>
                                                        Submit
                                                    </>
                                                )}
                                            </button>
                                        </form>

                                        {formStatus.success && (
                                            <div
                                                className="mt-5 p-4 bg-[rgba(253,248,243,0.8)] border border-[rgba(228,177,122,0.3)] rounded-lg flex items-center gap-3 animate-fadeIn"
                                            >
                                                <CheckCircle className="w-5 h-5 text-[#e4b17a] flex-shrink-0"/>
                                                <p className="text-[0.875rem] text-[#1e293b]">
                                                    Thank you! Your submission has been received!
                                                </p>
                                            </div>
                                        )}

                                        {formStatus.error && (
                                            <div
                                                className="mt-5 p-4 bg-[rgba(254,242,242,0.8)] border border-[rgba(248,113,113,0.3)] rounded-lg flex items-center gap-3 animate-fadeIn"
                                            >
                                                <AlertCircle className="w-5 h-5 text-[#f43f5e] flex-shrink-0"/>
                                                <p className="text-[0.875rem] text-[#1e293b]">
                                                    Oops! Something went wrong while submitting the form.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right panel with support information */}
                    <div className="right-panel hidden lg:flex">
                        <div className="panel-header">
                            <h2>Support Resources</h2>
                        </div>

                        <div className="flex flex-col p-6 gap-5 overflow-y-auto">
                            <div
                                className="question-item p-4 border border-[rgba(226,232,240,0.8)] rounded-xl transition-all hover:-translate-y-[1px] hover:shadow-sm relative"
                            >
                                <div
                                    className="absolute right-0 top-0 bg-[#e4b17a] text-white text-xs font-semibold py-1 px-3 rounded-tr-xl rounded-bl-xl shadow-sm">
                                    Coming Soon
                                </div>
                                <div className="question-header flex justify-between items-center">
                                    <h3 className="font-semibold text-[0.9375rem] text-[#1e293b]">Documentation</h3>
                                    {/*<FileText className="w-5 h-5 text-[#e4b17a]"/>*/}
                                </div>
                                <p className="text-[0.875rem] text-[#475569] mt-2">
                                    Access our comprehensive user guides and documentation for detailed information
                                    about GradeWiz features.
                                </p>
                            </div>

                            <div
                                className="question-item p-4 border border-[rgba(226,232,240,0.8)] rounded-xl transition-all hover:-translate-y-[1px] hover:shadow-sm relative"
                            >
                                <div
                                    className="absolute right-0 top-0 bg-[#e4b17a] text-white text-xs font-semibold py-1 px-3 rounded-tr-xl rounded-bl-xl shadow-sm">
                                    Coming Soon
                                </div>
                                <div className="question-header flex justify-between items-center">
                                    <h3 className="font-semibold text-[0.9375rem] text-[#1e293b]">FAQ</h3>
                                    {/*<HelpCircle className="w-5 h-5 text-[#e4b17a]"/>*/}
                                </div>
                                <p className="text-[0.875rem] text-[#475569] mt-2">
                                    Find answers to commonly asked questions about using GradeWiz for your
                                    assignments and exams.
                                </p>
                            </div>

                            <div
                                className="question-item p-4 border border-[rgba(226,232,240,0.8)] rounded-xl transition-all hover:-translate-y-[1px] hover:shadow-sm relative"
                            >
                                <div
                                    className="absolute right-0 top-0 bg-[#e4b17a] text-white text-xs font-semibold py-1 px-3 rounded-tr-xl rounded-bl-xl shadow-sm">
                                    Coming Soon
                                </div>
                                <div className="question-header flex justify-between items-center">
                                    <h3 className="font-semibold text-[0.9375rem] text-[#1e293b]">Video Tutorials</h3>
                                    {/*<BookOpen className="w-5 h-5 text-[#e4b17a]"/>*/}
                                </div>
                                <p className="text-[0.875rem] text-[#475569] mt-2">
                                    Watch step-by-step video guides for getting the most out of GradeWiz's features.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportPage;
