import React from 'react';
import SideBar from '../../components/SideBar';
import TClasses from '../../components/Teacher/TClasses';

const HomePage = () => {
    return (
        <div className="w-full h-screen flex">
            <div
                id="Left-Sidebar"
                className="h-screen flex-shrink-0 w-side-bar-size"
            >
                <SideBar activePage="Home"/>
            </div>
            <div
                id="Right-content"
                className="flex-grow overflow-y-auto h-screen"
            >
                <TClasses/>
            </div>
        </div>
    );
};

export default HomePage;