import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../auth/AuthContext';
import { gradeApi } from '../../../../api.js';
import { GRACE_PERIOD_OPTIONS } from '../../../../utils/constants.ts';
import { Copy } from 'lucide-react';

const CourseSettings = ({ course, class_id }) => {
  const { user } = useAuth();
  const [gracePeriod, setGracePeriod] = useState(0);
  const [allowRegrades, setAllowRegrades] = useState(course?.allow_regrades);
  const [sendRegradeNotifications, setSendRegradeNotifications] = useState(course?.send_regrade_notifications);
  const [lateGetsZero, setLateGetsZero] = useState(course?.late_gets_zero);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [classOpen, setClassOpen] = useState(course?.class_open);
  const [classCode, setClassCode] = useState(course?.class_code);
  const [copySuccess, setCopySuccess] = useState(false);

  const isOpenToManage = course?.isOpenToManage;

  useEffect(() => {
    if (course?.grace_period !== undefined) {
      setGracePeriod(course.grace_period);
    }
    setAllowRegrades(course?.allow_regrades);
    setSendRegradeNotifications(course?.send_regrade_notifications);
    setLateGetsZero(course?.late_gets_zero);
    setClassOpen(course?.class_open);
    setClassCode(course?.class_code);
  }, [course]);
  
  useEffect(() => {
    if (course && !classCode) {
      fetchClassDetails();
    }
  }, [course]);
  
  const fetchClassDetails = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/courses/${class_id}/teacher-assignments-lite/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setClassOpen(response.data.class_open);
      setClassCode(response.data.class_code);
    } catch (error) {
      console.error('Error fetching class details:', error);
    }
  };

  const handleGracePeriodChange = async (minutes) => {
    try {
      setSuccess('');
      setError('');
      await gradeApi.patch({
        path: `/api/courses/${class_id}/grace-period`,
        body: { gracePeriodMinutes: minutes },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setGracePeriod(minutes);
      setSuccess('Grace period updated successfully');
    } catch (error) {
      console.error('Error updating grace period:', error);
      setError('Failed to update grace period');
    }
  };

  const handleChangeAllowRegrades = async () => {
    try {
      setSuccess('');
      setError('');
      await gradeApi.patch({
        path: `/api/courses/${class_id}/allow-regrades`,
        body: { allowRegrades: !allowRegrades },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setAllowRegrades(!allowRegrades);
      setSuccess('Regrade setting updated successfully');
    } catch (error) {
      console.error('Error updating allow regrades:', error);
      setError('Failed to update allow regrades setting');
    }
  };

  const handleChangeSendRegradeNotifications = async () => {
    try {
      setSuccess('');
      setError('');
      await gradeApi.patch({
        path: `/api/courses/${class_id}/regrade-notifications`,
        body: { sendRegradeNotifications: !sendRegradeNotifications },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setSendRegradeNotifications(!sendRegradeNotifications);
      setSuccess('Notification setting updated successfully');
    } catch (error) {
      console.error('Error updating regrade notifications setting:', error);
      setError('Failed to update regrade notifications setting');
    }
  };

  const handleChangeLateGetsZero = async () => {
    try {
      setSuccess('');
      setError('');
      await gradeApi.patch({
        path: `/api/courses/${class_id}/late-gets-zero`,
        body: { lateGetsZero: !lateGetsZero },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setLateGetsZero(!lateGetsZero);
      setSuccess('Late submission setting updated successfully');
    } catch (error) {
      console.error('Error updating late gets zero setting:', error);
      setError('Failed to update late gets zero setting');
    }
  };
  
  const handleChangeClassOpen = async () => {
    try {
      setSuccess('');
      setError('');
      await gradeApi.post({
        path: `/api/courses/${class_id}/open/`,
        body: { openValue: !classOpen },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setClassOpen(!classOpen);
      setSuccess(classOpen ? 'Class code disabled successfully' : 'Class code enabled successfully');
    } catch (error) {
      console.error('Error updating class open status:', error);
      setError('Failed to update class code status');
    }
  };
  
  const copyClassCodeToClipboard = () => {
    if (classCode) {
      navigator.clipboard.writeText(classCode)
        .then(() => {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 2000);
        })
        .catch(err => {
          console.error('Failed to copy code:', err);
        });
    }
  };

  return (
    <div className="course-settings-container">
      <h2 className="text-xl font-semibold mb-6">Course Settings</h2>
      
      {success && (
        <div className="mb-4 p-3 bg-green-50 text-green-700 rounded-md border border-green-200">
          {success}
        </div>
      )}
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md border border-red-200">
          {error}
        </div>
      )}

      <div className="bg-white rounded-lg border border-gray-200 p-6 mb-6">
        <h3 className="text-lg font-medium mb-4">Submission Settings</h3>
        
        <div className="space-y-6">
          {/* Grace Period Setting */}
          <div className="flex flex-col space-y-2">
            <label className="text-sm font-medium text-gray-700">Grace Period</label>
            <div className="flex items-center gap-4">
              <select
                disabled={!isOpenToManage}
                value={gracePeriod}
                className={`rounded-md border border-gray-300 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500 ${!isOpenToManage ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                onChange={e => handleGracePeriodChange(Number(e.target.value))}
              >
                {GRACE_PERIOD_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <p className="text-xs text-gray-500">
              Grace period allows students to submit assignments after the deadline without penalty.
            </p>
          </div>

          {/* Late Submissions Setting */}
          <div className="flex flex-col space-y-2">
            <label className="text-sm font-medium text-gray-700">Late Submissions</label>
            <div className="flex items-center">
              <label htmlFor="lateGetsZeroCheckBox" className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    id="lateGetsZeroCheckBox"
                    checked={lateGetsZero}
                    className="sr-only peer"
                    onChange={handleChangeLateGetsZero}
                    disabled={!isOpenToManage}
                  />
                  <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 peer-focus:ring-amber-300 ${lateGetsZero ? 'peer-checked:bg-amber-400' : ''} ${!isOpenToManage ? 'opacity-50' : ''}`}></div>
                  <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-all peer-checked:translate-x-5 ${!isOpenToManage ? 'opacity-50' : ''}`}></div>
                </div>
                <span className="ml-3 text-sm text-gray-700">Automatically give late submissions a score of 0</span>
              </label>
            </div>
            <p className="text-xs text-gray-500">
              When enabled, submissions after the due date (including grace period) will automatically receive a score of 0.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 p-6 mb-6">
        <h3 className="text-lg font-medium mb-4">Class Access</h3>
        
        <div className="space-y-6">
          {/* Join by Class Code Setting */}
          <div className="flex flex-col space-y-2">
            <label className="text-sm font-medium text-gray-700">Join by Class Code</label>
            <div className="flex items-center">
              <label htmlFor="classCodeToggle" className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    id="classCodeToggle"
                    checked={classOpen}
                    className="sr-only peer"
                    onChange={handleChangeClassOpen}
                    disabled={!isOpenToManage}
                  />
                  <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 peer-focus:ring-amber-300 ${classOpen ? 'peer-checked:bg-amber-400' : ''} ${!isOpenToManage ? 'opacity-50' : ''}`}></div>
                  <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-all peer-checked:translate-x-5 ${!isOpenToManage ? 'opacity-50' : ''}`}></div>
                </div>
                <span className="ml-3 text-sm text-gray-700">Allow students to join this class with a code</span>
              </label>
            </div>
            
            {classOpen && classCode && (
              <div className="mt-2">
                <div className="flex items-center space-x-2">
                  <div className="bg-gray-50 p-2 rounded-md border border-gray-200 text-gray-800 font-medium">
                    {classCode}
                  </div>
                  <button 
                    onClick={copyClassCodeToClipboard}
                    className="p-2 rounded-md hover:bg-gray-100 transition-colors"
                    title="Copy class code"
                  >
                    <Copy className="w-4 h-4 text-gray-600" />
                  </button>
                  {copySuccess && (
                    <span className="text-sm text-green-600">Copied!</span>
                  )}
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  Share this code with students to allow them to join this class.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h3 className="text-lg font-medium mb-4">Regrade Settings</h3>
        
        <div className="space-y-6">
          {/* Allow Regrades Setting */}
          <div className="flex flex-col space-y-2">
            <label className="text-sm font-medium text-gray-700">Regrade Requests</label>
            <div className="flex items-center">
              <label htmlFor="allowRegradeCheckBox" className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    id="allowRegradeCheckBox"
                    checked={allowRegrades}
                    className="sr-only peer"
                    onChange={handleChangeAllowRegrades}
                    disabled={!isOpenToManage}
                  />
                  <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 peer-focus:ring-amber-300 ${allowRegrades ? 'peer-checked:bg-amber-400' : ''} ${!isOpenToManage ? 'opacity-50' : ''}`}></div>
                  <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-all peer-checked:translate-x-5 ${!isOpenToManage ? 'opacity-50' : ''}`}></div>
                </div>
                <span className="ml-3 text-sm text-gray-700">Allow students to request regrades</span>
              </label>
            </div>
            <p className="text-xs text-gray-500">
              Enable this option to allow students to request reassessment of their work.
            </p>
          </div>

          {/* Regrade Notifications Setting */}
          <div className="flex flex-col space-y-2">
            <label className="text-sm font-medium text-gray-700">Regrade Notifications</label>
            <div className="flex items-center">
              <label htmlFor="sendRegradeNotificationsCheckBox" className={`flex items-center ${!allowRegrades ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                <div className="relative">
                  <input
                    type="checkbox"
                    id="sendRegradeNotificationsCheckBox"
                    checked={sendRegradeNotifications}
                    className="sr-only peer"
                    onChange={handleChangeSendRegradeNotifications}
                    disabled={!allowRegrades || !isOpenToManage}
                  />
                  <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 peer-focus:ring-amber-300 ${sendRegradeNotifications && allowRegrades ? 'peer-checked:bg-amber-400' : ''} ${!allowRegrades || !isOpenToManage ? 'opacity-50' : ''}`}></div>
                  <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-all peer-checked:translate-x-5 ${!allowRegrades || !isOpenToManage ? 'opacity-50' : ''}`}></div>
                </div>
                <span className="ml-3 text-sm text-gray-700">Receive email notifications for regrade requests</span>
              </label>
            </div>
            <p className="text-xs text-gray-500">
              When enabled, instructors will receive email notifications when students submit regrade requests.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSettings;
