import React, {useRef, useState} from 'react';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import DragAndDropUpload from '../DragDropAnswer.js';

const ParsePDF = ({
                      assignment_id,
                      setUpdateTrigger,
                      isPublished,
                      setPullQuestions,
                      setEdited,
                      setSolRecent,
                      isEssay,
                  }) => {
    const [file, setFile] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [inputKey, setInputKey] = useState(Date.now());
    const {user} = useAuth();
    const disabledStyle = isPublished ? '!opacity-65 !cursor-not-allowed pointer-events-none' : '';
    const [isPolling, setIsPolling] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const sanitizeFilename = filename => {
        return filename.replace(/[^a-zA-Z0-9._-]/g, '');
    };

    const resetForm = () => {
        setFile(null);
        setSubmitted(false);
        setError(null);
        setLoading(false);
        setInputKey(Date.now());
    };

    const handleFileChange = selectedFile => {
        if (selectedFile) {
            const sanitizedName = sanitizeFilename(selectedFile.name);
            const newFile = new File([selectedFile], sanitizedName, {type: selectedFile.type});
            setFile(newFile);
        } else {
            setFile(null);
        }
    };

    const checkParsePdfStatus = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/parse-pdf-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            const isDone = response.data.is_parse_pdf_done;
            const isInProgress = !isDone;

            return {isDone, isInProgress};
        } catch (error) {
            console.error('Error checking parse PDF status:', error);
            return {isDone: false, isInProgress: false};
        }
    };

    const startPolling = () => {
        setIsPolling(true);
        const pollInterval = setInterval(async () => {
            const {isDone, isInProgress} = await checkParsePdfStatus();
            if (isDone) {
                clearInterval(pollInterval);
                setIsPolling(false);
                setSubmitted(true);
                setPullQuestions(true);
                setEdited(true);
                setLoading(false);
                setUpdateTrigger(prev => !prev);
                setUploadSuccess(true);
                resetForm();
            } else if (!isInProgress) {
                clearInterval(pollInterval);
                setIsPolling(false);
                setLoading(false);
                setError('PDF parsing process stopped unexpectedly. Please try again.');
            }
        }, 3000);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSolRecent(true);
        if (!file) {
            setError('Please select a file to upload.');
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/parse-pdf/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            if (response.status === 200) {
                startPolling();
            } else {
                setError('Failed to start PDF parsing. Please try again.');
            }
        } catch (error) {
            console.log(error);
            setError(error.response?.data?.error || 'An error occurred while parsing the PDF.');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsPolling(false);
        setLoading(false);
        resetForm();
    };

    return (
        <div className={`flex flex-col w-full ${disabledStyle}`}>
            <h2 className="text-xl font-bold mb-1 mt-5">
                Step 0 (optional):
                Upload {isEssay ? "essay (grid) rubric" : "assignment "} PDF {!isEssay && "with solutions"}
            </h2>
            <p className="text-sm mb-2">
                {isEssay
                    ? "Uploading a PDF will parse the essay rubric and update the assignment structure. "
                    : "Uploading a PDF will parse the questions and solutions and update the assignment structure. "}
            </p>
            <p className="text-sm font-semibold mb-2">
                {isEssay
                    ? "Make sure all rubric criteria are parsed correctly."
                    : "Images and tables cannot be extracted automatically. Make sure all questions are parsed correctly."}
            </p>

            <div>
                <DragAndDropUpload isPublished={isPublished} onFileChange={handleFileChange}/>
                <div className="flex items-center space-x-4 mt-4">
                    <button
                        type="submit"
                        disabled={!file || loading || isPublished || isPolling}
                        className={`px-10 py-2 rounded-3xl border transition-all duration-300 text-smallish font-medium relative w-[170px] ${
                            loading || isPolling || !file || isPublished
                                ? 'bg-gray-100 text-gray-400 border-gray-300 cursor-not-allowed opacity-75'
                                : `sexy-button border-black desktop:hover:-translate-y-[0.5px] desktop:hover:shadow ${disabledStyle}`
                        }`}
                        onClick={handleSubmit}
                    >
                        {loading || isPolling ? (
                            <>
                                <span className="opacity-0">{submitted ? 'Resubmit' : 'Submit'}</span>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div
                                        className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                </div>
                            </>
                        ) : submitted ? (
                            'Resubmit'
                        ) : (
                            'Submit'
                        )}
                    </button>
                    {(loading || isPolling) && (
                        <button
                            className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    )}
                </div>
            </div>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            {(loading || isPolling) && (
                <p className="text-orange-300 mt-4">Parsing PDF, please stay on the page... Please allow up to 5 minutes
                    for large files.</p>
            )}
            {uploadSuccess && !isPolling && (
                <p className="text-orange-300 mt-4 font-semibold">
                    {isEssay
                        ? "Essay rubric parsing successful. Please make sure the criteria were extracted correctly."
                        : "PDF parsing successful. Please make sure the questions were extracted correctly."}
                </p>
            )}
        </div>
    );
};

export default ParsePDF;