import {useDispatch, useSelector} from 'react-redux';
import {Tooltip} from 'react-tooltip';

import {isNull} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {createSearchParams, Link, useNavigate, useParams} from 'react-router-dom';
import {apiUrl, gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext';
import {formatDateTime} from "../../DueTimeDisplayTable";
import {useQueryParams} from '../../../shared/hooks/use-query-params.tsx';
import {socket} from '../../../socket.js';

import {
    clearAssignmentStudents,
    getAssignmentStudents,
    getLogs
} from '../../../store/teacherSlice.js';
import {DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE} from '../../../utils/pagination.ts';
import ConfirmationModal from '../../ConfirmationModal';
import AIRegrade from './AIRegrade';
import MCQModify from './MCQModify';
import {PDFView} from "../PDFRendered";

import SUnpublishedFlexibleAssignment
    from '../../Student/SUnpublishedFlexibleAssignment/SUnpublishedFlexibleAssignment';
import StudentSearchDropdown from "../TeacherAssignments/TeacherAssignmentSettings/StudentSearchDropdown";

import {BrainIcon, Check, UserCircleIcon} from 'lucide-react';


import {
    PencilIcon,
    SparklesIcon,
    TrashIcon,
    PlusCircleIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import {ExclamationCircleIcon} from "@heroicons/react/16/solid";
import {LaTeXPreviewContent} from "../../LaTeXPreviewContent";

const TDetails = props => {
    const dispatch = useDispatch();

    const {user} = useAuth();
    const {assignment_id, submission_id} = useParams();
    const [submissionData, setSubmissionData] = useState({});
    const [examPaper, setExamPaper] = useState([]);
    const [feedbackItems, setFeedbackItems] = useState({});
    const [editableFeedback, setEditableFeedback] = useState({});
    const [message, setMessage] = useState('');
    const [isEditingInstructorComment, setIsEditingInstructorComment] = useState({});
    const [editableInstructorComment, setEditableInstructorComment] = useState({});
    const instructorCommentRefs = useRef({});
    const [assignment, setAssignment] = useState(null);
    const [studentName, setStudentName] = useState('');
    const [isMCQModifyOpen, setIsMCQModifyOpen] = useState(false);
    const [activeMCQSubQuestionId, setActiveMCQSubQuestionId] = useState(null);
    const navigate = useNavigate();
    const [isRegradeOpen, setIsRegradeOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [regradeData, setRegradeData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
    const [activeSubQuestionId, setActiveSubQuestionId] = useState(null);
    const [isReviewed, setIsReviewed] = useState(false);
    const [assignedStudentId, setAssignedStudentId] = useState(null);
    const [error, setError] = useState(null);
    const [isStudentAssignmentConfirmationOpen, setIsStudentAssignmentConfirmationOpen] =
        useState(false);
    const [newStudentAssignment, setNewStudentAssignment] = useState(null);
    const [regradeInProgress, setRegradeInProgress] = useState({});
    const pollingInterval = useRef(null);
    const [gradePending, setGradePending] = useState(false);
    const [groupStudents, setGroupStudents] = useState([]);
    const [regradeRequests, setRegradeRequests] = useState([]);
    const [nextRegrade, setNextRegrade] = useState(-1);
    const [showSubmissionLog, setShowSubmissionLog] = useState(false);
    const regradeRef = useRef(null);
    const [isReloadMessageShown, setIsReloadMessageShown] = useState(false);
    const [assignmentDetails, setAssignmentDetails] = useState(null);
    const [teachers, setTeachers] = useState(null);
    const [showAllReviewedPopup, setShowAllReviewedPopup] = useState(false);

    // reset
    const [socketApi, setSocketApi] = useState(socket(user.session_token));
    const [isConnected, setIsConnected] = useState(false);
    const [prevSubmissionId, setPrevSubmissionId] = useState(submission_id);

    const [showConnectionErrorPopup, setShowConnectionErrorPopup] = useState(false);

    const {data: students, isLoading: isAssignmentStudentsLoading} = useSelector(
        state => state?.teacherReducer?.assignmentStudents
    );

    const {data: logs, isLoading: isLogsLoading} = useSelector(
        state => state?.teacherReducer?.logs
    );

    const {
        params: {search, pageNumber: scrollPageNumber, itemsPerPage},
        updateSearchParams
    } = useQueryParams({
        search: '',
        pageNumber: DEFAULT_PAGE_NUMBER,
        itemsPerPage: ITEMS_PER_PAGE.default
    });

    useEffect(() => {
        updateSearchParams({
            search: '',
            pageNumber: DEFAULT_PAGE_NUMBER
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        socketApi.on('connect', () => {
            console.log('connected');
            setIsConnected(true);
            setShowConnectionErrorPopup(false);

            socketApi.emit('assignment-join', {
                assignmentId: assignment_id
            });
        });

        socketApi.on('connect_error', err => {
            setShowConnectionErrorPopup(true);
            setTimeout(() => {
                socketApi.connect();
            }, 1000);
        });

        return () => {
            socketApi.emit('assignment-leave', {
                assignmentId: assignment_id
            });

            socketApi.disconnect();
            socketApi.on('disconnect', () => {
                console.log('disconnected');
                setIsConnected(false);
                setShowConnectionErrorPopup(false);
            });
        };
    }, []);

    useEffect(() => {
        if (isConnected) {
            socketApi.emit('submission-join', {
                fullName: `${user.user.first_name} ${user.user.last_name}`,
                submissionId: submission_id,
                id: user.user.id,
                assignmentId: assignment_id
            });
            setPrevSubmissionId(submission_id);
        }
    }, [submission_id, isConnected]);

    useEffect(() => {
        if (isConnected) {
            socketApi.on('submission-teachers', data => {
                setTeachers(data);
                console.log(data);
            });

            socketApi.on('assignment-details', data => {
                setAssignmentDetails(data);
                console.log(data, 'assignment-details');
            });

            socketApi.on('submission-refresh', data => {
                console.log(data, 'submission-refresh');

                if (user.user.id !== data.id) {
                    setIsReloadMessageShown(true);
                    setTimeout(() => setIsReloadMessageShown(false), 2000);

                    getQuestions();
                    getAssignmentSubmission();
                    getAssignment();
                }
            });

            socketApi.on('errors', data => {
                console.error(data);
            });
        }

        return () => {
            socketApi.removeAllListeners();
        };
    }, [isConnected]);

    useEffect(() => {
        getRegrades();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.regrade_question_id]);

    useEffect(() => {
        if (assignment?.is_exam && assignment.course_id && submissionData.user) {
            getStudents();
        }

        return () => dispatch(clearAssignmentStudents());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (assignment?.is_exam && assignment.course_id && submissionData.user && !search) {
            getStudents();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment, submissionData, scrollPageNumber, itemsPerPage]);

    useEffect(() => {
        if (props.regrade_question_id && regradeRef.current) {
            const timer = setTimeout(() => {
                regradeRef.current?.scrollIntoView({behavior: 'smooth'});
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [props.regrade_question_id, examPaper, regradeRef]);

    useEffect(() => {
        fetchGroupStudents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment]);

    useEffect(() => {
        getQuestions();
        getAssignmentSubmission();
        getAssignment();
        return () => stopPolling();
    }, [assignment_id, submission_id]);

    useEffect(() => {
        if (assignedStudentId) {
            getAllLogs();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment, assignedStudentId]);

    const socketSubmissionUpdate = () =>
        socketApi.emit('submission-update', {
            submissionId: submission_id,
            assignmentId: assignment_id,
            id: user.user.id
        });

    const getRegrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/regrade-requests/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const regradesForTeacher = response.data;
            console.log(regradesForTeacher);
            setRegradeRequests(regradesForTeacher);
            const currentIndex = regradesForTeacher
                .map(item => {
                    return item.id;
                })
                .indexOf(Number(submission_id));
            const feedback = regradesForTeacher[currentIndex]?.feedback;
            var nextFeedback = -1;
            var found = false;
            for (const feedbackItem in feedback) {
                if (found && feedback[feedbackItem].regrade_request) {
                    nextFeedback = feedbackItem;
                    setNextRegrade(nextFeedback);
                    break;
                }
                if (feedbackItem == props.regrade_question_id) {
                    found = true;
                }
            }
            return;
        } catch (error) {
            console.log(error);
        }
    };

    // Fetching assignment submission details
    const getAssignmentSubmission = async () => {
        setIsSubmissionLoading(true);

        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/submission/${submission_id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const data = response.data;

            setSubmissionData(data);
            setFeedbackItems(data?.submitted ? data?.feedback : {});
            setIsReviewed(data.is_reviewed);
            setAssignedStudentId(data.user.id);
            setStudentName(`${data.user.first_name} ${data.user.last_name}`);
        } catch (error) {
            setMessage(error.response.data.error);
        } finally {
            setIsSubmissionLoading(false);
        }
    };

    const getStudents = async () => {
        await dispatch(
            getAssignmentStudents({
                classId: assignment.course_id,
                token: user.session_token,
                search,
                pageNumber: Number(scrollPageNumber),
                itemsPerPage: Number(itemsPerPage)
            })
        );

        setAssignedStudentId(submissionData.user.id);
    };

    const getAllLogs = async () => {
        await dispatch(
            getLogs({
                userId: assignedStudentId,
                token: user.session_token,
                assignmentId: assignment_id
            })
        );
    };

    const handleConfirmStudentAssignment = async () => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/assign-student/`,
                body: {new_student_id: newStudentAssignment.id},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            setAssignedStudentId(newStudentAssignment.id);
            setStudentName(newStudentAssignment.full_name);

            // Refresh the submission data
            getAssignmentSubmission();
            setIsStudentAssignmentConfirmationOpen(false);
        } catch (error) {
            console.error('Failed to assign student:', error);
            setError('Failed to assign student. Please try again.');
        }
    };

    const fetchGroupStudents = async () => {
        try {
            if (!assignedStudentId) {
                return;
            }
            const response = await gradeApi.get({
                path: `/api/assignment/get_students/${assignment.id}/student/${assignedStudentId}`, // Make sure to use assignment.id, not aid
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setGroupStudents(response.data); // Assuming response.data is an array
        } catch (error) {
            console.error('Error fetching group students:', error);
            // Consider setting an error state here instead of using setError
        }
    };

    const handleMarkReviewed = async () => {
        try {
            const newReviewedStatus = !isReviewed;
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/mark-reviewed/`,
                body: {is_reviewed: newReviewedStatus},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setIsReviewed(newReviewedStatus);
            socketSubmissionUpdate();
        } catch (error) {
            console.error('Failed to update review status:', error);
            setMessage('Failed to update review status.');
        }
    };

    const getIsDisabledGradingButton = subQuestionId => {
        return !!assignmentDetails?.activeSubmissionsQuestionRegradeRooms.find(
            obj => obj.id !== user.user.id && obj.questionId === subQuestionId
        );
    };

    const startPolling = () => {
        if (!pollingInterval.current) {
            pollingInterval.current = setInterval(getQuestions, 5000);
        }
    };

    const stopPolling = () => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
            if (!isRegradeOpen) {
                window.location.reload();
            }
        }
    };

    const getQuestions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const questionsFromDB = response.data;

            if (pollingInterval.current) {
                // If we're polling, only update subquestions that are being regraded
                setExamPaper(prevExamPaper =>
                    prevExamPaper.map(question => ({
                        ...question,
                        subQuestions: question.subQuestions.map(subQuestion => {
                            // Find corresponding subquestion from API response
                            const updatedSubQuestion = questionsFromDB.questions
                                .flatMap(q => q.subQuestions)
                                .find(sq => sq.id === subQuestion.id);

                            // Only update if this subquestion is being regraded
                            if (regradeInProgress[subQuestion.id]) {
                                return updatedSubQuestion || subQuestion;
                            }
                            return subQuestion;
                        })
                    }))
                );
            } else {
                // If not polling, update everything as normal
                setExamPaper(questionsFromDB.questions);
            }

            // Check for regrade_in_progress
            const newRegradeStatus = {};
            questionsFromDB.questions.forEach(question => {
                question.subQuestions.forEach(subQuestion => {
                    if (subQuestion.regrade_in_progress) {
                        newRegradeStatus[subQuestion.id] = true;
                    }
                });
            });
            setRegradeInProgress(newRegradeStatus);

            // Start or stop polling based on regrade status
            if (Object.values(newRegradeStatus).some(status => status)) {
                startPolling();
            } else {
                stopPolling();
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Fetching assignment details
    const getAssignment = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/only/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAssignment(response.data);
        } catch (error) {
            setMessage('Failed to fetch assignment details');
        }
    };

    // Update student name when submission data changes
    useEffect(() => {
        if (submissionData.user) {
            setStudentName(`${submissionData.user.first_name} ${submissionData.user.last_name}`);
        }
    }, [submissionData]);

    const clickRight = () => {
        if (!props.submissionIds || props.submissionIds.length === 0) {
            console.error('No submissions available for navigation');
            return;
        }

        const currentIndex = props.submissionIds.indexOf(Number(submission_id));
        let prevIndex =
            currentIndex === -1
                ? props.submissionIds.length - 1
                : (currentIndex - 1 + props.submissionIds.length) % props.submissionIds.length;

        navigateToSubmission(prevIndex, -1);
    };

    const clickUnseen = () => {
        let prevIndex;
        if (!props.submissionUnseenIds || props.submissionUnseenIds.length === 0) {
            console.error('No submissions available for navigation');
            return;
        }

        const availableIds = props.submissionUnseenIds.filter(
            id => !assignmentDetails?.activeSubmissionsRooms.includes(String(id))
        );

        const currentIndex = props.submissionUnseenIds.indexOf(Number(submission_id));

        if (currentIndex === availableIds.length || currentIndex === -1) {
            prevIndex = 0;
        } else if (currentIndex !== -1 && currentIndex < availableIds.length) {
            prevIndex = currentIndex;
        } else {
            prevIndex = null;
        }

        navigateToSubmission(prevIndex, -1, true, availableIds);
    };

    const clickLeft = () => {
        if (!props.submissionIds || props.submissionIds.length === 0) {
            console.error('No submissions available for navigation');
            return;
        }

        const currentIndex = props.submissionIds.indexOf(Number(submission_id));
        let nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % props.submissionIds.length;

        navigateToSubmission(nextIndex, 1);
    };

    const clearEditableFeedback = () => {
        setEditableFeedback({});
    };

    const navigateToSubmission = (startIndex, direction, isUnseenOnly = false, availableIds) => {
        if (props.submissionIds.length === 1) {
            return;
        }
        clearEditableFeedback(); // Clear editable feedback before navigation

        let currentIndex = startIndex;
        const ids = isUnseenOnly ? availableIds : props.submissionIds;
        const maxAttempts = ids.length;

        if (isNull(currentIndex) || maxAttempts === 0) {
            setShowAllReviewedPopup(true);
            setTimeout(() => {
                setShowAllReviewedPopup(false);
                navigate({
                    pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions`,
                    search: `?${createSearchParams({
                        search,
                        pageNumber: scrollPageNumber,
                        itemsPerPage
                    })}`
                });
            }, 3000);
            return;
        }

        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            const nextSubmissionId = ids[currentIndex];
            if (nextSubmissionId !== null) {
                if (props.regrade_question_id) {
                    for (var request in regradeRequests) {
                        if (regradeRequests[request].id === nextSubmissionId) {
                            var subquestion_id = null;
                            for (var subquestion in regradeRequests[request].feedback) {
                                if (regradeRequests[request].feedback[subquestion].regrade_request) {
                                    subquestion_id = subquestion;
                                    break;
                                }
                            }
                            navigate({
                                pathname: `/tclasses/${regradeRequests[request].assignment.course.id}/tassignments/${regradeRequests[request].assignment.id}/tsubmissions/${nextSubmissionId}/tdetails/${subquestion_id}`,
                                search: `?${createSearchParams({
                                    search,
                                    pageNumber: scrollPageNumber,
                                    itemsPerPage
                                })}`
                            });
                            window.location.reload();
                            return;
                        }
                    }
                } else {
                    navigate({
                        pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${nextSubmissionId}/tdetails`,
                        search: `?${createSearchParams({
                            search,
                            pageNumber: scrollPageNumber,
                            itemsPerPage
                        })}`
                    });
                    window.location.reload();
                    return;
                }
            }
            currentIndex = (currentIndex + direction + ids.length) % ids.length;
        }

        console.error('No valid submissions found to navigate to');
    };
    const hasRubricItem = (subquestionId, rubricId) => {
        if (!feedbackItems) {
            return false;
        }
        if (!subquestionId) {
            return false;
        }
        if (subquestionId in feedbackItems) {
            let rubricItems = feedbackItems[subquestionId]?.rubric_items;
            for (let i = 0; i < rubricItems.length; i++) {
                if (rubricItems[i].id === rubricId) {
                    return true;
                }
            }
        }
        return false;
    };

    const getFeedback = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].feedback;
        }
        return '';
    };

    const getInstructorFeedback = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].instructor_text;
        }
        return '';
    };

    const getRR = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].regrade_request;
        }
        return '';
    };

    const getRRText = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].regrade_text;
        }
        return '';
    };

    const handleRubricItemChange = (subquestionId, rubricItem) => () => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/grade/`,
                body: {
                    subquestionId: subquestionId,
                    rubric: rubricItem
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                // console.log(error);
                setGradePending(false);
            })
            .then(response => {
                // Merge the response with existing feedback items instead of replacing
                setFeedbackItems(prevFeedbackItems => {
                    const newFeedbackItems = {...prevFeedbackItems};

                    // Update the feedback items based on the response
                    Object.keys(response.data.feedback).forEach(key => {
                        newFeedbackItems[key] = {
                            ...prevFeedbackItems[key], // Keep existing instructor_text
                            ...response.data.feedback[key], // Update with new data
                            // Preserve instructor_text if it exists
                            instructor_text: prevFeedbackItems[key]?.instructor_text || response.data.feedback[key]?.instructor_text
                        };
                    });

                    return newFeedbackItems;
                });

                setSubmissionData({...submissionData, grade: response.data.grade});
                setGradePending(false);
                socketSubmissionUpdate();
            });
    };

    const isEditing = subquestionId => editableFeedback.hasOwnProperty(subquestionId);

    const handleRegradeReq = subquestionId => {
        if (subquestionId in feedbackItems) {
            handleRegradeReqDel(subquestionId);
        }
    };

    const handleRegradeReqDel = subquestionId => {
        const submissionID = submissionData.id;

        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submissionID}/regrade-request/`,
                body: {
                    subquestionId: subquestionId,
                    regradeRequest: false
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating regrade request:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            regrade_request: false
                        }
                    }));
                }
            });
    };

    const renderFeedback = feedback => {
        return (
            <div className="mb-2">
                <LaTeXPreviewContent content={feedback}/>
            </div>
        );
    };


    const handleRegradeClick = subQuestionId => {
        setActiveSubQuestionId(subQuestionId);
        setIsRegradeOpen(true);

        socketApi.emit('submission-question-regrade-join', {
            submissionId: submission_id,
            id: user.user.id,
            assignmentId: assignment_id,
            questionId: subQuestionId
        });
    };

    const handleRegradeClose = () => {
        setIsRegradeOpen(false);
        setActiveSubQuestionId(null);

        socketApi.emit('submission-question-regrade-leave', {
            submissionId: submission_id,
            id: user.user.id,
            assignmentId: assignment_id,
            questionId: activeSubQuestionId
        });

        window.location.reload();
    };

    const handleMCQModifyClick = subQuestionId => {
        setActiveMCQSubQuestionId(subQuestionId);
        setIsMCQModifyOpen(true);

        socketApi.emit('submission-question-regrade-join', {
            submissionId: submission_id,
            id: user.user.id,
            assignmentId: assignment_id,
            questionId: subQuestionId
        });
    };

    const handleRemoveFeedback = (subquestionId) => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/feedback/`,
                body: {
                    subquestionId: subquestionId,
                    feedback: ""
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error removing AI feedback:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            feedback: ""
                        }
                    }));
                    socketSubmissionUpdate();
                }
            });
    };

    const handleAddInstructorFeedback = (subquestionId) => {
        // Set up the editing state for this specific subquestion
        setEditableInstructorComment(prev => ({
            ...prev,
            [subquestionId]: getInstructorFeedback(subquestionId) || ''
        }));

        setIsEditingInstructorComment(prev => ({
            ...prev,
            [subquestionId]: true
        }));

        // Focus on the textarea after it renders
        setTimeout(() => {
            if (instructorCommentRefs.current[subquestionId]) {
                instructorCommentRefs.current[subquestionId].focus();
            }
        }, 0);
    };

    const handleInstructorCommentChange = (subquestionId, value) => {
        setEditableInstructorComment(prev => ({
            ...prev,
            [subquestionId]: value
        }));
    };


    const handleMCQModifySubmit = async updatedMcqOptions => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/update-mcq/`,
                body: {
                    subQuestionId: activeMCQSubQuestionId,
                    mcqOptions: updatedMcqOptions
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            // Wait for 2 seconds before refreshing
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Refresh the questions after delay
            // await getQuestions();
            window.location.reload();
            handleMCQModifyClose();
        } catch (error) {
            console.error('Failed to update MCQ options:', error);
            setError('Failed to update MCQ options. Please try again.');
        }
    };

    const handleMCQModifyClose = () => {
        setIsMCQModifyOpen(false);
        setActiveMCQSubQuestionId(null);

        socketApi.emit('submission-question-regrade-leave', {
            submissionId: submission_id,
            id: user.user.id,
            assignmentId: assignment_id,
            questionId: activeSubQuestionId
        });
    };


// Function to handle saving instructor comment
    const handleInstructorCommentSave = (subquestionId) => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/instructor-feedback/`,
                body: {
                    subquestionId: subquestionId,
                    instructorText: editableInstructorComment[subquestionId]
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating instructor comment:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            instructor_text: editableInstructorComment[subquestionId]
                        }
                    }));

                    // Clear the editing state
                    setIsEditingInstructorComment(prev => {
                        const {[subquestionId]: _, ...rest} = prev;
                        return rest;
                    });

                    setEditableInstructorComment(prev => {
                        const {[subquestionId]: _, ...rest} = prev;
                        return rest;
                    });

                    socketSubmissionUpdate();
                }
            });
    };

// Function to cancel instructor comment editing
    const handleInstructorCommentCancel = (subquestionId) => {
        setIsEditingInstructorComment(prev => {
            const {[subquestionId]: _, ...rest} = prev;
            return rest;
        });

        setEditableInstructorComment(prev => {
            const {[subquestionId]: _, ...rest} = prev;
            return rest;
        });
    };

// Function to check if instructor comment is being edited
    const isEditingInstructor = (subquestionId) =>
        isEditingInstructorComment.hasOwnProperty(subquestionId);
    const handleRegradeSubmit = (
        newRubric,
        regradeInstructions,
        regradeFeedbackInstructions,
        solution
    ) => {
        setRegradeData({newRubric, regradeInstructions, regradeFeedbackInstructions, solution});
        setIsConfirmationOpen(true);
    };

    const handleConfirmRegrade = async () => {
        setIsConfirmationOpen(false);
        setIsLoading(true);
        try {
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/ai-regrade/`,
                body: {
                    subQuestionId: activeSubQuestionId,
                    newRubric: regradeData.newRubric,
                    regradeInstructions: regradeData.regradeInstructions,
                    regradeFeedbackInstructions: regradeData.regradeFeedbackInstructions,
                    solution: regradeData.solution
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log('AI-Regrade initiated:', response.data);
            getQuestions(); // Fetch the updated questions
            socketApi.emit('submission-question-regrade-leave', {
                submissionId: submission_id,
                id: user.user.id,
                assignmentId: assignment_id,
                questionId: activeSubQuestionId
            });
            window.location.reload();
            setMessage('AI-Regrade initiated. The system will update the grades shortly.');
        } catch (error) {
            console.error('AI-Regrade failed:', error);
            setMessage('AI-Regrade failed. Please try again.');
        } finally {
            setIsLoading(false);
            setIsRegradeOpen(false);
            setActiveSubQuestionId(null);
        }
    };

    const SortRubricItemsAndDisplay = ({subQuestion}) => {
        subQuestion.rubricItems.sort((a, b) => {
            if (a.value == b.value) {
                return 0;
            }
            return a.value > b.value ? -1 : 1;
        });

        return (
            <div className="rounded-xl overflow-hidden border border-gray-300">
                {subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
                    <div
                        key={rubricIndex}
                        className={`
                    flex items-center px-3 py-2 cursor-pointer
                    ${
                            hasRubricItem(subQuestion.id, rubricItem.id)
                                ? 'bg-orange-200'
                                : 'bg-white hover:bg-gray-50'
                        }
                    ${rubricIndex !== 0 ? 'border-t border-gray-300' : ''}
                `}
                        onClick={handleRubricItemChange(subQuestion.id, rubricItem)}
                    >
                        <div className="flex items-center flex-1">
                            <div
                                className={`
                        w-5 h-5 rounded-full border border-gray-400 
                        flex items-center justify-center mr-3
                        ${
                                    hasRubricItem(subQuestion.id, rubricItem.id)
                                        ? 'bg-gray-500 border-gray-700'
                                        : ''
                                }
                    `}
                            >
                                {hasRubricItem(subQuestion.id, rubricItem.id) && (
                                    <Check className="w-3 h-3 text-white stroke-2"/>
                                )}
                            </div>
                            <div className="flex items-center">
                                <span className="text-xl font-medium mr-3">{rubricItem.value}</span>
                                <div className="text-sm">
                                    <LaTeXPreviewContent content={rubricItem.descriptor}/>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const handleScroll = () => {
        const currentIndex = props.submissionIds.indexOf(Number(submission_id));
        navigate(
            `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${props.submissionIds[currentIndex]}/tdetails/${nextRegrade}`
        );
    };

    return (
        <div
            key={`${assignment_id}-${submission_id}`}
            className="flex flex-col w-full h-screen overflow-hidden"
        >
            {/* navigation trail */}
            <div className="flex flex-col fixed w-full pb-5 pt-10 bg-white px-5 mb-2 z-50">
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-2">
                        <Link to="/tclasses" className="text-black text-lg font-medium ml-4">
                            My Classes
                        </Link>
                        <Link to={`/tclasses/${assignment?.course_id}/tassignments`}>
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                            </button>
                        </Link>
                        <Link
                            to={`/tclasses/${assignment?.course_id}/tassignments`}
                            className="text-black text-lg font-medium ml-4"
                        >
                            {assignment?.course?.name.length > 40
                                ? assignment?.course?.name.substring(0, 37) + '...'
                                : assignment?.course?.name}
                        </Link>
                        <Link
                            to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
                        >
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                            </button>
                        </Link>
                        <Link
                            to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
                            className="text-black text-lg font-medium ml-4"
                        >
                            {assignment?.title.length > 40
                                ? assignment?.title.substring(0, 37) + '...'
                                : assignment?.title}
                        </Link>
                        <Link
                            to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
                        >
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                        </Link>
                        <h1 className="text-black text-lg font-medium ml-4">
                            {studentName.length > 20 ? studentName.substring(0, 17) + '...' : studentName}
                        </h1>
                    </div>

                    {isReloadMessageShown && (
                        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                            Change detected, reloading
                        </div>
                    )}

                    <div className="flex items-center justify-between">
                        {teachers &&
                            teachers.entities?.length > 0 &&
                            (teachers?.entities?.length <= 3 ? (
                                (teachers?.entities ?? []).map(t => (
                                    <div key={t.id} className="relative flex items-center space-x-2 ml-2">
                    <span
                        id={`teacher${t.id}`}
                        className={`relative cursor-pointer text-lg w-7 h-7 rounded-full border border-gray-400 
                            flex items-center justify-center p-4 ${t.id === user.user.id ? 'border-orange-200' : 'border-gray-400'}`}
                    >
                      {t.fullName[0].toUpperCase()}
                    </span>
                                        <Tooltip anchorSelect={`#teacher${t.id}`} place="bottom">
                                            {t.fullName}
                                        </Tooltip>
                                    </div>
                                ))
                            ) : (
                                <>
                                    {(teachers?.entities?.slice(0, 3) ?? []).map(t => (
                                        <div key={t.id} className="relative flex items-center space-x-2 ml-2">
                      <span
                          id={`teacher${t.id}`}
                          className={`relative cursor-pointer text-lg w-7 h-7 rounded-full border border-gray-400 
                            flex items-center justify-center p-4 ${t.id === user.user.id ? 'border-orange-200' : 'border-gray-400'}`}
                      >
                        {t.fullName[0].toUpperCase()}
                      </span>
                                            <Tooltip anchorSelect={`#teacher${t.id}`} place="bottom">
                                                {t.fullName}
                                            </Tooltip>
                                        </div>
                                    ))}
                                    <div className="relative flex items-center space-x-2 ml-2">
                    <span
                        id="teachers-hidden"
                        className={`relative cursor-pointer text-lg w-7 h-7 rounded-full border border-gray-400 flex items-center justify-center p-4`}
                    >
                      ...
                    </span>
                                        <Tooltip anchorSelect="#teachers-hidden" place="bottom">
                                            {(teachers?.entities?.slice(3) ?? []).map(t => (
                                                <div key={t.id} className="flex gap-2 items-center justify-center">
                                                    <p>{t.fullName}</p>
                                                    {t.role === 'owner' ? (
                                                        <span
                                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-red-100 text-red-800">
                              I
                            </span>
                                                    ) : (
                                                        <span
                                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-green-100 text-green-800">
                              C
                            </span>
                                                    )}
                                                </div>
                                            ))}
                                        </Tooltip>
                                    </div>
                                </>
                            ))}
                        <div className="flex items-center space-x-2 ml-5">
                            <button
                                className="text-gray-600 text-l font-medium flex items-center"
                                onClick={clickRight}
                            >
                                <span>Back</span>
                                <i className="fas fa-sort-up -rotate-90 text-2xl ml-3"></i>
                            </button>
                            <button
                                className="text-gray-600 text-l font-medium flex items-center"
                                onClick={clickLeft}
                            >
                                <i className="fas fa-sort-up rotate-90 text-2xl mr-3"></i>
                                <span>Next</span>
                            </button>
                            <button
                                className="text-gray-600 text-l hover:bg-gray-200 hover:text-gray-800 font-medium flex items-center border border-black rounded-full p-2"
                                onClick={clickUnseen}
                            >
                                <span>Next Unseen</span>
                                <div className="ml-2 flex flex-row">
                                    <i className="fas fa-play text-sm"></i>
                                    <i className="fas fa-play text-sm"></i>
                                </div>
                            </button>
                        </div>
                        <div className="flex items-center ml-5 bg-grey-700 rounded-full p-2 shadow-md">
                            <input
                                type="checkbox"
                                id="markReviewedCheckbox"
                                checked={isReviewed}
                                className="sr-only peer"
                                onChange={handleMarkReviewed}
                            />
                            <label
                                htmlFor="markReviewedCheckbox"
                                className="cursor-pointer w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out mr-2"
                            ></label>
                            <label htmlFor="markReviewedCheckbox" className="text-sm font-medium text-black">
                                Mark Reviewed
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            {showAllReviewedPopup && (
                <>
                    {/* Blurred background overlay */}
                    <div
                        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50"
                        style={{backdropFilter: 'blur(4px)'}}
                    />

                    {/* Popup */}
                    <div className="fixed inset-0 flex items-center justify-center z-[99]">
                        <div
                            className="bg-white rounded-xl shadow-lg p-6 border border-gray-200 transform transition-all animate-fade-in">
                            <div className="flex items-center">
                                <div className="bg-orange-100 rounded-full p-3 mr-4">
                                    <img src="/assets/checkmark.png" className="size-6" alt="Success"/>
                                </div>
                                <p className="text-lg font-medium">
                                    All submissions reviewed. Taking you to the submissions list.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* main content */}
            <div
                className="flex fixed left-8 w-[calc(100%-1rem)] bottom-0 top-0 h-[calc(100vh-7rem)] mt-[7rem] overflow-y-hidden">
                {/* student work */}
                <div className="w-1/2 p-4 h-full overflow-hidden flex flex-col">
                    {submissionData?.submitted && (
                        submissionData.essay_text ? (
                            <div className="w-full h-full overflow-auto bg-white border border-gray-300 p-4 rounded-xl">
                                <pre className="whitespace-pre-wrap font-sans">{submissionData.essay_text}</pre>
                            </div>
                        ) : submissionData.assignment?.type === 'flexible_problem_set' ? (
                            <div
                                className="bg-white h-full border border-gray-200 rounded-lg shadow-sm p-6 overflow-auto">
                                <SUnpublishedFlexibleAssignment
                                    isReadMode
                                    assignment_id={submissionData.assignment.id}
                                    assignment={submissionData.assignment}
                                    class_id={props.class_id}
                                    studentId={submissionData.user.id}
                                />
                            </div>
                        ) : (
                            <div className="w-full h-full border border-gray-300 rounded-xl overflow-hidden">
                                <div className="w-full h-full relative">
                                    <PDFView
                                        url={`${apiUrl}/api/pdf-file/${submissionData.file.filename}`}
                                        className="absolute inset-0"
                                    />
                                </div>
                            </div>
                        )
                    )}
                </div>

                <div className="w-1/2 overflow-y-auto p-8">
                    {assignment?.is_exam && (
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Assigned to:</label>
                            <StudentSearchDropdown
                                search={search}
                                isLoading={isAssignmentStudentsLoading}
                                pageNumber={Number(scrollPageNumber)}
                                itemsPerPage={itemsPerPage}
                                students={students}
                                currentStudentName={studentName}
                                updateSearchParams={updateSearchParams}
                                onStudentSelect={student => {
                                    setNewStudentAssignment(student);
                                    setIsStudentAssignmentConfirmationOpen(true);
                                }}
                            />
                            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                        </div>
                    )}
                    {/* Score and Late Submission Section */}
                    <div className="flex flex-col">
                        <h2 className="text-3xl font-bold mb-4">
                            Score: {submissionData?.grade}/{assignment?.max_score}
                        </h2>

                        {submissionData?.late && assignment?.course?.late_gets_zero && (
                            <div className="mb-6">
                                <div className="text-red-600 font-medium mb-2">
                                    This submission is late, so a score of 0 was assigned.
                                </div>
                                <button
                                    className=" px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                    onClick={async () => {
                                        try {
                                            await gradeApi.patch({
                                                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/approve-late/`,
                                                config: {
                                                    headers: {
                                                        Authorization: `Bearer ${user.session_token}`
                                                    }
                                                }
                                            });
                                            // Refresh submission data after approval
                                            await getAssignmentSubmission();
                                        } catch (error) {
                                            console.error('Error approving late submission:', error);
                                            setError('Failed to approve late submission');
                                        }
                                    }}
                                >
                                    Approve Late Submission
                                </button>
                            </div>
                        )}
                    </div>
                    {/* Submission Log Section */}
                    <div className="flex flex-col w-full mb-5">
                        <button
                            type="button"
                            className="flex items-center text-sm mb-2"
                            onClick={() => setShowSubmissionLog(!showSubmissionLog)}
                        >
                            <img
                                src="/assets/back_arrow.png"
                                className={`size-5 opacity-60 transform ${showSubmissionLog ? 'rotate-90' : '-rotate-90'} mr-2`}
                                alt="Toggle submission log"
                            />
                            Submission Log
                        </button>
                    </div>

                    {showSubmissionLog && (
                        <div className="flex flex-col w-full">
                            {logs?.entities?.length > 0 ? (
                                logs.entities.map((log, index) => (
                                    <div key={index} className="mb-2 ml-4">
                                        <p className="text-sm text-gray-700">
                                            {logs.totalCount - index}. Submitted at{' '}
                                            {formatDateTime(log.submissionDate, user.user.timezone)} by{' '}
                                            <span
                                                id={`user${log.firstName + log.userId}`}
                                                className="relative cursor-pointer hover:text-gray-900"
                                            >
                        <strong>{log.fullName}</strong>
                      </span>
                                        </p>
                                        <Tooltip anchorSelect={`#user${log.firstName + log.userId}`} place="bottom">
                                            <p>ID: {log.userId}</p>
                                            <p>Email: {log.email}</p>
                                        </Tooltip>
                                    </div>
                                ))
                            ) : (
                                <p className="text-sm text-gray-700 ml-4">No submissions made yet.</p>
                            )}
                        </div>
                    )}
                    {groupStudents.length > 0 && (
                        <div>
                            <p>Group Members:</p>
                            <p>
                                {Array.isArray(groupStudents) && groupStudents.length > 0
                                    ? groupStudents.map((student, index) => (
                                        <span key={index}>
                        {student[0]} ({student[1]}){index < groupStudents.length - 1 ? ', ' : ''}
                      </span>
                                    ))
                                    : 'No students to display'}
                            </p>
                        </div>
                    )}
                    {examPaper.map((question, questionIndex) => (
                        <div key={questionIndex} className="mb-8">
                            {question.subQuestions.map((subQuestion, subQuestionIndex) => {
                                const isDisabledGrading = getIsDisabledGradingButton(subQuestion.id);
                                const itemProps = subQuestion.id == props.regrade_question_id ? {ref: regradeRef} : {};
                                return (
                                    <div
                                        key={subQuestionIndex}
                                        className={`mb-6 ml-4 ${regradeInProgress[subQuestion.id] ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''}`}
                                        {...itemProps}
                                    >
                                        {regradeInProgress[subQuestion.id] && (
                                            <div
                                                className="mt-2 mb-4 p-3 bg-blue-50 border-l-4 border-blue-400 text-blue-700 rounded">
                                                ⏳ This subquestion is being regraded...
                                            </div>
                                        )}

                                        {/* Question header styling */}
                                        {assignment?.type === 'flexible_problem_set' ? (
                                            <>
                                                <h2 className="text-xl font-semibold mb-4 text-gray-700">{`Question ${questionIndex + 1}:`}</h2>
                                                {!question.is_without_sub_questions && (
                                                    <h3 className="font-medium mb-2 text-gray-700">
                                                        {`${String.fromCharCode(97 + subQuestionIndex)}) ${subQuestion.question}`}
                                                    </h3>
                                                )}
                                            </>
                                        ) : (
                                            <h3 className="font-semibold mb-1 pt-4 text-gray-700">
                                                {questionIndex + 1}. {String.fromCharCode(97 + subQuestionIndex)})
                                            </h3>
                                        )}

                                        {/* Essay question styling */}
                                        {assignment?.type === 'essay' && (
                                            <p className="font-medium mb-1 pt-4 text-gray-700">{subQuestion.question}</p>
                                        )}

                                        {/* Rubric section */}
                                        <div className="flex flex-col gap-2 mb-2 mt-3">
                                            <SortRubricItemsAndDisplay subQuestion={subQuestion}/>
                                        </div>

                                        {/* Feedback section */}
                                        <div className="mt-4">
                                            {!isEditing(subQuestion.id) && (
                                                <>
                                                    {/* AI Feedback Section */}
                                                    {getFeedback(subQuestion.id) && (
                                                        <div
                                                            className="mb-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
                                                            <div className="flex justify-between items-start mb-2">
                                                                <h4 className="font-medium text-gray-600 text-sm uppercase tracking-wide flex items-center">
                                                                    <BrainIcon
                                                                        className="w-5 h-5 mr-2"/> {/* AI icon */}
                                                                    AI Feedback
                                                                </h4>
                                                                <button
                                                                    className="hover:text-red-700 transition-colors"
                                                                    title="Remove AI feedback"
                                                                    onClick={() => handleRemoveFeedback(subQuestion.id)}
                                                                >
                                                                    <TrashIcon className="w-5 h-5"/>
                                                                </button>
                                                            </div>
                                                            <div className="text-gray-700 leading-relaxed">
                                                                {renderFeedback(getFeedback(subQuestion.id))}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* Instructor Comment Section */}
                                                    <div
                                                        className="p-4 bg-white rounded-lg border border-gray-200 shadow-sm">
                                                        <div className="flex justify-between items-start mb-2">
                                                            <h4 className="font-medium text-gray-600 text-sm uppercase tracking-wide flex items-center">
                                                                <UserCircleIcon
                                                                    className="w-5 h-5 mr-2 text-gray-400"/> {/* Instructor icon */}
                                                                Instructor Comments
                                                            </h4>
                                                            <button
                                                                className="hover:text-green-700 transition-colors"
                                                                title="Add instructor comment"
                                                                onClick={() => handleAddInstructorFeedback(subQuestion.id)}
                                                            >
                                                                <PlusCircleIcon className="w-5 h-5"/>
                                                            </button>
                                                        </div>

                                                        {isEditingInstructor(subQuestion.id) ? (
                                                            <div className="space-y-3">
            <textarea
                ref={el => (instructorCommentRefs.current[subQuestion.id] = el)}
                value={editableInstructorComment[subQuestion.id]}
                className="w-full p-3 border border-[rgba(228,177,121,0.2)] rounded-lg focus:ring-2 focus:ring-[rgba(228,177,121,0.3)] focus:border-[#e4b17a]"
                rows="3"
                placeholder="Add your instructor comment here..."
                onChange={e => handleInstructorCommentChange(subQuestion.id, e.target.value)}
            />
                                                                <div className="flex justify-end gap-3 mt-8">
                                                                    <button
                                                                        type="button"
                                                                        className="px-5 py-2.5 rounded-xl border border-gray-200 text-gray-600 text-[0.875rem] font-medium hover:bg-gray-50 transition-all duration-200 hover:transform hover:-translate-y-0.5"
                                                                        onClick={() => handleInstructorCommentCancel(subQuestion.id)}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        className="px-5 py-2.5 rounded-xl bg-gradient-to-br from-[#e4b17a] to-[#c99b69] text-white text-[0.875rem] font-medium hover:from-[#c99b69] hover:to-[#b38a5e] transition-all duration-200 hover:transform hover:-translate-y-0.5 shadow-sm hover:shadow"
                                                                        onClick={() => handleInstructorCommentSave(subQuestion.id)}
                                                                    >
                                                                        Save Comment
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="text-black">
                                                                {getInstructorFeedback(subQuestion.id) ? (
                                                                    <div className="text-black">
                                                                        {getInstructorFeedback(subQuestion.id)}
                                                                    </div>
                                                                ) : (
                                                                    <p className="text-black italic opacity-60 flex items-center">
                                                                        No instructor comments added yet
                                                                    </p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {getRR(subQuestion.id) && (
                                                        <div
                                                            className="mt-4 p-4 bg-gradient-to-r from-[rgba(228,177,121,0.08)] to-[rgba(228,177,121,0.04)] border-l-4 border-[#e4b17a] rounded-lg transition-all duration-200 hover:shadow-sm">
                                                            <div className="flex justify-between items-start">
                                                                <h4 className="font-medium text-[#8c6b4a] mb-2 flex items-center gap-2">
                                                                    <ExclamationCircleIcon
                                                                        className="w-5 h-5 text-[#e4b17a]"/>
                                                                    Regrade Request
                                                                </h4>
                                                                <button
                                                                    className="text-[#e4b17a] hover:text-[#c99b69] transition-all duration-200 hover:transform hover:-translate-y-0.5"
                                                                    onClick={() => handleRegradeReq(subQuestion.id)}
                                                                >
                                                                    <XMarkIcon className="w-5 h-5"/>
                                                                </button>
                                                            </div>
                                                            <p className="text-[#a17c57]">{getRRText(subQuestion.id)}</p>
                                                        </div>
                                                    )}

                                                    {/* Fine Tune Grading Button */}
                                                    <div className="mt-4 flex justify-end">
                                                        <button
                                                            disabled={isDisabledGrading}
                                                            className={`px-4 py-2 flex items-center gap-2 ${
                                                                subQuestion.questionType === 'mcq' || subQuestion.questionType === 'bubble'
                                                                    ? 'text-gray-600 hover:bg-gray-50'
                                                                    : 'text-amber-600 hover:bg-amber-50'
                                                            } rounded-lg transition-colors ${isDisabledGrading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                            onClick={() => {
                                                                subQuestion.questionType === 'mcq' || subQuestion.questionType === 'bubble'
                                                                    ? handleMCQModifyClick(subQuestion.id)
                                                                    : handleRegradeClick(subQuestion.id)
                                                            }}
                                                        >
                                                            {subQuestion.questionType === 'mcq' || subQuestion.questionType === 'bubble' ? (
                                                                <>
                                                                    <PencilIcon className="w-5 h-5"/>
                                                                    Modify Answer
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <SparklesIcon className="w-5 h-5"/>
                                                                    Fine Tune Grading
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                    ))}


                </div>
            </div>

            {isRegradeOpen && (
                <AIRegrade
                    subQuestion={examPaper
                        .flatMap(q => q.subQuestions)
                        .find(sq => sq.id === activeSubQuestionId)}
                    isEssay={assignment?.type === 'essay'}
                    onClose={handleRegradeClose}
                    onSubmit={handleRegradeSubmit}
                />
            )}

            {isMCQModifyOpen && (
                <MCQModify
                    subQuestion={examPaper
                        .flatMap(q => q.subQuestions)
                        .find(sq => sq.id === activeMCQSubQuestionId)}
                    onClose={handleMCQModifyClose}
                    onSubmit={handleMCQModifySubmit}
                />
            )}

            <ConfirmationModal
                isOpen={isConfirmationOpen}
                message="Are you sure you want to regrade this subquestion for all students with the new rubric and grading instructions?"
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleConfirmRegrade}
            >
                <p>This action cannot be undone.</p>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={isStudentAssignmentConfirmationOpen}
                message={`Assigning to ${newStudentAssignment?.full_name} (ID: ${newStudentAssignment?.id}) will unassign any submission that may be currently assigned to them.`}
                onClose={() => setIsStudentAssignmentConfirmationOpen(false)}
                onConfirm={handleConfirmStudentAssignment}
            >
                <p>Are you sure you want to proceed?</p>
            </ConfirmationModal>

            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-xl shadow-lg">
                        <p>Regrading in progress...</p>
                        <p>Expected wait time: 30 seconds</p>
                        {/* Add your loading animation here */}
                    </div>
                </div>
            )}

            {showConnectionErrorPopup && (
                <>
                    <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded">
                        Connection error, reconnecting ...
                    </div>
                </>
            )}

            {nextRegrade != -1 ? (
                <button
                    className="fixed h-[40px] w-[40px] right-[3%] bottom-[5%] z-40
            rounded-full border-[1px] border-black bg-white"
                    onClick={handleScroll}
                >
                    <i className="fas fa-chevron-down m-auto"/>
                </button>
            ) : null}
        </div>
    );
};

export default TDetails;
