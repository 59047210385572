import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { gradeApi } from '../api.js';
import { getPaginationProps } from '../utils/pagination.ts';

export const getStudents = createAsyncThunk(
  'teacher/getStudents',
  async ({ classId, token, pageNumber, itemsPerPage, search, sortOrder }, { rejectWithValue }) => {
    const pagingProps = getPaginationProps({ pageNumber, itemsPerPage });
    try {
      const response = await gradeApi.get({
        path: `/api/v2/courses/${classId}/students`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            offset: pagingProps.offset,
            limit: pagingProps.limit,
            search: !!search ? search : undefined,
            sortOrder
          }
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch students. Please try again.');
    }
  }
);

export const getTeachers = createAsyncThunk(
  'teacher/getTeachers',
  async ({ classId, token, pageNumber, itemsPerPage, search, sortOrder }, { rejectWithValue }) => {
    const pagingProps = getPaginationProps({ pageNumber, itemsPerPage });
    try {
      const response = await gradeApi.get({
        path: `/api/v2/courses/${classId}/teachers`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            offset: pagingProps.offset,
            limit: pagingProps.limit,
            search: !!search ? search : undefined,
            sortOrder
          }
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch teachers. Please try again.');
    }
  }
);

export const removeStudent = createAsyncThunk(
  'teacher/deleteStudent',
  async ({ classId, token, studentId }, { rejectWithValue }) => {
    try {
      await gradeApi.delete({
        path: `/api/v2/courses/${classId}/students/${studentId}`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return true;
    } catch (error) {
      return rejectWithValue('Failed to remove student. Please try again.');
    }
  }
);

export const removeTeacher = createAsyncThunk(
  'teacher/deleteTeacher',
  async ({ classId, token, teacherId }, { rejectWithValue }) => {
    try {
      await gradeApi.delete({
        path: `/api/v2/courses/${classId}/teachers/${teacherId}`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return true;
    } catch (error) {
      return rejectWithValue('Failed to remove teacher. Please try again.');
    }
  }
);

export const getSubmissions = createAsyncThunk(
  'teacher/getSubmissions',
  async (
    { assignmentId, token, pageNumber, itemsPerPage, search, sortOrder },
    { rejectWithValue }
  ) => {
    const pagingProps = getPaginationProps({ pageNumber, itemsPerPage });

    try {
      const response = await gradeApi.get({
        path: `/api/v2/assignments/${assignmentId}/submissions`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            offset: pagingProps.offset,
            limit: pagingProps.limit,
            search: !!search ? search : undefined,
            sortOrder
          }
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to load submissions. Please try again.');
    }
  }
);

export const getAssignmentDetails = createAsyncThunk(
  'teacher/getAssignmentDetails',
  async ({ assignmentId, token, classId }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.get({
        path: `/api/courses/${classId}/assignment/${assignmentId}/`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to load assignment details. Please try again.');
    }
  }
);

export const getAssignmentStudents = createAsyncThunk(
  'teacher/getAssignmentStudents',
  async ({ classId, token, pageNumber, itemsPerPage, search }, { rejectWithValue }) => {
    const pagingProps = getPaginationProps({ pageNumber, itemsPerPage });
    try {
      const response = await gradeApi.get({
        path: `/api/v2/courses/${classId}/assignments/students`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            offset: pagingProps.offset,
            limit: pagingProps.limit,
            search: !!search ? search : undefined
          }
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch students. Please try again.');
    }
  }
);

export const addTeachers = createAsyncThunk(
  'teacher/addTeachers',
  async ({ classId, emails, token }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.post({
        path: `/api/v2/courses/${classId}/teachers`,
        body: { emails },
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to add teachers. Please try again.');
    }
  }
);

export const getLogs = createAsyncThunk(
  'teacher/getLogs',
  async ({ userId, token, assignmentId }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.get({
        path: `/api/v2/assignments/${assignmentId}/user/${userId}/submission/logs`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            offset: 0,
            limit: 100
          }
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch logs. Please try again.');
    }
  }
);

const teacherSlice = createSlice({
  name: 'teacher',
  initialState: {
    students: {
      isLoading: false,
      data: {},
      errorMessage: '',
      infiniteStudents: []
    },
    teachers: {
      isLoading: false,
      data: {},
      errorMessage: '',
      infiniteTeachers: []
    },
    submissions: {
      isLoading: true,
      errorMessage: '',
      data: {}
    },
    assignment: {
      isLoading: true,
      errorMessage: '',
      data: null
    },
    assignmentStudents: {
      data: null,
      isLoading: false,
      errorMessage: ''
    },
    logs: {
      data: null,
      isLoading: false,
      errorMessage: ''
    }
  },
  reducers: {
    clearAssignment: state => {
      state.assignment.data = null;
    },
    clearAssignmentStudents: state => {
      state.assignmentStudents.data = null;
    },
    clearInfiniteStudents: state => {
      state.students.infiniteStudents = [];
    },
    clearInfiniteTeachers: state => {
      state.teachers.infiniteTeachers = [];
    },
    clearSubmissions: state => {
      state.submissions.data = [];
    }
  },
  extraReducers: builder => {
    builder.addCase(getStudents.pending, state => {
      state.students.isLoading = true;
    });
    builder.addCase(getStudents.rejected, (state, action) => {
      state.students.isLoading = false;
      state.students.errorMessage = action.payload;
    });
    builder.addCase(getStudents.fulfilled, (state, action) => {
      state.students.isLoading = false;
      state.students = {
        data: action.payload,
        infiniteStudents: [
          ...(state.students.infiniteStudents ?? []),
          ...(action.payload?.entities ?? [])
        ]
      };
    });
    builder.addCase(getTeachers.pending, state => {
      state.teachers.isLoading = true;
    });
    builder.addCase(getTeachers.rejected, (state, action) => {
      state.teachers.isLoading = false;
      state.teachers.errorMessage = action.payload;
    });
    builder.addCase(getTeachers.fulfilled, (state, action) => {
      state.teachers.isLoading = false;
      state.teachers = {
        data: action.payload,
        infiniteTeachers: [
          ...(state.teachers.infiniteTeachers ?? []),
          ...(action.payload?.entities ?? [])
        ]
      };
    });
    builder.addCase(getSubmissions.pending, state => {
      state.submissions.isLoading = true;
    });
    builder.addCase(getSubmissions.rejected, (state, action) => {
      state.submissions.isLoading = false;
      state.submissions.errorMessage = action.payload;
    });
    builder.addCase(getSubmissions.fulfilled, (state, action) => {
      state.submissions.isLoading = false;
      state.submissions = {
        data: action.payload
      };
    });
    builder.addCase(getAssignmentDetails.pending, state => {
      state.assignment.isLoading = true;
    });
    builder.addCase(getAssignmentDetails.rejected, (state, action) => {
      state.assignment.isLoading = false;
      state.assignment.errorMessage = action.payload;
    });
    builder.addCase(getAssignmentDetails.fulfilled, (state, action) => {
      state.assignment.isLoading = false;
      state.assignment = {
        data: action.payload
      };
    });

    builder.addCase(getAssignmentStudents.pending, state => {
      state.assignmentStudents.isLoading = true;
    });
    builder.addCase(getAssignmentStudents.rejected, (state, action) => {
      state.assignmentStudents.isLoading = false;
      state.assignmentStudents.errorMessage = action.payload;
    });
    builder.addCase(getAssignmentStudents.fulfilled, (state, action) => {
      state.assignmentStudents.isLoading = false;
      state.assignmentStudents = {
        data: {
          ...action.payload,
          entities: [
            ...(state.assignmentStudents?.data?.entities ?? []),
            ...(action.payload?.entities ?? [])
          ]
        }
      };
    });

    builder.addCase(getLogs.pending, state => {
      state.logs.isLoading = true;
    });
    builder.addCase(getLogs.rejected, state => {
      state.logs.isLoading = false;
    });
    builder.addCase(getLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.logs = {
        data: action.payload
      };
    });
  }
});

export const {
  clearAssignment,
  clearAssignmentStudents,
  clearInfiniteStudents,
  clearInfiniteTeachers,
  clearSubmissions
} = teacherSlice.actions;

export default teacherSlice.reducer;
