import React, {useState, useRef, useEffect} from 'react';
import {InlineMath} from 'react-katex';

const EditableText = ({
                          text,
                          onSave,
                          renderLatex = true,
                          className = '',
                          placeholder = 'Enter text...',
                          multiline = false
                      }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(text || '');
    const inputRef = useRef(null);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            // Position cursor at the end of text
            const length = editedText ? editedText.length : 0;
            // Use setTimeout to ensure focus is complete before setting position
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.setSelectionRange(length, length);
                }
            }, 0);
        }
    }, [isEditing]); // Removed editedText from dependencies

    useEffect(() => {
        setEditedText(text || '');
    }, [text]);

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (editedText !== text) {
            onSave(editedText);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !multiline) {
            e.preventDefault();
            handleBlur();
        } else if (e.key === 'Escape') {
            setEditedText(text);
            setIsEditing(false);
        }
    };

    const renderLatexContent = (content) => {
        if (!content) {
            return null;
        }

        // Split content by code blocks first
        const segments = [];
        let currentIndex = 0;
        const codeBlockRegex = /```[\s\S]*?```/g;
        let match;

        while ((match = codeBlockRegex.exec(content)) !== null) {
            // Add text before code block
            if (match.index > currentIndex) {
                segments.push({
                    type: 'text',
                    content: content.slice(currentIndex, match.index)
                });
            }

            // Add code block
            segments.push({
                type: 'code',
                content: match[0].slice(3, -3)  // Remove ``` from start and end
            });

            currentIndex = match.index + match[0].length;
        }

        // Add remaining text
        if (currentIndex < content.length) {
            segments.push({
                type: 'text',
                content: content.slice(currentIndex)
            });
        }

        // Process each segment
        return segments.map((segment, index) => {
            if (segment.type === 'code') {
                return (
                    <pre key={index}
                         className="bg-gray-100 rounded-md p-2 my-2 font-mono text-sm whitespace-pre-wrap break-all"
                         style={{wordBreak: 'break-all', overflowWrap: 'break-word'}}>
                        {segment.content}
                    </pre>
                );
            } else {
                // Process LaTeX in text segments
                const lines = segment.content.split('\n');
                return lines.map((line, lineIndex) => {
                    // Modified regex to handle escaped dollar signs
                    const parts = line.split(/(?<!\\)\$/g);
                    const processedLine = parts.map((part, partIndex) => {
                        if (partIndex % 2 === 1) {
                            // This is LaTeX content
                            return <InlineMath key={`latex-${partIndex}`} math={part}/>;
                        }
                        return part;
                    });

                    return (
                        <React.Fragment key={`${index}-${lineIndex}`}>
                            {processedLine}
                            {lineIndex < lines.length - 1 && <br/>}
                        </React.Fragment>
                    );
                });
            }
        });
    };

    // Calculate minimum height based on content to maintain consistent height
    const getMinHeight = () => {
        const content = isEditing ? editedText : text;
        if (!content || typeof content !== 'string') {
            return multiline ? '8rem' : '3rem'; // Larger default minimum size
        }
        
        // For multiline text, use a larger base size by default
        if (multiline) {
            // Count explicit newlines, but also account for potential wrapping
            const explicitLineCount = (content.match(/\n/g) || []).length + 1;
            const textLength = content.length;
            
            // Estimate: assume characters per line is roughly 60 for wrapping purposes (reduced from 80)
            // Add estimated wrapped lines to explicit newlines
            const estimatedLines = Math.max(explicitLineCount, Math.ceil(textLength / 60));
            
            // Use a minimum of 8rem for multiline, but grow based on estimated lines with more generous spacing
            return `${Math.max(estimatedLines * 2, 8)}rem`;
        }
        
        // For single-line text, more generous height based on length
        const estimatedHeight = Math.max(3, Math.min(8, Math.ceil(content.length / 60)));
        return `${estimatedHeight}rem`;
    };

    const minHeight = getMinHeight();

    if (isEditing) {
        return multiline ? (
            <textarea
                ref={inputRef}
                value={editedText}
                className={`w-full p-3 rounded-md ${className}`}
                style={{
                    minHeight,
                    height: 'auto',
                    transition: 'all 0.2s ease',
                    boxShadow: 'none',
                    outline: 'none',
                    border: '2px solid hsl(31, 90%, 60%)', // Amber border
                    resize: 'vertical', // Allow vertical resizing
                    boxSizing: 'border-box',
                    width: '100%',
                    maxWidth: 'none',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    overflowY: 'auto', // Allow vertical scrolling if needed
                    whiteSpace: 'pre-wrap', // Preserve line breaks but wrap text
                    wordBreak: 'break-word' // Break words to prevent horizontal overflow
                }}
                placeholder={placeholder}
                rows="auto" // Let the browser handle row count automatically
                onChange={(e) => setEditedText(e.target.value)}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
            />
        ) : (
            <input
                ref={inputRef}
                type="text"
                value={editedText}
                className={`w-full p-3 rounded-md ${className}`}
                style={{
                    minHeight,
                    height: 'auto',
                    transition: 'all 0.2s ease',
                    boxShadow: 'none',
                    outline: 'none',
                    border: '2px solid hsl(31, 90%, 60%)', // Amber border
                    boxSizing: 'border-box',
                    width: '100%',
                    maxWidth: 'none',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    textOverflow: 'ellipsis' // Show ellipsis for overflowing text
                }}
                placeholder={placeholder}
                onChange={(e) => setEditedText(e.target.value)}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
            />
        );
    }

    return (
        <div
            className={`cursor-text hover:bg-gray-50 p-3 rounded-md ${className} ${!text ? 'text-gray-400' : ''}`}
            style={{
                minHeight,
                transition: 'all 0.2s ease',
                border: '1px solid transparent',
                width: '100%',
                boxSizing: 'border-box',
                fontSize: '1rem',
                lineHeight: '1.5',
                wordWrap: 'break-word',
                maxWidth: 'none',
                whiteSpace: 'pre-wrap', // Preserve formatting but allow wrapping
                overflowWrap: 'break-word' // Ensure long words wrap
            }}
            onClick={handleClick}
        >
            {text ? (renderLatex ? renderLatexContent(text) : text) : placeholder}
        </div>
    );
};

export default EditableText;
