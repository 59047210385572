import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext';
import {Loading} from '../../Loading';

function TQuestionGrade(props) {
    const [submissions, setSubmissions] = useState([]);
    const [assignment, setAssignment] = useState();
    const {user} = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [examPaper, setExamPaper] = useState([]);
    const [regrades, setRegrades] = useState([]);
    const [firstSubmission, setFirstSubmission] = useState(0);
    const [groupedSubmissions, setGroupedSubmissions] = useState([]);

    const numSubmissions = assignment?.num_submissions;
    const hasSubmissions = numSubmissions > 0;

    const getSubmissions = async () => {
        if (!assignment) {
            return;
        }
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/submissions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setFirstSubmission(response.data.submissions[0].id);
            console.log(submissions);
            console.log(hasSubmissions);
        } catch (error) {
            console.log(error);
            setErrorMessage('Failed to fetch submissions. Please try again.');
        }
    };

    const getRegrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/regrade-requests/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const regradesForTeacher = response.data;
            console.log(regradesForTeacher);
            setRegrades(regradesForTeacher);
        } catch (error) {
            console.log(error);
        }
    };

    const hasRegrade = submission => {
        for (var i = 0; i < regrades.length; i++) {
            if (regrades[i].id === submission.id) {
                return true;
            }
        }
        return false;
    };

    const getQuestions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const questionsFromDB = response.data;
            // console.log(questionsFromDB);
            setExamPaper(questionsFromDB.questions);
            console.log(questionsFromDB.questions);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getRegrades();
    }, []);

    useEffect(() => {
        getQuestions();
        getSubmissions();
    }, [assignment]);

    // If the assignment is a flexible problem set, ensure we're not using Default view
    useEffect(() => {
        if (assignment?.type === 'flexible_problem_set' && props.reviewMode === 'Default') {
            props.setReviewMode('Student');
        }
    }, [assignment, props.reviewMode]);

    useEffect(() => {
        setSubmissions(props.submissions_in);
        setAssignment(props.assignment_in);
    }, [props.submissions_in, props.assignment_in]);

    useEffect(() => {
        if (submissions && submissions.length > 0) {
            // Group submissions in sets of five
            const groups = [];
            for (let i = 0; i < submissions.length; i += 5) {
                groups.push(submissions.slice(i, i + 5));
            }
            setGroupedSubmissions(groups);
        }
    }, [submissions]);

    if (!assignment || !submissions) {
        return <Loading/>;
    }

    return (
        <div id="HomeTeacher" className="flex flex-row">
            <div id="Main-Content-container" className="flex flex-col space-y-8 w-full">
                <div className="flex flex-col w-full">
                    <h2 className="text-xl font-bold mb-1">
                        Step {assignment ? (assignment.type === 'essay' ? 2 : assignment.is_exam ? 4 : 2) : 2}:
                        Review grades
                    </h2>
                    <p className="text-sm mb-4">
                        After submission upload, suggested grades will be available within two minutes.
                    </p>
                </div>

                <div className="flex justify-start">
                    <div className="flex justify-start mb-4">
                        <div className="flex space-x-4">
                            {assignment.type !== 'flexible_problem_set' && (
                                <button
                                    className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-300 ${props.reviewMode === 'Default'
                                        ? 'bg-orange-200 border-orange-300 text-black shadow-md'
                                        : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50'
                                    } border`}
                                    onClick={() => props.setReviewMode('Default')}
                                >
                                    Default
                                </button>
                            )}

                            <button
                                className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-300 ${props.reviewMode === 'Student'
                                    ? 'bg-orange-200 border-orange-300 text-black shadow-md'
                                    : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50'
                                } border`}
                                onClick={() => props.setReviewMode('Student')}
                            >
                                View by Student
                            </button>

                            <button
                                className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-300 ${props.reviewMode === 'Question'
                                    ? 'bg-orange-200 border-orange-300 text-black shadow-md'
                                    : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50'
                                } border`}
                                onClick={() => props.setReviewMode('Question')}
                            >
                                {`View by ${assignment.type === 'flexible_problem_set' ? 'Question' : 'Sub-Question'}`}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="ml-4bg-white sexy-border p-6">
                    {errorMessage && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {errorMessage}
                        </div>
                    )}
                    {props.reviewMode === 'Default' && assignment.type !== 'flexible_problem_set' ? (
                        <div>
                            {groupedSubmissions.map((group, groupIndex) => (
                                <div key={groupIndex} className="mb-8">
                                    <h3 className="text-lg font-semibold mb-4">Group {groupIndex + 1}</h3>
                                    <div className="grid grid-cols-5 gap-4">
                                        {group.map((submission, index) => (
                                            <div key={index}
                                                 className="bg-white p-4 rounded-lg border border-gray-200 hover:border-gray-300 transition-all duration-200">
                                                <Link
                                                    to={`/tclasses/${assignment.course_id}/tassignments/${assignment.id}/tsubmissions/${submission.id}`}
                                                    className="block"
                                                >
                                                    <div className="font-medium text-gray-900 mb-1">
                                                        {submission.user.first_name} {submission.user.last_name}
                                                    </div>
                                                    <div className="text-sm text-gray-500">
                                                        Submission #{submission.submission_count}
                                                    </div>
                                                    {submission.late && (
                                                        <div className="text-sm text-red-500 mt-1">Late</div>
                                                    )}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <table className="w-full border-collapse">
                            <thead>
                            <tr className="border-b border-border">
                                <th className="py-4 text-left text-gray custom-16">Question</th>
                            </tr>
                            </thead>
                            {examPaper ? (
                                <tbody>
                                {examPaper.map((question, questionIndex) => (
                                    <React.Fragment key={questionIndex}>
                                        {!question.is_without_sub_questions ? (
                                            <>
                                                <tr className="my-4 text-left text-gray-500 custom-16-semi pt-4">
                                                    <div className="pt-6">
                                                        Question {questionIndex + 1}:{' '}
                                                        {question.description.length > 45
                                                            ? question.description.substring(0, 42) + '...'
                                                            : question.description}
                                                    </div>
                                                </tr>

                                                {question.subQuestions &&
                                                    question.subQuestions.map((subQuestion, subQuestionIndex) => (
                                                        <tr
                                                            key={`${questionIndex}-${subQuestionIndex}`}
                                                            className="border-b border-border"
                                                        >
                                                            {hasSubmissions ? (
                                                                <Link
                                                                    to={`/tclasses/${assignment.course_id}/tassignments/${assignment.id}/tsubmissions/${firstSubmission}/tquestiondetails/${subQuestion.id}`}
                                                                    className="hover:underline text-black relative group ml-4"
                                                                >
                                                                    <td className="py-4 text-left custom-16">
                                                                        <div>
                                                                            SubQuestion {questionIndex + 1}
                                                                            {String.fromCharCode(97 + subQuestionIndex)}:{' '}
                                                                            {subQuestion.question.length > 45
                                                                                ? subQuestion.question.substring(0, 42) + '...'
                                                                                : subQuestion.question}
                                                                        </div>
                                                                        <div className="text-sm text-gray-600 mt-1">
                                                                            {subQuestion.viewed_count}/{numSubmissions} manually
                                                                            reviewed
                                                                        </div>
                                                                    </td>
                                                                </Link>
                                                            ) : (
                                                                <td className="py-4 text-left custom-16 ml-4 text-gray-500 pl-4">
                                                                    SubQuestion {questionIndex + 1}
                                                                    {String.fromCharCode(97 + subQuestionIndex)}:{' '}
                                                                    {subQuestion.question}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                            </>
                                        ) : (
                                            <>
                                                {question.subQuestions &&
                                                    question.subQuestions.map((subQuestion, subQuestionIndex) =>
                                                        hasSubmissions ? (
                                                            <tr
                                                                key={`${questionIndex}-${subQuestionIndex}`}
                                                                className="py-4 text-left text-gray-500 custom-16-semi border-b border-border"
                                                            >
                                                                <Link
                                                                    to={`/tclasses/${assignment.course_id}/tassignments/${assignment.id}/tsubmissions/${firstSubmission}/tquestiondetails/${subQuestion.id}`}
                                                                    className="hover:underline text-black relative group ml-4"
                                                                >
                                                                    <div>
                                                                        Question {questionIndex + 1}:{' '}
                                                                        {question.description.length > 45
                                                                            ? question.description.substring(0, 42) + '...'
                                                                            : question.description}
                                                                    </div>
                                                                    <div className="text-sm text-gray-600 mt-1 pb-6">
                                                                        {subQuestion.viewed_count}/{numSubmissions} manually
                                                                        reviewed
                                                                    </div>
                                                                </Link>
                                                            </tr>
                                                        ) : (
                                                            <tr
                                                                key={`${questionIndex}-${subQuestionIndex}`}
                                                                className="py-4 text-left text-gray-500 custom-16-semi border-b border-border"
                                                            >
                                                                <div
                                                                    className="py-4 text-left text-gray-500 custom-16-semi">
                                                                    Question {questionIndex + 1}:{' '}
                                                                    {question.description.length > 45
                                                                        ? question.description.substring(0, 42) + '...'
                                                                        : question.description}
                                                                </div>
                                                            </tr>
                                                        )
                                                    )}
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                                </tbody>
                            ) : (
                                <tbody>
                                <tr>
                                    <td colSpan="3" className="text-center py-4 text-gray-500">
                                        No submissions found
                                    </td>
                                </tr>
                                </tbody>
                            )}
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TQuestionGrade;
