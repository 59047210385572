import React from 'react';
import {Plus, Trash2, CircleCheck} from "lucide-react";
import EditableText from './EditableText';
import './styling/OptionsList.scss';

// Helper function to extract text from option data in various formats
const getOptionText = (option) => {
    // If option is null or undefined, return empty string
    if (!option) {return '';}

    // If option is a string directly, return it
    if (typeof option === 'string') {
        return option;
    }

    // Handle case where string was accidentally turned into a character object
    if (typeof option === 'object' && option !== null) {
        // Check for "0" property first
        if (option.hasOwnProperty('0')) {
            // If "0" property is a string, return it
            if (typeof option['0'] === 'string') {
                return option['0'];
            }

            // If "0" contains many numbered keys (like object-like string), try to reconstruct
            if (typeof option['0'] === 'object' && option['0'] !== null) {
                return '';
            }
        }

        // Check for "text" property
        if (option.hasOwnProperty('text')) {
            // If text property is a string, return it
            if (typeof option.text === 'string') {return option.text;}
        }

        // Handle case where string was converted to character array
        // This happens when an option string is accidentally treated as an object
        // and gets destructured into individual character properties with numeric keys
        try {
            // Check if we have sequential numeric keys starting from 0
            const keys = Object.keys(option).filter(k => !isNaN(parseInt(k)));
            if (keys.length > 0 && keys.every((k, i) => parseInt(k) === i)) {
                // We likely have a string that was converted to character array
                // Let's try to reconstruct it
                const chars = [];
                for (let i = 0; i < keys.length; i++) {
                    chars.push(option[i.toString()]);
                }
                return chars.join('');
            }
        } catch (e) {
            console.error('Error reconstructing string from character array:', e);
        }
    }

    // Default fallback
    return '';
};

const OptionsList = ({
                         options = [],
                         correctAnswer = [],
                         onOptionTextChange,
                         onOptionCorrectChange,
                         onAddOption,
                         onDeleteOption,
                         subIndex
                     }) => {
    // Direct implementation of MCQ option with letter click
    const MCQOption = ({option, index, isCorrect}) => {
        const letter = String.fromCharCode(65 + index);
        console.log('Rendering MCQ Option:', index, option, isCorrect);

        return (
            <div className={`option-item ${isCorrect ? "correct" : ""}`}>
                <div
                    className={`option-letter ${isCorrect ? "correct" : ""}`}
                    style={{cursor: 'pointer'}}
                    onClick={() => onOptionCorrectChange(subIndex, index, !isCorrect)}
                >
                    {letter}
                </div>
                <div className="option-content">
                    <EditableText
                        text={option} // Just use the option directly - it's always a string
                        className="option-text"
                        placeholder="Enter option text..."
                        onSave={(newText) => {
                            console.log('Option text changed:', newText);
                            onOptionTextChange(subIndex, index, newText);
                        }}
                    />
                    {isCorrect && (
                        <div className="correct-indicator">
                            <CircleCheck className="correct-mark" size={16}/>
                            <span>Correct Answer</span>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="options-list">
            {Array.isArray(options) && options.map((option, idx) => {
                // We're now using simple strings for options, so we rely on correctAnswer array
                const letter = String.fromCharCode(65 + idx);
                const isCorrect = Array.isArray(correctAnswer) && correctAnswer.includes(letter);

                return (
                    <div key={idx} className="option-container">
                        <MCQOption
                            option={option}
                            index={idx}
                            isCorrect={isCorrect}
                        />
                        {options.length > 2 && (
                            <div className="option-actions">
                                <button
                                    className="delete-btn"
                                    aria-label="Delete option"
                                    onClick={() => {
                                        console.log('Deleting option at index:', idx);
                                        onDeleteOption(subIndex, idx);
                                    }}
                                >
                                    <div className="icon-wrapper">
                                        <Trash2 className="w-4 h-4"/>
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                );
            })}

            <button
                className="add-subquestion-btn add-option-btn"
                style={{"--sub-animation-delay": `0.1s`}}
                onClick={() => onAddOption(subIndex)}
            >
                <Plus className="w-4 h-4"/>
                <span>Add Option</span>
            </button>
        </div>
    );
};

export default OptionsList;