import React, {useEffect, useState} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import {GroupLoading} from '../Loading';
import TGroupDetails from '../../components/Teacher/TGrading/TGroupDetails';

function TGroupSubmissionsPage() {
    const {groupIndex} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {user} = useAuth();
    const [loading, setLoading] = useState(true);
    const [assignment, setAssignment] = useState(null);
    const {class_id, assignment_id} = useParams();
    const {students: rawStudents, allStudents: rawAllStudents} = location.state || {students: [], allStudents: []};

    // Filter out invalid students
    const students = rawStudents.filter(student => student && student.id);
    const allStudents = rawAllStudents.filter(student => student && student.id);

    useEffect(() => {
        const fetchAssignment = async () => {
            try {
                console.log('Fetching assignment data...');
                const response = await gradeApi.get({
                    path: `/api/courses/${class_id}/assignment/${assignment_id}/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });
                console.log('Assignment response:', response.data);

                if (!response.data) {
                    throw new Error('No assignment data received');
                }

                setAssignment(response.data);
            } catch (error) {
                console.error('Error fetching assignment:', error);
                // Navigate back to submissions page if there's an error
                navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions`);
            } finally {
                setLoading(false);
            }
        };

        fetchAssignment();
    }, [assignment_id, class_id, user.session_token, navigate]);

    useEffect(() => {
        // Check if we have valid students before proceeding
        if (!loading && (!students || students.length === 0 || students.length > 10)) {
            console.log('Invalid number of students in group, redirecting...');
            navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions`);
        }
    }, [loading, students, class_id, assignment_id, navigate]);

    if (loading) {
        return <GroupLoading/>;
    }

    console.log('Current students:', students);
    console.log('Current assignment:', assignment);

    return (
        <div className="min-h-screen bg-gray-50">
            <TGroupDetails
                groupIndex={parseInt(groupIndex)}
                students={students}
                allStudents={allStudents}
                assignment={assignment}
            />
        </div>
    );
}

export default TGroupSubmissionsPage;