import {DndContext, rectIntersection} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove} from '@dnd-kit/sortable';
import {Field, Form, Formik} from 'formik';
import {default as React, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PDFView} from '../PDFRendered.js';

import {isEmpty, isEqual} from 'lodash';
import {apiUrl} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext.js';

import {
    loadDraftData,
    loadQuestions,
    updateDraftData,
    uploadQuestions as uploadQuestionsAction
} from '../../../store/tpsetSlice.js';
import {TQuestionColumn} from './TQuestionColumn.js';
import {updateIds} from './utils.js';

import {FileQuestion} from 'lucide-react';
import * as Yup from 'yup';
import {defaultGradingInstructions, defaultInstructions} from './constants.js';
import {FormikSubmitEffect} from './FormikSubmitEffect';

function TPset({
                   assignment_id,
                   setUpdateTrigger,
                   isPublished,
                   pullQuestions,
                   setPullQuestions,
                   setEdited,
                   isExam,
                   edited,
                   setSolRecent,
                   solRecent,
                   setGenerated,
                   setTopErrorMessages
               }) {
    const {user} = useAuth();
    const dispatch = useDispatch();

    // Warning banner for outdated workflow
    const OutdatedWorkflowWarning = () => (
        <div
            className="fixed top-0 left-0 right-0 z-50 flex justify-center items-center p-4 bg-red-600 text-white font-bold shadow-lg"
            style={{fontSize: '24px', padding: '20px', textAlign: 'center', zIndex: 9999}}>
            ⚠️ THIS WORKFLOW IS OUTDATED ⚠️
            <br/>
            We strongly recommend creating a new assignment instead
        </div>
    );
    const [isGradingInstructionsVisible, setIsGradingInstructionsVisible] = useState(false);
    const [isExamInstructionsVisible, setIsExamInstructionsVisible] = useState(true);
    const [iframeKey, setIframeKey] = useState(Date.now());
    const [showSavedPopup, setShowSavedPopup] = useState(false);
    const [showErrorMessagePopup, setShowErrorMessagePopup] = useState(false);
    const [lastSaved, setLastSaved] = useState(null);
    const [error, setError] = useState('');
    const [viewedQuestions, setViewedQuestions] = useState(new Map());
    const [copiedRubricItems, setCopiedRubricItems] = useState(null);
    const [isSettingsVisible, setIsSettingsVisible] = useState(true);
    const generateRubricsRef = useRef(null);
    const [isBubbleSheetVisible, setIsBubbleSheetVisible] = useState(false);
    const [bubbleSheet, setBubbleSheet] = useState([]);
    const [currentPage, setCurrentPage] = useState(() => {
        return parseInt(localStorage.getItem(`pdf-page-${assignment_id}`) || '1');
    });
    const [pdfTimestamp, setPdfTimestamp] = useState(Date.now());

    const {
        data: draftData,
        isLoading: isDraftDataLoading,
        errorMessage
    } = useSelector(state => state?.tpsetReducer?.draftData);

    const disabledStyle = isPublished || isDraftDataLoading ? '!opacity-65' : '';

    const handleAddBubbleSheet = (amount = 50) => {
        const currentLength = bubbleSheet.length;
        if (currentLength >= 150) {
            setError('Maximum of 150 bubble sheet questions allowed');
            return;
        }

        const remainingSpace = 150 - currentLength;
        const actualAmount = Math.min(amount, remainingSpace);

        const newBubbleSheet = [
            ...bubbleSheet,
            ...Array(actualAmount)
                .fill()
                .map((_, i) => ({
                    id: currentLength + i + 1,
                    selectedAnswers: []
                }))
        ];

        setBubbleSheet(newBubbleSheet);
        handleChange({fieldName: 'bubbleSheet', value: newBubbleSheet});
    };

    // Add this with the other handlers
    const handleAllowPregradeChange = e => {
        handleChange({
            fieldName: 'allowPregrade',
            value: e.target.checked
        });
    };

    const handleRemoveBubbleSheet = () => {
        setBubbleSheet([]);
        handleChange({fieldName: 'bubbleSheet', value: []});
    };

    const handleRemoveBubbleQuestion = id => {
        const newBubbleSheet = bubbleSheet
            .filter(q => q.id !== id)
            .map((q, index) => ({
                ...q,
                id: index + 1
            }));

        setBubbleSheet(newBubbleSheet);
        handleChange({fieldName: 'bubbleSheet', value: newBubbleSheet});
    };

    const handleSelectAnswer = (questionId, answer) => {
        const newBubbleSheet = bubbleSheet.map(q => {
            if (q.id === questionId) {
                const newAnswers = q.selectedAnswers.includes(answer)
                    ? q.selectedAnswers.filter(a => a !== answer)
                    : [...q.selectedAnswers, answer];
                return {...q, selectedAnswers: newAnswers};
            }
            return q;
        });

        setBubbleSheet(newBubbleSheet);
        handleChange({fieldName: 'bubbleSheet', value: newBubbleSheet});
    };

    useEffect(() => {
        dispatch(loadDraftData({assignment_id, token: user.session_token}));
    }, []);

    useEffect(() => {
        if (errorMessage) {
            setShowErrorMessagePopup(true);
            setTimeout(() => setShowErrorMessagePopup(false), 2000);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (!draftData.edited) {
            if (!solRecent) {
                setEdited(true);
            }
            setSolRecent(false);
        }
    }, [draftData.examPaper]);

    useEffect(() => {
        if (pullQuestions) {
            dispatch(loadQuestions({assignment_id, token: user.session_token}));
            setPullQuestions(false);
            setEdited(false);
        }
    }, [pullQuestions]);

    useEffect(() => {
        if (draftData?.bubbleSheet?.length > 0) {
            setBubbleSheet(draftData.bubbleSheet);
        }
    }, [draftData]);

    const validationSchema = Yup.object().shape({
        examPaper: Yup.array()
            .of(
                Yup.object().shape({
                    question: Yup.string().required('This field is required!'),
                    weight: Yup.number().when('subQuestions', {
                        is: val => val.length < 1,
                        then: () =>
                            Yup.number()
                                .min(1, 'Each MCQ subquestion must have a weight of at least 1.')
                                .required('This field is required!')
                    }),
                    mcqOptions: Yup.array().when(['questionType', 'subQuestions'], {
                        is: (questionType, subQuestions) => questionType === 'mcq' && subQuestions.length < 1,
                        then: () =>
                            Yup.array()
                                .of(
                                    Yup.object().shape({
                                        text: Yup.string().required('Each MCQ option must have text.'),
                                        isCorrect: Yup.boolean().required(
                                            'Each MCQ option must specify if it is correct.'
                                        )
                                    })
                                )
                                .required('MCQ options are required')
                                .min(2, 'Minimum of 2 MCQ options')
                                .test(
                                    'one-correct-answer',
                                    'Each MCQ must have exactly one correct answer.',
                                    options => {
                                        if (!options) {
                                            return false;
                                        }
                                        const correctCount = options.filter(option => option.isCorrect).length;
                                        return correctCount === 1;
                                    }
                                )
                    }),
                    rubricItems: Yup.array().when(['questionType', 'subQuestions'], {
                        is: (questionType, subQuestions) =>
                            subQuestions.length < 1 && questionType === 'freeform',
                        then: () =>
                            Yup.array()
                                .of(
                                    Yup.object().shape({
                                        value: Yup.string().required('Must have a point value.'),
                                        descriptor: Yup.string().required('Must have a descriptor value.')
                                    })
                                )
                                .min(1, 'Minimum of 1 Rubric item')
                                .required('Must have Rubric item')
                    }),
                    subQuestions: Yup.array().of(
                        Yup.object().shape({
                            weight: Yup.number()
                                .min(1, 'Each MCQ subquestion must have a weight of at least 1.')
                                .required('This field is required!'),
                            mcqOptions: Yup.array().when('questionType', {
                                is: val => val === 'mcq',
                                then: () =>
                                    Yup.array()
                                        .of(
                                            Yup.object().shape({
                                                text: Yup.string().required('Each MCQ option must have text.'),
                                                isCorrect: Yup.boolean().required(
                                                    'Each MCQ option must specify if it is correct.'
                                                )
                                            })
                                        )
                                        .required('MCQ options are required')
                                        .min(2, 'Minimum of 2 MCQ options')
                                        .test(
                                            'one-correct-answer',
                                            'Each MCQ must have exactly one correct answer.',
                                            options => {
                                                if (!options) {
                                                    return false;
                                                }
                                                const correctCount = options.filter(option => option.isCorrect).length;
                                                return correctCount === 1;
                                            }
                                        )
                            }),
                            rubricItems: Yup.array().when('questionType', {
                                is: val => val === 'tfreeformext',
                                then: () =>
                                    Yup.array()
                                        .of(
                                            Yup.object().shape({
                                                value: Yup.string().required('Must have a point value.'),
                                                descriptor: Yup.string().required('Must have a descriptor value.')
                                            })
                                        )
                                        .min(1, 'Minimum of 1 Rubric item')
                                        .required('Must have Rubric item')
                            })
                        })
                    )
                })
            )
            .min(1, 'Minimum of 1 question')
            .required('Must have question'),
        bubbleSheet: Yup.array().of(
            Yup.object().shape({
                selectedAnswers: Yup.array()
                    .min(1, 'Must select at least one answer')
                    .required('Answers are required')
            })
        )
    });

    const handleChange = async ({fieldName, value}) => {
        if (!isEqual(draftData[fieldName], value)) {
            const result = await dispatch(
                updateDraftData({
                    token: user.session_token,
                    assignment_id,
                    dataToSave: isEmpty(draftData)
                        ? {
                            ...initialData,
                            [fieldName]: value
                        }
                        : {
                            [fieldName]: value
                        }
                })
            );

            if (!result.error) {
                setLastSaved(new Date());
                setShowSavedPopup(true);
                setTimeout(() => setShowSavedPopup(false), 2000);
                setEdited(true);
            }
        }
    };

    const updateViewedQuestions = id => {
        const expandedQ = new Map(viewedQuestions);
        if (viewedQuestions.has(id)) {
            expandedQ.delete(id);
        } else {
            expandedQ.set(id, []);
        }

        return setViewedQuestions(expandedQ);
    };

    const getTaskPos = id => draftData.examPaper.findIndex(question => question.id === id);

    const handleDragEnd = event => {
        const originalExamPaper = draftData.examPaper;
        const {active, over} = event;

        if (!active || !over || !active.id || !over.id || active.id === over.id) {
            return;
        }

        const getNewExamPaper = examPaper => {
            const originalPos = getTaskPos(active.id);
            const newPos = getTaskPos(over.id);

            if (originalPos === -1 || newPos === -1) {
                console.error('Could not find position for active or over:', {
                    active,
                    over,
                    originalPos,
                    newPos
                });
                return examPaper;
            }

            const updatedPaper = arrayMove(examPaper, originalPos, newPos);
            return updateIds(updatedPaper);
        };

        const newExamPaper = getNewExamPaper(originalExamPaper);
        handleChange({value: newExamPaper, fieldName: 'examPaper'});
    };

    const handleHideFirstPageChange = e => {
        handleChange({
            fieldName: 'hideFirstPage',
            value: e.target.checked
        });
    };

    const handleShowPointsChange = e => {
        handleChange({
            fieldName: 'showPoints',
            value: e.target.checked
        });
    };

    const initialData = {
        examPaper: [
            {
                id: 1,
                questionType: 'freeform',
                question: '',
                solution: '',
                questionSizing: 30,
                rubricItems: [
                    {descriptor: 'Fully correct', value: '2'},
                    {descriptor: 'Partially correct', value: '1'},
                    {descriptor: 'Incorrect or no work', value: '0'}
                ],
                mcqOptions: [
                    {option: 'A', text: '', isCorrect: false},
                    {option: 'B', text: '', isCorrect: false}
                ],
                image: null,
                weight: 1,
                subQuestions: []
            }
        ],
        instructions: defaultInstructions,
        created: false,
        edited: false,
        pdfUrl: '',
        teacherPdfUrl: '',
        gradingInstructions: defaultGradingInstructions,
        feedbackInstructions: 'detailed',
        showPoints: false,
        hideFirstPage: false,
        bubbleSheet: [],
        allowPregrade: false
    };

    const getInitialValues = useCallback(() => {
        if (!isEmpty(draftData)) {
            return {
                ...draftData,
                instructions: draftData.instructions || defaultInstructions,
                gradingInstructions: draftData.gradingInstructions || defaultGradingInstructions,
                feedbackInstructions: draftData.feedbackInstructions || 'detailed',
                showPoints: draftData.showPoints || false,
                hideFirstPage: draftData.hideFirstPage || false,
                bubbleSheet: draftData.bubbleSheet || [],
                allowPregrade: draftData.allowPregrade || false
            };
        }

        return initialData;
    }, [isDraftDataLoading, draftData]);

    const uploadQuestions = useCallback(
        async (notSetGenerated) => {
            const payload = {
                exam_paper: draftData.examPaper.map(question => ({
                    ...question,
                    questionSizing: question.questionSizing || 30,
                    subQuestions: question.subQuestions.map(subQuestion => ({
                        ...subQuestion,
                        questionSizing: subQuestion.questionSizing || 30,
                        mcqOptions: subQuestion.questionType === 'mcq' ? subQuestion.mcqOptions : undefined,
                        rubricItems:
                            subQuestion.questionType === 'mcq'
                                ? [
                                    {descriptor: 'Correct Answer', value: subQuestion.weight.toString()},
                                    {descriptor: 'Incorrect Answer', value: '0'}
                                ]
                                : subQuestion.rubricItems,
                        ...(subQuestion.questionType === 'mcq' && {mcqOptions: subQuestion.mcqOptions})
                    }))
                })),
                instructions: draftData.instructions,
                model_instructions: draftData.gradingInstructions,
                feedback_instructions: draftData.feedbackInstructions,
                show_points: draftData.showPoints,
                hide_first_page: draftData.hideFirstPage,
                page: currentPage,
                allow_pregrade: draftData.allowPregrade,
                bubble_sheet: bubbleSheet
            };

            const response = await dispatch(
                uploadQuestionsAction({assignment_id, token: user.session_token, body: payload})
            );

            if (response.type === 'tpset/uploadQuestions/fulfilled') {
                setEdited(false);
                if (!notSetGenerated) {
                    setGenerated(true);
                    setSolRecent(true);
                    setUpdateTrigger(prev => !prev);
                    setIframeKey(Date.now());
                    setError('');
                    setTopErrorMessages('');
                    setPdfTimestamp(Date.now());
                }
            } else {
                const errorMessage =
                    response.payload || response.error?.message || 'An error occurred. Please try again.';
                setError(errorMessage);
            }
        },
        [bubbleSheet, currentPage, draftData, user.session_token, assignment_id]
    );

    const handleCopyRubricItems = useCallback(rubricItems => {
        setCopiedRubricItems(rubricItems);
    }, []);

    const handlePasteRubricItems = useCallback(
        (values, setFieldValue) => (questionIndex, subQuestionIndex) => {
            if (copiedRubricItems) {
                const newExamPaper = values.examPaper.map((question, qIndex) => {
                    if (questionIndex === -1 ? qIndex === 0 : qIndex === questionIndex) {
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map((subQuestion, sqIndex) => {
                                if (sqIndex === subQuestionIndex) {
                                    return {
                                        ...subQuestion,
                                        rubricItems: [...copiedRubricItems]
                                    };
                                }
                                return subQuestion;
                            })
                        };
                    }
                    return question;
                });

                setFieldValue('examPaper', newExamPaper);
                handleChange({fieldName: 'examPaper', value: newExamPaper});
            }
        },
        [copiedRubricItems]
    );

    const BubbleSheet = ({questions, onSelect, onRemove}) => (
        <div className="mt-4 p-4 border rounded-lg">
            <div className="flex justify-between mb-4">
                <h3 className="text-lg font-semibold">Bubble Sheet</h3>
                <div className="space-x-2">
                    <button
                        type="button"
                        className="px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 text-slate-700 border-slate-200 hover:border-slate-400"
                        disabled={isPublished}
                        onClick={() => handleAddBubbleSheet(1)}
                    >
                        Add 1
                    </button>
                    <button
                        type="button"
                        className="px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 text-slate-700 border-slate-200 hover:border-slate-400"
                        disabled={isPublished}
                        onClick={() => handleAddBubbleSheet(10)}
                    >
                        Add 10
                    </button>
                    <button
                        type="button"
                        className="px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 text-slate-700 border-slate-200 hover:border-slate-400"
                        disabled={isPublished}
                        onClick={() => handleAddBubbleSheet(50)}
                    >
                        Add 50
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-4 gap-4">
                {questions.map(question => (
                    <div key={question.id} className="p-3 border rounded">
                        <div className="flex justify-between mb-2">
                            <span>Question {question.id}</span>
                            <button
                                type="button"
                                className="text-red-500 hover:text-red-700"
                                disabled={isPublished}
                                onClick={() => onRemove(question.id)}
                            >
                                ×
                            </button>
                        </div>
                        <div className="flex space-x-2">
                            {['A', 'B', 'C', 'D', 'E'].map(answer => (
                                <button
                                    key={answer}
                                    type="button"
                                    className={`w-8 h-8 rounded-full border transition-all duration-200 ${
                                        question.selectedAnswers.includes(answer)
                                            ? 'bg-orange-200 border-orange-300'
                                            : 'bg-white hover:border-slate-400'
                                    }`}
                                    disabled={isPublished}
                                    onClick={() => onSelect(question.id, answer)}
                                >
                                    {answer}
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="flex-grow container">
            <OutdatedWorkflowWarning/>
            <div className="flex lg:space-x-4">
                <div className="lg:w-full pr-4">
                    <div className={`flex-grow container ${disabledStyle}`}>
                        <Formik
                            enableReinitialize
                            initialValues={getInitialValues()}
                            validateOnBlur={true}
                            validateOnChange={true}
                            validateOnMount={true}
                            validationSchema={validationSchema}
                            onSubmit={async (values, {setSubmitting}) => {
                                setSubmitting(true);
                                await handleChange({fieldName: 'examPaper', value: values.examPaper});
                                setSubmitting(false);
                            }}
                        >
                            {({values, setFieldValue}) => (
                                <Form>
                                    <FormikSubmitEffect
                                        onSubmitError={async () => {
                                            await handleChange({fieldName: 'examPaper', value: values.examPaper});
                                        }}
                                    />

                                    <div className="relative">
                                        <div className="flex flex-col w-full mb-5">
                                            <h2 className="text-xl font-bold mb-1">Step 1: Build Assignment</h2>
                                            <p className="text-sm mb-4">
                                                Enter the questions, preferably in LaTeX, and, optionally, edit rubrics.
                                                <br/> You can enter solutions manually, or upload a GradeWiz solutions
                                                PDF
                                                later.
                                            </p>
                                        </div>
                                        <div className="flex flex-col w-full mb-5">
                                            <button
                                                type="button"
                                                className="flex items-center text-sm mb-2"
                                                onClick={() => setIsExamInstructionsVisible(!isExamInstructionsVisible)}
                                            >
                                                <img
                                                    src="/assets/back_arrow.png"
                                                    className={`size-5 opacity-60 transform ${isExamInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                                    alt="Toggle exam instructions"
                                                />
                                                Instructions for Students
                                            </button>
                                        </div>

                                        {isExamInstructionsVisible && (
                                            <div className="sexy-border rounded-xl mb-4 w-full text-sm h-64">
                                                <div className="w-full h-full flex flex-col scroll-auto">
                                                    <Field
                                                        name="instructions"
                                                        as="textarea"
                                                        disabled={isPublished}
                                                        className={`actual-input p-4 ${disabledStyle}`}
                                                        onBlur={e =>
                                                            handleChange({
                                                                fieldName: 'instructions',
                                                                value: e.target.value
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <div className="flex flex-col w-full mb-5">
                                            <button
                                                type="button"
                                                className="flex items-center text-sm mb-2"
                                                onClick={() => setIsSettingsVisible(!isSettingsVisible)}
                                            >
                                                <img
                                                    src="/assets/back_arrow.png"
                                                    className={`size-5 opacity-60 transform ${isSettingsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                                    alt="Toggle settings"
                                                />
                                                Settings
                                            </button>
                                        </div>

                                        {isSettingsVisible && (
                                            <div className="flex flex-col w-full">
                                                <div className="grid grid-cols-2 gap-4 mb-4">
                                                    <div className="flex items-center">
                                                        <div className="relative inline-block mr-2">
                                                            <input
                                                                type="checkbox"
                                                                id="showPointsCheckbox"
                                                                checked={values.showPoints}
                                                                disabled={isPublished}
                                                                className="sr-only peer"
                                                                onChange={handleShowPointsChange}
                                                            />
                                                            <label
                                                                htmlFor="showPointsCheckbox"
                                                                className={`${isPublished ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} 
                                                                relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out
                                                                ${values.showPoints ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                                                            >
                                <span
                                    className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                                                                    ${values.showPoints ? 'translate-x-6' : 'translate-x-1'}`}
                                />
                                                            </label>
                                                        </div>
                                                        <label
                                                            htmlFor="showPointsCheckbox"
                                                            className="text-sm font-medium text-gray-700"
                                                        >
                                                            Make Weights Visible
                                                        </label>
                                                    </div>

                                                    {/* Allow Pre-grade Toggle */}
                                                    {!isExam && (
                                                        <div className="flex items-center">
                                                            <div className="relative inline-block mr-2">
                                                                <input
                                                                    type="checkbox"
                                                                    id="allowPregradeCheckbox"
                                                                    checked={values.allowPregrade}
                                                                    disabled={isPublished}
                                                                    className="sr-only peer"
                                                                    onChange={handleAllowPregradeChange}
                                                                />
                                                                <label
                                                                    htmlFor="allowPregradeCheckbox"
                                                                    className={`${isPublished ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} 
                    relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out
                    ${values.allowPregrade ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                                                                >
                                  <span
                                      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                    ${values.allowPregrade ? 'translate-x-6' : 'translate-x-1'}`}
                                  />
                                                                </label>
                                                            </div>
                                                            <label
                                                                htmlFor="allowPregradeCheckbox"
                                                                className="text-sm font-medium text-gray-700"
                                                            >
                                                                Allow Pre-Grading
                                                            </label>
                                                        </div>
                                                    )}

                                                    <div className="flex items-center">
                                                        <div className="relative inline-block mr-2">
                                                            <input
                                                                type="checkbox"
                                                                id="hideFirstPageCheckbox"
                                                                checked={values.hideFirstPage}
                                                                disabled={isPublished}
                                                                className="sr-only peer"
                                                                onChange={handleHideFirstPageChange}
                                                            />
                                                            <label
                                                                htmlFor="hideFirstPageCheckbox"
                                                                className={`${isPublished ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} 
                                                                relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out
                                                                ${values.hideFirstPage ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                                                            >
                                <span
                                    className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                                                                    ${values.hideFirstPage ? 'translate-x-6' : 'translate-x-1'}`}
                                />
                                                            </label>
                                                        </div>
                                                        <label
                                                            htmlFor="hideFirstPageCheckbox"
                                                            className="text-sm font-medium text-gray-700"
                                                        >
                                                            Hide Title Page
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="flex flex-col w-full mb-5">
                                            <button
                                                type="button"
                                                className="flex items-center text-sm mb-2"
                                                onClick={() =>
                                                    setIsGradingInstructionsVisible(!isGradingInstructionsVisible)
                                                }
                                            >
                                                <img
                                                    src="/assets/back_arrow.png"
                                                    className={`size-5 opacity-60 transform ${isGradingInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                                    alt="Toggle grading instructions"
                                                />
                                                AI Grading Instructions (optional)
                                            </button>
                                        </div>

                                        {isGradingInstructionsVisible && (
                                            <div className="sexy-border rounded-xl mb-4 w-full text-sm h-40">
                                                <div className="w-full h-full flex flex-col scroll-auto">
                                                    <Field
                                                        name="gradingInstructions"
                                                        as="textarea"
                                                        disabled={isPublished}
                                                        className={`actual-input p-4 ${disabledStyle} text-xs text-gray-500`}
                                                        onBlur={e =>
                                                            handleChange({
                                                                fieldName: 'gradingInstructions',
                                                                value: e.target.value
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <div className="flex flex-col w-full mb-5">
                                            <button
                                                type="button"
                                                className="flex items-center text-sm mb-2"
                                                onClick={() => setIsBubbleSheetVisible(!isBubbleSheetVisible)}
                                            >
                                                <img
                                                    src="/assets/back_arrow.png"
                                                    className={`size-5 opacity-60 transform ${isBubbleSheetVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                                    alt="Toggle bubble sheet"
                                                />
                                                Bubble Sheet Options
                                            </button>
                                        </div>
                                        {isBubbleSheetVisible && (
                                            <div className="mb-4">
                                                <div className="flex items-center gap-2 mb-4">
                                                    {bubbleSheet.length === 0 ? (
                                                        <button
                                                            type="button"
                                                            className="px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 text-slate-700 border-slate-200 hover:border-slate-400"
                                                            disabled={isPublished}
                                                            onClick={() => handleAddBubbleSheet(10)}
                                                        >
                                                            Add Bubble Sheet
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 text-red-600 border-red-200 hover:border-red-400"
                                                            disabled={isPublished}
                                                            onClick={handleRemoveBubbleSheet}
                                                        >
                                                            Remove Bubble Sheet
                                                        </button>
                                                    )}
                                                </div>

                                                {bubbleSheet.length > 0 && (
                                                    <BubbleSheet
                                                        questions={bubbleSheet}
                                                        onSelect={handleSelectAnswer}
                                                        onRemove={handleRemoveBubbleQuestion}
                                                    />
                                                )}
                                            </div>
                                        )}

                                        {showSavedPopup && (
                                            <div
                                                className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                                                Progress saved
                                            </div>
                                        )}

                                        {showErrorMessagePopup && (
                                            <div
                                                className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded">
                                                {errorMessage}
                                            </div>
                                        )}

                                        {lastSaved && (
                                            <div className="text-sm text-gray-500 mb-2">
                                                Last saved: {lastSaved.toLocaleTimeString()}
                                            </div>
                                        )}

                                        <div className="flex justify-end">
                                            {!!viewedQuestions.size ? (
                                                <button
                                                    type="button"
                                                    className="ml-2"
                                                    onClick={() => {
                                                        setViewedQuestions(prev => new Map(prev.clear()));
                                                    }}
                                                >
                                                    <img src="/assets/hide.png" className="size-6" alt="Hide all"/>
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="ml-2"
                                                    onClick={() => {
                                                        const allIds = new Map();
                                                        values.examPaper.map(question => allIds.set(question.id, []));
                                                        setViewedQuestions(allIds);
                                                    }}
                                                >
                                                    <img src="/assets/view.png" className="size-6" alt="View all"/>
                                                </button>
                                            )}
                                        </div>

                                        <DndContext
                                            collisionDetection={rectIntersection}
                                            modifiers={[restrictToVerticalAxis]}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <TQuestionColumn
                                                fieldName={'examPaper'}
                                                examPaper={values.examPaper}
                                                isPublished={isPublished}
                                                disabledStyle={disabledStyle}
                                                isCreated={values.created}
                                                isDraftDataLoading={isDraftDataLoading}
                                                viewedQuestions={viewedQuestions}
                                                setViewedQuestions={setViewedQuestions}
                                                updateViewedQuestions={updateViewedQuestions}
                                                setError={setError}
                                                error={error}
                                                uploadQuestions={async () => {
                                                    await handleChange({
                                                        fieldName: 'examPaper',
                                                        value: values.examPaper
                                                    });
                                                    uploadQuestions();
                                                }}
                                                handleChange={handleChange}
                                                copiedRubricItems={copiedRubricItems}
                                                assignmentId={assignment_id}
                                                generateRubricsRef={generateRubricsRef}
                                                bubbleSheet={bubbleSheet}
                                                onCopyRubricItems={handleCopyRubricItems}
                                                onPasteRubricItems={handlePasteRubricItems(values, setFieldValue)}
                                            />
                                        </DndContext>
                                    </div>

                                    {values.teacherPdfUrl ? (
                                        <div
                                            className={
                                                'lg:w-2/5 border-2 rounded-xl lg:fixed overflow-auto overflow-x-hidden lg:right-0 mt-[72px] lg:top-[72px] lg:bottom-[64px] mr-10'
                                            }
                                        >
                                            {isExam && (
                                                <div
                                                    className="absolute bottom-0 left-0 right-0 bg-yellow-50/80 border-l-4 border-yellow-400 p-2 z-10">
                                                    <div className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <img
                                                                src="/assets/tclasses/warning.png"
                                                                alt="Warning"
                                                                className="h-4 w-4 text-yellow-400"
                                                            />
                                                        </div>
                                                        <div className="ml-2">
                                                            <p className="text-xs text-yellow-700 font-semibold">
                                                                Do not print and give this PDF to students.
                                                            </p>
                                                            <p className="text-xs text-yellow-700">
                                                                This is an answer key copy. Publish the assignment to
                                                                access the
                                                                student PDF.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="flex justify-end w-full mr-5 h-full overflow-x-hidden">
                                                <PDFView
                                                    url={`${apiUrl}/api/pdf-file/${values.teacherPdfUrl}?t=${pdfTimestamp}`}
                                                    initialPage={
                                                        parseInt(
                                                            localStorage.getItem(`pdf-page-${assignment_id}`) || '1'
                                                        ) - 1
                                                    }
                                                    className="h-[calc(100vh-136px)] overflow-x-hidden"
                                                    onPageChange={pageNum => {
                                                        localStorage.setItem(`pdf-page-${assignment_id}`, pageNum.toString());
                                                        setCurrentPage(pageNum);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                'w-full lg:w-2/5 lg:fixed lg:right-0 mt-[72px] lg:top-[72px] lg:bottom-[64px] lg:overflow-auto mr-10'
                                            }
                                        >
                                            <div
                                                className="w-full h-full flex flex-col items-center justify-center bg-gray-100 rounded-xl">
                                                <div className="flex flex-col items-center">
                                                    <FileQuestion
                                                        className="w-24 h-24 mb-4 text-gray-400 stroke-[1.5]"/>
                                                    <p className="text-xl font-semibold text-gray-700">
                                                        Please generate PDF to start
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TPset;
