import * as pdfjsLib from "pdfjs-dist";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export async function generateThumbnail({ pdfUrl, pageNumber, width = 150 }) {
  try {
    // For data URLs, we need to handle them differently
    const loadingTask = pdfjsLib.getDocument({
      url: pdfUrl,
      cMapUrl: "https://unpkg.com/pdfjs-dist@3.4.120/cmaps/",
      cMapPacked: true,
    });

    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(pageNumber);

    // Calculate scale to match desired width while maintaining aspect ratio
    const viewport = page.getViewport({ scale: 1.0 });
    const scale = width / viewport.width;
    const scaledViewport = page.getViewport({ scale });

    // Create canvas with proper dimensions
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d", { alpha: false });
    canvas.width = scaledViewport.width;
    canvas.height = scaledViewport.height;

    // Set white background
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Render PDF page to canvas
    await page.render({
      canvasContext: context,
      viewport: scaledViewport,
      background: "white",
    }).promise;

    return canvas.toDataURL("image/jpeg", 0.8); // Use JPEG for smaller size
  } catch (error) {
    console.error("Error generating thumbnail:", error);
    return null;
  }
}
