import React, { useMemo } from "react";
import { AlertCircle, ArrowRight, CheckCircle, Plus } from "lucide-react";
import QuestionCard from './QuestionCard';
import { useParams } from 'react-router-dom';
import { useAuth } from "../../../../auth/AuthContext";
import { useDispatch } from "react-redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { gradeApi } from "../../../../api";

export function transformJSON(inputJSON) {
    const questions = Object.entries(inputJSON)
        .map(([id, data]) => ({
            ...data,
            id: parseInt(id)
        }))
        .sort((a, b) => a.id - b.id);

    return questions.map(question => {
        const defaultRubricItems = [
            {
                descriptor: "Fully correct",
                value: "2"
            },
            {
                descriptor: "Partially correct",
                value: "1"
            },
            {
                descriptor: "Incorrect or no work",
                value: "0"
            }
        ];

        const defaultMcqOptions = [
            {
                isCorrect: false,
                option: "A",
                text: ""
            },
            {
                isCorrect: false,
                option: "B",
                text: ""
            }
        ];

        // Check if this is a merged question (has answerBox at the main question level)
        if (question.mergedSubquestions) {
            // For merged questions, create a single question with merged subquestion text and solution
            return {
                answerImageS3Key: null,
                answerImageSize: null,
                answerImageUrl: null,
                id: question.id,
                imageS3Key: null,
                imageSize: null,
                imageUrl: null,
                mcqOptions: defaultMcqOptions,
                question: question.questionText,
                questionSizing: 30,
                questionType: "freeform",
                rubricItems: defaultRubricItems,
                solution: question.mergedSolutionText || "",
                subQuestions: [{
                    answerImageS3Key: null,
                    answerImageSize: null,
                    answerImageUrl: null,
                    id: 1,
                    imageS3Key: null,
                    imageSize: 50,
                    imageUrl: null,
                    question: question.mergedSubquestionText || "",
                    questionSizing: 30,
                    questionType: "freeform",
                    rubricItems: defaultRubricItems,
                    solution: question.mergedSolutionText || "",
                    weight: 1
                }],
                weight: 1
            };
        }
        
        // For non-merged questions, process as before
        const transformedSubQuestions = question.subquestions.map((subq, index) => {
            const subqData = {
                answerImageS3Key: null,
                answerImageSize: null,
                answerImageUrl: null,
                id: index + 1,
                imageS3Key: null,
                imageSize: 50,
                imageUrl: null,
                question: subq.text || "",
                questionSizing: 30,
                questionType: subq.type,
                rubricItems: defaultRubricItems,
                solution: subq.solution,
                weight: 1
            };
            
            // Add mcqOptions if this is an MCQ question
            if (subq.type === 'mcq' && subq.options) {
                // Ensure options are always simple strings
                subqData.mcqOptions = subq.options.map((option, idx) => ({
                    isCorrect: subq.correctAnswer?.includes(String.fromCharCode(65 + idx)),
                    option: String.fromCharCode(65 + idx),
                    text: option // Text should already be a string at this point
                }));
            }
            
            return subqData;
        });

        // Determine if the main question is MCQ type
        const isMainQuestionMCQ = question.type === 'mcq';
        
        const mainQuestionData = {
            answerImageS3Key: null,
            answerImageSize: null,
            answerImageUrl: null,
            id: question.id,
            imageS3Key: null,
            imageSize: null,
            imageUrl: null,
            question: question.questionText,
            questionSizing: 30,
            questionType: isMainQuestionMCQ ? "mcq" : "freeform",
            rubricItems: defaultRubricItems,
            solution: question.subquestions[0]?.solution || "",
            subQuestions: transformedSubQuestions,
            weight: 1
        };
        
        // Add MCQ options if this is an MCQ question
        if (isMainQuestionMCQ && question.options) {
            mainQuestionData.mcqOptions = question.options.map((option, idx) => ({
                isCorrect: question.correctAnswer?.includes(String.fromCharCode(65 + idx)),
                option: String.fromCharCode(65 + idx),
                text: option
            }));
        } else {
            // Default MCQ options for non-MCQ questions
            mainQuestionData.mcqOptions = defaultMcqOptions;
        }
        
        return mainQuestionData;
    });
}

const QuestionsView = ({ extractedQuestions, setExtractedQuestions, uploadQuestions, onEditMCQ, currentMissingSolution }) => {
    // Function to add a new question
    const handleAddQuestion = () => {
        if (!extractedQuestions) {
            // If no questions exist yet, create the first one
            const newQuestions = {
                1: {
                    questionText: '',
                    type: 'freeform',
                    subquestions: [
                        {
                            letter: 'a',
                            text: '',
                            type: 'freeform',
                            solution: '',
                            options: [],
                            correctAnswer: null
                        }
                    ]
                }
            };
            setExtractedQuestions(newQuestions);
            return;
        }

        // Find the highest question ID and add a new question with the next ID
        const questionIds = Object.keys(extractedQuestions).map(id => parseInt(id));
        const nextId = Math.max(...questionIds) + 1;

        const updatedQuestions = {
            ...extractedQuestions,
            [nextId]: {
                questionText: '',
                type: 'freeform',
                subquestions: [
                    {
                        letter: 'a',
                        text: '',
                        type: 'freeform',
                        solution: '',
                        options: [],
                        correctAnswer: null
                    }
                ]
            }
        };

        setExtractedQuestions(updatedQuestions);
    };

    if (!extractedQuestions) {
        return (
            <div className="no-questions">
                <AlertCircle className="w-12 h-12" />
                <p>No questions extracted yet</p>
                <span className="help-text">Upload a document to get started</span>
                <button 
                    className="add-question-btn" 
                    onClick={handleAddQuestion}
                >
                    <Plus className="w-4 h-4" />
                    <span>Add Question</span>
                </button>
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col gap-4 p-4">
                {Object.entries(extractedQuestions).map(([id, question], index) => (
                    <div key={id} className="w-full" style={{ "--animation-delay": `${index * 0.1}s` }}>
                        {currentMissingSolution && 
                         currentMissingSolution.type === 'mcq' && 
                         currentMissingSolution.id === id && 
                         currentMissingSolution.subIndex === null && (
                            <div className="mcq-edit-button-container">
                                <button 
                                    className="edit-mcq-button" 
                                    onClick={onEditMCQ}
                                    title="Edit MCQ Solution"
                                >
                                    Edit MCQ Solution
                                </button>
                            </div>
                        )}
                        <QuestionCard
                            id={id}
                            question={question}
                            extractedQuestions={extractedQuestions}
                            setExtractedQuestions={setExtractedQuestions}
                            uploadQuestions={uploadQuestions}
                            currentMissingSolution={currentMissingSolution}
                            onEditMCQ={onEditMCQ}
                        />
                    </div>
                ))}
                <button 
                    className="add-question-btn" 
                    onClick={handleAddQuestion}
                >
                    <Plus className="w-4 h-4" />
                    <span>Add Question</span>
                </button>
            </div>
        </>
    );
};

export default QuestionsView;