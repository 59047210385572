import {isEmpty} from 'lodash';
import {nanoid} from 'nanoid';
import React, {useEffect, useRef, useState} from 'react';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext';
import DragAndDropUpload from '../../DragDropAnswer.js';
import OddPagesModal from './OddPagesModal.js';
import {GRADING_STATUS} from '../constants.js';

function ExamUpload({assignment_id, setUpdateTrigger}) {
    const [file, setFile] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isPolling, setIsPolling] = useState(false);
    const [details, setDetails] = useState([]);
    const {user} = useAuth();
    const pollIntervalRef = useRef(null);
    const [showOddPagesModal, setShowOddPagesModal] = useState(false);
    const [numPages, setNumPages] = useState(0);
    // This controls whether to use alignment markers or not
    // We want the UI to show "Do NOT use alignment markers" as toggled ON by default
    // But the API expects use_text_alignment=false for this case
    const [doNotUseAlignmentMarkers, setDoNotUseAlignmentMarkers] = useState(false);

    // This is what gets sent to the API - always false when doNotUseAlignmentMarkers is true
    const useTextAlignment = !doNotUseAlignmentMarkers;
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);

    const checkNumberOfPages = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/problem-set/num-pages/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            console.log(response.data)
            return response.data;
        } catch (error) {
            console.error('Error checking number of pages:', error);
            setErrorMessage('Failed to upload file. Please try again or contact support.');
            return null;
        }
    };


    const handleFileChange = selectedFile => {
        setFile(selectedFile);
    };

    const checkGradingStatus = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/batch-upload/status`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error checking grading status:', error);
            return false;
        }
    };

    const cancelGradingStatus = async () => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/batch-upload/status`,
                body: {},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
        } catch (error) {
            console.error('Error canceling grading status:', error);
            return false;
        }
    };

    useEffect(() => {
        const checkStatus = async () => {
            const {status, details} = await checkGradingStatus();
            if (status === GRADING_STATUS.processed) {
                setErrorMessage('Assignments graded successfully!');
                setDetails(details);
            }

            if (status === GRADING_STATUS.failed) {
                setErrorMessage('Assignments grading failed!');
                setDetails(details);
            }

            if (status === GRADING_STATUS.in_progress) {
                startPolling();
            }
        };

        checkStatus();
    }, []);

    const startPolling = () => {
        setIsPolling(true);
        pollIntervalRef.current = setInterval(async () => {
            const {status, details} = await checkGradingStatus();
            console.log(status);
            if (status !== GRADING_STATUS.in_progress) {
                clearInterval(pollIntervalRef.current);
                setIsPolling(false);
                setLoading(false);
                setErrorMessage(
                    status === GRADING_STATUS.failed
                        ? 'Assignments grading failed'
                        : 'Assignments graded successfully!'
                );
                setUpdateTrigger(prev => !prev);
            }
            setDetails(details);
        }, 5000);
    };

    const handleCancel = async () => {
        await cancelGradingStatus();
        if (pollIntervalRef.current) {
            clearInterval(pollIntervalRef.current);
        }

        setIsPolling(false);
        setLoading(false);
        setErrorMessage('Manually canceled by teacher!');
    };

    const uploadAndGradeAssignments = async (e, discardBlankPages = false, bypassModalCheck = false) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        if (!file) {
            setErrorMessage('Please select a file to upload.');
            setLoading(false);
            return;
        }


        const pagesData = await checkNumberOfPages();
        if (!pagesData) {
            setLoading(false);
            return;
        }

        // Use pagesData directly instead of numPages state
        if (pagesData % 2 !== 0 && !discardBlankPages && !bypassModalCheck) {
            setNumPages(pagesData);
            setShowOddPagesModal(true);
            setLoading(false);
            return;
        }
        setNumPages(pagesData);

        // Alignment method is always set to false now (don't use text-based alignment)

        const formData = new FormData();
        formData.append('file_data', file);
        formData.append('user_id', user.user.id);
        formData.append('discard_blank_pages', discardBlankPages);
        formData.append('use_text_alignment', useTextAlignment);


        try {
            const uploadResponse = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/batch-upload/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            if (uploadResponse.status === 200) {
                setSubmitted(true);
                startPolling();
            } else {
                setErrorMessage('Failed to upload. Please try again.');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error uploading or grading assignments:', error);
            setErrorMessage(error.response?.data?.error || 'An error occurred. Please try again.');
            setLoading(false);
        }
    };

    const handleOddPagesConfirm = (e) => {
        setShowOddPagesModal(false);
        uploadAndGradeAssignments(e, true, true);
    };

    const handleOddPagesReject = (e) => {
        setShowOddPagesModal(false);
        uploadAndGradeAssignments(e, false, true); // to prevent infinite loop of saying no, proceed
    };

    return (
        <div className="flex flex-col w-full mb-4 overflow-x-hidden">
            <h2 className="text-xl font-bold mb-1">Step 3: Upload student work</h2>
            <p className="text-sm mb-4">
                Scan all student exams and upload in a single PDF.
                <br/> The system will automatically grade the submissions.
            </p>
            <div className="w-full mb-5 overflow-x-hidden">
                <button
                    type="button"
                    className="flex items-center text-sm mb-2 hover:bg-gray-100 px-2 py-1 rounded"
                    onClick={() => setIsSettingsVisible(!isSettingsVisible)}
                >
                    <img
                        src="/assets/back_arrow.png"
                        className={`size-5 opacity-60 transform ${isSettingsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                        alt="Toggle settings"
                    />
                    <span className="font-medium">Advanced Settings (Not Recommended)</span>
                </button>

                {isSettingsVisible && (
                    <div className="p-4 bg-gray-50 rounded-lg border border-gray-200 mb-4 overflow-x-hidden">
                        <div className="flex items-center mb-3">
                            <div className="flex items-center">
                                <div className="relative inline-block mr-2">
                                    <input
                                        type="checkbox"
                                        id="disableAlignment"
                                        checked={doNotUseAlignmentMarkers}
                                        className="sr-only peer"
                                        onChange={() => setDoNotUseAlignmentMarkers(!doNotUseAlignmentMarkers)}
                                    />
                                    <label
                                        htmlFor="disableAlignment"
                                        className={`cursor-pointer relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out
                                        ${doNotUseAlignmentMarkers ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                                    >
                                        <span
                                            className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                                            ${doNotUseAlignmentMarkers ? 'translate-x-6' : 'translate-x-1'}`}
                                        />
                                    </label>
                                </div>
                                <label
                                    htmlFor="disableAlignment"
                                    className="text-sm font-medium text-gray-700"
                                >
                                    Do NOT use alignment markers
                                </label>
                            </div>
                        </div>

                        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-2">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <img
                                        src="/assets/tclasses/warning.png"
                                        alt="Warning"
                                        className="h-5 w-5 mt-0.5"
                                    />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-yellow-800 font-semibold">
                                        Important: Student work might get lost or not be seen by the AI
                                    </p>
                                    <p className="text-sm text-yellow-700 mt-1">
                                        We cannot ensure pages are in the correct order. <strong>MAKE
                                        SURE</strong> pages are in correct order, none are missing, no extra pages are
                                        included, and you ARE using an industrial-grade scanner.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div>
                <DragAndDropUpload onFileChange={handleFileChange}/>
                <div className="flex items-center space-x-4 mt-4">
                    <button
                        disabled={!file || loading || isPolling}
                        className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${
                            loading || isPolling
                                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                : 'sexy-button w-[200px]' // Increased width from 170px to 200px
                        }`}
                        onClick={uploadAndGradeAssignments}
                    >
                        {loading || isPolling ? (
                            <>
                                <span
                                    className="opacity-0">{submitted ? 'Reupload & Grade' : 'Upload & Grade'}</span>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                </div>
                            </>
                        ) : submitted ? (
                            'Reupload & Grade'
                        ) : (
                            'Upload & Grade'
                        )}
                    </button>
                    {(loading || isPolling) && (
                        <button
                            className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    )}
                </div>
            </div>
            {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
            {(loading || isPolling) && (
                <p className="text-blue-500 mt-4">Grading in progress, please check back later...</p>
            )}
            {showOddPagesModal && (
                <OddPagesModal
                    isOpen={showOddPagesModal}
                    numPages={numPages}
                    onClose={() => setShowOddPagesModal(false)}
                    onConfirm={handleOddPagesConfirm}
                    onReject={handleOddPagesReject}
                />
            )}
            {!isEmpty(details) && !(loading || isPolling) && (
                <div className="space-x-4 max-h-[250px] overflow-y-scroll">
                    <b className="mt-4">Grading details :</b> <br/>
                    {details.map(info => (
                        <>
              <span key={nanoid()} className="text-sm mb-4">
                {info}
              </span>
                            <br/>
                        </>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ExamUpload;
