import React, {useState, useRef, useEffect} from 'react';
import {InlineMath} from 'react-katex';
import {Field} from 'formik';
import 'katex/dist/katex.min.css';

const LaTeXPreviewField = ({
                               name,
                               onBlur,
                               disabled = false,
                               className = "",
                               placeholder = "",
                               rows = 3,
                               isEditMode = false,
                           }) => {
    const [isEditing, setIsEditing] = useState(false);
    const textareaRef = useRef(null);
    // console.log('isEditMode', isEditMode)
    // console.log(name)
    // console.log(disabled)
    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        }
    };

    useEffect(() => {
        if (isEditing) {
            adjustTextareaHeight();
        }
    }, [isEditing]);

    const renderContent = (content) => {
        if (!content) {
            return null;
        }

        // Split content by code blocks first
        const segments = [];
        let currentIndex = 0;
        const codeBlockRegex = /```[\s\S]*?```/g;
        let match;


        while ((match = codeBlockRegex.exec(content)) !== null) {
            // Add text before code block
            if (match.index > currentIndex) {
                segments.push({
                    type: 'text',
                    content: content.slice(currentIndex, match.index)
                });
            }

            // Add code block
            segments.push({
                type: 'code',
                content: match[0].slice(3, -3)  // Remove ``` from start and end
            });

            currentIndex = match.index + match[0].length;
        }

        // Add remaining text
        if (currentIndex < content.length) {
            segments.push({
                type: 'text',
                content: content.slice(currentIndex)
            });
        }

        // Process each segment
        return segments.map((segment, index) => {
            if (segment.type === 'code') {
                return (
                    <pre key={index}
                         className="bg-gray-100 rounded-md p-2 my-2 font-mono text-sm whitespace-pre-wrap break-all"
                         style={{wordBreak: 'break-all', overflowWrap: 'break-word'}}>
                        {segment.content}
                    </pre>
                );
            } else {
                // Process LaTeX in text segments
                const lines = segment.content.split('\n');
                return lines.map((line, lineIndex) => {
                    // Modified regex to handle escaped dollar signs
                    const parts = line.split(/(?<!\\)(\$.*?(?<!\\)\$)/g);
                    const processedLine = parts.map((part, partIndex) => {
                        if (part.startsWith('$') && part.endsWith('$') && !part.startsWith('\\$')) {
                            const latex = part.slice(1, -1)
                                .replace(/\\\$/g, '$'); // Replace escaped dollar signs with actual dollar signs
                            try {
                                return <InlineMath key={`${index}-${lineIndex}-${partIndex}`} math={latex}/>;
                            } catch (error) {
                                return <span key={`${index}-${lineIndex}-${partIndex}`}
                                             className="text-red-500">{part}</span>;
                            }
                        }
                        // Replace escaped dollar signs with actual dollar signs in regular text
                        return <span key={`${index}-${lineIndex}-${partIndex}`}>{part.replace(/\\\$/g, '$')}</span>;
                    });

                    return (
                        <React.Fragment key={`${index}-${lineIndex}`}>
                            {processedLine}
                            {lineIndex < lines.length - 1 && <br/>}
                        </React.Fragment>
                    );
                });
            }
        });
    };

    const handleBlur = async (e) => {
        if (e.relatedTarget?.closest('.latex-toolbar')) {
            return;
        }
        setIsEditing(false);
        if (onBlur) {
            await onBlur(e);
        }
    };

    const insertText = (textToInsert, field, form) => {
        if (!textareaRef.current) {
            return;
        }

        const start = textareaRef.current.selectionStart;
        const end = textareaRef.current.selectionEnd;
        const currentValue = field.value || '';
        const selectedText = currentValue.substring(start, end);

        let newValue;
        let newCursorPosition;

        if (textToInsert === '```') {
            // For code blocks, add newlines and indent cursor position
            newValue = currentValue.substring(0, start) +
                '```\n' + selectedText + (selectedText ? '' : '\n') + '```' +
                currentValue.substring(end);
            newCursorPosition = start + 4; // Position after ``` and newline
        } else {
            newValue = currentValue.substring(0, start) +
                textToInsert +
                currentValue.substring(end);
            newCursorPosition = start + textToInsert.length;
        }

        form.setFieldValue(name, newValue);

        setTimeout(() => {
            textareaRef.current.focus();
            textareaRef.current.selectionStart = newCursorPosition;
            textareaRef.current.selectionEnd = newCursorPosition;
            adjustTextareaHeight();
        }, 0);
    };

    const LatexToolbar = ({field, form}) => (
        <div className="latex-toolbar flex flex-wrap gap-1 p-2 bg-gray-50 border-b">
            {[
                {label: 'x²', symbol: '$x^2$'},
                {label: '∫', symbol: '$\\int$'},
                {label: '√', symbol: '$\\sqrt{}$'},
                {label: 'π', symbol: '$\\pi$'},
                {label: '±', symbol: '$\\pm$'},
                {label: '∞', symbol: '$\\infty$'},
                {label: '∑', symbol: '$\\sum$'},
                {label: '≠', symbol: '$\\neq$'},
                {label: '≤', symbol: '$\\leq$'},
                {label: '≥', symbol: '$\\geq$'},
                {label: '→', symbol: '$\\rightarrow$'},
                {label: 'θ', symbol: '$\\theta$'},
                {label: 'code', symbol: '```', className: 'font-mono'}
            ].map(({label, symbol, className = ''}) => (
                <button
                    key={symbol}
                    type="button"
                    disabled={disabled}
                    className={`px-2 py-1 text-sm border rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-orange-200 ${
                        disabled ? 'opacity-50 cursor-not-allowed' : ''
                    } ${className}`}
                    onClick={() => insertText(symbol, field, form)}
                >
                    {label}
                </button>
            ))}
        </div>
    );

    return (
        <Field name={name}>
            {({field, form}) => (
                <div className="relative w-full">
                    {isEditing ? (
                        <div className={`border rounded-lg ${className}`}>
                            {!isEditMode &&
                                <LatexToolbar field={field} form={form}/>
                            }
                            <textarea
                                {...field}
                                ref={textareaRef}
                                disabled={disabled}
                                placeholder={placeholder}
                                className="w-full p-2 border-none focus:ring-0 rounded-b-lg overflow-hidden"
                                rows={rows}
                                style={{
                                    resize: 'none',
                                    minHeight: `${rows * 1.5}rem`
                                }}
                                onBlur={handleBlur}
                                onFocus={() => setIsEditing(true)}
                                onChange={(e) => {
                                    field.onChange(e);
                                    adjustTextareaHeight();
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className={`w-full bg-white p-2 border rounded-lg cursor-text ${className}`}
                            style={{
                                minHeight: `${rows * 1.5}rem`
                            }}
                            onClick={() => {
                                if (!disabled) {
                                    setIsEditing(true);
                                    // Focus the textarea on the next render
                                    setTimeout(() => {
                                        if (textareaRef.current) {
                                            textareaRef.current.focus();
                                            const length = textareaRef.current.value.length;
                                            textareaRef.current.selectionStart = length;
                                            textareaRef.current.selectionEnd = length;
                                        }
                                    }, 0);
                                }
                            }}
                        >
                            {field.value ? (
                                renderContent(field.value)
                            ) : (
                                <span className="text-gray-400">{placeholder}</span>
                            )}
                        </div>
                    )}
                </div>
            )}
        </Field>
    );
};

export default LaTeXPreviewField;