import React, { useCallback } from "react";

const useBoxMouseActions = ({
  setSelectedBoxIndex,
  setIsEditMode,
  isDrawMode,
  setIsDragging,
  savedBoxes,
  setDragOffset,
  setBoundaryWarning,
  setLastUpdate,
  setSavedBoxesByPage,
  selectedBoxIndex,
  isResizing,
  isDragging,
  resizeHandle,
  dragOffset,
  lastUpdate,
  currentPage,
  overlayRef,
}) => {
  const handleBoxClick = (e, id) => {
    e.stopPropagation();
    if (isDrawMode) {
      console.log('is draw mode');
      return;
    }

    setSelectedBoxIndex(id);
    setIsEditMode(true);
  };

  const handleBoxMouseDown = (e, id, handle = null) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDrawMode) {
      console.log('is draw mode');
      return;
    }

    const container = overlayRef.current;
    const rect = container.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    setSelectedBoxIndex(id);
    setIsEditMode(true);

    if (handle) {
      setIsResizing(true);
      setResizeHandle(handle);
    } else {
      setIsDragging(true);
      const box = savedBoxes[id];
      setDragOffset({
        x: x - box.x,
        y: y - box.y,
      });
    }
  };

  // Add this new function for drag handle
  const handleDragMouseDown = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDrawMode) {
      console.log('is draw mode');
      return;
    }

    const container = overlayRef.current;
    const rect = container.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    setSelectedBoxIndex(id);
    setIsEditMode(true);
    setIsDragging(true);

    const box = savedBoxes[id];
    setDragOffset({
      x: x - box.x,
      y: y - box.y,
    });
  };

  // Add click handler to exit edit mode when clicking outside
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setSelectedBoxIndex(null);
      setIsEditMode(false);
      setBoundaryWarning(null);
    }
  };

  const handleBoxMouseMove = useCallback(
    (e) => {
      if (!selectedBoxIndex && selectedBoxIndex !== 0) {
        console.log('no selected box index');
        return;
      }
      if (!overlayRef.current) {
        console.log('no overlay ref');
        return;
      }

      // Throttle updates to every 16ms (approximately 60fps)
      const now = Date.now();
      if (now - lastUpdate < 16) {
        console.log('not enough time');
        return;
      }
      setLastUpdate(now);

      const rect = overlayRef.current.getBoundingClientRect();
      const currentX = ((e.clientX - rect.left) / rect.width) * 100;
      const currentY = ((e.clientY - rect.top) / rect.height) * 100;

      // Check for boundary violations
      let warning = null;
      if (currentX < 0 || currentX > 100 || currentY < 0 || currentY > 100) {
        const directions = [];
        if (currentX < 0) {
          directions.push("left");
        }
        if (currentX > 100) {
          directions.push("right");
        }
        if (currentY < 0) {
          directions.push("top");
        }
        if (currentY > 100) {
          directions.push("bottom");
        }

        warning = {
          message: `Selection extends beyond ${directions.join(
            " and "
          )} boundary`,
          position: { x: e.clientX, y: e.clientY },
        };
      }
      setBoundaryWarning(warning);

      setSavedBoxesByPage((prev) => {
        const newBoxes = [...(prev[currentPage] || [])];
        const box = { ...newBoxes[selectedBoxIndex] };
        const originalBox = { ...box };

        if (isResizing) {
          // Round values to 2 decimal places to reduce jitter
          const roundTo2 = (num) => Math.round(num * 100) / 100;

          switch (resizeHandle) {
            case "top":
              const newHeightFromTop = roundTo2(
                originalBox.height + (originalBox.y - currentY)
              );
              if (newHeightFromTop > 0) {
                box.height = newHeightFromTop;
                box.y = roundTo2(currentY);
              }
              break;
            case "bottom":
              box.height = roundTo2(Math.max(0, currentY - box.y));
              break;
            case "left":
              const newWidthFromLeft = roundTo2(
                originalBox.width + (originalBox.x - currentX)
              );
              if (newWidthFromLeft > 0) {
                box.width = newWidthFromLeft;
                box.x = roundTo2(currentX);
              }
              break;
            case "right":
              box.width = roundTo2(Math.max(0, currentX - box.x));
              break;
            case "topLeft":
              const newWidthFromTopLeft = roundTo2(
                originalBox.width + (originalBox.x - currentX)
              );
              const newHeightFromTopLeft = roundTo2(
                originalBox.height + (originalBox.y - currentY)
              );
              if (newWidthFromTopLeft > 0) {
                box.width = newWidthFromTopLeft;
                box.x = roundTo2(currentX);
              }
              if (newHeightFromTopLeft > 0) {
                box.height = newHeightFromTopLeft;
                box.y = roundTo2(currentY);
              }
              break;
            case "topRight":
              const newWidthFromTopRight = roundTo2(
                Math.max(0, currentX - box.x)
              );
              const newHeightFromTopRight = roundTo2(
                originalBox.height + (originalBox.y - currentY)
              );
              if (newWidthFromTopRight > 0) {
                box.width = newWidthFromTopRight;
              }
              if (newHeightFromTopRight > 0) {
                box.height = newHeightFromTopRight;
                box.y = roundTo2(currentY);
              }
              break;
            case "bottomLeft":
              const newWidthFromBottomLeft = roundTo2(
                originalBox.width + (originalBox.x - currentX)
              );
              const newHeightFromBottomLeft = roundTo2(currentY - box.y);
              if (newWidthFromBottomLeft > 0) {
                box.width = newWidthFromBottomLeft;
                box.x = roundTo2(currentX);
              }
              if (newHeightFromBottomLeft > 0) {
                box.height = newHeightFromBottomLeft;
              }
              break;
            case "bottomRight":
              const newWidthFromBottomRight = roundTo2(
                Math.max(0, currentX - box.x)
              );
              const newHeightFromBottomRight = roundTo2(currentY - box.y);
              if (newWidthFromBottomRight > 0) {
                box.width = newWidthFromBottomRight;
              }
              if (newHeightFromBottomRight > 0) {
                box.height = newHeightFromBottomRight;
              }
              break;
          }
        } else if (isDragging) {
          // Add smooth damping to drag movement
          const damping = 0.8;
          const targetX = currentX - dragOffset.x;
          const targetY = currentY - dragOffset.y;
          box.x = box.x + (targetX - box.x) * damping;
          box.y = box.y + (targetY - box.y) * damping;

          // Round final values
          box.x = Math.round(box.x * 100) / 100;
          box.y = Math.round(box.y * 100) / 100;
        }

        // Constrain to PDF boundaries with some padding
        const padding = 0.1; // 0.1% padding
        box.x = Math.max(padding, Math.min(box.x, 100 - box.width - padding));
        box.y = Math.max(padding, Math.min(box.y, 100 - box.height - padding));

        // Ensure minimum dimensions
        box.width = Math.max(box.width, 1);
        box.height = Math.max(box.height, 1);

        newBoxes[selectedBoxIndex] = box;
        return {
          ...prev,
          [currentPage]: newBoxes,
        };
      });
    },
    [
      selectedBoxIndex,
      isResizing,
      isDragging,
      resizeHandle,
      dragOffset,
      lastUpdate,
      currentPage,
    ]
  );

  return {
    handleBoxClick,
    handleBoxMouseDown,
    handleDragMouseDown,
    handleOverlayClick,
    handleBoxMouseMove,
  };
};

export default useBoxMouseActions;
