import React, {useEffect, useRef, useState} from "react";
import {gradeApi} from '../../../../api.js';
import {useAuth} from '../../../../auth/AuthContext';
import {toast} from 'react-hot-toast';
import {
    CheckCircle,
    Code,
    Cpu,
    FileText,
    Download,
    ArrowRight,
} from "lucide-react";
import PDFProcessor from "../components/PDFProcessor";
import "../styles/globals.scss";
import "./style.scss";
import "./page.scss";
import {ArrowLeft} from "lucide-react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {generateThumbnail} from "../utils/generateThumbnail";
import useMouseActions from "../hooks/useMouseActions";
import useBoxMouseActions from "../hooks/useBoxMouseActions";
import useStateLogger from "../hooks/useStateLogger";
import useKeyboardActions from "../hooks/useKeyboardActions";
import {pdfToImage} from "../utils/pdfToImage";
import UploadPDFsComponent from '../components/UploadPDFsComponent';
import SolutionUploadComponent from '../components/SolutionUploadComponent';
import RubricViewer from '../components/RubricViewer';
import {useParams} from "react-router-dom";
import {clearAssignment, clearSubmissions, getAssignmentDetails} from "../../../../store/teacherSlice";

export default function PDFAssignmentHome() {
    const [pdfFiles, setPdfFiles] = useState({
        withSolutions: null,
        withoutSolutions: null
    });
    const {user} = useAuth();
    const navigate = useNavigate();
    const [isDrawing, setIsDrawing] = useState(false);
    const [isDrawMode, setIsDrawMode] = useState(false);
    const [startPoint, setStartPoint] = useState(null);
    const overlayRef = useRef(null);
    const [currentBox, setCurrentBox] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [savedBoxesByPage, setSavedBoxesByPage] = useState({});
    const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
    const [isResizing, setIsResizing] = useState(false);
    const [resizeHandle, setResizeHandle] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({x: 0, y: 0});
    const [pdfDimensions, setPdfDimensions] = useState({width: 0, height: 0});
    const [isEditMode, setIsEditMode] = useState(false);
    const [boundaryWarning, setBoundaryWarning] = useState(null);
    const [lastUpdate, setLastUpdate] = useState(0);
    const [pageThumbnails, setPageThumbnails] = useState({});
    const [pdfImage, setPdfImage] = useState(null);
    const [activeTab, setActiveTab] = useState("questions");
    const [extractedQuestions, setExtractedQuestions] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [proceed, setProceed] = useState(false);
    const {assignment_id, class_id} = useParams();
    const [uploadHover, setUploadHover] = useState(false);
    const dispatch = useDispatch();
    const [uploadStatus, setUploadStatus] = useState({
        withSolutions: false,
        withoutSolutions: false
    });

    // Workflow step state
    const [currentStep, setCurrentStep] = useState(1); // 1: Upload Template, 2: Download, 3: Upload Solutions
    const [markedPdfUrl, setMarkedPdfUrl] = useState(null);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [hasDownloaded, setHasDownloaded] = useState(false);
    const [templateFile, setTemplateFile] = useState(null); // Store the template file

    const [isUploading, setIsUploading] = useState({
        withSolutions: false,
        withoutSolutions: false
    });

    const {
        data: assignment,
        isLoading: isAssignmentLoading,
        errorMessage: assignmentError
    } = useSelector(state => state?.teacherReducer?.assignment);

    const getAssignment = () =>
        dispatch(
            getAssignmentDetails({
                assignmentId: assignment_id,
                token: user.session_token,
                classId: class_id
            })
        );


    useEffect(() => {
        getAssignment();

        return () => {
            dispatch(clearAssignment());
            dispatch(clearSubmissions());
        };
    }, []);

    useEffect(() => {
        if (assignment && assignment.is_assignment_published) {
            navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions`);
        }
    }, [assignment]);

    const [loadingSteps, setLoadingSteps] = useState([
        {
            id: 1,
            label: "Parsing Document",
            icon: FileText,
            progress: 0,
            status: "pending",
        },
        {
            id: 2,
            label: "Extracting Questions",
            icon: Code,
            progress: 0,
            status: "pending",
        },
        {
            id: 3,
            label: "Analyzing Structure",
            icon: Cpu,
            progress: 0,
            status: "pending",
        },
        {
            id: 4,
            label: "Generating Rubrics",
            icon: CheckCircle,
            progress: 0,
            status: "pending",
        },
    ]);
    const [currentLoadingStep, setCurrentLoadingStep] = useState(1);

    // Helper to get current page's boxes
    const savedBoxes = savedBoxesByPage[currentPage] || [];

    const boxMouseActions = useBoxMouseActions({
        setSelectedBoxIndex,
        setIsEditMode,
        isDrawMode,
        setIsDragging,
        savedBoxes,
        setDragOffset,
        setBoundaryWarning,
        setLastUpdate,
        setSavedBoxesByPage,
        selectedBoxIndex,
        isResizing,
        isDragging,
        resizeHandle,
        dragOffset,
        lastUpdate,
        currentPage,
        overlayRef,
    });

    const mouseActions = useMouseActions({
        isDrawing,
        isDrawMode,
        startPoint,
        setCurrentBox,
        setIsDrawing,
        setStartPoint,
        setIsDragging,
        boxMouseActions,
        isDragging,
        isResizing,
        overlayRef,
    });

    useStateLogger({isDrawMode, isDrawing, currentBox, startPoint});

    useKeyboardActions({
        setIsDrawMode,
        setIsDrawing,
        setStartPoint,
        setCurrentBox,
        selectedBoxIndex,
        setSavedBoxesByPage,
        currentPage,
        setSelectedBoxIndex,
    });

    useEffect(() => {
        const updatePdfDimensions = () => {
            const pdfElement = document.querySelector(
                '[class*="rpv-core__viewer"] [class*="rpv-core__page-layer"]'
            );
            if (pdfElement) {
                const rect = pdfElement.getBoundingClientRect();
                setPdfDimensions({
                    width: rect.width,
                    height: rect.height,
                });
            }
        };

        if (pdfFiles.withSolutions || pdfFiles.withoutSolutions) {
            setTimeout(updatePdfDimensions, 1000);
            window.addEventListener("resize", updatePdfDimensions);
            return () => window.removeEventListener("resize", updatePdfDimensions);
        }
    }, [pdfFiles]);

    useEffect(() => {
        if (!uploadedFile || !pdfFiles.withoutSolutions) {
            return;
        }
        if (!totalPages) {
            return;
        }

        (async () => {
            const image = await pdfToImage({
                file: pdfFiles.withSolutions || pdfFiles.withoutSolutions,
                pageNumber: currentPage,
            });
            setPdfImage(image);
        })();

        const generateVisibleThumbnails = async () => {
            const pagesToGenerate = [
                currentPage - 2,
                currentPage - 1,
                currentPage,
                currentPage + 1,
                currentPage + 2,
            ].filter((page) => page > 0 && page <= totalPages);

            for (const pageNumber of pagesToGenerate) {
                await generateThumbnail({
                    pageNumber,
                    pageThumbnails,
                    setPageThumbnails,
                    pdfFiles,
                });
            }
        };

        generateVisibleThumbnails();
    }, [pdfFiles, currentPage, totalPages]);

    const updateLoadingProgress = (stepId, progress, status = "processing") => {
        setLoadingSteps((steps) =>
            steps.map((step) =>
                step.id === stepId ? {...step, progress, status} : step
            )
        );
    };


    const handleProcessDocuments = async () => {
        if (!uploadedFile) {
            console.error('No file uploaded');
            toast.error('Please upload a PDF file first.');
            return;
        }

        console.log('Processing uploaded file:', uploadedFile.name);

        // Create a copy of the file for pdfFiles state to ensure it's available for the viewer
        const fileUrl = URL.createObjectURL(uploadedFile);
        setPdfFiles({
            withoutSolutions: fileUrl,
            withSolutions: fileUrl
        });

        // For BYOB assignments, we need to download the PDF with alignment markers
        // Force byob detection to true for testing
        const isByob = true; // In production, detect this properly based on assignment type
        console.log('isByob:', isByob);

        if (isByob) {
            try {
                console.log('Getting PDF with alignment markers');
                // Get the PDF with alignment markers
                const result = await getMarkedPdf();
                if (result) {
                    console.log('Successfully got marked PDF:', result);
                    setMarkedPdfUrl(result);
                    console.log('Updated markedPdfUrl state:', result);
                    setShowDownloadStep(true);
                    return;
                }
            } catch (error) {
                console.error('Error preparing PDF for download:', error);
                toast.error('Failed to prepare PDF for download. Please try again.');
            }
        }

        // For non-BYOB assignments, proceed directly to analysis
        setProceed(true);
        setIsProcessing(true);

        const reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = async () => {
            const base64String = reader.result;
            try {
                const response = await temporaryUpload(base64String);
                if (response?.data?.success) {
                    localStorage.setItem("solutionsUrl", response.data.url);
                    setPdfFiles(prev => ({
                        ...prev,
                        withSolutions: base64String
                    }));
                    setUploadStatus(prev => ({
                        ...prev,
                        withSolutions: true
                    }));
                }
            } catch (error) {
                console.error("Error uploading solutions PDF:", error);
                setIsProcessing(false);
            }
        };
    };

    const handleSolutionFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setIsUploading(prev => ({...prev, withSolutions: true}));
            setUploadedFile(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const base64String = reader.result;
                try {
                    const response = await temporaryUpload(base64String);
                    if (response?.data?.success) {
                        localStorage.setItem("solutionsUrl", response.data.url);
                        setPdfFiles(prev => ({
                            ...prev,
                            withSolutions: base64String
                        }));
                        setUploadStatus(prev => ({
                            ...prev,
                            withSolutions: true
                        }));
                    }
                } catch (error) {
                    console.error("Error uploading solutions PDF:", error);
                } finally {
                    setIsUploading(prev => ({...prev, withSolutions: false}));
                }
            };
        }
    };

    // Step 1: Handle uploading the template PDF file
    const handleQuestionFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setIsUploading(prev => ({...prev, withoutSolutions: true}));
            setTemplateFile(file); // Store the template file for later use

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const base64String = reader.result;
                try {
                    const response = await temporaryUpload(base64String);
                    if (response?.data?.success) {
                        localStorage.setItem("questionsUrl", response.data.url);
                        setPdfFiles(prev => ({
                            ...prev,
                            withoutSolutions: response.data.url
                        }));
                        setUploadStatus(prev => ({
                            ...prev,
                            withoutSolutions: true
                        }));
                    }
                } catch (error) {
                    console.error("Error uploading questions PDF:", error);
                } finally {
                    setIsUploading(prev => ({...prev, withoutSolutions: false}));
                }
            };
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setUploadHover(true);
    };

    const handleDragLeave = () => {
        setUploadHover(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        setUploadHover(false);
        const file = e.dataTransfer.files[0];
        if (file && file.type === "application/pdf") {
            if (currentStep === 1) {
                // In step 1, upload as template file
                await handleQuestionFileUpload({target: {files: [file]}});
            } else if (currentStep === 3) {
                // In step 3, upload as solution file
                await handleSolutionFileUpload({target: {files: [file]}});
            } else {
                toast.info(`You can't upload files in step ${currentStep}`);
            }
        }
    };

    async function temporaryUpload(base64) {
        try {
            return await gradeApi.post({
                path: `api/assignment/byob-problem-set/upload-base64-pdf/`,
                body: {'file': base64},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            });
        } catch (error) {
            console.error("Error in temporaryUpload:", error);
            throw error;
        }
    }

    // Function to get the PDF with alignment markers
    const getMarkedPdf = async () => {
        console.log("getMarkedPdf");
        // Use the template file for generating the marked PDF
        if (!templateFile) {
            toast.error('No template PDF file uploaded.');
            return null;
        }

        try {
            setDownloadLoading(true);

            // The template file is already a File/Blob object
            const pdfBlob = templateFile;

            // Convert to base64
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = async (e) => {
                    try {
                        const base64pdf = e.target.result;
                        // Extract the base64 content without the prefix
                        const base64Content = base64pdf.split(',')[1];

                        console.log("Sending PDF to backend for alignment markers");

                        // Call the backend to get the PDF with alignment markers
                        const result = await gradeApi.post({
                            path: `/api/assignment/${assignment_id}/get-initial-pdf/`,
                            body: {base64_pdf: base64Content},
                            config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                        });

                        setDownloadLoading(false);
                        if (result && result.data && result.data.pdf && result.data.url) {
                            console.log("Received marked PDF from backend", result.data);
                            resolve(result.data);
                        } else {
                            console.error("Backend returned invalid result", result);
                            reject(new Error('Failed to get marked PDF.'));
                        }
                    } catch (error) {
                        console.error("Error in reader.onload:", error);
                        setDownloadLoading(false);
                        reject(error);
                    }
                };
                reader.onerror = (error) => {
                    console.error("FileReader error:", error);
                    setDownloadLoading(false);
                    reject(error);
                };
                reader.readAsDataURL(pdfBlob);
            });
        } catch (error) {
            setDownloadLoading(false);
            console.error('Error getting marked PDF:', error);
            toast.error('Failed to get PDF with alignment markers. Please try again.');
            return null;
        }
    };

    // Function to handle downloading the PDF
    const handleDownloadPdf = () => {
        console.log('Attempting to download PDF...', markedPdfUrl);
        if (!markedPdfUrl || !markedPdfUrl.pdf) {
            console.error('PDF data not available', markedPdfUrl);
            toast.error('No PDF available for download.');
            return;
        }

        try {
            // Set loading state
            setDownloadLoading(true);

            // For debugging - print out what we're working with
            console.log('PDF URL data:', markedPdfUrl.pdf);

            // Create a temporary link and trigger download
            const link = document.createElement('a');
            link.href = markedPdfUrl.pdf;
            link.download = `gradewiz_marked_assignment_${assignment_id}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Update states
            setHasDownloaded(true);
            setDownloadLoading(false);
            toast.success('PDF downloaded successfully. Please print this PDF for your students to complete.');
        } catch (error) {
            console.error('Error during PDF download:', error);
            setDownloadLoading(false);
            toast.error('Failed to download PDF. Please try again.');
        }
    };

    // Function to continue after download
    const handleContinueAfterDownload = async () => {
        if (!hasDownloaded) {
            toast.warning('Please download the PDF first before continuing.');
            return;
        }

        // Move to step 3 (upload solutions)
        setCurrentStep(3);
    };

    // Step 1: Handle uploading the template PDF and proceed to download step
    const handleUploadTemplate = () => {
        // Proceed to download step after template is uploaded
        if (templateFile) {
            // Set loading state
            setIsUploading(prev => ({
                ...prev,
                withoutSolutions: true
            }));
            
            // Get the PDF with alignment markers
            getMarkedPdf().then(result => {
                if (result) {
                    setMarkedPdfUrl(result);
                    // Store marked PDF URL for template page to use
                    localStorage.setItem("markedPdfUrl", result.url);
                    // Move to step 2 (download)
                    setCurrentStep(2);
                }
                // Reset loading state
                setIsUploading(prev => ({
                    ...prev,
                    withoutSolutions: false
                }));
            }).catch(error => {
                console.error('Error getting marked PDF:', error);
                toast.error('Failed to prepare the PDF with alignment markers. Please try again.');
                // Reset loading state on error too
                setIsUploading(prev => ({
                    ...prev,
                    withoutSolutions: false
                }));
            });
        } else {
            toast.error('Please upload the questions template PDF first.');
        }
    };

    // Go back to the previous step
    const handleBackClick = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
            
            // If going back from step 3 to step 2
            if (currentStep === 3) {
                // No need to reset proceed as we're still in the download step
            }
            // If going back from step 2 to step 1
            else if (currentStep === 2) {
                setProceed(false);
                setExtractedQuestions(null);
            }
        }
    };

    return (
        <div className="app solution-rubrics">
            {/* Only show header during the 3-step workflow (steps 1-3), hide on RubricViewer */}
            {currentStep <= 3 && !extractedQuestions && (
                <div className="page-header">
                    <div className="step-indicator">Step {currentStep}/3</div>
                    <div className="header-left">
                        {currentStep > 1 && (
                            <button onClick={handleBackClick} className="back-button">
                                <ArrowLeft size={20}/>
                                <span>Back</span>
                            </button>
                        )}
                        <div className="gradewiz-logo-container">
                        <div style={{cursor: 'pointer'}} onClick={() => navigate(`/tclasses/${class_id}/tassignments/`)}>
                                <img src="/gradewiz_logo.png" alt="GradeWiz Logo" className="gradewiz-logo"/>
                            </div>
                            <h1>Prepare your Assignment for AI Grading.</h1>
                        </div>
                    </div>
                </div>
            )}

            {/* Replace the existing conditional rendering with this cleaner structure */}
            <div className="content-area">
                <input
                    type="file"
                    accept=".pdf"
                    id="rubric-pdf-upload-solutions"
                    className="hidden"
                    style={{display: "none"}}
                    onChange={handleSolutionFileUpload}
                />
                <input
                    type="file"
                    accept=".pdf"
                    id="rubric-pdf-upload-questions"
                    className="hidden"
                    style={{display: "none"}}
                    onChange={handleQuestionFileUpload}
                />

                {currentStep === 1 && (
                    <UploadPDFsComponent
                        uploadHover={uploadHover}
                        uploadStatus={uploadStatus}
                        isUploading={isUploading}
                        handleDragOver={handleDragOver}
                        handleDragLeave={handleDragLeave}
                        handleDrop={handleDrop}
                        handleProcessDocuments={handleUploadTemplate}
                        step={currentStep}
                    />
                )}

                {currentStep === 2 && (
                    <div className="download-step">
                        <div className="content-container">
                            <div className="marker-download-card">
                                <h2 className="section-title">Download Assignment with Alignment Markers</h2>
                                <p className="section-description">
                                    Before proceeding, you need to download the PDF with alignment markers.
                                    This PDF should be printed and given to students to complete the assignment.
                                </p>

                                <div className="info-notice">
                                    <div className="notice-content">
                                        <strong>Important:</strong> Make sure to print this exact PDF for your students.
                                        The
                                        alignment markers are essential for our system to properly grade the completed
                                        assignments.
                                    </div>
                                </div>

                                <div className="action-buttons">
                                    <button
                                        className={`marker-download-button download-button ${downloadLoading ? 'loading' : ''}`}
                                        disabled={downloadLoading}
                                        onClick={handleDownloadPdf}
                                    >
                                        {downloadLoading ? (
                                            <>
                                                <div className="spinner"></div>
                                                <span>Preparing Download...</span>
                                            </>
                                        ) : (
                                            <>
                                                <Download className="icon" size={18}/>
                                                <span>Download PDF</span>
                                            </>
                                        )}
                                    </button>

                                    <button
                                        className={`continue-button ${!hasDownloaded ? 'disabled' : ''}`}
                                        disabled={!hasDownloaded}
                                        onClick={handleContinueAfterDownload}
                                    >
                                        <span>I've downloaded the PDF</span>
                                        <ArrowRight className="icon" size={18}/>
                                    </button>
                                </div>

                                {hasDownloaded && (
                                    <div className="success-notice">
                                        <div className="notice-content">
                                            <CheckCircle className="icon"/>
                                            PDF downloaded successfully. Click "I've downloaded the PDF" to continue.
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {currentStep === 3 && !proceed && (
                    <SolutionUploadComponent
                        uploadHover={uploadHover}
                        uploadStatus={uploadStatus}
                        isUploading={isUploading}
                        handleDragOver={handleDragOver}
                        handleDragLeave={handleDragLeave}
                        handleDrop={handleDrop}
                        handleProcessSolutions={() => {
                            if (uploadStatus.withSolutions) {
                                setProceed(true);
                            }
                        }}
                        handleFileChange={handleSolutionFileUpload}
                    />
                )}

                {currentStep === 3 && proceed && (
                    <>
                        <PDFProcessor
                            assignmentId={assignment_id}
                            uploadedFile={uploadedFile}
                            setIsProcessing={setIsProcessing}
                            updateLoadingProgress={updateLoadingProgress}
                            setCurrentLoadingStep={setCurrentLoadingStep}
                            onProcessingComplete={(draftData) => {
                                setExtractedQuestions(draftData);
                                setIsProcessing(false);
                                setLoadingSteps(steps =>
                                    steps.map(step => ({...step, progress: 0, status: "pending"}))
                                );
                            }}
                            onError={(errorMessage) => {
                                console.error(errorMessage);
                                setIsProcessing(false);
                            }}
                        />
                        <RubricViewer
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            isProcessing={isProcessing}
                            loadingSteps={loadingSteps}
                            extractedQuestions={extractedQuestions}
                            setExtractedQuestions={setExtractedQuestions}
                            currentLoadingStep={currentLoadingStep}
                            pdfFiles={pdfFiles}
                            setProceed={setProceed}
                        />
                    </>
                )}
            </div>
        </div>
    );
}