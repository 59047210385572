import React from 'react';
import {InlineMath} from 'react-katex';
import 'katex/dist/katex.min.css';

export const LaTeXPreviewContent = ({content}) => {
    if (!content) {
        return null;
    }

    const renderContent = (text) => {
        // Split content by code blocks first
        const segments = [];
        let currentIndex = 0;
        const codeBlockRegex = /```[\s\S]*?```/g;
        let match;

        while ((match = codeBlockRegex.exec(text)) !== null) {
            // Add text before code block
            if (match.index > currentIndex) {
                segments.push({
                    type: 'text',
                    content: text.slice(currentIndex, match.index)
                });
            }

            // Add code block
            segments.push({
                type: 'code',
                content: match[0].slice(3, -3)  // Remove ``` from start and end
            });

            currentIndex = match.index + match[0].length;
        }

        // Add remaining text
        if (currentIndex < text.length) {
            segments.push({
                type: 'text',
                content: text.slice(currentIndex)
            });
        }

        // Process each segment
        return segments.map((segment, index) => {
            if (segment.type === 'code') {
                return (
                    <pre key={index}
                         className="bg-gray-100 rounded-md p-2 my-2 font-mono text-sm whitespace-pre-wrap break-all"
                         style={{wordBreak: 'break-all', overflowWrap: 'break-word'}}>
                        {segment.content}
                    </pre>
                );
            } else {
                // Process LaTeX in text segments
                const lines = segment.content.split('\n');
                return lines.map((line, lineIndex) => {
                    // Modified regex to handle escaped dollar signs
                    const parts = line.split(/(?<!\\)(\$.*?(?<!\\)\$)/g);
                    const processedLine = parts.map((part, partIndex) => {
                        if (part.startsWith('$') && part.endsWith('$') && !part.startsWith('\\$')) {
                            const latex = part.slice(1, -1)
                                .replace(/\\\$/g, '$'); // Replace escaped dollar signs with actual dollar signs
                            try {
                                return <InlineMath key={`${index}-${lineIndex}-${partIndex}`} math={latex}/>;
                            } catch (error) {
                                return <span key={`${index}-${lineIndex}-${partIndex}`}
                                             className="text-red-500">{part}</span>;
                            }
                        }
                        // Replace escaped dollar signs with actual dollar signs in regular text
                        return <span key={`${index}-${lineIndex}-${partIndex}`}>{part.replace(/\\\$/g, '$')}</span>;
                    });

                    return (
                        <React.Fragment key={`${index}-${lineIndex}`}>
                            {processedLine}
                            {lineIndex < lines.length - 1 && <br/>}
                        </React.Fragment>
                    );
                });
            }
        });
    };

    return <div className="latex-content">{renderContent(content)}</div>;
};