// RubricViewer.jsx
import React, {useMemo, useState, useEffect, useRef, useLayoutEffect} from 'react';
import {ArrowRight, CheckCircle, FileText, ArrowLeft, ChevronLeft, ChevronRight, X, Plus, Trash2} from 'lucide-react';
import {toast} from 'react-hot-toast';
import ProcessingOverlay from './ProcessingOverlay';
import QuestionsView, {transformJSON} from './QuestionsView';
import {useParams} from "react-router-dom";
import {useAuth} from "../../../../auth/AuthContext";
import {useDispatch} from "react-redux";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {gradeApi} from "../../../../api";
import {pdfToImage} from '../utils/pdfToImage';
import './styling/RubricViewer.scss';

const
    RubricViewer = ({
                        activeTab,
                        setActiveTab,
                        isProcessing,
                        loadingSteps,
                        extractedQuestions,
                        currentLoadingStep,
                        pdfFiles,
                        setExtractedQuestions,
                        setProceed
                    }) => {
        const [totalPages, setTotalPages] = useState(1);
        const [loadingPdf, setLoadingPdf] = useState(false);
        const [pdfLoadingProgress, setPdfLoadingProgress] = useState(0);
        const [hasShownInitialNotification, setHasShownInitialNotification] = useState(false);
        const [currentMissingSolution, setCurrentMissingSolution] = useState(null);
        const [isEditingMCQ, setIsEditingMCQ] = useState(false);
        const {class_id, assignment_id} = useParams();
        const {user} = useAuth();
        const dispatch = useDispatch();
        const questionsRef = useRef({});
        const pdfContainerRef = useRef(null);

        useEffect(() => {
            const loadScrollablePdf = async () => {
                try {
                    if (!pdfFiles) {
                        console.error('pdfFiles is ndefined or null');
                        setLoadingPdf(false);
                        return;
                    }

                    const pdfUrl = pdfFiles.withSolutions || pdfFiles.withoutSolutions;

                    if (!pdfUrl) {
                        console.error('No PDF URL available in pdfFiles');
                        setLoadingPdf(false);
                        return;
                    }

                    if (!pdfContainerRef.current) {
                        console.error('PDF container reference is not available');
                        setLoadingPdf(false);
                        return;
                    }

                    console.log('Loading PDF from URL:', pdfUrl);
                    setLoadingPdf(true);
                    setPdfLoadingProgress(0);

                    const totalPdfPages = await pdfToImage({
                        file: pdfUrl,
                        scale: 1.5,
                        container: pdfContainerRef.current,
                        onProgress: (current, total) => {
                            setPdfLoadingProgress(Math.round((current / total) * 100));
                        },
                        onComplete: (total) => {
                            console.log(`PDF loaded with ${total} pages`);
                            setTotalPages(total);
                            setLoadingPdf(false);
                        }
                    });
                } catch (error) {
                    console.error('Error loing PF:', error);
                    setLoadingPdf(false);
                    toast.error(`Failed to load PDF: ${error.message}`);
                }
            };

            // Only attempt to load if both the container ref and PDF files exist
            if (pdfContainerRef.current && pdfFiles) {
                loadScrollablePdf();
            }
        }, [pdfFiles, pdfContainerRef, activeTab, loadingSteps]); // Ad

        const uploadQuestionsAction = createAsyncThunk(
            'tpset/uploadQuestions',
            async ({assignment_id, token, body}, thunkApi) => {
                try {
                    return await gradeApi.post({
                        path: `/api/assignment/${assignment_id}/constructor/`,
                        body,
                        config: {headers: {Authorization: `Bearer ${token}`}}
                    });
                } catch (error) {
                    return thunkApi.rejectWithValue(
                        error.response?.data?.error || error.message || 'An error occurred. Please try again.'
                    );
                }
            }
        );


        const uploadQuestions = async (questions) => {
            // Clear any previous constructor data to prevent stale data issues when switching between assignments
            // or when parsing new PDFs for the same assignment
            localStorage.removeItem('constructorData');

            // Check if we have constructor data from localStorage (from merged questions)
            const constructorDataStr = localStorage.getItem('constructorData');
            let questionsToTransform = questions;

            if (constructorDataStr) {
                try {
                    const constructorData = JSON.parse(constructorDataStr);
                    // Use the constructor data if available
                    questionsToTransform = constructorData;
                    // Clear the localStorage item after using it
                    localStorage.removeItem('constructorData');
                } catch (error) {
                    console.error('Error parsing constructor data:', error);
                    // Fall back to the original questions if there's an error
                    questionsToTransform = questions;
                }
            }

            const questions2 = transformJSON(questionsToTransform);
            console.log(questions2);

            const payload = {
                exam_paper: questions2,
                instructions: "Complete this assignment",
                model_instructions: "Grade the student's work carefully. Pay attention to their problem-solving approach, clarity of explanations, and correctness of calculations. Select the appropriate rubric item, based on the demonstrated understanding and effort.",
                feedback_instructions: 'detailed',
                show_points: true,
                hide_first_page: false,
                page: 0,
                allow_pregrade: false,
                bubble_sheet: false,
                preserve_questions: false // Flag to indicate that questions should not be deleted and recreated
            };

            await dispatch(
                uploadQuestionsAction({assignment_id, token: user.session_token, body: payload})
            );
        };

        const handleBackClick = () => {
            if (typeof setProceed === 'function') {
                setProceed(false);
            } else {
                // Fallback behavior if setProceed is not available
                console.warn('setProceed function is not available, using history.back()');
                window.history.back();
            }
            if (typeof setExtractedQuestions === 'function') {
                setExtractedQuestions(null);
            }
        };

        // Function to get all missing solutions
        const getMissingSolutions = (questions) => {
            if (!questions) {
                return [];
            }

            const missing = [];
            Object.entries(questions).forEach(([id, question]) => {
                // Check main MCQ questions
                if (question.type === 'mcq' || question.questionType === 'mcq') {
                    // Check if correctAnswer array exists and has at least one element
                    const hasCorrectAnswer = Array.isArray(question.correctAnswer) && question.correctAnswer.length > 0;
                    // Fallback to the old method if correctAnswer doesn't exist
                    const hasCorrectOptionOldMethod = question.mcqOptions && question.mcqOptions.some(opt => opt.isCorrect);

                    if (!hasCorrectAnswer && !hasCorrectOptionOldMethod) {
                        missing.push({id, subIndex: null, type: 'mcq', question});
                    }
                } else if (question.subquestions) {
                    // Check each subquestion
                    question.subquestions.forEach((sub, index) => {
                        if (sub.type === 'mcq' || sub.questionType === 'mcq') {
                            // Check if correctAnswer array exists and has at least one element
                            const hasCorrectAnswer = Array.isArray(sub.correctAnswer) && sub.correctAnswer.length > 0;
                            // Fallback to the old method if correctAnswer doesn't exist
                            const hasCorrectOptionOldMethod = sub.mcqOptions && sub.mcqOptions.some(opt => opt.isCorrect);

                            if (!hasCorrectAnswer && !hasCorrectOptionOldMethod) {
                                missing.push({id, subIndex: index, type: 'mcq', question: sub});
                            }
                        } else if (!sub.solution || !sub.solution.trim()) {
                            // For freeform questions, still require a text solution
                            missing.push({id, subIndex: index, type: 'freeform', question: sub});
                        }
                    });
                }
            });
            return missing;
        };

        useEffect(() => {
            const missingSolutions = getMissingSolutions(extractedQuestions);
            if (missingSolutions.length > 0) {

                if (!currentMissingSolution ||
                    !missingSolutions.some(sol =>
                        sol.id === currentMissingSolution.id &&
                        sol.subIndex === currentMissingSolution.subIndex
                    )) {
                    setCurrentMissingSolution(missingSolutions[0]);
                }
            } else {
                setCurrentMissingSolution(null);
                setIsEditingMCQ(false);
            }
        }, [extractedQuestions]);

        // Highlight all missing solutions permanently
        useEffect(() => {
            // First, remove highlights from all solution elements
            document.querySelectorAll('.highlight-missing-solution').forEach(el => {
                el.classList.remove('highlight-missing-solution');
            });

            // Then highlight all missing solutions
            const missingSolutions = getMissingSolutions(extractedQuestions);
            missingSolutions.forEach(solution => {
                const elementId = `solution-${solution.id}${solution.subIndex !== null ? `-${solution.subIndex}` : ''}`;
                const element = document.getElementById(elementId);
                if (element) {
                    element.classList.add('highlight-missing-solution');
                }
            });

            // Scroll to current missing solution if it exists
            if (currentMissingSolution && !isEditingMCQ) {
                const elementId = `solution-${currentMissingSolution.id}${currentMissingSolution.subIndex !== null ? `-${currentMissingSolution.subIndex}` : ''}`;
                const element = document.getElementById(elementId);
                if (element) {
                    element.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
            }
        }, [extractedQuestions, currentMissingSolution, isEditingMCQ]);

        const getMissingSolutionsMessage = (questions) => {
            if (!questions) {
                return "No questions have been extracted yet";
            }

            const missingSolutions = getMissingSolutions(questions);
            console.log('missingSolutions:', missingSolutions);

            console.log(missingSolutions);
            if (missingSolutions.length === 0) {
                return "You cannot proceed at this time. This is likely a bug - please report to support@gradewiz.ai";
            }

            const formatSolution = (sol) => {
                return sol.subIndex !== null
                    ? `Question ${sol.id}-${String.fromCharCode(97 + sol.subIndex)}`
                    : `Question ${sol.id}`;
            };

            return `Please enter solutions for: ${missingSolutions.map(formatSolution).join(', ')}`;
        };

        const allQuestionsHaveSolutions = useMemo(() => {

            if (!extractedQuestions) {
                return false;
            }

            return Object.entries(extractedQuestions).every(([id, question]) => {
                if (question.type === 'mcq' || question.questionType === 'mcq') {
                    // Check using correctAnswer array first
                    const hasCorrectAnswer = Array.isArray(question.correctAnswer) && question.correctAnswer.length > 0;
                    // Fallback to old method
                    const hasCorrectOptionOldMethod = question.mcqOptions && question.mcqOptions.some(opt => opt.isCorrect);
                    return hasCorrectAnswer || hasCorrectOptionOldMethod;
                }

                if (!question.subquestions || question.subquestions.length === 0) {
                    return false;
                }

                return question.subquestions.every(sub => {
                    if (sub.type === 'mcq' || sub.questionType === 'mcq') {
                        // Check using correctAnswer array first
                        const hasCorrectAnswer = Array.isArray(sub.correctAnswer) && sub.correctAnswer.length > 0;
                        // Fallback to old method
                        const hasCorrectOptionOldMethod = sub.mcqOptions && sub.mcqOptions.some(opt => opt.isCorrect);
                        return hasCorrectAnswer || hasCorrectOptionOldMethod;
                    }
                    return typeof sub.solution === 'string' && sub.solution.trim().length > 0;
                });
            });
        }, [extractedQuestions]);

        console.log('allQuestionsHaveSolutions:', allQuestionsHaveSolutions);

        useEffect(() => {
            if (extractedQuestions && !hasShownInitialNotification && !allQuestionsHaveSolutions) {
                const message = getMissingSolutionsMessage(extractedQuestions);
                toast(message, {
                    duration: 5000,
                    style: {
                        background: '#F3F4F6',
                        color: '#374151',
                        border: '1px solid #E5E7EB',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                    },
                    icon: '⚠️',
                });
                setHasShownInitialNotification(true);
            }
        }, [extractedQuestions, hasShownInitialNotification, allQuestionsHaveSolutions]);

        const handleProceedClick = async () => {
            if (!allQuestionsHaveSolutions) {
                const message = getMissingSolutionsMessage(extractedQuestions);
                toast(message, {
                    duration: 4000,
                    style: {
                        background: '#F3F4F6',
                        color: '#374151',
                        border: '1px solid #E5E7EB',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                    },
                    icon: '⚠️',
                });
                return;
            }

            console.log('Uploading questions:', extractedQuestions);

            try {
                // Wait for the upload to complete
                await uploadQuestions(extractedQuestions);
                // Only navigate once upload is successful
                window.location.href = `/tclasses/${class_id}/tassignments/${assignment_id}/create/template`;
            } catch (error) {
                console.error('Error uploading questions:', error);
                toast.error('Failed to upload questions. Please try again.');
            }
        };

        return (
            <div className="rubric-viewer flex flex-col h-screen">

                {/* Header with proceed button */}
                <div className="proceed-section flex justify-between p-4 border-b border-gray-200">
                    <button
                        className="back-btn bg-white text-gray-700 border border-gray-300 rounded-md px-4 py-2 flex items-center gap-2 hover:bg-gray-50 transition-colors"
                        onClick={handleBackClick}
                    >
                        <ArrowLeft className="w-5 h-5"/>
                        <span>Back</span>
                    </button>
                    <div className="relative">
                        <button
                            className={`proceed-btn ${!allQuestionsHaveSolutions ? 'disabled' : ''}`}
                            data-tooltip={!allQuestionsHaveSolutions ? getMissingSolutionsMessage(extractedQuestions) : ''}
                            onClick={handleProceedClick}
                        >
                            <CheckCircle className="w-5 h-5"/>
                            <span>Proceed to Template Editor</span>
                            <ArrowRight className="w-5 h-5"/>
                        </button>
                        {!allQuestionsHaveSolutions && (
                            <div className="tooltip absolute right-0 mt-2">
                                {getMissingSolutionsMessage(extractedQuestions)}
                            </div>
                        )}
                    </div>
                </div>

                {/* Main content area */}
                <div className="viewer-content flex flex-1">
                    {isProcessing ? (
                        <ProcessingOverlay
                            loadingSteps={loadingSteps}
                            currentStep={currentLoadingStep}
                        />
                    ) : (
                        <>
                            {/* PDF Preview on the left - now scrollable */}
                            <div ref={pdfContainerRef} className="w-1/2 h-full border-r border-gray-200 relative">
                                {loadingPdf && (
                                    <div
                                        className="absolute inset-0 flex flex-col items-center justify-center bg-white/80 z-10">
                                        <div className="loading-spinner mb-4"></div>
                                        <p className="text-gray-700">Loading PDF: {pdfLoadingProgress}%</p>
                                    </div>
                                )}
                            </div>

                            {/* Extracted Questions on the right */}
                            <div className="w-1/2 h-full overflow-y-auto">
                                <div className="questions-view">
                                    {isEditingMCQ && currentMissingSolution && currentMissingSolution.type === 'mcq' ? (
                                        <MCQSolutionEditor
                                            currentMissingSolution={currentMissingSolution}
                                            extractedQuestions={extractedQuestions}
                                            setExtractedQuestions={setExtractedQuestions}
                                            onClose={() => setIsEditingMCQ(false)}
                                        />
                                    ) : (
                                        <QuestionsView
                                            extractedQuestions={extractedQuestions}
                                            setExtractedQuestions={setExtractedQuestions}
                                            uploadQuestions={uploadQuestions}
                                            currentMissingSolution={currentMissingSolution}
                                            onEditMCQ={() => {
                                                if (currentMissingSolution && currentMissingSolution.type === 'mcq') {
                                                    setIsEditingMCQ(true);
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

// MCQ Solution Editor Component
const MCQSolutionEditor = ({currentMissingSolution, extractedQuestions, setExtractedQuestions, onClose}) => {
    const {id, subIndex, question} = currentMissingSolution;

    // Get the actual question from extractedQuestions
    const questionData = subIndex === null
        ? extractedQuestions[id]
        : extractedQuestions[id].subquestions[subIndex];

    // Initialize options if they don't exist or convert them to the right format
    const [options, setOptions] = useState(() => {
        if (questionData.mcqOptions && Array.isArray(questionData.mcqOptions)) {
            return questionData.mcqOptions;
        } else if (questionData.options && Array.isArray(questionData.options)) {
            // Convert from old format to new format
            return questionData.options.map((option, idx) => ({
                option: String.fromCharCode(65 + idx),
                text: option,
                isCorrect: questionData.correctAnswer?.includes(String.fromCharCode(65 + idx)) || false
            }));
        } else {
            // Create default options
            return [
                {option: 'A', text: '', isCorrect: false},
                {option: 'B', text: '', isCorrect: false},
                {option: 'C', text: '', isCorrect: false},
                {option: 'D', text: '', isCorrect: false}
            ];
        }
    });

    const updateOption = (index, field, value) => {
        const newOptions = [...options];
        newOptions[index] = {...newOptions[index], [field]: value};
        setOptions(newOptions);
    };

    const addOption = () => {
        if (options.length >= 26) {
            return; // Limit to alphabet letters
            const newOption = {
                option: String.fromCharCode(65 + options.length),
                text: '',
                isCorrect: false
            };
            setOptions([...options, newOption]);
        }
        ;

        const removeOption = (index) => {
            if (options.length <= 2) {
                return;
            } // Maintain at least 2 options
            const newOptions = options.filter((_, i) => i !== index);
            // Re-assign letters
            const updatedOptions = newOptions.map((opt, i) => ({
                ...opt,
                option: String.fromCharCode(65 + i)
            }));
            setOptions(updatedOptions);
        };

        const saveMCQSolution = () => {
            // Make sure at least one option is marked correct
            if (!options.some(opt => opt.isCorrect)) {
                toast.error('Please mark at least one option as correct');
                return;
            }

            const updatedQuestions = {...extractedQuestions};

            if (subIndex === null) {
                // Update main question
                updatedQuestions[id] = {
                    ...updatedQuestions[id],
                    mcqOptions: options
                };
            } else {
                // Update subquestion
                const subquestions = [...updatedQuestions[id].subquestions];
                subquestions[subIndex] = {
                    ...subquestions[subIndex],
                    mcqOptions: options
                };
                updatedQuestions[id] = {
                    ...updatedQuestions[id],
                    subquestions
                };
            }

            setExtractedQuestions(updatedQuestions);
            onClose();
        };

        return (
            <div className="mcq-solution-editor">
                <div className="editor-header">
                    <h2>Edit MCQ Solution</h2>
                    <div className="question-info">
                        <p className="question-id">
                            Question {id}{subIndex !== null ? String.fromCharCode(97 + subIndex) : ''}
                        </p>
                        <p className="question-text">{questionData.questionText || questionData.text}</p>
                    </div>
                    <button className="close-btn" onClick={onClose}>
                        <X size={18}/>
                    </button>
                </div>

                <div className="options-container">
                    <h3>Mark the correct answer(s):</h3>
                    {options.map((option, index) => (
                        <div key={index} className="mcq-option">
                            <div className="option-header">
                                <div className="option-letter">{option.option}</div>
                                <label className="correct-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={option.isCorrect}
                                        onChange={() => updateOption(index, 'isCorrect', !option.isCorrect)}
                                    />
                                    <span>Correct Answer</span>
                                </label>
                                {options.length > 2 && (
                                    <button className="delete-option" onClick={() => removeOption(index)}>
                                        <Trash2 size={16}/>
                                    </button>
                                )}
                            </div>
                            <input
                                type="text"
                                value={option.text}
                                placeholder="Enter option text..."
                                className="option-text-input"
                                onChange={(e) => updateOption(index, 'text', e.target.value)}
                            />
                        </div>
                    ))}

                    <button className="add-option-btn" onClick={addOption}>
                        <Plus size={16}/> Add Option
                    </button>
                </div>

                <div className="controls">
                    <button className="cancel-btn" onClick={onClose}>Cancel</button>
                    <button className="save-btn" onClick={saveMCQSolution}>Save Solution</button>
                </div>
            </div>
        );
    }
};

export default RubricViewer;
