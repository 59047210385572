import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {gradeApi} from '../../../../api.js';
import {useAuth} from '../../../../auth/AuthContext';
import {useQueryParams} from '../../../../shared/hooks/use-query-params.tsx';
import {addStudents} from '../../../../store/studentSlice.js';
import {getStudents, removeStudent} from '../../../../store/teacherSlice.js';
import {DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE} from '../../../../utils/pagination.ts';
import ConfirmationModal from '../../../ConfirmationModal';
import DragAndDropUpload from '../../../DragDropCSV';
import Table from '../../../Table.js';
import ColumnMappingModal from '../../ColumnMappingModal.js';
import EditableMatchKeyCell from '../../EditableMatchKeyCell.js';
import {Plus, X, Upload, Info, User, ChevronDown, ChevronUp, Loader} from 'lucide-react';

const AddDropBig = ({course, class_id}) => {
    const dispatch = useDispatch();
    const {user} = useAuth();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [csv, setCsv] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const {data, isLoading, errorMessage} = useSelector(state => state?.teacherReducer?.students);
    const [isMatchKeyModalOpen, setIsMatchKeyModalOpen] = useState(false);
    const [isAddStudentSectionOpen, setIsAddStudentSectionOpen] = useState(false);
    const [studentErrors, setStudentErrors] = useState(null);
    const [isBulkImportSectionOpen, setIsBulkImportSectionOpen] = useState(false);
    const [studentEmail, setStudentEmail] = useState('');
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const isOpenToManage = true;

    const {
        params: {search, pageNumber, itemsPerPage, sortOrder},
        updateSearchParams
    } = useQueryParams({
        search: '',
        pageNumber: DEFAULT_PAGE_NUMBER,
        itemsPerPage: ITEMS_PER_PAGE.default,
        sortOrder: 'asc'
    });

    useEffect(() => {
        getAllStudents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, pageNumber, itemsPerPage, sortOrder]);

    useEffect(() => {
        if (errorMessage) {
            setError(errorMessage);
        }
    }, [errorMessage]);

    const getAllStudents = () =>
        dispatch(
            getStudents({
                classId: class_id,
                token: user.session_token,
                search,
                pageNumber: Number(pageNumber),
                itemsPerPage: Number(itemsPerPage),
                sortOrder
            })
        );

    const handleFileChange = file => {
        if (file) {
            setCsv(file);
            setError(''); // Clear any previous errors
        } else {
            setError('No file selected or invalid file type.');
        }
    };

    const handleUpload = async () => {
        if (!csv) {
            setError('Please select a CSV file first.');
            return;
        }

        // Parse CSV headers before opening modal
        try {
            const reader = new FileReader();
            reader.onload = async e => {
                const text = e.target.result;
                const lines = text.split('\n');
                if (lines.length > 0) {
                    // Get headers from first line and clean them
                    const headers = lines[0].split(',');
                    setIsMatchKeyModalOpen(true);
                    // Store headers in state for the modal
                    setCsvHeaders(headers);
                } else {
                    setError('CSV file appears to be empty');
                }
            };
            reader.readAsText(csv);
        } catch (error) {
            console.error('Error reading CSV:', error);
            setError('Failed to read CSV file. Please try again.');
        }
    };

    const handleMatchKeyConfirm = async mappings => {
        setIsMatchKeyModalOpen(false);
        setIsUploading(true);
        setError('');

        const formData = new FormData();
        formData.append('file', csv);
        formData.append('classId', class_id);
        formData.append('emailColumn', mappings.emailColumn);
        formData.append('hasNameColumns', String(mappings.hasNameColumns));
        if (mappings.hasNameColumns) {
            formData.append('firstNameColumn', mappings.firstNameColumn);
            formData.append('lastNameColumn', mappings.lastNameColumn);
        }
        formData.append('hasStudentId', String(mappings.hasStudentId));
        if (mappings.hasStudentId) {
            formData.append('studentIdColumn', mappings.studentIdColumn);
        }

        try {
            const response = await gradeApi.post({
                path: '/api/upload-csv/',
                body: formData,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            const {success_count, failed_count, failed_emails} = response.data;

            // Always call getAllStudents() after successful upload
            await getAllStudents(); // Use await to ensure it completes before continuing

            // Clear previous messages to avoid showing both
            setError('');
            setSuccess('');

            // Show a single combined message that represents the actual result
            if (success_count > 0 && failed_count > 0) {
                // If we have both success and failures, show a combined message
                setSuccess(`Added ${success_count} student(s). Failed to add ${failed_count} student(s).`);

                // If there are specific emails that failed, show them in a more subtle way
                if (failed_emails && failed_emails.length > 0) {
                    console.log('Failed emails:', failed_emails.join(', '));
                }
            } else if (failed_count > 0) {
                // If only failures, show error
                setError(`Failed to add ${failed_count} student(s): ${failed_emails.join(', ')}`);
            } else {
                // If only successes, show success
                setSuccess(`Successfully added ${success_count} student(s)`);
            }
            setIsAddModalOpen(false);
        } catch (error) {
            console.error('Error uploading file:', error);
            setSuccess(''); // Clear any success message first
            setError('Failed to process the CSV file. Please try again.');
        } finally {
            setIsUploading(false);
            setCsv(null); // Clear the uploaded file
            setIsBulkImportSectionOpen(false); // Close the import section after completion
        }
    };

    const handleMatchKeyDecline = async () => {
        setIsMatchKeyModalOpen(false);
        setError('');
    };

    const handleRemove = async id => {
        setSelectedStudentId(id);
        setIsConfirmationModalOpen(true);
    };

    const handleMatchKeyUpdate = async (studentId, newMatchKey) => {
        try {
            await gradeApi.patch({
                path: `/api/courses/${class_id}/students/${studentId}/match-key`,
                body: {match_key: newMatchKey},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            getAllStudents(); // Refresh the table data
            setSuccess('Student ID updated successfully');
        } catch (error) {
            console.error('Error updating match key:', error);
            setError('Failed to update student ID');
            throw error;
        }
    };

    const addStudentByEmail = async () => {
        if (!studentEmail) {
            setError('Please enter a student email');
            return;
        }

        try {
            // Clear previous messages
            setSuccess('');
            setError('');
            setStudentErrors(null);

            const response = await dispatch(
                addStudents({
                    classId: class_id,
                    token: user.session_token,
                    emails: [studentEmail]
                })
            );

            // Check if we got a response with payload
            if (response.payload) {
                // Check if there were any issues
                const hasIssues =
                    (response.payload?.already_added_emails?.length > 0) ||
                    (response.payload?.not_a_student_emails?.length > 0) ||
                    (response.payload?.invalid_emails?.length > 0) ||
                    (response.payload?.teacher_not_found_emails?.length > 0);

                if (hasIssues) {
                    // Store the specific errors
                    setStudentErrors(response.payload);
                } else {
                    // Everything went well
                    setSuccess('Student added successfully');
                    setIsAddStudentSectionOpen(false);
                    setStudentEmail('');
                }
            } else if (response.error) {
                // There was an error in the response
                setError('Failed to add student. Please check the email and try again.');
            } else {
                // Success case
                setSuccess('Student added successfully');
                setIsAddStudentSectionOpen(false);
                setStudentEmail('');
            }

            // Always refresh the student list
            await getAllStudents();
        } catch (error) {
            console.error('Error adding student:', error);
            setError('Failed to add student. Please check the email and try again.');
            setSuccess('');
        }
    };

    const confirmRemoveStudent = async () => {
        const deleteUser = await dispatch(
            removeStudent({
                classId: class_id,
                token: user.session_token,
                studentId: selectedStudentId
            })
        );

        if (deleteUser.error) {
            setError(deleteUser.payload);
        } else {
            setSuccess('Student removed successfully');
            getAllStudents();
        }

        setIsConfirmationModalOpen(false);
    };

    const columnNames = ['Name', 'Email', 'Student ID', 'Status', 'Actions'];

    return (
        <div className="students-management-container">


            {/* Feedback messages */}
            {success && (
                <div
                    className="mb-4 p-3 bg-green-50 text-green-700 rounded-md border border-green-200 flex justify-between items-center">
                    <span>{success}</span>
                    <button className="text-green-700" onClick={() => setSuccess('')}>
                        <X className="w-4 h-4"/>
                    </button>
                </div>
            )}

            {error && (
                <div
                    className="mb-4 p-3 bg-red-50 text-red-700 rounded-md border border-red-200 flex justify-between items-center">
                    <span>{error}</span>
                    <button className="text-red-700" onClick={() => setError('')}>
                        <X className="w-4 h-4"/>
                    </button>
                </div>
            )}

            <div className="mb-6 bg-white rounded-lg border border-gray-200 overflow-hidden">
                <div className="p-6">
                    <div className="space-y-4">
                        <span>Import Student Roster CSV</span>
                        <div className="flex items-center justify-between text-xs text-gray-600 mb-2">
                            <div className="flex items-center">
                                <span>Canvas Instructions</span>
                                <a
                                    href="https://athelp.sfsu.edu/hc/en-us/articles/14014800586387-Download-a-list-of-student-email-addresses-in-Canvas"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="ml-1"
                                >
                                    <Info size={14}/>
                                </a>
                            </div>
                        </div>

                        <DragAndDropUpload onFileChange={handleFileChange}/>

                        <div className="flex justify-end">
                            <button
                                disabled={isUploading || !csv}
                                className={`px-5 py-2 rounded-md flex items-center gap-2 transition-colors ${
                                    !csv
                                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                                        : 'border border-[#e4b17a] bg-[#e4b17a] text-white hover:bg-[#d9a064]'
                                }`}
                                onClick={handleUpload}
                            >
                                {isUploading ? (
                                    <>
                                        <span className="animate-pulse">Processing</span>
                                        <Loader className="w-4 h-4 ml-2 animate-spin"/>
                                    </>
                                ) : (
                                    <>
                                        <Upload className="w-4 h-4"/>
                                        Upload CSV
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-6 bg-white rounded-lg border border-gray-200 overflow-hidden">
                <div className="p-6">
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="studentEmail" className="block text-sm font-medium text-gray-700 mb-2">
                                Add Student by Email
                            </label>
                            <form className="relative" onSubmit={(e) => {
                                e.preventDefault();
                                addStudentByEmail();
                            }}>
                                <input
                                    type="email"
                                    id="studentEmail"
                                    value={studentEmail}
                                    placeholder="student@example.com"
                                    className="w-full px-4 py-2 pr-[120px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#e4b17a] focus:border-[#e4b17a]"
                                    onChange={(e) => setStudentEmail(e.target.value)}
                                />
                                <button
                                    type="submit"
                                    className="absolute right-1 top-1 px-3 py-1 border border-[#e4b17a] bg-[#e4b17a] text-white rounded-md hover:bg-[#d9a064] transition-colors flex items-center gap-1 text-sm"
                                >
                                    {isLoading ? (
                                        <>
                                            <span>Processing</span>
                                            <Loader className="w-3 h-3 animate-spin"/>
                                        </>
                                    ) : (
                                        <>
                                            <Plus className="w-4 h-4"/>
                                            Add Student
                                        </>
                                    )}
                                </button>
                            </form>
                        </div>

                        {studentErrors?.already_added_emails?.length > 0 && (
                            <div className="text-red-600 text-sm">
                                <p className="font-medium">Email already added:</p>
                                <ul className="list-disc list-inside">
                                    {studentErrors.already_added_emails.map(email => (
                                        <li key={email}>{email}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {studentErrors?.not_a_student_emails?.length > 0 && (
                            <div className="text-red-600 text-sm">
                                <p className="font-medium">Cannot add teacher as student:</p>
                                <ul className="list-disc list-inside">
                                    {studentErrors.not_a_student_emails.map(email => (
                                        <li key={email}>{email}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {studentErrors?.invalid_emails?.length > 0 && (
                            <div className="text-red-600 text-sm">
                                <p className="font-medium">Email not found:</p>
                                <ul className="list-disc list-inside">
                                    {studentErrors.invalid_emails.map(email => (
                                        <li key={email}>{email}</li>
                                    ))}
                                </ul>
                            </div>
                        )}


                    </div>
                </div>
            </div>

            {/* Students Table */}
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden mt-6">
                <Table
                    search={search}
                    sortOrder={sortOrder}
                    isLoading={isLoading}
                    totalCount={data?.totalCount}
                    offset={data?.offset}
                    nextOffset={data?.nextOffset}
                    pageNumber={Number(pageNumber)}
                    itemsPerPage={Number(itemsPerPage)}
                    columnNames={columnNames}
                    noDataTitle="No students found"
                    columnBodies={(data?.entities ?? []).map(student => (
                        <tr key={`${student.first_name + ' ' + student.id}`} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <div className="truncate max-w-[30ch]"
                                     title={`${student.first_name} ${student.last_name}`}>
                                    {`${student.first_name} ${student.last_name}`}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <div className="truncate max-w-[30ch]" title={student.email}>
                                    {student.email}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm group">
                                <div className="truncate max-w-[30ch]">
                                    <EditableMatchKeyCell
                                        isOpenToManage={isOpenToManage}
                                        initialValue={student.match_key}
                                        studentId={student.id}
                                        onSave={handleMatchKeyUpdate}
                                    />
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {!student.is_verified ? (
                                    <span
                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    No account
                  </span>
                                ) : (
                                    <span
                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Account created
                  </span>
                                )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                {isOpenToManage && (
                                    <button
                                        className="text-red-600 hover:text-red-900 focus:outline-none"
                                        onClick={() => handleRemove(student.id)}
                                    >
                                        <img
                                            src="/assets/trash.png"
                                            alt="Remove"
                                            className="w-5 h-5 z-5 opacity-70 hover:opacity-100 inline"
                                        />
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                    updateSearchParams={updateSearchParams}
                />
            </div>

            {/* Confirmation Modal */}
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                message="Are you sure you want to remove this student? This action cannot be undone."
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmRemoveStudent}
            />

            {/* Column Mapping Modal */}
            <ColumnMappingModal
                isOpen={isMatchKeyModalOpen}
                csvColumns={csvHeaders}
                onClose={handleMatchKeyDecline}
                onConfirm={mappings => {
                    handleMatchKeyConfirm(mappings);
                }}
            />
        </div>
    );
};

export default AddDropBig;
