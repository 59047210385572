import React, {useEffect, useState} from 'react';
import {X, ArrowLeft, UserCircle, Users, Upload} from 'lucide-react';
import AssignmentTypeSelector from './AssignmentTypeSelector';
import ImportAssignmentModal from "./ImportAssignmentModal";
import DateTimeSelector from './DueTimeSelector';

// TODO: Polish the modal
const Modal = ({
                   isModalOpen,
                   setIsModalOpen,
                   handleSubmit,
                   assignmentName,
                   setAssignmentName,
                   format,
                   setFormat,
                   dueDate,
                   setDueDate,
                   dueTime,
                   timezone,
                   setDueTime,
                   studentSubmitted,
                   setStudentSubmitted,
                   errorMessage,
                   setErrorMessage,
                   modalRef,
                   handleCloneAssignment
               }) => {
    const [mode, setMode] = useState('new');
    const [step, setStep] = useState('type');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            const isSubmitDisabled = !assignmentName ||
                (studentSubmitted === 'student' && (!dueDate || !dueTime));

            if (!isSubmitDisabled && step === 'details') {
                e.preventDefault();
                handleSubmit(e);
            }
        }
    };
    useEffect(() => {
        if (isModalOpen) {
            window.addEventListener('keypress', handleKeyPress);
        }

        return () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, [isModalOpen, assignmentName, dueDate, dueTime, studentSubmitted, step]);

    const ModeSelector = () => (
        <div className="flex space-x-4 mb-6">
            {['new', 'import'].map((optionMode) => (
                <button
                    key={optionMode}
                    className={`
            flex items-center space-x-2 px-4 py-2 rounded-full transition-all duration-300
            ${mode === optionMode ?
                        'bg-orange-100 text-orange-800 shadow-sm' :
                        'bg-gray-50 text-gray-600 hover:bg-gray-100'
                    }
          `}
                    onClick={() => setMode(optionMode)}
                >
                    <div className={`
            w-4 h-4 rounded-full border-2 flex items-center justify-center
            ${mode === optionMode ? 'border-orange-400' : 'border-gray-400'}
          `}>
                        {mode === optionMode && (
                            <div className="w-2 h-2 rounded-full bg-orange-400"/>
                        )}
                    </div>
                    <span className="font-medium capitalize">{optionMode}</span>
                </button>
            ))}
        </div>
    );

    const SubmissionTypeSelector = () => {
        const [hoveredOption, setHoveredOption] = useState(null);

        const OptionCard = ({icon: Icon, title, description, onClick}) => (
            <button
                className={`
          w-full p-6 text-left rounded-xl transition-all duration-300 transform
          ${hoveredOption === title ?
                    'border-2 border-orange-200 shadow-orange-100 hover:scale-[1.02]' :
                    'border border-gray-200 hover:border-orange-200'
                }
          bg-white hover:shadow-lg
        `}
                onClick={onClick}
                onMouseEnter={() => setHoveredOption(title)}
                onMouseLeave={() => setHoveredOption(null)}
            >
                <div className="flex items-start space-x-4">
                    <div className={`
            p-3 rounded-lg 
            ${hoveredOption === title ? 'bg-orange-100' : 'bg-orange-50'}
          `}>
                        <Icon className="w-6 h-6 text-orange-500"/>
                    </div>
                    <div className="flex-1">
                        <div className="font-medium text-lg text-gray-900">{title}</div>
                        <div className="mt-1 text-sm text-gray-500">{description}</div>
                    </div>
                </div>
            </button>
        );

        return (
            <div className="space-y-4">
                <button
                    className="group flex items-center space-x-2 text-gray-500 hover:text-gray-900 transition-colors duration-200 mb-6"
                    onClick={() => setStep('type')}
                >
                    <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform duration-200"/>
                    <span>Back</span>
                </button>

                <h3 className="text-xl font-semibold text-gray-900 mb-6">
                    Who will be submitting this assignment?
                </h3>

                <div className="space-y-4">
                    <OptionCard
                        icon={UserCircle}
                        title="Student Submission"
                        description="Students will log into their GradeWiz accounts and submit work directly to the platform"
                        onClick={() => {
                            setStudentSubmitted('student');
                            setStep('details');
                        }}
                    />

                    <OptionCard
                        icon={Upload}
                        title="Teacher Upload"
                        description="You'll upload student work to the platform (useful for exams and in-class assignments)"
                        onClick={() => {
                            setStudentSubmitted('teacher');
                            setStep('details');
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderDetailsForm = () => (
        <div className="space-y-4">
            <button
                className="group flex items-center space-x-2 text-gray-500 hover:text-gray-900 transition-colors duration-200 mb-6"
                onClick={() => setStep('type')}
            >
                <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform duration-200"/>
                <span>Back</span>
            </button>

            <div className="flex flex-col gap-4">
                <div className="relative">
                    <input
                        type="text"
                        value={assignmentName}
                        className="w-full p-3 rounded-lg border border-gray-200 focus:border-orange-300 focus:ring-2 focus:ring-orange-100 outline-none transition-all"
                        placeholder="Assignment Name"
                        onChange={e => {
                            setAssignmentName(e.target.value);
                            setErrorMessage(e.target.value.length > 70 ?
                                'Assignment name must not exceed 70 characters.' : '');
                        }}
                    />
                </div>

                {studentSubmitted === 'student' && (
                    <DateTimeSelector
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        dueTime={dueTime}
                        setDueTime={setDueTime}
                        timezone={timezone}
                        errorMessage={errorMessage}
                        onFocus={() => setErrorMessage('')}
                    />
                )}
            </div>

            {errorMessage && (
                <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
            )}

            <div className="flex justify-end gap-3 mt-6">
                <button
                    className={`
                        w-full px-5 py-2.5 rounded-xl text-[0.875rem] font-medium transition-all duration-200 hover:transform hover:-translate-y-0.5 shadow-sm hover:shadow
                        ${!assignmentName || (studentSubmitted === 'student' && (!dueDate || !dueTime)) ?
                            'bg-gray-100 text-gray-400 cursor-not-allowed' :
                            'bg-gradient-to-br from-[#e4b17a] to-[#c99b69] text-white hover:from-[#c99b69] hover:to-[#b38a5e]'
                        }
                    `}
                    disabled={!assignmentName || (studentSubmitted === 'student' && (!dueDate || !dueTime))}
                    onClick={handleSubmit}
                >
                    Create Assignment
                </button>
            </div>
        </div>
    );

    return (
        isModalOpen && (
            <div
                className="fixed inset-0 bg-black/50 backdrop-blur-sm overflow-y-auto h-full w-full z-50 flex justify-center items-center">
                <div
                    ref={modalRef}
                    className="relative mx-auto p-6 bg-white w-full max-w-[500px] rounded-xl shadow-xl"
                    style={{
                        boxShadow: '0 0 0 1px rgba(0,0,0,0.05), 0 20px 25px -5px rgba(0,0,0,0.1), 0 10px 10px -5px rgba(0,0,0,0.04)'
                    }}
                >
                    <button
                        className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
                        onClick={() => setIsModalOpen(false)}
                    >
                        <X className="w-5 h-5 text-gray-400"/>
                    </button>

                    {/* <ModeSelector/> */}

                    {mode === 'new' ? (
                        <div className="space-y-6">
                            {step === 'type' ? (
                                <AssignmentTypeSelector
                                    onSelectType={(type) => {
                                        setFormat(type);
                                        // If it's a flexible (online) assignment, automatically set student submission
                                        if (type === 'flexible_problem_set') {
                                            setStudentSubmitted('student');
                                            setStep('details');
                                        } else {
                                            // For PDF assignments, continue to submission type selection
                                            setStep('submitter');
                                        }
                                    }}
                                />
                            ) : step === 'submitter' ? (
                                <SubmissionTypeSelector/>
                            ) : (
                                renderDetailsForm()
                            )}
                        </div>
                    ) : (
                        <ImportAssignmentModal
                            setErrorMessage={setErrorMessage}
                            errorMessage={errorMessage}
                            onClose={() => setIsModalOpen(false)}
                            onImport={assignmentId => {
                                handleCloneAssignment(assignmentId);
                                setIsModalOpen(false);
                            }}
                        />
                    )}
                </div>
            </div>
        )
    );
};

export default Modal;