import React, {useState, useEffect} from 'react';

const MCQModify = ({subQuestion, onClose, onSubmit}) => {
    const hasMultipleOptions = subQuestion.mcqOptions.length >= 4;
    const [selectedAnswers, setSelectedAnswers] = useState([]);

    useEffect(() => {
        // Find all currently correct answers
        const currentCorrectOptions = subQuestion.mcqOptions
            .filter(option => option.isCorrect)
            .map(option => option.id);
        setSelectedAnswers(currentCorrectOptions);
    }, [subQuestion]);

    const handleToggleAnswer = (id) => {
        setSelectedAnswers(prev => {
            if (prev.includes(id)) {
                return prev.filter(answerId => answerId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const handleSubmit = () => {
        const updatedMcqOptions = subQuestion.mcqOptions.map(option => ({
            ...option,
            isCorrect: selectedAnswers.includes(option.id)
        }));
        onSubmit(updatedMcqOptions);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-xl shadow-lg max-w-xl w-full">
                <h2 className="text-2xl font-bold mb-4">Modify Correct Answers</h2>

                <div className="bg-yellow-100 border-l-4 border-yellow-400 p-4 mb-6">
                    <div className="flex items-start">
                        <div className="flex-shrink-0 mr-2">
                            <img
                                src="/assets/tclasses/warning.png"
                                alt="Warning"
                                className="h-4 w-4"
                            />
                        </div>
                        <div className="text-sm font-semibold">
                            <p>• Changing the correct answers will affect grading for all students.</p>
                            <p>• All submissions for this question will be regraded automatically.</p>
                        </div>
                    </div>
                </div>

                <div className="mb-6">
                    <div className="text-lg font-semibold mb-4">Select Correct Answers:</div>
                    <div className="relative border border-slate-200 rounded-lg p-2">
                        <div
                            className="max-h-64 overflow-y-auto pr-2 space-y-2 scrollbar-thin scrollbar-thumb-slate-300">
                            {hasMultipleOptions && (
                                <div className="text-xs text-slate-500 mb-2">Scroll to see more options</div>
                            )}
                            {subQuestion.mcqOptions.map((option, index) => {
                                const letter = String.fromCharCode(65 + index);
                                const isSelected = selectedAnswers.includes(option.id);
                                return (
                                    <div
                                        key={option.id}
                                        className={`flex items-center p-3 rounded-lg cursor-pointer transition-all duration-200
                                        ${isSelected
                                            ? 'bg-orange-200 border border-orange-300'
                                            : 'hover:bg-slate-50 border border-transparent'}`}
                                        onClick={() => handleToggleAnswer(option.id)}
                                    >
                                        <div className={`w-8 h-8 rounded-full border flex items-center justify-center mr-3
                                        ${isSelected
                                            ? 'bg-orange-200 border-orange-300'
                                            : 'border-slate-300'}`}
                                        >
                                            {letter}
                                        </div>
                                        <div className="flex-grow">
                                            {option.text}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {hasMultipleOptions && (
                            <div
                                className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
                        )}
                    </div>
                </div>

                <div className="flex justify-end space-x-3">
                    <button
                        className="px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 text-slate-700 border-slate-200 hover:border-slate-400"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 
        ${selectedAnswers.length === 0
                            ? 'bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed'
                            : 'bg-orange-200 hover:bg-orange-300 text-black border-black'
                        }`}
                        disabled={selectedAnswers.length === 0}
                        onClick={handleSubmit}
                    >
                        Confirm Changes
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MCQModify;