import {ErrorMessage, Field, useFormikContext} from 'formik';
import React from 'react';
import DragAndDropUpload from '../../DragDropQuestion.js';
import {getFieldName} from '../../Teacher/TPset/utils.js';
import {ImageWithPopup} from './ImageWithPopup';
import {LaTeXPreviewContent} from "../../LaTeXPreviewContent";
import LaTeXPreviewField from "../../LaTeXPreviewField";

export const SQuestionType = ({
                                  question,
                                  questionPoint,
                                  fieldName,
                                  index,
                                  disabledStyle,
                                  isDisabled,
                                  isSubQuestion
                              }) => {
    const {submitForm, setFieldValue} = useFormikContext();

    const getLabel = () => {
        if (isSubQuestion) {
            // For subquestions, use letters (a, b, c, etc.)
            // Use position if provided, otherwise fallback to index
            return String.fromCharCode(question.position ? 96 + question.position : 97 + index);
        }
        // For main questions, use numbers (1, 2, 3, etc.)
        return index + 1;
    };

    const handleImageUpload = async event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const imageUrl = reader.result;
                setFieldValue(getFieldName(index, 'answerImageUrl', fieldName), imageUrl);
                setFieldValue(getFieldName(index, 'answerImageS3Key', fieldName), null);
            };
            reader.readAsDataURL(file);

            setTimeout(async () => {
                await submitForm();
            }, 500);
        }
    };

    return (
        <div className="mb-8 bg-white p-6 rounded-xl border border-gray-300">
            <div className="flex items-start gap-3 mb-4">
                <div
                    className="flex-shrink-0 w-8 h-8 bg-custom-orange rounded-full flex items-center justify-center text-white font-medium">
                    {getLabel()}
                </div>
                <LaTeXPreviewContent content={questionPoint}/>
            </div>
            {question.imageS3Key && (
                <div className="mb-4">
                    <ImageWithPopup
                        image={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${question.imageS3Key}`}
                    />
                </div>
            )}

            {question.questionType === 'mcq' && (
                <div className="mt-4 bg-orange-50 p-4 rounded-lg border border-gray-200">
                    <div className="mb-2 text-sm font-medium text-gray-600">Select an Answer</div>
                    <Field name={getFieldName(index, 'answerMcqOptionId', fieldName)}>
                        {({field, form}) => (
                            <>
                                {question.mcqOptions.map(({id, text}, optionIndex) => (
                                    <React.Fragment key={`${question.id}-${optionIndex}`}>
                                        <div className="flex gap-[15px] items-center mb-[15px] bg-white p-3 rounded-lg">
                                            <input
                                                id={`radio-${question.id}-${optionIndex}`}
                                                type="radio"
                                                disabled={isDisabled}
                                                value={id}
                                                checked={field.value === id}
                                                className={`${
                                                    isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                                                } w-5 h-5 bg-white border border-gray-300 rounded-3xl checked:bg-orange-200 checked:border-orange-300 checked:hover:bg-orange-100 checked:hover:border-orange-200 flex items-center justify-center transition-colors duration-200 ease-in-out`}
                                                onChange={async (e) => {
                                                    const newValue = e.target.value;
                                                    await field.onChange({
                                                        target: {
                                                            name: field.name,
                                                            value: newValue
                                                        }
                                                    });
                                                    form.submitForm();
                                                }}
                                            />
                                            <label htmlFor={`radio-${question.id}-${optionIndex}`}
                                                   className="text-black-600">
                                                <LaTeXPreviewContent content={text}/>
                                            </label>
                                        </div>
                                        <span className="text-red-800 text-xs">
                                <ErrorMessage name={getFieldName(index, 'answerMcqOptionId', fieldName)}/>
                            </span>
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </Field>
                </div>
            )}

            {question.questionType === 'image' && (
                <>
                    <div className="mt-4 bg-orange-50 p-4 rounded-lg border border-gray-200">
                        <div className="mb-2 text-sm font-medium text-gray-600">Upload Your Answer (PNG, JPG or JPEG)
                        </div>
                        {!question.answerImageS3Key && (
                            <div className="w-full">
                                <DragAndDropUpload handleImageUpload={handleImageUpload} isPublished={isDisabled}/>
                            </div>
                        )}
                        {question.answerImageS3Key && (
                            <div className="relative mb-4 mt-2 flex items-center">
                                <ImageWithPopup
                                    image={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${question.answerImageS3Key}`}
                                />
                                <button
                                    type="button"
                                    disabled={isDisabled}
                                    onClick={() => {
                                        setFieldValue(getFieldName(index, 'answerImageUrl', fieldName), null);
                                        setFieldValue(getFieldName(index, 'answerImageS3Key', fieldName), null);

                                        setTimeout(async () => {
                                            submitForm();
                                        }, 500);
                                    }}
                                >
                                    <img src="/assets/trash.png"
                                         className="size-8 ml-2 hover:bg-gray-200 p-1 rounded-full"
                                         alt="Delete image"/>
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}

            {question.questionType === 'text' && (
                <div className="mt-4 bg-orange-50 p-4 rounded-lg border border-gray-200">
                    <div className="mb-2 text-sm font-medium text-gray-600">Type Your Answer</div>
                    <LaTeXPreviewField
                        name={getFieldName(index, 'answerText', fieldName)}
                        placeholder="Type your answer here..."
                        disabled={isDisabled}
                        className={`bg-white sexy-border rounded-lg mb-2 focus:ring-0 focus:sexy-border focus:rounded-lg w-full ${disabledStyle}`}
                        rows={6}
                        onBlur={submitForm}
                    />
                    <span className="text-red-800 text-xs">
            <ErrorMessage name={getFieldName(index, 'answerText', fieldName)}/>
        </span>
                </div>
            )}
        </div>
    );
};
