import React from 'react';
import SideBar from '../../components/SideBar';
import TRegrade from '../../components/Teacher/TRegrade';

const TProfilePage = () => {
    return (
        <div className="HomePageHeader">
            <div className="w-full h-screen flex">
                <div id="Left-Sidebar" className="h-screen flex-shrink-0 w-side-bar-size">
                    <SideBar activePage="Regrade"/>
                </div>
                <div id="Right-content" className="flex-grow overflow-y-auto h-screen">
                    <TRegrade/>
                </div>
            </div>
        </div>
    );
};

export default TProfilePage;
