// TeacherStudentUpload.js

import React, {useState, useRef, useEffect} from 'react';
import {gradeApi, apiUrl} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext';
import DragAndDropUpload from '../../DragDropAnswer';

function TeacherStudentUpload({assignmentId, studentId, setUpdateTrigger, onClose}) {
    const {user} = useAuth();
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [submissionData, setSubmissionData] = useState({});
    const [file, setFile] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const pollingIntervalRef = useRef(null);
    const [showHelp, setShowHelp] = useState(false);
    // This controls whether to use alignment markers or not
    // We want the UI to show "Do NOT use alignment markers" as toggled ON by default
    // But the API expects use_text_alignment=false for this case
    const [ditchAlignmentMarkers, setDitchAlignmentMarkers] = useState(false);

    // This is what gets sent to the API - always false when ditchAlignmentMarkers is true
    const useTextAlignment = !ditchAlignmentMarkers;
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);

    const startPolling = submissionId => {
        if (isPolling) {
            return;
        }
        setIsPolling(true);
        pollingIntervalRef.current = setInterval(() => {
            checkGradingStatus(submissionId);
        }, 5000);
    };

    const stopPolling = () => {

        setIsPolling(false);
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
        }
    };

    const checkGradingStatus = async submissionId => {
        try {
            const response = await gradeApi.get({
                path: `/api/submission/${submissionId}/grading-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            if (response.data.is_grading_done) {
                stopPolling();
                setMessage('Grading completed. Refreshing submission data...');
                setUpdateTrigger(prev => !prev);
                setShowHelp(false);
                getSubmissionData();
                onClose();
                window.location.reload();
            }
        } catch (error) {
            console.error('Error checking grading status:', error);
            stopPolling();
            setMessage('Error checking grading status. Please refresh the page.');
            setShowHelp(false);
        }
    };

    const getSubmissionData = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignmentId}/submission/${studentId}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const data = response.data;
            if (data.is_grade_in_progres) {
                setMessage('Assignment is being graded... Please wait.');
                setShowHelp(false);
                setIsButtonDisabled(true);
                if (data.id) {
                    startPolling(data.id);
                }
            } else {
                setUpdateTrigger(prev => !prev);
                setIsButtonDisabled(false);
                setMessage('');
                setShowHelp(false);
            }
            setSubmitted(data.submitted);
            setSubmissionData(data.submitted ? data : {});
        } catch (error) {
            setIsButtonDisabled(false);
            // setMessage(error.response?.data?.error || 'An error occurred while fetching submission data.');
            setShowHelp(false);
        }
    };
    const submitOrResubmit = async isResubmission => {
        // Use the current value of useTextAlignment which is derived from ditchAlignmentMarkers
        // When ditchAlignmentMarkers is true, useTextAlignment is false

        const formData = new FormData();
        formData.append('file_data', file);
        formData.append('user_id', studentId);
        formData.append('teacher_submission', 'true');
        formData.append('submitter_id', user.user.id);
        formData.append('use_text_alignment', useTextAlignment);

        try {
            setMessage(
                isResubmission
                    ? 'Resubmission in progress... please wait'
                    : 'Submission in progress... please wait'
            );
            setIsButtonDisabled(true);
            setShowHelp(false);

            const response = await gradeApi.post({
                path: `/api/assignment/${assignmentId}/submit-or-resubmit/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            const submissionId = response.data.id;
            setMessage(
                isResubmission
                    ? 'Assignment resubmitted successfully! Please wait, grading...'
                    : 'Assignment submitted successfully! Please wait, grading...'
            );
            setShowHelp(false);

            const gradingResponse = await gradeApi.get({
                path: `/api/assignment/${assignmentId}/ai-grade/?user_id=${studentId}`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (gradingResponse.status === 200) {
                setMessage('Assignment is being graded... Please wait.');
                setShowHelp(false);
                startPolling(submissionId);
            } else {
                setUpdateTrigger(prev => !prev);
                throw new Error('Grading failed. Please try again later.');
            }
        } catch (error) {
            console.error(
                isResubmission ? 'Error resubmitting assignment:' : 'Error submitting assignment:',
                error
            );
            setIsButtonDisabled(false);
            setMessage(error.response?.data?.error || 'An unknown error occurred');
            setUpdateTrigger(prev => !prev);
            setShowHelp(true);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        await submitOrResubmit(false);
    };


    const handleCancel = async () => {
        try {
            await gradeApi.delete({
                path: `/api/assignment/${assignmentId}/delete-submissions/`,
                body: {
                    user_id: studentId
                },
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            setIsButtonDisabled(false);
            setMessage('Submission cancelled.');
            setShowHelp(false);

            stopPolling();
        } catch (error) {
            console.error('Error cancelling submission:', error);
            setMessage('Error cancelling submission. Please try again.');
            setShowHelp(false);
        }
    };

    useEffect(() => {
        getSubmissionData();
        return () => {
            stopPolling();
        };
    }, []);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-xl shadow-lg max-w-3xl w-full max-h-[90vh] flex flex-col">
                <h2 className="text-2xl font-bold mb-4">Upload Student Work</h2>

                <div className="mb-4 p-4 bg-gray-100 rounded-lg text-sm">
                    <p>• Uploading work on behalf of a student will overwrite any existing submission they have made for
                        this assignment.</p>
                    <p>• This action will trigger grading for the new submission.</p>
                </div>

                <div className="w-full mb-5 overflow-x-hidden">
                    <button
                        type="button"
                        className="flex items-center text-sm mb-2 hover:bg-gray-100 px-2 py-1 rounded"
                        onClick={() => setIsSettingsVisible(!isSettingsVisible)}
                    >
                        <img
                            src="/assets/back_arrow.png"
                            className={`size-5 opacity-60 transform ${isSettingsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                            alt="Toggle settings"
                        />
                        <span className="font-medium">Advanced Settings (Not Recommended)</span>
                    </button>

                    {isSettingsVisible && (
                        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200 mb-4 overflow-x-hidden">
                            <div className="flex items-center mb-3">
                                <div className="flex items-center">
                                    <div className="relative inline-block mr-2">
                                        <input
                                            type="checkbox"
                                            id="ditchAlignment"
                                            checked={ditchAlignmentMarkers === true}
                                            className="sr-only peer"
                                            onChange={() => setDitchAlignmentMarkers(!ditchAlignmentMarkers)}
                                        />
                                        <label
                                            htmlFor="ditchAlignment"
                                            className={`cursor-pointer relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out
                                            ${ditchAlignmentMarkers ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                                        >
                                            <span
                                                className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                                                ${ditchAlignmentMarkers ? 'translate-x-6' : 'translate-x-1'}`}
                                            />
                                        </label>
                                    </div>
                                    <label
                                        htmlFor="ditchAlignment"
                                        className="text-sm font-medium text-gray-700"
                                    >
                                        Do NOT use alignment markers
                                    </label>
                                </div>
                            </div>

                            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-2">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <img
                                            src="/assets/tclasses/warning.png"
                                            alt="Warning"
                                            className="h-5 w-5 mt-0.5"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm text-yellow-800 font-semibold">
                                            Important: Student work might get lost or not be seen by the AI
                                        </p>
                                        <p className="text-sm text-yellow-700 mt-1">
                                            We cannot ensure pages are in the correct order. <strong>MAKE
                                            SURE</strong> pages are in correct order, none are missing, no extra pages
                                            are included, and you ARE using an industrial-grade scanner.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex-grow overflow-y-auto">
                    <DragAndDropUpload onFileChange={setFile}/>
                </div>
                <form className="flex items-center space-x-4" onSubmit={handleSubmit}>
                    <button
                        type="submit"
                        disabled={isButtonDisabled}
                        className={`mt-5 px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${isButtonDisabled
                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            : 'bg-orange-200 hover:bg-orange-300 text-black'
                        }`}
                    >
                        {isButtonDisabled ? (
                            <>
                                <span className="opacity-0">Submit</span>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div
                                        className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                </div>
                            </>
                        ) : (
                            'Submit'
                        )}
                    </button>
                    {isButtonDisabled && (
                        <button
                            type="button"
                            className="mt-5 px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className="mt-5 px-10 py-2 bg-gray-200 border border-black hover:bg-gray-300 text-black rounded-3xl transition-colors duration-300 text-smallish font-medium"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </form>
                {message && <p className="text-red-500 mt-4">{message}</p>}
                {showHelp && (
                    <a
                        href="https://drive.google.com/file/d/1gWYt4atdKHK_-Dt8i5Ab6VaC7EWdxcg7/view?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-300 font-bold mb-4 hover:underline"
                    >
                        Refer to Submission Error documentation
                    </a>
                )}
            </div>
        </div>
    );
}

export default TeacherStudentUpload;