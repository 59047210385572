import {FieldArray, Form, Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import {isEmpty, isEqual} from 'lodash';
import {default as React, useEffect, useRef, useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import * as Yup from 'yup';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext.js';
import {formatDateTime} from '../../DueTimeDisplayTable';
import {getLogs} from '../../../store/teacherSlice.js';
import {SQuestionType} from './SQuestionType.js';
import {SubQuestion} from './SubQuestion.js';
import {LaTeXPreviewContent} from '../../LaTeXPreviewContent.js';
import {FormikSubmitEffect} from '../../Teacher/TPset/FormikSubmitEffect';
import {getFieldName} from '../../Teacher/TPset/utils';
import {
    clearSubmissionData,
    loadDraftData,
    loadPublishedData,
    loadPublishedQuestionData,
    publishData,
    updateDraftData
} from '../../../store/studentSlice.js';
import ConfirmationModal from '../../ConfirmationModal.js';
import {Tooltip} from 'react-tooltip';
import DueTimeDisplay from '../../DueTimeDisplay.js';
import {LoadingSmall} from '../../LoadingSmall';
import AddStudentsToSubmission from '../AddStudentsToSubmission';

// Question Navigator Component
const QuestionNavigator = ({
                               questions,
                               currentQuestionIndex,
                               onNavigate,
                               completionStatus,
                               totalCompleted
                           }) => {
    const completionPercentage = questions.length > 0
        ? (totalCompleted / questions.length) * 100
        : 0;

    return (
        <div
            className="fixed right-4 top-1/3 bg-white border border-gray-200 rounded-lg shadow-sm p-4 max-h-[60vh] overflow-y-auto z-10 w-64">
            <div className="mb-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                        className="bg-green-500 h-2.5 rounded-full"
                        style={{width: `${completionPercentage}%`}}
                    ></div>
                </div>
                <p className="text-sm text-gray-600 mt-1 font-medium text-center">
                    {totalCompleted}/{questions.length} questions answered
                </p>
            </div>

            <div className="space-y-2">
                {questions.map((question, index) => (
                    <button
                        key={index}
                        className={`w-full text-left px-3 py-2 rounded-md text-sm flex items-center ${
                            currentQuestionIndex === index
                                ? 'bg-orange-100 border border-orange-300'
                                : 'hover:bg-gray-100'
                        }`}
                        onClick={() => onNavigate(index)}
                    >
                        <span className={`mr-2 flex-shrink-0 w-5 h-5 rounded-full flex items-center justify-center
                        ${completionStatus[index] ? 'bg-green-500 text-white' : 'bg-gray-200'}
                        `}>
                        {completionStatus[index] ? '✓' : (index + 1)}
                        </span>
                        <span className="truncate">Question {index + 1}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};

// Focus Mode Toggle Component
const FocusToggle = ({isFocusModeActive, toggleFocusMode}) => {
    return (
        <div className="fixed right-4 top-1/4 bg-white border border-gray-200 rounded-lg shadow-sm p-3 z-10">
            <button
                className="flex items-center text-sm"
                onClick={toggleFocusMode}
            >
                <div className={`w-10 h-5 rounded-full p-1 transition-colors duration-200 ease-in-out ${
                    isFocusModeActive ? 'bg-orange-300' : 'bg-gray-300'
                }`}>
                    <div
                        className={`bg-white w-3 h-3 rounded-full shadow-md transform transition-transform duration-200 ease-in-out ${
                            isFocusModeActive ? 'translate-x-5' : 'translate-x-0'
                        }`}></div>
                </div>
                <span className="ml-2 font-medium">Focus Mode</span>
            </button>
        </div>
    );
};

// Modified Sidebar Component with Collapse Functionality
const Sidebar = ({
                     questions,
                     currentQuestionIndex,
                     onNavigate,
                     completionStatus,
                     totalCompleted,
                     isFocusModeActive,
                     toggleFocusMode,
                     isButtonDisabled,
                     isReadMode,
                     onSubmit,
                     isResubmission,
                     setConfirmationModalOpen,
                     onCollapseChange,
                     timeRemaining,
                     isTimedAssignment
                 }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const completionPercentage = questions.length > 0
        ? (totalCompleted / questions.length) * 100
        : 0;

    const toggleCollapse = () => {
        const newCollapsedState = !isCollapsed;
        setIsCollapsed(newCollapsedState);
        // Notify parent component of collapse state change
        onCollapseChange(newCollapsedState);
    };

    return (
        <div
            className={`fixed right-0 top-0 bottom-0 bg-white border-l border-gray-200 shadow-md z-10 flex flex-col h-screen transition-all duration-300 ${
                isCollapsed ? 'w-12' : 'w-64'
            }`}
        >
            {/* Collapse Toggle Button */}
            <button
                className="absolute -left-4 top-1/2 transform -translate-y-1/2 bg-white border border-gray-200 rounded-full p-1 shadow-md z-20"
                aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
                onClick={toggleCollapse}
            >
                <div className="flex items-center justify-center w-6 h-6">
                    <img
                        src="/assets/back_arrow.png"
                        className={`w-4 h-4 transform ${isCollapsed ? 'rotate-180' : 'rotate-0'}`}
                        alt={isCollapsed ? "Expand" : "Collapse"}
                    />
                </div>
            </button>

            {/* Sidebar Content - Only show when expanded */}
            <div className={`${isCollapsed ? 'hidden' : 'block'} p-4 h-full flex flex-col`}>
                {/* Focus Mode Toggle */}
                <div className="border-b border-gray-200 pb-3 pt-6">
                    <button
                        className="flex items-center text-sm w-full"
                        onClick={toggleFocusMode}
                    >
                        <div className={`w-10 h-5 rounded-full p-1 transition-colors duration-200 ease-in-out ${
                            isFocusModeActive ? 'bg-orange-300' : 'bg-gray-300'
                        }`}>
                            <div
                                className={`bg-white w-3 h-3 rounded-full shadow-md transform transition-transform duration-200 ease-in-out ${
                                    isFocusModeActive ? 'translate-x-5' : 'translate-x-0'
                                }`}></div>
                        </div>
                        <span className="ml-2 font-medium">Focus Mode</span>
                    </button>
                </div>

                <div className="mb-4">
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                            className="bg-green-500 h-2.5 rounded-full transition-all duration-300"
                            style={{width: `${completionPercentage}%`}}
                        ></div>
                    </div>
                    <p className="text-sm text-gray-600 mt-1 font-medium text-center">
                        {totalCompleted}/{questions.length} questions answered
                    </p>

                    {/* Timer display in sidebar */}
                    {isTimedAssignment && timeRemaining !== null && (
                        <div
                            className={`mt-3 text-center p-2 rounded-lg ${timeRemaining <= 300 ? 'bg-red-100 text-red-700' : 'bg-blue-100 text-blue-700'}`}>
                            <div className="font-medium text-sm">
                                {timeRemaining <= 0
                                    ? "Time's up!"
                                    : `Time: ${Math.floor(timeRemaining / 60)}:${(timeRemaining % 60).toString().padStart(2, '0')}`}
                            </div>
                            {timeRemaining > 0 && timeRemaining <= 300 && (
                                <div className="text-xs mt-1 font-medium">Time running out!</div>
                            )}
                        </div>
                    )}
                </div>

                {/* Question Navigator */}
                <div className="mt-4 flex-1 overflow-y-auto">
                    <div className="space-y-2">
                        {questions.map((question, index) => (
                            <button
                                key={index}
                                className={`w-full text-left px-3 py-2 rounded-md text-sm flex items-center ${
                                    currentQuestionIndex === index
                                        ? 'bg-orange-100 border border-orange-300'
                                        : 'hover:bg-gray-100'
                                }`}
                                onClick={() => onNavigate(index)}
                            >
                                <span className={`mr-2 flex-shrink-0 w-5 h-5 rounded-full flex items-center justify-center
                                ${completionStatus[index] ? 'bg-green-500 text-white' : 'bg-gray-200'}
                                `}>
                                {completionStatus[index] ? '✓' : (index + 1)}
                                </span>
                                <span className="truncate">Question {index + 1}</span>
                            </button>
                        ))}
                    </div>
                </div>

                {/* Submit Button */}
                {!isReadMode && (
                    <div className="mt-6 border-t border-gray-200 pt-4">
                        <button
                            disabled={isButtonDisabled}
                            className={`w-full px-6 py-3 rounded-xl border border-black text-sm font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow ${
                                isButtonDisabled
                                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                    : 'bg-orange-200 hover:bg-orange-300 text-black'
                            }`}
                            onClick={() => {
                                return onSubmit(isResubmission);
                            }}
                        >
                            {isResubmission ? 'Resubmit' : 'Submit'}
                        </button>
                    </div>
                )}
            </div>

            {/* Collapsed View - Only show icons and indicators */}
            {isCollapsed && (
                <div className="flex flex-col items-center p-2 h-full">
                    {/* Mini progress indicator */}
                    <div
                        className="mt-20 mb-4 w-8 h-8 rounded-full border-4 border-gray-200 flex items-center justify-center">
                        <div
                            className="text-xs font-bold text-gray-600"
                            title={`${totalCompleted}/${questions.length} questions answered`}
                        >
                            {totalCompleted}
                        </div>
                    </div>

                    {/* Submit button - mini version */}
                    {!isReadMode && (
                        <button
                            disabled={isButtonDisabled}
                            className={`w-8 h-8 rounded-full mt-auto mb-4 flex items-center justify-center ${
                                isButtonDisabled
                                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                    : 'bg-orange-200 hover:bg-orange-300 text-black'
                            }`}
                            title={isResubmission ? 'Resubmit' : 'Submit'}
                            onClick={() => onSubmit(isResubmission)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

function SUnpublishedFlexibleAssignment({
                                            assignment_id,
                                            assignment,
                                            class_id,
                                            isReadMode = false,
                                            isPregradeMode = false,
                                            studentId,
                                            questionId,
                                            directData = null // New prop to accept direct data
                                        }) {
    const {user} = useAuth();
    const dispatch = useDispatch();
    const [showSavedPopup, setShowSavedPopup] = useState(false);
    const [showErrorMessagePopup, setShowErrorMessagePopup] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isTimeLimitModalOpen, setIsTimeLimitModalOpen] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [timerExpired, setTimerExpired] = useState(false);
    const timerIntervalRef = useRef(null);
    const [timerActive, setTimerActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isPolling, setIsPolling] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [submissionData, setSubmissionData] = useState({});
    const [showSubmissionLog, setShowSubmissionLog] = useState(false);

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const pollingIntervalRef = useRef(null);
    // Add start_time state to record when the student started the assignment
    const [startTime, setStartTime] = useState(null);
    // Add these to the component state declarations
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isFocusModeActive, setIsFocusModeActive] = useState(false);
    const [completionStatus, setCompletionStatus] = useState({});
    const [totalCompleted, setTotalCompleted] = useState(0);

// Reference for scrolling to questions
    const questionRefs = useRef({});

// Helper function to check question completion
// Updated function to check question completion for all question types
    const isQuestionCompleted = (question) => {
        if (question.questionType === 'text') {
            return !!question.answerText?.trim();
        } else if (question.questionType === 'mcq') {
            return !!question.answerMcqOptionId;
        } else if (question.questionType === 'image') {
            return !!question.answerImageS3Key;
        }
        return false;
    };

// Navigation function
    const navigateToQuestion = (index) => {
        setCurrentQuestionIndex(index);
        // Scroll to the question if not in focus mode
        if (!isFocusModeActive && questionRefs.current[index]) {
            questionRefs.current[index].scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

// Toggle focus mode
    const toggleFocusMode = () => {
        setIsFocusModeActive(!isFocusModeActive);
    };


    // Use directData if provided, otherwise get from Redux
    const {
        draftData: {data: reduxDraftData, isLoading: isDraftDataLoading, errorMessage}
    } = useSelector(state => state?.studentReducer);

    // Use directData if provided, otherwise use data from Redux
    const draftData = directData || reduxDraftData;

    const {data: logs, isLoading: isLogsLoading} = useSelector(
        state => state?.teacherReducer?.logs
    );

    // Update completion status whenever examPaper changes
    useEffect(() => {
        if (draftData?.examPaper?.length) {
            const status = {};
            let completed = 0;

            draftData.examPaper.forEach((question, index) => {
                const mainQuestionCompleted = isQuestionCompleted(question);

                // Check if all subquestions are completed
                const subQuestionsCompleted = question.subQuestions.length > 0 ?
                    question.subQuestions.every(subQ => isQuestionCompleted(subQ)) : true;

                // Question is completed if main question and all subquestions are completed
                status[index] = mainQuestionCompleted || (subQuestionsCompleted && question.subQuestions.length > 0);

                if (status[index]) {
                    completed++;
                }
            });

            setCompletionStatus(status);
            setTotalCompleted(completed);
        }
    }, [draftData?.examPaper]);

    useEffect(() => {
        // If directData is provided, use it instead of fetching from Redux
        if (directData) {
            // Set the direct data to the component's state
            console.log('Using direct data for student:', studentId);
            return;
        }

        // Otherwise, use Redux as before
        if (isReadMode) {
            if (questionId) {
                dispatch(
                    loadPublishedQuestionData({
                        assignment_id,
                        token: user.session_token,
                        questionId,
                        studentId: studentId || user.user.id
                    })
                );
            } else {
                dispatch(
                    loadPublishedData({
                        assignment_id,
                        token: user.session_token,
                        studentId: studentId || user.user.id
                    })
                );
            }
        } else {
            dispatch(
                loadDraftData({
                    assignment_id,
                    token: user.session_token,
                    studentId: studentId || user.user.id
                })
            );
        }

        return () => dispatch(clearSubmissionData());
    }, [isReadMode, directData, studentId]);

    useEffect(() => {
        if (errorMessage) {
            setShowErrorMessagePopup(true);
            setTimeout(() => setShowErrorMessagePopup(false), 2000);
        }
    }, [errorMessage]);

    useEffect(() => {
        getAssignmentSubmission();
        return () => {
            stopPolling();
        };
    }, []);

    useEffect(() => {
        getAllLogs();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment]);

    const getAllLogs = async () => {
        await dispatch(
            getLogs({
                userId: user.user.id,
                token: user.session_token,
                assignmentId: assignment_id
            })
        );
    };

    const startPolling = submissionId => {
        if (isPolling) {
            return; // Prevent multiple polling intervals
        }
        setIsPolling(true);
        pollingIntervalRef.current = setInterval(() => {
            checkGradingStatus(submissionId);
        }, 5000);
    };

    const stopPolling = () => {
        setIsPolling(false);
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
        }
    };

    const checkGradingStatus = async submissionId => {
        try {
            const response = await gradeApi.get({
                path: `/api/submission/${submissionId}/grading-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            if (response.data.is_grading_done) {
                stopPolling();
                setMessage('Grading completed. Refreshing submission data...');
                window.location.reload();
            }
        } catch (error) {
            console.error('Error checking grading status:', error);
            stopPolling();
            setMessage('Error checking grading status. Please refresh the page.');
        }
    };

    const handleChange = async ({fieldName, value}) => {
        if (!isEqual(draftData[fieldName], value)) {
            console.log(draftData);
            const result = await dispatch(
                updateDraftData({
                    token: user.session_token,
                    assignment_id,
                    dataToSave: {
                        [fieldName]: value,
                        instructions: draftData.instructions
                    }
                })
            );

            if (!result.error) {
                setShowSavedPopup(true);
                setTimeout(() => setShowSavedPopup(false), 20000);
                return true;
            } else {
                return false;
            }
        }
    };

    const publishSubmission = async (isResubmission = false, isAutoSubmit = false) => {
        // If this is an auto-submit due to timer expiration and we've already completed submission, don't submit again
        if (isAutoSubmit && localStorage.getItem(`timer_expired_${assignment_id}`) === 'completed') {
            console.log('Preventing duplicate auto-submission');
            return;
        }
        await handleChange({
            fieldName: 'examPaper',
            value: draftData.examPaper
        });

        try {
            setMessage(
                isResubmission
                    ? 'Resubmission in progress... Please wait up to 10 seconds'
                    : 'Submission in progress... Please wait up to 10 seconds'
            );
            setIsButtonDisabled(true);

            // Record submission time when student submits
            const submissionTime = new Date().toISOString();

            const response = await dispatch(
                publishData({
                    assignment_id,
                    token: user.session_token,
                    start_time: startTime,
                    submission_time: submissionTime
                })
            );

            if (response.type === 'studentSlice/publishData/fulfilled') {
                setMessage(
                    'Grading in progress... You can safely leave this page now. Your work is saved. You cannot make changes right now.'
                );
                startPolling(response.payload.id);
            }
        } catch (error) {
            console.error(
                isResubmission ? 'Error resubmitting assignment:' : 'Error submitting assignment:',
                error
            );
            setIsButtonDisabled(false);
            setMessage(error.response?.data?.error || 'An unknown error occurred');
        } finally {
            setIsConfirmationModalOpen(false);
        }
    };


    const validationSchema = Yup.object().shape({
        examPaper: Yup.array().of(
            Yup.object().shape({
                answerText: Yup.string()
                    .nullable(true)
                    .when('questionType', {
                        is: val => val === 'text',
                        then: () => Yup.string().nullable(false).required('This field is required!')
                    }),
                answerMcqOptionId: Yup.string()
                    .nullable(true)
                    .when('questionType', {
                        is: val => val === 'mcq',
                        then: () => Yup.string().nullable(false).required('This field is required!')
                    }),

                subQuestions: Yup.array().of(
                    Yup.object().shape({
                        answerText: Yup.string()
                            .nullable(true)
                            .when('questionType', {
                                is: val => val === 'text',
                                then: () => Yup.string().nullable(false).required('This field is required!')
                            }),
                        answerMcqOptionId: Yup.string()
                            .nullable(true)
                            .when('questionType', {
                                is: val => val === 'mcq',
                                then: () => Yup.string().nullable(false).required('This field is required!')
                            })
                    })
                )
            })
        )
    });

    const getAssignmentSubmission = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/v2/assignments/${assignment_id}/user/${studentId ?? user.user.id}/submission/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            const data = response.data;
            if (data.is_grade_in_progres) {
                setMessage('Assignment is being graded... You can leave the page. Your work is saved. You can\'t make changes right\n' +
                    '                                now.');
                setIsButtonDisabled(true);
                startPolling(data.id);
            } else {
                setIsButtonDisabled(false);
                setMessage('');
            }

            setSubmissionData(data);
        } catch (error) {
            setIsButtonDisabled(false);
            setMessage(
                error.response?.data?.error || 'An error occurred while fetching submission data.'
            );
        }
    };

    const handleLeaveGroup = async () => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/leave-group/`,
                body: {},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setMessage('You have left the group. Refreshing...');
            getAssignmentSubmission();
        } catch (error) {
            setMessage('Error leaving group. Please try again.');
        }
    };

    const disabledStyle = isDraftDataLoading ? '!opacity-65' : '';

    // Timer calculation function
    const calculateTimeRemaining = useCallback(() => {
        if (!startTime || !assignment?.time_limit_enabled) {
            return null;
        }

        const startDateTime = new Date(startTime);
        const timeLimit = assignment?.time_limit || 60; // default to 60 minutes if not specified
        const endDateTime = new Date(startDateTime.getTime() + timeLimit * 60 * 1000);
        const now = new Date();

        // If time has expired
        if (now >= endDateTime) {
            return 0;
        }

        // Return remaining time in seconds
        return Math.floor((endDateTime - now) / 1000);
    }, [startTime, assignment]);

    // Format time for display (MM:SS format)
    const formatTime = (totalSeconds) => {
        if (totalSeconds === null) {
            return '--:--';
        }

        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    // Handle timer expiration
    const handleTimerExpired = useCallback(async () => {
        if (timerExpired) {
            return;
        } // Prevent multiple executions

        setTimerExpired(true);
        setMessage('Time limit reached! Your assignment is being submitted automatically...');
        
        // Set a flag in localStorage to indicate timer has expired and submission is in progress
        localStorage.setItem(`timer_expired_${assignment_id}`, 'true');

        try {
            // Force-submit the assignment
            await publishSubmission(false, true);
        } catch (error) {
            console.error('Error during auto-submission:', error);
            setMessage('Error during automatic submission. Please try to submit manually.');
        } finally {
            // Regardless of success/failure, ensure we don't try to submit again
            localStorage.setItem(`timer_expired_${assignment_id}`, 'completed');
        }
    }, [timerExpired, assignment_id]);

    // Timer update effect - only starts when timerActive is true
    useEffect(() => {
        if (!assignment?.time_limit_enabled || isReadMode || isPregradeMode || !timerActive) {
            return;
        }
        
        // Initial calculation
        const initialRemaining = calculateTimeRemaining();
        setTimeRemaining(initialRemaining);
        
        // If time is already expired, handle it once and don't set up the interval
        if (initialRemaining === 0) {
            if (!timerExpired) {
                handleTimerExpired();
            }
            return;
        }

        // Update timer every second
        timerIntervalRef.current = setInterval(() => {
            const remaining = calculateTimeRemaining();
            setTimeRemaining(remaining);

            // Auto-submit when timer reaches zero
            if (remaining === 0) {
                clearInterval(timerIntervalRef.current);
                if (!timerExpired) {
                    handleTimerExpired();
                }
            }
        }, 1000);

        return () => {
            if (timerIntervalRef.current) {
                clearInterval(timerIntervalRef.current);
            }
        };
    }, [assignment, startTime, calculateTimeRemaining, handleTimerExpired, isReadMode, isPregradeMode, timerActive, timerExpired]);

    // Add useEffect to check if we should show the time limit modal
    useEffect(() => {
        // Only for non-read mode (actual student attempting assignment)
        if (!isReadMode && !isPregradeMode) {
            // Check if assignment has time limit and if timer has already been started
            const timerStarted = localStorage.getItem(`timer_started_${assignment_id}`) === 'true';
            const timerExpiredStatus = localStorage.getItem(`timer_expired_${assignment_id}`);
            
            // If timer has already expired and submission was completed, don't allow editing
            if (timerExpiredStatus === 'completed') {
                setTimerExpired(true);
                setIsButtonDisabled(true);
                setMessage('Your time has expired and your work has been submitted. You cannot make further changes.');
                return;
            }
            
            // If timer expired but submission was interrupted, try to submit again
            if (timerExpiredStatus === 'true') {
                setTimerExpired(true);
                handleTimerExpired();
                return;
            }
            
            if (assignment && assignment.time_limit_enabled && !timerStarted) {
                setIsTimeLimitModalOpen(true);
            } else if (timerStarted) {
                // If timer was previously started, activate it
                setTimerActive(true);
                // Load the stored start time
                const storedStartTime = localStorage.getItem(`start_time_${assignment_id}`);
                if (storedStartTime) {
                    setStartTime(storedStartTime);
                }
            }
        }
    }, [assignment_id, isReadMode, isPregradeMode, assignment, handleTimerExpired]);

    const handleSidebarCollapseChange = (collapsed) => {
        setIsSidebarCollapsed(collapsed);
    };

    // Handle user clicking "Not now" on the time limit modal
    const handleTimeLimitCancel = () => {
        setIsTimeLimitModalOpen(false);
        // Navigate back to assignments list
        window.location.href = `/sclasses/${class_id}/sassignments`;
    };
    
    // Start the timer when student confirms they understand
    const handleTimeLimitConfirm = () => {
        // Record new start time and store it
        const currentTime = new Date().toISOString();
        setStartTime(currentTime);
        localStorage.setItem(`start_time_${assignment_id}`, currentTime);
        localStorage.setItem(`timer_started_${assignment_id}`, 'true');
        
        // Activate the timer
        setTimerActive(true);
        setIsTimeLimitModalOpen(false);
    };

    return (
        <div className="flex flex-col bg-white relative">
            {/* Main content container with conditional right padding for sidebar */}
            <div
                className={`${(!isReadMode && !isPregradeMode) ? (isSidebarCollapsed ? "pr-12" : "pr-64") : ""} transition-all duration-300`}>
                {/* Main content area */}
                <div className="px-5 py-7">
                    <div className="fixed top-0 bg-white w-full z-10">
                        {!isReadMode && !isPregradeMode && (
                            <div className="flex flex-row items-center space-x-2 mb-4 mt-4">
                                <Link to="/sclasses">
                                    <div className="size-10 flex items-center justify-center bg-gray-50 rounded-full">
                                        <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Home"/>
                                    </div>
                                </Link>
                                <Link to="/sclasses">
                                    <div className="text-black text-lg font-medium ml-4">My Classes</div>
                                </Link>
                                <Link to="/sclasses">
                                    <div className="size-6 flex items-center justify-center">
                                        <img src="/assets/back_arrow.png" className="size-6" alt="Back Arrow"/>
                                    </div>
                                </Link>
                                <Link to={`/sclasses/${class_id}/sassignments`}>
                                    <div className="text-black text-lg font-medium ml-4">{assignment.course.name}</div>
                                </Link>
                                <Link to={`/sclasses/${class_id}/sassignments`}>
                                    <div className="size-6 flex items-center justify-center">
                                        <img src="/assets/back_arrow.png" className="size-6" alt="Back Arrow"/>
                                    </div>
                                </Link>
                                <h1 className="text-black text-lg font-medium ml-4">{assignment.title}</h1>

                                {/* Timer display for timed assignments */}
                                {assignment?.time_limit_enabled && !isReadMode && !isPregradeMode && timeRemaining !== null && (
                                    <div
                                        className={`ml-auto flex items-center rounded-lg px-3 py-1 ${timeRemaining <= 300 ? 'bg-red-100 text-red-700' : 'bg-blue-100 text-blue-700'}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        <span className="font-medium">
                                            {timeRemaining <= 0
                                                ? "Time's up!"
                                                : `Time remaining: ${Math.floor(timeRemaining / 60)}:${(timeRemaining % 60).toString().padStart(2, '0')}`}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Submission Log Section */}
                    {!isReadMode && !isPregradeMode && (
                        <div className="flex flex-col w-full mb-5 mt-12">
                            <button
                                type="button"
                                className="flex items-center text-sm mb-2"
                                onClick={() => {
                                    setShowSubmissionLog(!showSubmissionLog);
                                    console.log('ASSIGNMENT DETAILS BELOW');
                                    console.log(assignment);
                                }}
                            >
                                <img
                                    src="/assets/back_arrow.png"
                                    className={`size-5 opacity-60 transform ${showSubmissionLog ? 'rotate-90' : '-rotate-90'} mr-2`}
                                    alt="Toggle submission log"
                                />
                                Submission Log
                            </button>

                            {showSubmissionLog && (
                                <div className="flex flex-col w-full">
                                    {logs?.entities?.length > 0 ? (
                                        logs.entities.map((log, index) => (
                                            <div key={index} className="mb-2 ml-4">
                                                <p className="text-sm text-gray-700">
                                                    {logs.totalCount - index}. Submitted at{' '}
                                                    {formatDateTime(log.submissionDate, user.user.timezone)} by{' '}
                                                    <span
                                                        id={`user${log.firstName + log.userId}`}
                                                        className="relative cursor-pointer hover:text-gray-900"
                                                    >
                                                  <strong>{log.fullName}</strong>
                                                </span>
                                                </p>
                                                <Tooltip anchorSelect={`#user${log.firstName + log.userId}`}
                                                         place="bottom">
                                                    <p>ID: {log.userId}</p>
                                                    <p>Email: {log.email}</p>
                                                </Tooltip>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-sm text-gray-700 ml-4">No submissions made yet.</p>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {!questionId && (
                        <div
                            className={`bg-white border border-gray-200 rounded-lg shadow-sm p-6 ${disabledStyle}`}
                        >
                            {draftData?.instructions && (
                                <p className="text-black-600 mb-4 text-sm whitespace-pre-wrap">
                                    <LaTeXPreviewContent content={draftData.instructions}/>
                                </p>
                            )}
                            <div className="flex">
                                <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
                                <DueTimeDisplay
                                    dueTime={assignment.due_time}
                                    dueDate={assignment.due_date}
                                    timezone={assignment.timezone}
                                />
                            </div>
                        </div>
                    )}

                    {user.user.is_teacher && (isEmpty(draftData) || isDraftDataLoading) ? (
                        <div className="bg-white">
                            <LoadingSmall autoHeight="h-[200px]"/>
                        </div>
                    ) : (
                        <div className="bg-white border border-gray-400 rounded-lg shadow-sm p-6 mb-16">
                            <div className={`flex-grow container ${disabledStyle}`}>
                                <Formik
                                    enableReinitialize
                                    initialValues={{examPaper: draftData.examPaper ?? []}}
                                    validationSchema={validationSchema}
                                    validateOnChange={true}
                                    validateOnBlur={true}
                                    validateOnMount={true}
                                    onSubmit={async (values, {setSubmitting}) => {
                                        setSubmitting(true);
                                        await handleChange({fieldName: 'examPaper', value: values.examPaper});
                                        setSubmitting(false);
                                    }}
                                >
                                    {({values, errors}) => (
                                        <Form>
                                            <FormikSubmitEffect
                                                onSubmitError={async () => {
                                                    await handleChange({
                                                        fieldName: 'examPaper',
                                                        value: values.examPaper
                                                    });
                                                }}
                                            />
                                            <div className="relative">
                                                <FieldArray name={'examPaper'}>
                                                    {() => (
                                                        <div>
                                                            {values.examPaper.map((question, index) => (
                                                                <div
                                                                    key={`${question.id}-${index}`}
                                                                    ref={el => questionRefs.current[index] = el}
                                                                    style={{
                                                                        display: isFocusModeActive
                                                                            ? (index === currentQuestionIndex ? 'block' : 'none')
                                                                            : 'block'
                                                                    }}
                                                                >
                                                                    <SQuestionType
                                                                        questionPoint={`${question.question}`}
                                                                        disabledStyle={disabledStyle}
                                                                        index={index}
                                                                        question={question}
                                                                        fieldName={'examPaper'}
                                                                        isDisabled={isReadMode || isButtonDisabled}
                                                                    />
                                                                    {question.subQuestions.length > 0 && (
                                                                        <div
                                                                            className="bg-white border border-gray-200 rounded-lg shadow-sm p-6 m-6">
                                                                            <SubQuestion
                                                                                subQuestions={question.subQuestions}
                                                                                disabledStyle={disabledStyle}
                                                                                isDisabled={isReadMode || isButtonDisabled}
                                                                                fieldName={getFieldName(index, 'subQuestions', 'examPaper')}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </FieldArray>

                                                {submissionData.submitted &&
                                                    submissionData.is_group_task &&
                                                    submissionData.is_group_admin && (
                                                        <div className="mt-6">
                                                            <AddStudentsToSubmission aid={assignment.id}/>
                                                        </div>
                                                    )}

                                                <ConfirmationModal
                                                    isOpen={isConfirmationModalOpen}
                                                    message="You have not answered all questions yet. Are you sure you'd like to submit?"
                                                    onClose={() => setIsConfirmationModalOpen(false)}
                                                    onConfirm={() => publishSubmission()}
                                                />
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    )}
                    {isPregradeMode && !isReadMode && (
                        <div className="mt-6 mb-8">
                            <button
                                disabled={isButtonDisabled || (assignment?.time_limit_enabled && submissionData.submitted)}
                                className={`w-full px-6 py-3 rounded-xl border border-black text-sm font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow ${
                                    isButtonDisabled || (assignment?.time_limit_enabled && submissionData.submitted)
                                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                        : 'bg-orange-200 hover:bg-orange-300 text-black'
                                }`}
                                onClick={() => {
                                    if (assignment?.time_limit_enabled && submissionData.submitted) {
                                        // Show a notification that they can't resubmit a timed assignment
                                        setMessage('You cannot resubmit a timed assignment after submission.');
                                        setTimeout(() => setMessage(''), 5000);
                                        return;
                                    }

                                    // Regular submission
                                    return publishSubmission(assignment.is_group_task && submissionData.submitted);
                                }}
                            >
                                {assignment?.time_limit_enabled && submissionData.submitted ? 'Resubmission not allowed' : 'Submit'}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* Right Sidebar with Focus Mode and Question Navigator */}
            {!isReadMode && !isPregradeMode && draftData?.examPaper?.length > 0 && (
                <Sidebar
                    questions={draftData.examPaper}
                    currentQuestionIndex={currentQuestionIndex}
                    completionStatus={completionStatus}
                    totalCompleted={totalCompleted}
                    isFocusModeActive={isFocusModeActive}
                    toggleFocusMode={toggleFocusMode}
                    isButtonDisabled={isButtonDisabled || (assignment?.time_limit_enabled && submissionData.submitted)}
                    isReadMode={isReadMode}
                    isResubmission={assignment.is_group_task && submissionData.submitted}
                    setConfirmationModalOpen={setIsConfirmationModalOpen}
                    timeRemaining={timeRemaining}
                    isTimedAssignment={assignment?.time_limit_enabled}
                    onNavigate={navigateToQuestion}
                    onSubmit={publishSubmission}
                    onCollapseChange={handleSidebarCollapseChange}
                />
            )}

            {/* Sticky bottom notification for assignment status */}
            {!isReadMode && !isPregradeMode && submissionData.submitted && !submissionData.is_grade_in_progres && !isButtonDisabled && (
                <div
                    className={`fixed rounded-xl bottom-0 left-80 ${isSidebarCollapsed ? 'right-16' : 'right-80'} bg-white border-t border-gray-200 p-3 z-20 shadow-md transition-all duration-300`}>
                    <p className="text-orange-500 font-medium text-sm">
                        Your assignment has been graded, but you can still resubmit! You will see your grade once
                        professor publishes grades.
                    </p>
                </div>
            )}

            {/* Pregrade information */}
            {!isReadMode && !isPregradeMode && assignment.allow_pregrade && !isButtonDisabled && (
                <div
                    className={`fixed rounded-xl bottom-0 left-80 ${isSidebarCollapsed ? 'right-16' : 'right-80'} bg-white border-t border-gray-200 p-3 z-20 shadow-md transition-all duration-300`}>
                    {!submissionData.submission_count ? (
                        <p className="text-green-500 text-sm font-medium">
                            Professor enabled pre-grading, so you will be able to see the AI feedback for your first
                            submission.
                        </p>
                    ) : submissionData.submission_count > 1 && (
                        <p className="text-orange-500 text-sm font-medium">
                            You have already used your pre-grading attempt. You will see feedback for future submissions
                            after the professor publishes grades.
                        </p>
                    )}
                </div>
            )}

            {showSavedPopup && (
                <div
                    className={`fixed top-20 ${isSidebarCollapsed ? 'right-16' : 'right-72'} bg-green-500 text-white px-4 py-2 rounded z-10 transition-all duration-300`}>
                    Progress saved
                </div>
            )}

            {/* Error message popup */}
            {showErrorMessagePopup && (
                <div
                    className={`fixed top-20 ${isSidebarCollapsed ? 'right-16' : 'right-72'} bg-red-500 text-white px-4 py-2 rounded z-10 transition-all duration-300`}>
                    {errorMessage}
                </div>
            )}

            {/* Grading in progress notification - always show when button is disabled due to grading */}
            {(isButtonDisabled && !isReadMode && (submissionData.is_grade_in_progres || message.includes('Grading in progress'))) && (
                <div
                    className={`fixed bottom-0 left-80 rounded-xl ${isSidebarCollapsed ? 'right-16' : 'right-80'} bg-green-100 border-t border-green-400 p-4 z-50 shadow-md transition-all duration-300`}>
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-green-700"
                             fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M13 10V3L4 14h7v7l9-11h-7z"/>
                        </svg>
                        <p className="text-green-700 font-medium">
                            {message.includes('Grading in progress')
                                ? message
                                : "Assignment is being graded... You can leave the page. Your work is saved. You can't make changes right now."
                            }
                        </p>
                    </div>
                </div>
            )}

            {/* Time limit confirmation modal */}
            <ConfirmationModal
                isOpen={isTimeLimitModalOpen}
                message={`IMPORTANT: This assignment has a time limit of ${assignment?.time_limit || 60} minutes. After clicking 'Start Timer', a countdown will be displayed at the top of the page. Your work will be automatically submitted when time expires. Failing to complete within the time limit may result in partial or no credit.`}
                confirmText="Start Timer"
                cancelText="Cancel"
                onClose={handleTimeLimitCancel}
                onConfirm={handleTimeLimitConfirm}
            />
        </div>
    );
}

export default SUnpublishedFlexibleAssignment;