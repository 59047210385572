import React, {useState, useEffect} from 'react';
import {Calendar, Clock} from 'lucide-react';

const DateTimeSelector = ({
                              dueDate,
                              setDueDate,
                              dueTime,
                              setDueTime,
                              timezone,
                              errorMessage = '',
                              onFocus = () => {
                              },
                              onBlur = () => {
                              }
                          }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [localDateTime, setLocalDateTime] = useState('');

    // Initialize with current values
    useEffect(() => {
        if (dueDate && dueTime) {
            const formattedDate = `${dueDate}T${dueTime}`;
            setLocalDateTime(formattedDate);
        }
    }, [dueDate, dueTime]);

    // Handle changes and maintain format compatibility
    const handleDateTimeChange = (e) => {
        const newDateTime = e.target.value;
        setLocalDateTime(newDateTime);

        // Split into date and time components
        const [datePart, timePart] = newDateTime.split('T');

        // Update parent state while maintaining format
        setDueDate(datePart); // Already in YYYY-MM-DD format
        setDueTime(timePart); // Already in HH:mm format
    };

    return (
        <div className="relative">
            <div
                className={`
          flex items-center gap-3 p-3 rounded-lg border transition-all cursor-pointer
          ${errorMessage ? 'border-red-300' : 'border-gray-200'}
          ${isOpen ? 'border-orange-300 ring-2 ring-orange-100' : 'hover:border-gray-300'}
        `}
                onClick={() => setIsOpen(true)}
            >
                <div className="flex items-center gap-2 flex-1">
                    {/*<Calendar className={`w-5 h-5 ${isOpen ? 'text-orange-500' : 'text-gray-400'}`}/>*/}
                    <input
                        type="datetime-local"
                        value={localDateTime}
                        className="w-full focus:outline-none focus:ring-0 outline-none bg-transparent border-transparent focus:border-transparent cursor-pointer"
                        onChange={handleDateTimeChange}
                        onFocus={(e) => {
                            setIsOpen(true);
                            onFocus(e);
                        }}
                        onBlur={(e) => {
                            setIsOpen(false);
                            onBlur(e);
                        }}
                    />
                </div>
                <div className="flex items-center gap-2 text-gray-400">
                    <span className="text-sm">{timezone}</span>
                    <Clock className="w-5 h-5"/>
                </div>
            </div>

            {errorMessage && (
                <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
            )}
        </div>
    );
};

export default DateTimeSelector;