import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';

const ConfirmationModal = ({isOpen, onClose, onConfirm, message, showCancelButton = true, children}) => {
    // Create a portal container if it doesn't exist
    useEffect(() => {
        let portalRoot = document.getElementById('modal-root');
        if (!portalRoot) {
            portalRoot = document.createElement('div');
            portalRoot.id = 'modal-root';
            portalRoot.style.position = 'fixed';
            portalRoot.style.top = '0';
            portalRoot.style.left = '0';
            portalRoot.style.width = '100%';
            portalRoot.style.height = '100%';
            portalRoot.style.pointerEvents = 'none';
            portalRoot.style.zIndex = '9999'; // Extremely high z-index
            document.body.appendChild(portalRoot);
        }

        // Prevent body scrolling when modal is open
        if (isOpen) {
            document.body.style.overflow = 'hidden';

            // When ESC key is pressed, close the modal
            const handleEsc = (e) => {
                if (e.key === 'Escape') {
                    onClose();
                }
            };
            document.addEventListener('keydown', handleEsc);

            return () => {
                document.body.style.overflow = 'auto';
                document.removeEventListener('keydown', handleEsc);
            };
        }
    }, [isOpen, onClose]);

    if (!isOpen) {
        return null;
    }

    // Modal content
    const modalContent = (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pointerEvents: 'auto',
                zIndex: 10000 // Extremely high z-index
            }}
            className="bg-black/50 backdrop-blur-sm overflow-hidden"
            onClick={onClose}>
            <div
                style={{
                    position: 'relative',
                    boxShadow: '0 0 0 1px rgba(0,0,0,0.05), 0 20px 25px -5px rgba(0,0,0,0.1), 0 10px 10px -5px rgba(0,0,0,0.04)',
                    zIndex: 10001
                }}
                className="p-8 bg-white w-full max-w-[400px] rounded-xl shadow-xl"
                onClick={(e) => e.stopPropagation()}>

                <div className="space-y-4">
                    <p className="text-[0.9375rem] text-gray-600 leading-relaxed">{message}</p>
                    {children}
                </div>

                <div className="flex justify-end gap-3 mt-8">
                    {showCancelButton && (
                        <button
                            type="button"
                            className="px-5 py-2.5 rounded-xl border border-gray-200 text-gray-600 text-[0.875rem] font-medium hover:bg-gray-50 transition-all duration-200 hover:transform hover:-translate-y-0.5"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className="px-5 py-2.5 rounded-xl bg-gradient-to-br from-[#e4b17a] to-[#c99b69] text-white text-[0.875rem] font-medium hover:from-[#c99b69] hover:to-[#b38a5e] transition-all duration-200 hover:transform hover:-translate-y-0.5 shadow-sm hover:shadow"
                        onClick={onConfirm}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );

    // Create portal to render modal at the root level
    return createPortal(
        modalContent,
        document.getElementById('modal-root') || document.body
    );
};

export default ConfirmationModal;