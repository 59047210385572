import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {gradeApi} from '../api.js';

export const loadDraftData = createAsyncThunk(
    'flexibleAssignment/loadDraftData',
    async ({assignment_id, token}) => {
        try {
            const response = await gradeApi.get({
                path: `/api/v2/assignments/${assignment_id}/flexible-problem-set/draft`,
                config: {
                    headers: {Authorization: `Bearer ${token}`}
                }
            });

            return await response.data;
        } catch (error) {
            console.log(error);
            return await response.data;
        }
    }
);

export const editQuestions = createAsyncThunk(
    'flexibleAssignment/editQuestions',
    async ({assignment_id, token, questions}, thunkApi) => {
        try {
            // Make sure we're using the real database IDs, not frontend IDs
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/edit_questions/`,
                body: {questions},
                config: {headers: {Authorization: `Bearer ${token}`}}
            });

            thunkApi.dispatch(
                updateDraftData({
                    token,
                    assignment_id,
                    dataToSave: {
                        edited: false
                    },
                    isUploading: true
                })
            );

            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(
                error.response?.data?.error || error.message || 'An error occurred while editing questions. Please try again.'
            );
        }
    }
);

export const updateDraftData = createAsyncThunk(
    'flexibleAssignment/updateDraftData',
    async ({dataToSave, token, assignment_id, isUploading = false}, {rejectWithValue}) => {
        try {
            console.log(dataToSave);
            const response = await gradeApi.post({
                path: `/api/v2/assignments/${assignment_id}/flexible-problem-set/draft`,
                body: {
                    ...dataToSave,
                    edited: isUploading ? dataToSave.edited : true
                },
                config: {headers: {Authorization: `Bearer ${token}`}}
            });
            return await response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// Check the loadQuestions implementation to ensure it preserves the real database IDs
export const loadQuestions = createAsyncThunk(
    'flexibleAssignment/loadQuestions',
    async ({assignment_id, token}, thunkApi) => {
        const response = await gradeApi.get({
            path: `/api/assignment/${assignment_id}/questions-frontend/`,
            config: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        });

        // Make sure we're preserving the real database IDs here
        thunkApi.dispatch(
            updateDraftData({
                token,
                assignment_id,
                dataToSave: {
                    examPaper: response.data.questions
                }
            })
        );

        return await response.data;
    }
);

export const uploadQuestions = createAsyncThunk(
    'flexibleAssignment/uploadQuestions',
    async ({assignment_id, token, body}, thunkApi) => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/constructor/`,
                body,
                config: {headers: {Authorization: `Bearer ${token}`}}
            });

            thunkApi.dispatch(
                updateDraftData({
                    token,
                    assignment_id,
                    dataToSave: {
                        created: true,
                        edited: false
                    },
                    isUploading: true
                })
            );

            return await response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(
                error.response?.data?.error || error.message || 'An error occurred. Please try again.'
            );
        }
    }
);

const flexibleAssignmentSlice = createSlice({
    name: 'flexibleAssignment',
    initialState: {
        draftData: {
            data: {},
            isLoading: false,
            errorMessage: ''
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadDraftData.pending, state => {
            state.draftData.isLoading = true;
        });
        builder.addCase(loadDraftData.rejected, state => {
            console.log(state);
            state.draftData.isLoading = false;
        });
        builder.addCase(loadDraftData.fulfilled, (state, action) => {
            state.draftData.isLoading = false;
            state.draftData.data = action.payload?.examPaper ? action.payload : {};
        });
        builder.addCase(updateDraftData.pending, state => {
            state.draftData.isLoading = true;
        });
        builder.addCase(updateDraftData.rejected, (state, action) => {
            state.draftData.isLoading = false;
            state.draftData.errorMessage = action.payload.message;
        });
        builder.addCase(updateDraftData.fulfilled, (state, action) => {
            state.draftData.isLoading = false;
            state.draftData.data = action.payload;
        });
        builder.addCase(loadQuestions.pending, state => {
            state.draftData.isLoading = true;
        });
        builder.addCase(loadQuestions.rejected, state => {
            state.draftData.isLoading = false;
            state.draftData.data = {
                ...state.draftData.data,
                created: false
            };
        });
        builder.addCase(loadQuestions.fulfilled, (state, action) => {
            const {questions} = action.payload;
            state.draftData.isLoading = false;
            state.draftData.data = {
                ...state.draftData.data,
                examPaper: questions,
                created: questions.length !== 0
            };
        });
        builder.addCase(uploadQuestions.pending, state => {
            state.draftData.isLoading = true;
        });
        builder.addCase(uploadQuestions.rejected, (state, action) => {
            state.draftData.isLoading = false;
            state.draftData.errorData = action.error;
        });
        builder.addCase(uploadQuestions.fulfilled, (state, action) => {
            state.draftData.isLoading = false;
            state.draftData.data = {
                ...state.draftData.data,
                edited: false
            };
        });

        builder.addCase(editQuestions.pending, state => {
            state.draftData.isLoading = true;
        });
        builder.addCase(editQuestions.rejected, (state, action) => {
            state.draftData.isLoading = false;
            state.draftData.errorMessage = action.payload || 'Failed to edit questions';
        });
        builder.addCase(editQuestions.fulfilled, (state, action) => {
            state.draftData.isLoading = false;
            state.draftData.data = {
                ...state.draftData.data,
                edited: false
            };
        });
    }
});

export default flexibleAssignmentSlice.reducer;
