import React, {useEffect} from 'react';
import {
    Check,
    ChevronRight,
    ChevronDown,
    Eye,
    EyeOff
} from "lucide-react";
import StandaloneFlexibleAssignment from './StandaloneFlexibleAssignment';

const SubmissionCard = ({
                            submission,
                            index,
                            currentBatchIndex,
                            currentQuestion,
                            assignment,
                            isRegrading,
                            selectedSubmissions,
                            setSelectedSubmissions,
                            expandedSubmissions,
                            setExpandedSubmissions,
                            showStudentNames,
                            setShowStudentNames,
                            assigningStudent,
                            setAssigningStudent,
                            availableStudents,
                            handleAssignStudent,
                            getAnswerImageUrl,
                            getCurrentGrade,
                            getMaxPoints,
                            toggleOverlay,
                            handleSelectAdjacentRubricItem,
                            reviewMode
                        }) => {
    // Handle checkbox selection
    const handleSelectionToggle = (e) => {
        e.stopPropagation();
        setSelectedSubmissions(prev =>
            prev.includes(submission.id)
                ? prev.filter(id => id !== submission.id)
                : [...prev, submission.id]
        );
    };

    // Handle expanding/collapsing the card
    const handleExpandToggle = (e) => {
        e.stopPropagation();
        setExpandedSubmissions(prev => ({
            ...prev,
            [submission.id]: !prev[submission.id]
        }));
    };

    // Toggle the visibility of student names
    const handleNameVisibilityToggle = (e) => {
        e.stopPropagation();
        setShowStudentNames(prev => ({
            ...prev,
            [submission.id]: !prev[submission.id]
        }));
    };

    return (
        <div
            className={`group bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-200 border ${
                selectedSubmissions.includes(submission.id)
                    ? 'border-orange-400 ring-2 ring-orange-200'
                    : 'border-gray-200/80'
            } relative flex flex-col overflow-hidden`}
        >
            {/* Card Header */}
            <div className="p-5 flex items-center justify-between border-b border-gray-100 relative">
                <div className="flex items-center space-x-4">
                    <div className="relative">
                        <div
                            className="w-10 h-10 rounded-xl flex items-center justify-center bg-custom-orange text-white font-medium">
                            {currentBatchIndex * 10 + index + 1}
                        </div>
                        {/* Red dot indicator based on current subquestion's viewed status */}
                        {currentQuestion && submission.feedback && (
                            submission.feedback[currentQuestion]?.is_viewed ? (
                                <div
                                    className="absolute -top-1 -right-1 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></div>
                            ) : (
                                <div
                                    className="absolute -top-1 -right-1 w-3 h-3 bg-orange-500 rounded-full border-2 border-white"></div>
                            )
                        )}
                    </div>
                    <div>
                        {reviewMode === 'Unmatched' ? (
                            <div className="flex items-center space-x-2">
                                <div className="text-base font-medium">Unmatched Submission</div>
                                <button
                                    className="px-3 py-1 text-sm bg-orange-100 text-orange-700 rounded-full hover:bg-orange-200"
                                    onClick={() => setAssigningStudent(submission.id)}
                                >
                                    Assign Student
                                </button>
                                {assigningStudent === submission.id && (
                                    <div
                                        className="absolute z-50 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200">
                                        <div className="p-2">
                                            <select
                                                className="w-full p-2 border rounded-md"
                                                onChange={(e) => handleAssignStudent(submission.id, e.target.value)}
                                            >
                                                <option value="">Select a student...</option>
                                                {availableStudents.map((student) => (
                                                    <option key={student.id} value={student.id}>
                                                        {student.full_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div
                                className="text-base font-medium cursor-pointer flex items-center"
                                onClick={handleNameVisibilityToggle}
                            >
                                {showStudentNames[submission.id] ? (
                                    <>
                                        <Eye size={16} className="mr-1.5 text-gray-500"/>
                                        <span>{submission.user.first_name + ' ' + submission.user.last_name}</span>
                                    </>
                                ) : (
                                    <>
                                        <EyeOff size={16} className="ml-1.5 text-gray-500"/>
                                    </>
                                )}
                            </div>
                        )}
                        <div className="text-xs text-gray-500 mt-0.5">
                            Submitted: {submission.submission_date ?
                            new Date(submission.submission_date).toLocaleString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true
                            }) : 'Date not available'}
                        </div>
                    </div>
                </div>

                {/* Grade display */}
                <div className="flex items-center space-x-3">
                    <div
                        className="flex items-center bg-white border border-gray-200 px-3 py-1.5 rounded-full shadow-sm">
                        <div className="flex items-center space-x-1.5">
                            <div className={`w-2.5 h-2.5 ${
                                isRegrading
                                    ? "bg-orange-500"
                                    : "bg-green-500"
                            } rounded-full`}></div>
                            <div className="text-sm font-medium">
                                <span className="text-gray-700">Grade: </span>
                                {isRegrading ? (
                                    <span className="text-orange-600">Pending</span>
                                ) : (
                                    <>
                                        <span className="text-green-600">{getCurrentGrade(submission)}</span>
                                        <span className="text-gray-400">/{getMaxPoints(currentQuestion)}</span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Expand/collapse button - only show if the submission has been viewed */}
                    {currentQuestion && submission.feedback && submission.feedback[currentQuestion]?.is_viewed && (
                        <button
                            className="p-1.5 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
                            onClick={handleExpandToggle}
                        >
                            {expandedSubmissions[submission.id] ? (
                                <ChevronDown size={16} className="text-gray-600"/>
                            ) : (
                                <ChevronRight size={16} className="text-gray-600"/>
                            )}
                        </button>
                    )}
                </div>
            </div>

            {/* Card Content - always show if not marked as viewed or if expanded */}
            {(!currentQuestion ||
                !submission.feedback ||
                !submission.feedback[currentQuestion]?.is_viewed ||
                expandedSubmissions[submission.id]) ? (
                <div className="flex-1 bg-gradient-to-b from-gray-50 to-white p-4 relative flex flex-col">
                    <div className="absolute inset-0 bg-grid-gray-500/10 bg-[size:20px_20px]"></div>

                    {/* Main Content Container */}
                    <div className="relative flex-1 flex flex-col">
                        {/* Submission Content Section */}
                        <div className="flex-1 flex items-center justify-center mb-0">
                            {(() => {
                                // Only modify for flexible problem set
                                if (assignment?.type === 'flexible_problem_set') {
                                    // Ensure we're using the correct student ID for this specific submission
                                    const studentIdForSubmission = submission.user?.id;

                                    // Only render if we have a valid student ID
                                    if (studentIdForSubmission) {
                                        return (
                                            <div className="w-full h-full overflow-auto bg-white rounded-lg shadow-sm">
                                                <StandaloneFlexibleAssignment
                                                    key={`standalone-${submission.id}-${studentIdForSubmission}-${Date.now()}`}
                                                    studentId={studentIdForSubmission}
                                                    assignmentId={assignment.id}
                                                    questionId={currentQuestion}
                                                />
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div
                                                className="w-full h-full flex items-center justify-center bg-white rounded-lg shadow-sm">
                                                <div className="text-center p-4">
                                                    <p className="text-red-500 font-medium">Error: Unable to load
                                                        student submission</p>
                                                    <p className="text-gray-600 mt-2">The student ID for this submission
                                                        could not be found.</p>
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                                // Use original image display for all other types
                                else if (getAnswerImageUrl(submission)) {
                                    return (
                                        <div className="relative w-full h-full flex items-center justify-center">
                                            <img
                                                src={getAnswerImageUrl(submission)}
                                                alt="Student answer"
                                                className="max-w-full h-auto max-h-full object-contain mx-auto rounded-lg shadow-sm"
                                                onError={(e) => {
                                                    console.error('Image load error URL:', e.target.src);
                                                    const errorDiv = document.createElement('div');
                                                    errorDiv.className = 'text-red-500 text-sm text-center';
                                                    errorDiv.textContent = 'Error loading image';
                                                    if (e.target.parentNode) {
                                                        e.target.parentNode.replaceChild(errorDiv, e.target);
                                                    }
                                                }}
                                            />
                                        </div>
                                    );
                                }
                                // No image available
                                else {
                                    return (
                                        <div
                                            className="flex flex-col items-center justify-center space-y-2 text-gray-500">
                                            <div
                                                className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center">
                                                <img src="/assets/no-image.png" alt="No image"
                                                     className="w-6 h-6 opacity-50"/>
                                            </div>
                                            <span className="text-sm">No image available</span>
                                        </div>
                                    );
                                }
                            })()}
                        </div>

                        {/* Feedback Section */}
                        <div className="min-h-24 mt-4 relative">
                            {/* Grading Criteria Button */}
                            <div
                                className={`bg-white/90 backdrop-blur-sm rounded-xl border border-gray-200/80 p-4 transition-all duration-300 ${
                                    isRegrading
                                        ? "cursor-not-allowed"
                                        : "hover:border-orange-200/50 hover:shadow-lg cursor-pointer"
                                }`}
                                onClick={() => !isRegrading && toggleOverlay(submission.id)}
                            >
                                {(() => {
                                    const feedbackEntry = submission?.feedback?.[currentQuestion];
                                    const rubricItems = feedbackEntry?.rubric_items || [];
                                    const selectedRubricItem = rubricItems[0]; // Get the first rubric item

                                    if (isRegrading) {
                                        // Show loading animation for regrading
                                        return (
                                            <div
                                                className="flex items-center px-3 py-2 rounded-lg border border-orange-200 bg-orange-50">
                                                <div className="flex items-center flex-1">
                                                    <div
                                                        className="w-5 h-5 rounded-full border border-orange-300 flex items-center justify-center mr-3">
                                                        <div
                                                            className="w-3 h-3 border-2 border-orange-400 border-t-transparent rounded-full animate-spin"></div>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <span className="text-sm text-orange-600">Subquestion being regraded...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    } else if (!selectedRubricItem) {
                                        return (
                                            <div
                                                className="flex items-center px-3 py-2 rounded-lg border border-gray-200 bg-white">
                                                <div className="flex items-center flex-1">
                                                    <div
                                                        className="w-5 h-5 rounded-full border border-gray-300 flex items-center justify-center mr-3">
                                                    </div>
                                                    <div className="flex items-center">
                                                        <span
                                                            className="text-xl font-medium mr-3 text-gray-700">0</span>
                                                        <span className="text-sm text-gray-600">Click to grade</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                    // Define color schemes based on the index
                                    const gradientColors = {
                                        0: 'from-[rgb(253,248,243)] to-[rgb(249,237,226)]',
                                        1: 'from-[rgb(243,248,253)] to-[rgb(226,237,249)]',
                                        2: 'from-[rgb(248,253,243)] to-[rgb(237,249,226)]',
                                        3: 'from-[rgb(253,243,248)] to-[rgb(249,226,237)]',
                                        4: 'from-[rgb(248,243,253)] to-[rgb(237,226,249)]',
                                        5: 'from-[rgb(243,253,248)] to-[rgb(226,249,237)]',
                                        6: 'from-[rgb(253,243,253)] to-[rgb(249,226,249)]',
                                        7: 'from-[rgb(243,253,253)] to-[rgb(226,249,249)]',
                                        8: 'from-[rgb(253,253,243)] to-[rgb(249,249,226)]',
                                        9: 'from-[rgb(235,245,255)] to-[rgb(220,235,250)]'
                                    };

                                    const borderColors = {
                                        0: 'border-[rgb(228,177,122)]',
                                        1: 'border-[rgb(122,177,228)]',
                                        2: 'border-[rgb(177,228,122)]',
                                        3: 'border-[rgb(228,122,177)]',
                                        4: 'border-[rgb(177,122,228)]',
                                        5: 'border-[rgb(122,228,177)]',
                                        6: 'border-[rgb(228,122,228)]',
                                        7: 'border-[rgb(122,228,228)]',
                                        8: 'border-[rgb(228,228,122)]',
                                        9: 'border-[rgb(100,150,220)]'
                                    };

                                    const iconColors = {
                                        0: 'text-[rgb(228,177,122)]',
                                        1: 'text-[rgb(122,177,228)]',
                                        2: 'text-[rgb(177,228,122)]',
                                        3: 'text-[rgb(228,122,177)]',
                                        4: 'text-[rgb(177,122,228)]',
                                        5: 'text-[rgb(122,228,177)]',
                                        6: 'text-[rgb(228,122,228)]',
                                        7: 'text-[rgb(122,228,228)]',
                                        8: 'text-[rgb(228,228,122)]',
                                        9: 'text-[rgb(100,150,220)]'
                                    };

                                    const textColors = {
                                        0: 'text-orange-800',
                                        1: 'text-blue-800',
                                        2: 'text-green-800',
                                        3: 'text-pink-800',
                                        4: 'text-purple-800',
                                        5: 'text-teal-800',
                                        6: 'text-rose-800',
                                        7: 'text-cyan-800',
                                        8: 'text-amber-800',
                                        9: 'text-indigo-800'
                                    };

                                    // Determine color index based on value
                                    const colorIndex = selectedRubricItem.id % 10;

                                    return (
                                        <div
                                            className={`flex items-start px-3 py-2 bg-gradient-to-r ${gradientColors[colorIndex]} rounded-lg`}>
                                            <div className="flex items-center flex-1">
                                                <div
                                                    className={`w-5 h-5 rounded-full border ${borderColors[colorIndex]} bg-white flex items-center justify-center mr-3 mt-1 flex-shrink-0`}>
                                                    <Check className={`w-3 h-3 ${iconColors[colorIndex]} stroke-2`}/>
                                                </div>
                                                <div className="flex items-center">
                          <span className={`text-xl font-medium mr-3 ${textColors[colorIndex]}`}>
                            {selectedRubricItem.value}
                          </span>
                                                    <span className={`text-sm text-left ${textColors[colorIndex]} break-words`} style={{ wordBreak: 'break-word' }}>
                            {selectedRubricItem.descriptor}
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })()}
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            {/* Centered plus and minus buttons */}
                            <div className="flex-1"></div>
                            <div className="flex items-center space-x-6">
                                {/* Minus button to select lower rubric item */}
                                <button
                                    className="w-9 h-9 rounded-md flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition-colors duration-200 border border-gray-200"
                                    title="Select lower rubric item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectAdjacentRubricItem(submission.id, 'down');
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-rose-400"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3}
                                              d="M18 12H6"/>
                                    </svg>
                                </button>

                                {/* Plus button to select higher rubric item */}
                                <button
                                    className="w-9 h-9 rounded-md flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition-colors duration-200 border border-gray-200"
                                    title="Select higher rubric item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectAdjacentRubricItem(submission.id, 'up');
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-emerald-400"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3}
                                              d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="flex-1"></div>

                            {/* Selector moved to the very right */}
                            <div>
                                <div
                                    className={`w-6 h-6 rounded-md flex items-center justify-center cursor-pointer transition-all duration-200 ${selectedSubmissions.includes(submission.id) ? 'bg-orange-500' : 'bg-gray-200 hover:bg-gray-300'}`}
                                    onClick={handleSelectionToggle}
                                >
                                    {selectedSubmissions.includes(submission.id) && (
                                        <Check size={16} className="text-white"/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                /* Reviewed indicator when card is collapsed but has been reviewed */
                <div className="flex-1 flex items-center justify-center py-2 bg-gradient-to-b from-gray-50 to-white">
                    <div
                        className="flex items-center space-x-2 px-3 py-2 bg-green-50 rounded-full border border-green-100">
                        <div className="w-6 h-6 rounded-full bg-green-100 flex items-center justify-center">
                            <Check size={14} className="text-green-600"/>
                        </div>
                        <span className="text-sm text-green-700 font-medium">Reviewed</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubmissionCard;