import React, {useState} from 'react';
import AddDropBig from './AddDropBig';
import AddDropBigTeachers from './AddDropBigTeacher';
import CourseSettings from './CourseSettings';
import {Users, UserCog, Settings} from 'lucide-react';

const CourseManagement = ({course, class_id}) => {
    const [activeTab, setActiveTab] = useState('students');

    return (
        <div className="course-management-container overflow-y-auto max-h-[calc(100vh-130px)]">
            {/* Tabbed Navigation */}
            <div className="flex border-b border-gray-200 mb-4 sticky top-0 bg-white z-10">
                <button
                    className={`py-4 px-8 font-medium text-base focus:outline-none flex items-center gap-2 ${
                        activeTab === 'students'
                            ? 'border-b-2 border-[#e4b17a] text-[#e4b17a] font-semibold'
                            : 'text-gray-600 hover:text-gray-800'
                    }`}
                    onClick={() => setActiveTab('students')}
                >
                    <Users className="w-5 h-5"/>
                    Students
                </button>
                <button
                    className={`py-4 px-8 font-medium text-base focus:outline-none flex items-center gap-2 ${
                        activeTab === 'instructors'
                            ? 'border-b-2 border-[#e4b17a] text-[#e4b17a] font-semibold'
                            : 'text-gray-600 hover:text-gray-800'
                    }`}
                    onClick={() => setActiveTab('instructors')}
                >
                    <UserCog className="w-5 h-5"/>
                    Instructors
                </button>
                <button
                    className={`py-4 px-8 font-medium text-base focus:outline-none flex items-center gap-2 ${
                        activeTab === 'settings'
                            ? 'border-b-2 border-[#e4b17a] text-[#e4b17a] font-semibold'
                            : 'text-gray-600 hover:text-gray-800'
                    }`}
                    onClick={() => setActiveTab('settings')}
                >
                    <Settings className="w-5 h-5"/>
                    Settings
                </button>
            </div>

            {/* Content Area */}
            <div className="p-1 overflow-y-auto">
                {activeTab === 'students' && (
                    <AddDropBig course={course} class_id={class_id}/>
                )}
                {activeTab === 'instructors' && (
                    <AddDropBigTeachers course={course} class_id={class_id}/>
                )}
                {activeTab === 'settings' && (
                    <CourseSettings course={course} class_id={class_id}/>
                )}
            </div>
        </div>
    );
};

export default CourseManagement;
