// ImportAssignmentModal.js
import React, {useState} from 'react';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext';

const ImportAssignmentModal = ({onClose, setErrorMessage, errorMessage, onImport}) => {
    const [code, setCode] = useState('');
    const {user} = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${code}/decode/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            const assignmentId = response.data.assignment_id;
            console.log(assignmentId)
            onImport(assignmentId);
        } catch (error) {
            setErrorMessage(error.response?.data?.error || 'Failed to decode assignment code');
        }
    };

    return (
        <div className="space-y-4">
            <div className="flex flex-row justify-between">
                <h2 className="custom-title-sm text-black font-bold">Import Shared Assignment</h2>
                <button
                    className="desktop:hover:opacity-50 transition-all duration-300"
                    onClick={onClose}
                >
                    <img src="/assets/X.png" alt="Exit" className="w-[20px] h-[20px]"/>
                </button>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="space-y-3">
                    <div className="flex flex-col mb-5">
                        <div
                            className={`flex items-center input-entry-box font-normal bg-white ${errorMessage !== '' ? 'border-red-500' : ''}`}
                            onFocus={() => {
                                setErrorMessage('');
                            }}
                        >
                            <input
                                type="text"
                                value={code}
                                maxLength={8}
                                className={`actual-input ml-2.5 mr-2.5 ${errorMessage !== '' ? 'text-red-500' : ''}`}
                                placeholder="Enter Assignment Code"
                                onChange={e => setCode(e.target.value)}
                            />
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {errorMessage}
                        </div>
                    )}

                    <button
                        type="submit"
                        disabled={!code}
                        className={`sexy-button w-full ${!code ? 'cursor-not-allowed opacity-50' : ''}`}
                    >
                        Import Assignment
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ImportAssignmentModal;