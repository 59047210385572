import React, {useState, useEffect} from 'react';
import {gradeApi} from '../../../../api.js';
import {useAuth} from '../../../../auth/AuthContext';
import {useParams} from 'react-router-dom';

const PDFProcessor = ({
                          assignmentId,
                          uploadedFile,
                          onProcessingComplete,
                          onError,
                          setIsProcessing,
                          updateLoadingProgress,
                          setCurrentLoadingStep
                      }) => {
    const [isPolling, setIsPolling] = useState(false);
    const {user} = useAuth();
    const POLL_INTERVAL = 10000; // 10 seconds between polls
    const UPDATE_INTERVAL = 10000; // Update progress every 10s

    const checkParsePdfStatus = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignmentId}/parse-pdf-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            return {
                isDone: response.data.is_parse_pdf_done,
                isInProgress: !response.data.is_parse_pdf_done
            };
        } catch (error) {
            console.error('Error checking parse PDF status:', error);
            return {isDone: false, isInProgress: false};
        }
    };

    const loadDraft = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignmentId}/byob-questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            return response.data;

        } catch (error) {
            console.error('Error loading draft:', error);
            throw error;
        }
    };

    const pollAndAnimate = async () => {
        setIsPolling(true);
        let currentStep = 1;
        setCurrentLoadingStep(currentStep);

        const animationInterval = setInterval(() => {
            updateLoadingProgress(currentStep, Math.round((Date.now() % POLL_INTERVAL) / POLL_INTERVAL * 100), "processing");
            currentStep = currentStep === 3 ? 1 : currentStep + 1;
            setCurrentLoadingStep(currentStep);
        }, UPDATE_INTERVAL);

        const pollInterval = setInterval(async () => {
            const {isDone, isInProgress} = await checkParsePdfStatus();

            if (isDone) {
                clearInterval(pollInterval);
                clearInterval(animationInterval);
                setIsPolling(false);
                try {
                    const draftData = await loadDraft();
                    onProcessingComplete(draftData);
                } catch (error) {
                    onError('Error loading draft after PDF parsing');
                }
            } else if (!isInProgress) {
                clearInterval(pollInterval);
                clearInterval(animationInterval);
                setIsPolling(false);
                onError('PDF parsing process stopped unexpectedly');
            }
        }, POLL_INTERVAL);

        return () => {
            clearInterval(pollInterval);
            clearInterval(animationInterval);
        };
    };

    useEffect(() => {
        const processPDF = async () => {
            console.log('processing pdf');

            if (!uploadedFile) {
                return;
            }
            
            // Clear any previous question data from localStorage to prevent stale data issues
            localStorage.removeItem('constructorData');

            try {
                setIsProcessing(true);
                const formData = new FormData();
                formData.append('file', uploadedFile);

                const response = await gradeApi.post({
                    path: `/api/assignment/${assignmentId}/parse-pdf/`,
                    body: formData,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                });

                if (response.status === 200) {
                    await pollAndAnimate();
                } else {
                    onError('Failed to start PDF parsing');
                }
            } catch (error) {
                onError(error.response?.data?.error || 'An error occurred while parsing the PDF');
            }
        };

        processPDF();

        return () => {
            setIsPolling(false);
        };
    }, [uploadedFile]);

    return null;
};

export default PDFProcessor;