import React, {useState, useRef, useEffect} from 'react';
import {
    ChevronRight,
    FileText,
    CheckCircle,
    Save,
    GraduationCap,
    BookOpen,
    ArrowLeft,
    Plus,
    Minus,
    Trash2,
} from 'lucide-react';
import 'katex/dist/katex.min.css';
import {InlineMath} from 'react-katex';
import './page.scss';

/* Add CSS for expandable text and rubric previews */
const expandableTextStyles = `
.expandable-text {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}

.expand-button {
    display: inline-block;
    margin-left: 6px;
    color: #e4b17a;
    font-size: 0.9em;
    font-weight: 500;
    cursor: pointer;
    background: linear-gradient(135deg, #fdf8f3 0%, #f9ede2 100%);
    border: 1px solid rgba(228, 177, 122, 0.2);
    padding: 2px 8px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(228, 177, 122, 0.08);
    transition: all 0.2s ease;
}

.expand-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 6px rgba(228, 177, 122, 0.15);
    background: linear-gradient(135deg, #f9ede2 0%, #f5e5d5 100%);
}

.rubric-item-preview {
    width: 100%;
    min-height: 38px;
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #fafafa;
    font-size: 0.95rem;
    line-height: 1.5;
    overflow-wrap: break-word;
    transition: all 0.2s ease;
}

.rubric-item-preview:hover {
    border-color: #e4b17a;
    background-color: #fffaf5;
    box-shadow: 0 1px 3px rgba(228, 177, 122, 0.1);
}
`;

// Add the styles to the document
const styleElement = document.createElement('style');
styleElement.textContent = expandableTextStyles;
document.head.appendChild(styleElement);
import {gradeApi} from '../../../../../api.js';
import {useAuth} from "../../../../../auth/AuthContext";
import {useParams, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import ConfirmationModal from "../../../../ConfirmationModal";

export default function PDFRubricGenerator() {
    const [isEditing, setIsEditing] = useState(false);
    const [editingRubricItem, setEditingRubricItem] = useState(null); // {questionId, subQuestionId, index}
    const activeSolutionTextareaRef = useRef(null);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [selectedSubQuestionId, setSelectedSubQuestionId] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isGeneratingRubrics, setIsGeneratingRubrics] = useState(false);
    const [generationStatus, setGenerationStatus] = useState(null);
    const textareaRefs = useRef({});
    const pollingInterval = useRef(null);
    const {user} = useAuth();
    const {assignment_id, class_id} = useParams();
    const dispatch = useDispatch();
    const [isPublishing, setIsPublishing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const totalPoints = localStorage.getItem('assignmentPoints')

    // Utility function to render LaTeX content
    const renderLatexContent = (content) => {
        if (!content) {
            return null;
        }

        // Split content by code blocks first
        const segments = [];
        let currentIndex = 0;
        const codeBlockRegex = /```[\s\S]*?```/g;
        let match;

        while ((match = codeBlockRegex.exec(content)) !== null) {
            // Add text before code block
            if (match.index > currentIndex) {
                segments.push({
                    type: 'text',
                    content: content.slice(currentIndex, match.index)
                });
            }

            // Add code block
            segments.push({
                type: 'code',
                content: match[0].slice(3, -3)  // Remove ``` from start and end
            });

            currentIndex = match.index + match[0].length;
        }

        // Add remaining text
        if (currentIndex < content.length) {
            segments.push({
                type: 'text',
                content: content.slice(currentIndex)
            });
        }

        // Process each segment
        return segments.map((segment, index) => {
            if (segment.type === 'code') {
                return (
                    <pre key={index}
                         className="bg-gray-100 rounded-md p-2 my-2 font-mono text-sm whitespace-pre-wrap break-all"
                         style={{wordBreak: 'break-all', overflowWrap: 'break-word'}}>
                        {segment.content}
                    </pre>
                );
            } else {
                // Process LaTeX in text segments
                const lines = segment.content.split('\n');
                return lines.map((line, lineIndex) => {
                    // Modified regex to handle escaped dollar signs
                    const parts = line.split(/(?<!\\)\$/g);
                    const processedLine = parts.map((part, partIndex) => {
                        if (partIndex % 2 === 1) {
                            // This is LaTeX content
                            return <InlineMath key={`latex-${partIndex}`} math={part}/>;
                        }
                        return part;
                    });

                    return (
                        <React.Fragment key={`${index}-${lineIndex}`}>
                            {processedLine}
                            {lineIndex < lines.length - 1 && <br/>}
                        </React.Fragment>
                    );
                });
            }
        });
    };

    const loadExistingRubrics = async () => {
        setIsLoading(true);
        try {
            console.log("Loading existing rubrics...");
            const response = await gradeApi.get({
                path: `api/assignment/${assignment_id}/constructor/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            // Check if we have valid exam_paper data
            if (response.data && response.data.exam_paper && response.data.exam_paper.length > 0) {
                console.log("Successfully loaded existing rubrics");
                setQuestions({examPaper: response.data.exam_paper});
                setIsLoading(false);
            } else {
                console.log("No valid rubric data found, generating new rubrics");
                // If we got a response but no valid exam_paper, start generation
                localStorage.removeItem('rubricsGenerated'); // Clear the flag
                startRubricGeneration();
            }
        } catch (error) {
            console.error('Error loading existing rubrics:', error);
            // If there was an error loading, fall back to generation
            localStorage.removeItem('rubricsGenerated'); // Clear the flag
            startRubricGeneration();
        }
    };

    useEffect(() => {
        const rubricsGenerated = localStorage.getItem('rubricsGenerated');

        if (rubricsGenerated !== 'true') {
            startRubricGeneration();
        } else {
            // Load existing rubrics without regenerating
            loadExistingRubrics();
        }
    }, []);

    // Create a local state to track unsaved changes
    const [localQuestions, setLocalQuestions] = useState(null);

    // Update local state when questions from API changes
    useEffect(() => {
        if (questions) {
            setLocalQuestions(questions);
        }
    }, [questions]);

    useEffect(() => {
        // Only save if questions exist and aren't being loaded initially
        if (questions && questions.examPaper && !isLoading && !isGeneratingRubrics) {
            handleSaveChanges();
        }
    }, [questions]);


    // Utility function to render question text with newlines
    const renderQuestionText = (text) => {
        if (!text) {
            return null
        }
        ;

        // Split the text by newlines and create paragraph elements
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {renderLatexContent(line)}
                {index < lines.length - 1 && <br/>}
            </React.Fragment>
        ));
    };

    // Utility function to create expandable text with a "..." button
    const TruncatableText = ({text, maxLength = 100}) => {
        const [isExpanded, setIsExpanded] = useState(false);

        if (!text) {
            return null;
        }

        // If text is shorter than maxLength, just render it
        if (text.length <= maxLength && !text.includes('\n')) {
            return renderQuestionText(text);
        }

        // If expanded, show full text
        if (isExpanded) {
            return (
                <div className="expandable-text">
                    {renderQuestionText(text)}
                    <button
                        className="expand-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsExpanded(false);
                        }}
                    >
                        Show less
                    </button>
                </div>
            );
        }

        // If not expanded, show truncated text
        // For multiline text, only show the first line
        const firstLineBreak = text.indexOf('\n');
        let truncatedText;

        if (firstLineBreak !== -1 && firstLineBreak < maxLength) {
            truncatedText = text.substring(0, firstLineBreak);
        } else {
            truncatedText = text.substring(0, maxLength);
        }

        return (
            <div className="expandable-text">
                {renderQuestionText(truncatedText)}
                <button
                    className="expand-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded(true);
                    }}
                >
                    ...
                </button>
            </div>
        );
    };
    const transformQuestionsForRubrics = (questions) => {
        // Convert from {1: {questionText, subquestions}, 2: {...}} format
        // to {examPaper: [{id: 1, question: questionText, subQuestions: [...]}]} format
        return Object.entries(questions).map(([id, questionData]) => ({
            id: id,
            question: questionData.questionText,
            subQuestions: questionData.subquestions.map(sub => ({
                id: sub.letter,
                question: sub.text,
                solution: sub.solution,
                type: sub.type
            }))
        }));

    };

    const startRubricGeneration = async () => {
        setIsGeneratingRubrics(true);
        try {
            const response1 = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/byob-questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            const allQuestions = response1.data;
            const transformedPayload = transformQuestionsForRubrics(allQuestions);
            // Initial request to start generation
            const response = await gradeApi.post({
                path: 'api/assignment/generate-all-rubrics/',
                body: {
                    examPaper: transformedPayload,
                    totalPoints: localStorage.getItem('assignmentPoints'),
                    assignmentId: assignment_id,
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            });

            if (response.data.status === "started") {
                localStorage.setItem('assignment_id', assignment_id);
                startPolling();
            }
        } catch (error) {
            console.error('Error starting rubric generation:', error);
            setIsGeneratingRubrics(false);
        }
    };


    const checkGenerationStatus = async () => {
        try {
            const assignmentId = localStorage.getItem('assignment_id');
            const response = await gradeApi.get({
                path: `api/assignment/${assignmentId}/generate-all-rubrics-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (response.data.status === "completed") {
                setGenerationStatus("completed");
                stopPolling();

                // Set flag in localStorage indicating rubrics are generated
                localStorage.setItem('rubricsGenerated', 'true');

                // Fetch questions with rubrics from byob-questions endpoint
                try {
                    const questionsResponse = response.data;
                    setQuestions(questionsResponse);
                } catch (error) {
                    console.error('Error fetching questions with rubrics:', error);
                }

                setIsGeneratingRubrics(false);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error checking generation status:', error);
            stopPolling();
            setIsGeneratingRubrics(false);
            setIsLoading(false);
        }
    };

    const startPolling = () => {
        if (!pollingInterval.current) {
            pollingInterval.current = setInterval(checkGenerationStatus, 2000);
        }
    };

    const stopPolling = () => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
        }
    };

    useEffect(() => {
        return () => stopPolling();
    }, []);


    const handleRubricItemPointsChange = (questionId, subQuestionId, itemIndex, newPoints) => {
        setQuestions(prev => ({
            ...prev,
            examPaper: prev.examPaper.map(question => {
                if (question.id === questionId) {
                    if (subQuestionId) {
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map(subQ => {
                                if (subQ.id === subQuestionId) {
                                    return {
                                        ...subQ,
                                        rubricItems: subQ.rubricItems.map((item, idx) =>
                                            idx === itemIndex ? {...item, value: newPoints} : item
                                        )
                                    };
                                }
                                return subQ;
                            })
                        };
                    } else {
                        return {
                            ...question,
                            rubricItems: question.rubricItems.map((item, idx) =>
                                idx === itemIndex ? {...item, value: newPoints} : item
                            )
                        };
                    }
                }
                return question;
            })
        }));
    };
    const calculateTotalPoints = (examPaper) => {
        if (!examPaper) {
            return 0;
        }

        return examPaper.reduce((total, question) => {
            if (question.subQuestions && question.subQuestions.length > 0) {
                // For questions with subquestions, sum up the max points from each subquestion
                const subQuestionPoints = question.subQuestions.reduce((subTotal, subQ) => {
                    // Handle MCQ questions differently
                    if (subQ.type === 'mcq' || subQ.questionType === 'mcq') {
                        // Use assigned points or default to 1 for MCQ questions
                        return subTotal + (subQ.weight || 1);
                    } else {
                        // For non-MCQ questions, calculate points from rubric items
                        const maxPoints = subQ.rubricItems && subQ.rubricItems.length > 0
                            ? Math.max(...subQ.rubricItems.map(item => parseInt(item.value) || 0))
                            : 0;
                        return subTotal + maxPoints;
                    }
                }, 0);
                return total + subQuestionPoints;
            } else if (question.rubricItems) {
                // For questions without subquestions, get the max points from the question's rubric items
                const maxPoints = Math.max(...question.rubricItems.map(item => parseInt(item.value) || 0));
                return total + maxPoints;
            }
            return total;
        }, 0);
    };

    // This function updates the local state without triggering API calls
    const updateLocalRubricItemDescription = (questionId, subQuestionId, itemIndex, newDescription) => {
        setLocalQuestions(prev => ({
            ...prev,
            examPaper: prev.examPaper.map(question => {
                if (question.id === questionId) {
                    if (subQuestionId) {
                        // Update subquestion rubric item description
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map(subQ => {
                                if (subQ.id === subQuestionId) {
                                    return {
                                        ...subQ,
                                        rubricItems: subQ.rubricItems.map((item, idx) =>
                                            idx === itemIndex ? {...item, descriptor: newDescription} : item
                                        )
                                    };
                                }
                                return subQ;
                            })
                        };
                    } else {
                        // Update main question rubric item description
                        return {
                            ...question,
                            rubricItems: question.rubricItems.map((item, idx) =>
                                idx === itemIndex ? {...item, descriptor: newDescription} : item
                            )
                        };
                    }
                }
                return question;
            })
        }));
    };

    // This function updates the actual questions state which triggers the API call
    const handleRubricItemDescriptionChange = (questionId, subQuestionId, itemIndex, newDescription) => {
        setQuestions(prev => ({
            ...prev,
            examPaper: prev.examPaper.map(question => {
                if (question.id === questionId) {
                    if (subQuestionId) {
                        // Update subquestion rubric item description
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map(subQ => {
                                if (subQ.id === subQuestionId) {
                                    return {
                                        ...subQ,
                                        rubricItems: subQ.rubricItems.map((item, idx) =>
                                            idx === itemIndex ? {...item, descriptor: newDescription} : item
                                        )
                                    };
                                }
                                return subQ;
                            })
                        };
                    } else {
                        // Update main question rubric item description
                        return {
                            ...question,
                            rubricItems: question.rubricItems.map((item, idx) =>
                                idx === itemIndex ? {...item, descriptor: newDescription} : item
                            )
                        };
                    }
                }
                return question;
            })
        }));
    };

    const handleAddRubricItem = (questionId, subQuestionId) => {
        setQuestions(prev => ({
            ...prev,
            examPaper: prev.examPaper.map(question => {
                if (question.id === questionId) {
                    if (subQuestionId) {
                        // Add rubric item to subquestion
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map(subQ => {
                                if (subQ.id === subQuestionId) {
                                    // Get the current max points to determine the next value
                                    const currentItems = [...subQ.rubricItems];
                                    const maxPoints = currentItems.length > 0
                                        ? Math.max(...currentItems.map(item => parseInt(item.value) || 0))
                                        : 0;

                                    return {
                                        ...subQ,
                                        rubricItems: [
                                            ...currentItems,
                                            {descriptor: "New criteria", value: "0"}
                                        ]
                                    };
                                }
                                return subQ;
                            })
                        };
                    } else {
                        // Add rubric item to main question
                        const currentItems = [...question.rubricItems];
                        const maxPoints = currentItems.length > 0
                            ? Math.max(...currentItems.map(item => parseInt(item.value) || 0))
                            : 0;

                        return {
                            ...question,
                            rubricItems: [
                                ...currentItems,
                                {descriptor: "New criteria", value: "0"}
                            ]
                        };
                    }
                }
                return question;
            })
        }));
    };

    const handleRemoveRubricItem = (questionId, subQuestionId, itemIndex) => {
        setQuestions(prev => ({
            ...prev,
            examPaper: prev.examPaper.map(question => {
                if (question.id === questionId) {
                    if (subQuestionId) {
                        // Remove rubric item from subquestion
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map(subQ => {
                                if (subQ.id === subQuestionId) {
                                    // Don't remove if it's the last item
                                    if (subQ.rubricItems.length <= 1) {
                                        return subQ;
                                    }

                                    return {
                                        ...subQ,
                                        rubricItems: subQ.rubricItems.filter((_, idx) => idx !== itemIndex)
                                    };
                                }
                                return subQ;
                            })
                        };
                    } else {
                        // Remove rubric item from main question
                        // Don't remove if it's the last item
                        if (question.rubricItems.length <= 1) {
                            return question;
                        }

                        return {
                            ...question,
                            rubricItems: question.rubricItems.filter((_, idx) => idx !== itemIndex)
                        };
                    }
                }
                return question;
            })
        }));
    };

    const adjustTextareaHeight = (element) => {
        element.style.height = 'auto';
        element.style.height = element.scrollHeight + 'px';
    };

    const handleSaveChanges = async () => {
        try {
            // Make sure questions and examPaper exist before saving
            if (!questions || !questions.examPaper) {
                console.error('Cannot save changes: questions or examPaper is undefined');
                return false;
            }

            const z = await gradeApi.post({
                path: `api/assignment/${assignment_id}/constructor/`,
                body: {
                    exam_paper: questions.examPaper,
                    instructions: "", // Add empty string as default
                    show_points: true, // Add default value
                    preserve_questions: true // Flag to indicate that questions should not be deleted and recreated
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            });
            console.log(z);
            return true;
        } catch (error) {
            console.error('Error saving changes:', error);
            // Optionally add error handling UI here
            return false;
        }
    };

    useEffect(() => {
        Object.values(textareaRefs.current).forEach(ref => {
            if (ref) {
                console.log('adjusting textarea height');
                adjustTextareaHeight(ref);
            }
        });
    }, [selectedQuestionId, questions]);


    if (isLoading || isGeneratingRubrics || isPublishing) {
        return (
            <div className="rubric-generator">
                <div className="loading-overlay">
                    <div className="loading-content">
                        <GraduationCap className="loading-icon" size={48}/>
                        <h2>
                            {isPublishing
                                ? "Publishing Assignment"
                                : isGeneratingRubrics
                                    ? "Generating Rubrics"
                                    : "Loading"}
                        </h2>
                        <p>
                            {isPublishing
                                ? "Publishing your assignment and preparing for student submissions..."
                                : isGeneratingRubrics
                                    ? "Our AI is analyzing your questions and creating detailed grading criteria..."
                                    : "Loading rubric data..."}
                        </p>
                        <div className="loading-bar">
                            <div className="loading-progress"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!questions) {
        return (
            <div className="rubric-generator">
                <div className="error-state">
                    <FileText size={48}/>
                    <h2>No Rubric Data Available</h2>
                    <p>Please complete the previous steps to generate a rubric.</p>
                </div>
            </div>
        );
    }


    // Helper function to ensure MCQ questions have rubricItems
    const ensureMCQRubricItems = () => {
        if (!questions || !questions.examPaper) {
            return Promise.resolve(false);
        }

        let updated = false;
        const updatedExamPaper = questions.examPaper.map(question => {
            if (question.subQuestions && question.subQuestions.length > 0) {
                const updatedSubQuestions = question.subQuestions.map(subQ => {
                    if ((subQ.type === 'mcq' || subQ.questionType === 'mcq') && (!subQ.rubricItems || subQ.rubricItems.length === 0)) {
                        updated = true;
                        // Add default rubricItems for MCQ questions
                        return {
                            ...subQ,
                            rubricItems: [
                                {descriptor: "Correct answer", value: (subQ.weight || 1).toString()},
                                {descriptor: "Incorrect answer", value: "0"}
                            ]
                        };
                    }
                    return subQ;
                });

                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });

        if (updated) {
            // Return a promise that resolves when the state update is complete
            return new Promise(resolve => {
                setQuestions(prev => {
                    const newState = {
                        ...prev,
                        examPaper: updatedExamPaper
                    };
                    // Use a setTimeout to ensure the next tick has the updated state
                    setTimeout(() => resolve(true), 0);
                    return newState;
                });
            });
        }

        return Promise.resolve(false);
    };

    // Function to ensure all MCQs have rubric items (synchronous, direct object manipulation)
    const ensureMCQRubricItemsSync = (examPaper) => {
        if (!examPaper) {
            return examPaper;
        }

        return examPaper.map(question => {
            if (question.subQuestions && question.subQuestions.length > 0) {
                const updatedSubQuestions = question.subQuestions.map(subQ => {
                    if ((subQ.type === 'mcq' || subQ.questionType === 'mcq') && (!subQ.rubricItems || subQ.rubricItems.length === 0)) {
                        console.log(`Adding rubric items to MCQ subquestion ${subQ.id} in question ${question.id}`);
                        return {
                            ...subQ,
                            rubricItems: [
                                {descriptor: "Correct answer", value: (subQ.weight || 1).toString()},
                                {descriptor: "Incorrect answer", value: "0"}
                            ]
                        };
                    }
                    return subQ;
                });

                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
    };

    const publishAssignment = async () => {
        setIsPublishing(true);

        try {
            // First attempt to update the state with MCQ rubrics
            const mcqUpdated = await ensureMCQRubricItems();

            // If we updated MCQ rubrics, wait a short moment to ensure React has processed the state update
            if (mcqUpdated) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            // Get the latest state of questions
            const currentExamPaper = questions?.examPaper ? [...questions.examPaper] : [];

            // Apply the synchronous processor to ensure all MCQs have rubrics
            // This serves as a fallback in case the state updates haven't fully propagated
            const processedExamPaper = ensureMCQRubricItemsSync(currentExamPaper);

            // Update the state one more time with our processed data
            setQuestions(prev => ({
                ...prev,
                examPaper: processedExamPaper
            }));

            // Wait a moment for this final update
            await new Promise(resolve => setTimeout(resolve, 100));

            // Save the current state of the rubrics, using our processed data
            const savePayload = {
                exam_paper: processedExamPaper,
                instructions: "", // Add empty string as default
                show_points: true, // Add default value
                preserve_questions: true // Flag to indicate that questions should not be deleted and recreated
            };

            // Save directly with our processed data to ensure consistency
            const saveResponse = await gradeApi.post({
                path: `api/assignment/${assignment_id}/constructor/`,
                body: savePayload,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            });

            if (saveResponse.status !== 200) {
                throw new Error('Failed to save rubrics before publishing');
            }

            // Call the build-assignment endpoint to build the assignment PDF context
            const buildResponse = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/build-assignment/`,
                body: {},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            });

            if (buildResponse.status !== 200) {
                throw new Error(`Build assignment request failed with status: ${buildResponse.status}`);
            }

            // Then publish the assignment
            const publishResponse = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/publish-assignment/`,
                body: {
                    is_assignment_published: true
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            });

            if (publishResponse.status !== 200) {
                throw new Error(`Publish request failed with status: ${publishResponse.status}`);
            }

            // Clear the flag as this assignment is now complete
            localStorage.removeItem('rubricsGenerated');
            localStorage.removeItem('assignment_id');

            // Add a small delay before navigating to ensure state is updated
            setTimeout(() => {
                navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions`);
            }, 500);

        } catch (error) {
            console.error('Error publishing assignment:', error);
            setIsPublishing(false);
            alert('There was an error publishing the assignment. Please try again.');
        }
    };

    // Using localQuestions for display to provide immediate feedback without API calls
    // Get the selected question directly using the selectedQuestionId
    const selectedQuestion = localQuestions?.examPaper?.find(q => q.id === selectedQuestionId);
    // Only look for the subquestion within the selected question
    const selectedSubQuestion = selectedQuestion?.subQuestions?.find(
        sq => sq.id === selectedSubQuestionId
    );
    const currentRubricItems = selectedSubQuestion ? selectedSubQuestion.rubricItems : selectedQuestion?.rubricItems;
    const currentSolution = selectedSubQuestion ? selectedSubQuestion.solution : selectedQuestion?.solution;

    return (
        <div className="rubric-generator">
            <header className="page-header">
                <div className="header-content">
                    <div className="header-left">
                        <a href={`/tclasses/${class_id}/tassignments/${assignment_id}/create/template`}
                           className="back-button">
                            <ArrowLeft size={20}/>
                            <span>Back</span>
                        </a>
                        <h1>Solution Rubric</h1>
                    </div>

                    <div className="header-actions">
                        
                        <div className="edit-toggle">
                            <CheckCircle size={18}/>
                            <span>{calculateTotalPoints(questions?.examPaper)} Total Points</span>
                        </div>

                        <button
                            className="total-points"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <Save size={18}/>
                            <span>Publish Assignment</span>
                        </button>
                    </div>
                </div>
            </header>

            <div className="split-layout">
                <div className="questions-panel">
                    <div className="panel-header">
                        <FileText size={20}/>
                        <h2>Questions</h2>
                    </div>
                    <div className="questions-list">
                        {questions.examPaper.map((question, index) => (
                            <div
                                key={question.id}
                                className="question-item"
                            >
                                <span className="letter">{index + 1}</span>
                                <div className="question-text truncate-text">
                                    {renderQuestionText(question.question)}
                                </div>

                                {question.subQuestions && (
                                    <div className="subquestions-list">
                                        {question.subQuestions.map((sub) => (
                                            <div
                                                key={sub.id}
                                                className={`subquestion-item ${selectedSubQuestionId === sub.id && selectedQuestionId === question.id ? "active" : ""}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedQuestionId(question.id);
                                                    setSelectedSubQuestionId(sub.id);
                                                }}
                                            >
                                                <span className="letter">{sub.id})</span>
                                                <span className="text truncate-text">
                                                    {renderQuestionText(sub.question)}
                                                </span>
                                                <div className="points-indicator">
                                                    {(sub.type === 'mcq' || sub.questionType === 'mcq')
                                                        ? (sub.weight || 1) + ' pts' // Use assigned points or default to 1 for MCQ
                                                        : (sub.rubricItems && sub.rubricItems.length > 0
                                                        ? Math.max(...sub.rubricItems.map(item => parseInt(item.value) || 0))
                                                        : 0) + ' pts'
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="rubric-panel">
                    {!selectedSubQuestionId ? (
                        <div className="empty-state">
                            <FileText size={48}/>
                            <h3>Select a Question</h3>
                            <p>Choose a question from the list to view and edit its rubric details</p>
                        </div>
                    ) : (
                        <div className="rubric-content">
                            <div className="rubric-header">
                                <div className="title-section">
                                    <h2>
                                        {selectedQuestion?.id && `Question ${selectedQuestion.id}`}
                                        {selectedSubQuestion && ` Part ${selectedSubQuestion.id}`}
                                    </h2>
                                    <div className="edit-toggle">
                                        <CheckCircle size={16}/>
                                        <span>
                                            {(selectedSubQuestion?.type === 'mcq' || selectedSubQuestion?.questionType === 'mcq')
                                                ? `${selectedSubQuestion.weight || 1} Points` // Use assigned points or default to 1 for MCQ
                                                : `${currentRubricItems && currentRubricItems.length > 0
                                                    ? Math.max(...currentRubricItems.map(item => parseInt(item.value) || 0))
                                                    : 0} Points`
                                            }
                                        </span>
                                    </div>
                                </div>
                                {(selectedSubQuestion?.question || selectedQuestion?.question) && (
                                    <div className="question-text text-size-medium">
                                        <TruncatableText
                                            text={selectedSubQuestion ?
                                                selectedQuestion.question + (selectedQuestion?.question && '\n\nSub Question:') + selectedSubQuestion.question :
                                                selectedQuestion.question}
                                            maxLength={200}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="grading-criteria">
                                <h3>Grading Criteria</h3>
                                <div className="criteria-list">
                                    {/* Display special message for MCQ questions */}
                                    {(selectedSubQuestion?.type === 'mcq' || selectedSubQuestion?.questionType === 'mcq') ? (
                                        <div className="mcq-criteria-info">
                                            <p>This is a multiple choice question. Points will be awarded automatically
                                                when correct option(s) are selected.</p>
                                        </div>
                                    ) : (
                                        <>
                                            {currentRubricItems?.map((item, index) => (
                                                <div key={index} className="criteria-item">
                                                    <div className="points">
                                                        <div className="points-control">
                                                            <button
                                                                className="points-btn"
                                                                onClick={() => handleRubricItemPointsChange(
                                                                    selectedQuestion.id,
                                                                    selectedSubQuestion?.id,
                                                                    index,
                                                                    Math.max(0, parseInt(item.value) - 1).toString()
                                                                )}
                                                            >
                                                                <Minus size={14}/>
                                                            </button>
                                                            <span className="points-value">{item.value}</span>
                                                            <button
                                                                className="points-btn"
                                                                onClick={() => handleRubricItemPointsChange(
                                                                    selectedQuestion.id,
                                                                    selectedSubQuestion?.id,
                                                                    index,
                                                                    (parseInt(item.value) + 1).toString()
                                                                )}
                                                            >
                                                                <Plus size={14}/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="description">
                                                        {(editingRubricItem &&
                                                            editingRubricItem.questionId === selectedQuestion.id &&
                                                            editingRubricItem.subQuestionId === selectedSubQuestion?.id &&
                                                            editingRubricItem.index === index) ? (
                                                            <textarea
                                                                ref={el => {
                                                                    const refKey = `${selectedQuestion.id}-${selectedSubQuestion?.id || ''}-${index}`;
                                                                    textareaRefs.current[refKey] = el;
                                                                    if (el) {
                                                                        adjustTextareaHeight(el);
                                                                        // Focus the textarea when editing starts
                                                                        el.focus();
                                                                    }
                                                                }}
                                                                value={localQuestions?.examPaper ?
                                                                    (selectedSubQuestion ?
                                                                        localQuestions.examPaper.find(q => q.id === selectedQuestion.id)?.subQuestions.find(sq => sq.id === selectedSubQuestion.id)?.rubricItems[index]?.descriptor :
                                                                        localQuestions.examPaper.find(q => q.id === selectedQuestion.id)?.rubricItems[index]?.descriptor) :
                                                                    item.descriptor}
                                                                placeholder="Enter grading criteria description... (supports LaTeX using $...$ for inline math)"
                                                                onChange={(e) => {
                                                                    // Update local state for immediate feedback
                                                                    updateLocalRubricItemDescription(
                                                                        selectedQuestion.id,
                                                                        selectedSubQuestion?.id,
                                                                        index,
                                                                        e.target.value
                                                                    );
                                                                    adjustTextareaHeight(e.target);
                                                                }}
                                                                onBlur={(e) => {
                                                                    // Only update questions state (and trigger API call) when user is done typing
                                                                    handleRubricItemDescriptionChange(
                                                                        selectedQuestion.id,
                                                                        selectedSubQuestion?.id,
                                                                        index,
                                                                        e.target.value
                                                                    );
                                                                    setEditingRubricItem(null); // Exit edit mode
                                                                }}
                                                            />
                                                        ) : (
                                                            <div
                                                                className="rubric-item-preview cursor-text"
                                                                onClick={() => setEditingRubricItem({
                                                                    questionId: selectedQuestion.id,
                                                                    subQuestionId: selectedSubQuestion?.id,
                                                                    index
                                                                })}
                                                            >
                                                                {item.descriptor ? renderLatexContent(item.descriptor) :
                                                                    <span className="text-gray-400">Click to add rubric criteria...</span>}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <button
                                                        className="delete-criteria-btn"
                                                        disabled={currentRubricItems.length <= 1}
                                                        onClick={() => handleRemoveRubricItem(
                                                            selectedQuestion.id,
                                                            selectedSubQuestion?.id,
                                                            index
                                                        )}
                                                    >
                                                        <Trash2 size={16}/>
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                className="add-criteria-btn"
                                                onClick={() => handleAddRubricItem(
                                                    selectedQuestion.id,
                                                    selectedSubQuestion?.id
                                                )}
                                            >
                                                <Plus size={16}/>
                                                <span>Add Criteria</span>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>

                            {currentSolution && (
                                <div className="solution-section">
                                    <div className="solution-header">
                                        <h3>
                                            <BookOpen size={20}/>
                                            Detailed Solution
                                        </h3>
                                        <p className="solution-description">
                                            Step-by-step solution guide with mathematical explanations
                                        </p>
                                    </div>
                                    <div className="solution-content">
                                        {isEditing ? (
                                            <div className="border rounded-3xl">
                                                <textarea
                                                    ref={el => {
                                                        const refKey = selectedSubQuestion
                                                            ? `solution-${selectedQuestion.id}-${selectedSubQuestion.id}`
                                                            : `solution-${selectedQuestion.id}`;
                                                        textareaRefs.current[refKey] = el;
                                                        activeSolutionTextareaRef.current = el;
                                                        if (el) {
                                                            adjustTextareaHeight(el);
                                                            // Focus the textarea when it's mounted while in editing mode
                                                            el.focus();

                                                            if (currentSolution) {
                                                                el.selectionStart = el.selectionEnd = currentSolution.length;
                                                            }
                                                        }
                                                    }}
                                                    value={currentSolution}
                                                    placeholder="Enter solution guide (supports LaTeX using $...$ for inline math)..."
                                                    className="solution-textarea w-full p-3"
                                                    onBlur={() => setIsEditing(false)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setQuestions(prev => ({
                                                            ...prev,
                                                            examPaper: prev.examPaper.map(q => {
                                                                if (q.id === selectedQuestion.id) {
                                                                    if (selectedSubQuestion) {
                                                                        const updatedSubQuestions = q.subQuestions.map(sq => {
                                                                            if (sq.id === selectedSubQuestion.id) {
                                                                                return {...sq, solution: value};
                                                                            }
                                                                            return sq;
                                                                        });
                                                                        return {
                                                                            ...q,
                                                                            subQuestions: updatedSubQuestions
                                                                        };
                                                                    } else {
                                                                        return {...q, solution: value};
                                                                    }
                                                                }
                                                                return q;
                                                            })
                                                        }));
                                                        adjustTextareaHeight(e.target);
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="solution-preview p-3 border rounded cursor-text rounded-3xl"
                                                onClick={() => setIsEditing(true)}
                                            >
                                                {currentSolution ? renderLatexContent(currentSolution) :
                                                    <span className="text-gray-400">Click to add a solution...</span>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <ConfirmationModal
                isOpen={isModalOpen}
                message="Publishing this assignment will prevent you from making changes to it. The assignment will become visible to students and allow their submissions."
                onClose={() => setIsModalOpen(false)}
                onConfirm={() => {
                    publishAssignment();
                    setIsModalOpen(false);
                }}
            />
        </div>
    );
}