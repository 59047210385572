import React, {useState, useEffect, useRef} from 'react';
import LaTeXPreviewField from "../../LaTeXPreviewField";
import {Formik, Form} from 'formik';

const TGroupAIRegrade = ({subQuestion, isEssay, onClose, onSubmit, question}) => {
    const [localRubricItems, setLocalRubricItems] = useState([]);
    const [solution, setSolution] = useState('');
    const [isSolutionVisible, setIsSolutionVisible] = useState(true);
    const [regradeInstructions, setRegradeInstructions] = useState('');
    const [isRegradeInstructionsVisible, setIsRegradeInstructionsVisible] = useState(true);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isClosing, setIsClosing] = useState(false);
    const modalRef = useRef(null);

    useEffect(() => {
        // Animation entrance effect
        if (modalRef.current) {
            modalRef.current.style.opacity = 0;
            modalRef.current.style.transform = 'translateY(20px)';

            // Add to DOM and then animate in
            setTimeout(() => {
                if (modalRef.current) {
                    modalRef.current.style.transition = 'opacity 0.3s ease, transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)';
                    modalRef.current.style.opacity = 1;
                    modalRef.current.style.transform = 'translateY(0)';
                }
            }, 10);
        }

        // Sort rubric items by value (points) in descending order
        const sortedRubricItems = [...subQuestion.rubricItems]
            .sort((a, b) => b.value - a.value);
        
        setLocalRubricItems(JSON.parse(JSON.stringify(sortedRubricItems)));
        setSolution(subQuestion.solution || '');
        setRegradeInstructions(subQuestion.regrade_instructions);
        
        // Set initial height for textareas on component mount
        setTimeout(() => {
            document.querySelectorAll('textarea').forEach(textarea => {
                textarea.style.height = 'auto';
                textarea.style.height = `${Math.max(38, textarea.scrollHeight)}px`;
            });
        }, 50);
    }, [subQuestion]);

    useEffect(() => {
        const isValid = localRubricItems.length >= 2 &&
            localRubricItems.every(item =>
                item.descriptor.trim() !== '' &&
                Number.isInteger(item.value) &&
                item.value >= 0
            );
        setIsSubmitDisabled(!isValid);
    }, [localRubricItems]);

    const handleRubricChange = (index, field, value) => {
        const newRubricItems = [...localRubricItems];
        if (field === 'value') {
            const numValue = parseInt(value);
            newRubricItems[index][field] = isNaN(numValue) ? '' : numValue;
        } else {
            newRubricItems[index][field] = value;
        }
        setLocalRubricItems(newRubricItems);
    };

    const addRubricItem = () => {
        setLocalRubricItems([...localRubricItems, {descriptor: '', value: ''}]);
    };

    const deleteRubricItem = (index) => {
        const newRubricItems = localRubricItems.filter((_, i) => i !== index);
        setLocalRubricItems(newRubricItems);
    };

    const handleCloseWithAnimation = () => {
        setIsClosing(true);
        if (modalRef.current) {
            modalRef.current.style.opacity = 0;
            modalRef.current.style.transform = 'translateY(20px)';
        }

        // Delay actual closing to allow animation to complete
        setTimeout(() => {
            onClose();
        }, 300);
    };

    const handleSubmit = () => {
        setIsClosing(true);
        if (modalRef.current) {
            modalRef.current.style.opacity = 0;
            modalRef.current.style.transform = 'translateY(20px)';
        }

        // Delay actual submission to allow animation to complete
        setTimeout(() => {
            onSubmit(localRubricItems, regradeInstructions, "", solution);
        }, 300);
    };

    return (
        <div
            className="fixed inset-0 flex justify-center items-center z-50"
            style={{
                transition: 'background-color 0.3s ease',
                backgroundColor: `rgba(0, 0, 0, ${isClosing ? 0 : 0.5})`
            }}
        >
            <div
                ref={modalRef}
                className="bg-white p-6 rounded-xl shadow-lg max-w-3xl w-full max-h-[90vh] flex flex-col"
            >
                <h2 className="text-2xl font-bold mb-4">Edit Rubric/Solution</h2>

                <div className="ai-regrade-warning p-3 mb-4 bg-amber-50 border border-amber-200 rounded-md">
                    <h3 className="text-sm font-semibold text-amber-700 mb-1 flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                             stroke="currentColor"
                             className="w-4 h-4 transition-all duration-300 hover:rotate-12 hover:text-amber-600 hover:scale-110">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                        </svg>
                        Warning
                    </h3>
                    <div className="text-amber-700 text-xs">
                        Modifying this rubric will trigger AI regrading of this specific subquestion for all students. Any manual grades for this subquestion will be OVERWRITTEN.
                    </div>
                </div>

                <div className="flex-grow overflow-y-auto">
                    {/* Question Display Section */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Question</h3>
                        <Formik
                            initialValues={{
                                question: (
                                    (question.description ? question.description : "") + 
                                    (question.description && subQuestion.question ? "\n\n" : "") + 
                                    (subQuestion.question ? subQuestion.question : "")
                                ) || ""
                            }}>
                            {() => (
                                <Form>
                                    <LaTeXPreviewField
                                        name="question"
                                        disabled={true}
                                        className="bg-gray-50 p-3 rounded"
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Rubric Items</h3>
                        {localRubricItems.map((item, index) => (
                            <div key={index} className="flex mb-2 items-start">
                                <textarea
                                    value={item.descriptor}
                                    className="sexy-border rounded px-2 py-1 flex-grow mr-2 min-h-[38px] resize-y overflow-hidden"
                                    placeholder="Descriptor"
                                    onChange={(e) => {
                                        handleRubricChange(index, 'descriptor', e.target.value);
                                        // Auto-resize the textarea based on content
                                        e.target.style.height = 'auto';
                                        e.target.style.height = `${Math.max(38, e.target.scrollHeight)}px`;
                                    }}
                                    onFocus={(e) => {
                                        // Set initial height when focused
                                        e.target.style.height = 'auto';
                                        e.target.style.height = `${Math.max(38, e.target.scrollHeight)}px`;
                                    }}
                                    rows="1"
                                />
                                <input
                                    type="number"
                                    value={item.value}
                                    className="sexy-border rounded px-2 py-1 w-16 mr-2 h-[38px]"
                                    placeholder="Pts"
                                    min="0"
                                    step="1"
                                    onChange={(e) => handleRubricChange(index, 'value', e.target.value)}
                                />
                                <button className="p-1 mt-1" onClick={() => deleteRubricItem(index)}>
                                    <img src="/assets/trash.png" alt="Delete" className="w-4 h-4"/>
                                </button>
                            </div>
                        ))}
                        <button className="less-sexy-button mt-2" onClick={addRubricItem}>
                            Add Rubric Item
                        </button>
                    </div>

                    <button
                        className="flex items-center text-sm mb-2 mt-2"
                        onClick={() => setIsRegradeInstructionsVisible(!isRegradeInstructionsVisible)}
                    >
                        <img
                            src="/assets/back_arrow.png"
                            className={`size-5 opacity-60 transform ${isRegradeInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                            alt="Toggle regrading instructions"
                        />
                        Edit Regrading Instructions (optional)
                    </button>

                    {isRegradeInstructionsVisible && (
                        <div className="mb-4">
                            <Formik
                                initialValues={{regradeInstructions: regradeInstructions}}
                                enableReinitialize={true}
                                onSubmit={() => {}}
                            >
                                {({setFieldValue}) => (
                                    <Form>
                                        <LaTeXPreviewField
                                            name="regradeInstructions"
                                            rows={4}
                                            placeholder="Enter specific instructions for AI regrading... (supports LaTeX notation with $ symbols)"
                                            onBlur={(e) => {
                                                const value = e.target.value;
                                                setRegradeInstructions(value);
                                            }}
                                        />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    )}

                    <button
                        className="flex items-center text-sm mb-2 mt-2"
                        onClick={() => setIsSolutionVisible(!isSolutionVisible)}
                    >
                        <img
                            src="/assets/back_arrow.png"
                            className={`size-5 opacity-60 transform ${isSolutionVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                            alt="Toggle solution"
                        />
                        Edit Solution (optional)
                    </button>

                    {isSolutionVisible && (
                        <div className="mb-4">
                            <Formik
                                initialValues={{solution: solution}}
                                enableReinitialize={true}
                                onSubmit={() => {
                                }}
                            >
                                {({setFieldValue}) => (
                                    <Form>
                                        <LaTeXPreviewField
                                            name="solution"
                                            rows={6}
                                            placeholder="Enter the solution... (supports LaTeX notation with $ symbols)"
                                            onBlur={(e) => {
                                                const value = e.target.value;
                                                setSolution(value);
                                            }}
                                        />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    )}
                </div>

                <div className="flex justify-end space-x-2 mt-4">
                    <button className="less-sexy-button" onClick={handleCloseWithAnimation}>
                        Cancel
                    </button>
                    <button
                        className={`bg-[rgb(228,177,122)] text-white cursor-pointer flex items-center gap-2 px-4 py-2 text-sm rounded-full font-semibold transition-all duration-300 hover:bg-[rgb(219,152,80)] hover:-translate-y-2 ${isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isSubmitDisabled}
                        onClick={handleSubmit}
                    >
                        Confirm and Regrade
                    </button>
                </div>

                {isSubmitDisabled && (
                    <p className="text-red-500 text-sm mt-2">
                        Please ensure there are at least 2 rubric items, all descriptors are filled, and all point
                        values are set to whole, non-negative integers.
                    </p>
                )}
            </div>
        </div>
    );
};

export default TGroupAIRegrade;