const formatQuestionId = (mainId, subLetter = null, subSubLetter = null) => {
    let formattedId = `Q${mainId}`;
    if (subLetter) {
        formattedId += `(${subLetter}`;
        if (subSubLetter) {
            formattedId += `.${subSubLetter}`;
        }
        formattedId += ')';
    }
    return formattedId;
};

export default formatQuestionId;