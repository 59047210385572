import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { apiUrl, gradeApi } from '../../../api.js';
import { useAuth } from '../../../auth/AuthContext';
import { useQueryParams } from '../../../shared/hooks/use-query-params.tsx';
import { socket } from '../../../socket.js';
import { clearAssignmentStudents, getAssignmentStudents } from '../../../store/teacherSlice.js';
import { DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE } from '../../../utils/pagination.ts';
import ConfirmationModal from '../../ConfirmationModal';
import SUnpublishedFlexibleAssignment
    from '../../Student/SUnpublishedFlexibleAssignment/SUnpublishedFlexibleAssignment';
import AIRegrade from './AIRegrade';
import { PDFView } from '../PDFRendered';
import StudentSearchDropdown from '../TeacherAssignments/TeacherAssignmentSettings/StudentSearchDropdown';
import { BrainIcon, Check, UserCircleIcon } from 'lucide-react';
import { PlusCircleIcon, TrashIcon, XMarkIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { CheckCircle, ArrowRightCircle } from 'lucide-react';


const TQuestionDetails = props => {
    const dispatch = useDispatch();

    const { user } = useAuth();
    const { assignment_id, subquestion_id, submission_id } = useParams();
    const [submissionData, setSubmissionData] = useState({});
    const [examPaper, setExamPaper] = useState([]);
    const [feedbackItems, setFeedbackItems] = useState({});
    const [editableFeedback, setEditableFeedback] = useState({});
    const [message, setMessage] = useState('');
    const [instructorComments, setInstructorComments] = useState({});
    const [isEditingInstructorComment, setIsEditingInstructorComment] = useState({});
    const [editableInstructorComment, setEditableInstructorComment] = useState({});
    const instructorCommentRefs = useRef({});
    const feedbackRefs = useRef({});
    const [assignment, setAssignment] = useState(null);
    const [studentName, setStudentName] = useState('');
    const navigate = useNavigate();
    const [isRegradeOpen, setIsRegradeOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [regradeData, setRegradeData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activeSubQuestionId, setActiveSubQuestionId] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [isSeen, setIsSeen] = useState(false);
    const [assignedStudentId, setAssignedStudentId] = useState(null);
    const [error, setError] = useState(null);
    const [isStudentAssignmentConfirmationOpen, setIsStudentAssignmentConfirmationOpen] =
        useState(false);
    const [newStudentAssignment, setNewStudentAssignment] = useState(null);
    const [regradeInProgress, setRegradeInProgress] = useState({});
    const pollingInterval = useRef(null);
    const [gradePending, setGradePending] = useState(false);
    const [groupStudents, setGroupStudents] = useState([]);
    const [questionMapping, setQuestionMapping] = useState(null);
    const [isMounting, setIsMounting] = useState(false);
    const [viewedCount, setViewedCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(null);
    const [showAllReviewedPopup, setShowAllReviewedPopup] = useState(false);
    const [showNextSubquestionPopup, setShowNextSubquestionPopup] = useState(false);
    const [nextSubQuestionId, setNextSubQuestionId] = useState(null);
    const [isReloadMessageShown, setIsReloadMessageShown] = useState(false);
    const [assignmentDetails, setAssignmentDetails] = useState(null);
    const [teachers, setTeachers] = useState(null);
    const [prevSubmissionId, setPrevSubmissionId] = useState(submission_id);
    const [regradeRequests, setRegradeRequests] = useState([]);
    const [nextRegrade, setNextRegrade] = useState(-1);
    const location = useLocation();
    const [socketApi, setSocketApi] = useState(socket(user.session_token));
    const [isConnected, setIsConnected] = useState(false);
    const [showConnectionErrorPopup, setShowConnectionErrorPopup] = useState(false);
    // Get sorting parameters to maintain order
    const params = new URLSearchParams(location.search);
    const sortKey = params.get('sortKey') || 'submission_date';
    const sortDir = params.get('sortDir') || 'desc';
    const [isRegradeMode, setIsRegradeMode] = useState(false);
    const [sortedRegradeRequests, setSortedRegradeRequests] = useState([]);
    const [currentRegradeIndex, setCurrentRegradeIndex] = useState(-1);
    const [isMarkingViewed, setIsMarkingViewed] = useState(false);
    const { data: students, isLoading: isAssignmentStudentsLoading } = useSelector(
        state => state?.teacherReducer?.assignmentStudents
    );

    const {
        params: { search, pageNumber: scrollPageNumber, itemsPerPage },
        updateSearchParams
    } = useQueryParams({
        search: '',
        pageNumber: DEFAULT_PAGE_NUMBER,
        itemsPerPage: ITEMS_PER_PAGE.default
    });

    useEffect(() => {
        socketApi.on('connect', () => {
            console.log('connected');
            setIsConnected(true);
            setShowConnectionErrorPopup(false);

            socketApi.emit('assignment-join', {
                assignmentId: assignment_id
            });
        });

        socketApi.on('connect_error', err => {
            setShowConnectionErrorPopup(true);
            setTimeout(() => {
                socketApi.connect();
            }, 1000);
        });

        return () => {
            socketApi.emit('assignment-leave', {
                assignmentId: assignment_id
            });

            socketApi.disconnect();
            socketApi.on('disconnect', () => {
                setIsConnected(false);
                setShowConnectionErrorPopup(false);
            });
        };
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const regradeMode = params.get('regradeMode') === 'true';
        setIsRegradeMode(regradeMode);

        if (regradeMode) {
            console.log('Regrade mode is active');
            try {
                const currentIndex = parseInt(params.get('currentIndex'), 10);
                // Get the storage key, using assignment_id for specificity
                const storageKey = `sortedRegradeIds_global`;
                const storedIds = localStorage.getItem(storageKey);
                console.log('storedIds', storedIds);
                console.log('currentIndex', currentIndex);
                console.log('storedIds', storedIds);
                console.log('storageKey', storageKey);

                if (storedIds) {
                    const decodedIds = JSON.parse(storedIds);
                    setSortedRegradeRequests(decodedIds);

                    if (!isNaN(currentIndex) && currentIndex >= 0) {
                        setCurrentRegradeIndex(currentIndex);
                    } else {
                        // If currentIndex is not in URL, try to find it by matching IDs
                        const foundIndex = decodedIds.findIndex(
                            item => item.submissionId === Number(submission_id) &&
                                item.subquestionId === subquestion_id
                        );
                        if (foundIndex !== -1) {
                            setCurrentRegradeIndex(foundIndex);
                        }
                    }
                }
            } catch (e) {
                console.error("Error processing stored IDs:", e);
            }
        }
    }, [location.search, submission_id, subquestion_id, assignment_id]);


    useEffect(() => {
        if (isConnected) {
            socketApi.emit('submission-question-join', {
                fullName: `${user.user.first_name} ${user.user.last_name}`,
                submissionId: submission_id,
                id: user.user.id,
                assignmentId: assignment_id,
                questionId: subquestion_id
            });
            setPrevSubmissionId(submission_id);
        }
    }, [submission_id, isConnected]);

    useEffect(() => {
        if (isConnected) {
            socketApi.on('submission-question-teachers', data => {
                setTeachers(data);
            });

            socketApi.on('assignment-details', data => {
                setAssignmentDetails(data);
                console.log(data, 'assignment-details');
            });

            socketApi.on('submission-question-refresh', data => {
                console.log(data);

                if (user.user.id !== data.id) {
                    setIsReloadMessageShown(true);
                    setTimeout(() => setIsReloadMessageShown(false), 2000);

                    getQuestions();
                    getAssignmentSubmission();
                    getAssignment();
                }
            });

            socketApi.on('errors', data => {
                console.error(data);
            });
        }

        return () => {
            socketApi.removeAllListeners();
        };
    }, [isConnected]);

    useEffect(() => {
        updateSearchParams({
            search: '',
            pageNumber: DEFAULT_PAGE_NUMBER
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (assignment?.is_exam && assignment.course_id && submissionData.user) {
            getStudents();
        }

        return () => dispatch(clearAssignmentStudents());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (assignment?.is_exam && assignment.course_id && submissionData.user && !search) {
            getStudents();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment, submissionData, scrollPageNumber, itemsPerPage]);

    useEffect(() => {
        fetchGroupStudents();
        getPageMapping();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment]);

    // Add this useEffect to fetch regrade requests when the component mounts
    useEffect(() => {
        getRegrades();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.regrade_question_id]);

    // And modify the useEffect to use forceTrue
    useEffect(() => {
        console.log('useEffect', assignment_id, submission_id);
        if (!isSeen && !isMounting) {
            handleMarkSeen(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment_id, submission_id, assignedStudentId, studentName, isMounting, submissionData]);

    useEffect(() => {
        getQuestions();
        getAssignmentSubmission();
        getAssignment();
        return () => stopPolling();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment_id, submission_id, refreshKey]);

    // Update student name when submission data changes
    useEffect(() => {
        if (submissionData.user) {
            setStudentName(`${submissionData.user.first_name} ${submissionData.user.last_name}`);
        }
    }, [submissionData, refreshKey]);

    // Re-fetch data when assignment_id or submission_id changes
    useEffect(() => {
        getAssignmentSubmission();
        getQuestions();
        getAssignment();
    }, [assignment_id, submission_id, refreshKey]);

    useEffect(() => {
        const currentViewedCount = getSubQuestionViewedCount();
        setViewedCount(currentViewedCount);
    }, [examPaper, subquestion_id]);

    const socketSubmissionUpdate = () =>
        socketApi.emit('submission-question-update', {
            submissionId: submission_id,
            assignmentId: assignment_id,
            questionId: subquestion_id,
            id: user.user.id
        });


    // Fetching assignment submission details
    const getAssignmentSubmission = async () => {
        setIsMounting(true);
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/submission/${submission_id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const data = response.data;
            setSubmissionData(data);
            if (data) {
                setFeedbackItems(data.submitted ? data.feedback : {});
                setIsSeen(data.feedback.is_viewed);
                setAssignedStudentId(data.user.id);
                setStudentName(`${data.user.first_name} ${data.user.last_name}`);
            }
        } catch (error) {
            setMessage(error.response.data.error);
        } finally {
            setIsMounting(false);
        }
    };

    const getQuestionMapping = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/question-mapping/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            console.log(response);
            const data = JSON.parse(response.data);
            console.log(JSON.parse(data));
            setQuestionMapping(JSON.parse(data));
        } catch (error) {
            setMessage(error.response.data.error);
        }
    };

    const getStudents = async () => {
        await dispatch(
            getAssignmentStudents({
                classId: assignment.course_id,
                token: user.session_token,
                search,
                pageNumber: Number(pageNumber),
                itemsPerPage: Number(itemsPerPage)
            })
        );

        setAssignedStudentId(submissionData.user.id);
    };

    const handleConfirmStudentAssignment = async () => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/assign-student/`,
                body: { new_student_id: newStudentAssignment.id },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            setAssignedStudentId(newStudentAssignment.id);
            setStudentName(newStudentAssignment.full_name);

            // Refresh the submission data
            getAssignmentSubmission();
            setIsStudentAssignmentConfirmationOpen(false);
        } catch (error) {
            console.error('Failed to assign student:', error);
            setError('Failed to assign student. Please try again.');
        }
    };

    const fetchGroupStudents = async () => {
        try {
            if (!assignedStudentId) {
                return;
            }
            const response = await gradeApi.get({
                path: `/api/assignment/get_students/${assignment.id}/student/${assignedStudentId}`, // Make sure to use assignment.id, not aid
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setGroupStudents(response.data); // Assuming response.data is an array
        } catch (error) {
            console.error('Error fetching group students:', error);
            // Consider setting an error state here instead of using setError
        }
    };

    // replace Mark Reviewed with Mark viewed
    const handleMarkSeen = async (forceTrue = false, shouldNavigate = false) => {
        setIsMarkingViewed(true);
        try {
            // If forceTrue is true, we want to mark it as reviewed regardless
            console.log(isSeen);
            console.log(forceTrue);
            console.log(!isSeen);
            const newReviewedStatus = forceTrue === true ? true : !isSeen;
            console.log(newReviewedStatus);
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/feedback/${subquestion_id}/mark-viewed/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setIsSeen(newReviewedStatus);
            setViewedCount(response.data.viewed_count);

            // Navigate if requested
            if (shouldNavigate === 'next') {
                setTimeout(() => {
                    setIsMarkingViewed(false);
                    clickLeft(); // Navigate to next submission
                }, 300);
            } else if (shouldNavigate === 'nextUnseen') {
                setTimeout(() => {
                    setIsMarkingViewed(false);
                    findNextUnseen(); // Find and navigate to next unseen submission
                }, 300);
            } else {
                setIsMarkingViewed(false);
            }
        } catch (error) {
            console.error('Failed to update review status:', error);
            setMessage('Failed to update review status.');
            setIsMarkingViewed(false);
        }
    };


    const startPolling = () => {
        if (!pollingInterval.current) {
            pollingInterval.current = setInterval(getQuestions, 5000);
        }
    };

    const stopPolling = () => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
            if (!isRegradeOpen) {
                window.location.reload();
            }
        }
    };

    // Modify the getQuestions function to only start polling for the active subquestion
    const getQuestions = async () => {
        try {
            console.log(assignment_id, subquestion_id);
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const questionsFromDB = response.data;
            setExamPaper(questionsFromDB.questions);

            // Check for regrade_in_progress only for the current subquestion
            const newRegradeStatus = {};
            questionsFromDB.questions.forEach(question => {
                question.subQuestions.forEach(subQuestion => {
                    if (subQuestion.id === Number(subquestion_id) && subQuestion.regrade_in_progress) {
                        newRegradeStatus[subQuestion.id] = true;
                    }
                });
            });
            setRegradeInProgress(newRegradeStatus);

            // Only start/stop polling if the current subquestion is being regraded
            if (Object.values(newRegradeStatus).some(status => status)) {
                startPolling();
            } else {
                stopPolling();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getIsDisabledGradingButton = subQuestionId => {
        return !!assignmentDetails?.activeSubmissionsQuestionRegradeRooms.find(
            obj => obj.id !== user.user.id && obj.questionId === subQuestionId
        );
    };

    // Fetching assignment details
    const getAssignment = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/only/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAssignment(response.data);
        } catch (error) {
            setMessage('Failed to fetch assignment details');
        }
    };

    // Modified to handle regrade navigation
    const clickRight = () => {
        if (isRegradeMode && sortedRegradeRequests.length > 0) {
            const prevIndex = (currentRegradeIndex - 1 + sortedRegradeRequests.length) % sortedRegradeRequests.length;
            const prevItem = sortedRegradeRequests[prevIndex];

            // Create a new URL with the updated index but without the full sortedIds
            const params = new URLSearchParams(location.search);
            params.set('currentIndex', prevIndex.toString());

            // Use full URL with correct assignment and course IDs
            const targetUrl = `/tclasses/${prevItem.courseId}/tassignments/${prevItem.assignmentId}/tsubmissions/${prevItem.submissionId}/tquestiondetails/${prevItem.subquestionId}?${params.toString()}`;

            // Navigate to the URL
            window.location.href = targetUrl;
            return;
        }

        // Fall back to regular navigation if no regrade context or no previous regrade found
        if (!props.submissionIds || props.submissionIds.length === 0) {
            console.error('No submissions available for navigation');
            return;
        }

        const currentIndex = props.submissionIds.indexOf(Number(submission_id));
        let prevIndex =
            currentIndex === -1
                ? props.submissionIds.length - 1
                : (currentIndex - 1 + props.submissionIds.length) % props.submissionIds.length;

        navigateToSubmission(prevIndex, -1);
    };

    // Helper function to apply sorting consistently with RegradeRequests
    const applySorting = (items, sortConfig) => {
        if (!sortConfig || !sortConfig.key) {
            return items;
        }

        return [...items].sort((a, b) => {
            // Handle different data types based on the column
            if (sortConfig.key === 'submission_date') {
                // Date comparison
                const dateA = new Date(a.submission_date);
                const dateB = new Date(b.submission_date);
                if (dateA < dateB) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (dateA > dateB) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            } else if (sortConfig.key === 'grade') {
                // Numeric comparison
                const numA = parseFloat(a.grade) || 0;
                const numB = parseFloat(b.grade) || 0;
                return sortConfig.direction === 'asc' ? numA - numB : numB - numA;
            } else if (sortConfig.key === 'studentName') {
                // Student name comparison
                const valueA = `${a.user.first_name} ${a.user.last_name}`;
                const valueB = `${b.user.first_name} ${b.user.last_name}`;
                if (valueA < valueB) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            } else if (sortConfig.key === 'assignment.title') {
                // Assignment title comparison
                const valueA = a.assignment?.title || '';
                const valueB = b.assignment?.title || '';
                if (valueA < valueB) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            } else {
                // Generic string comparison for other fields
                const valueA = a[sortConfig.key] || '';
                const valueB = b[sortConfig.key] || '';
                if (valueA < valueB) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            }
        });
    };


    const getRegrades = async () => {
        try {

            console.log('props', props)
            // Get sorting parameters from URL or localStorage

            // Try to get sort config from URL first
            let sortKey = params.get('sortKey');
            let sortDir = params.get('sortDir');

            // If not in URL, try localStorage
            if (!sortKey || !sortDir) {
                const savedConfig = localStorage.getItem('regradeRequestsSortConfig');
                if (savedConfig) {
                    const config = JSON.parse(savedConfig);
                    sortKey = config.key;
                    sortDir = config.direction;
                }
            }

            // Default sorting if no config is found
            if (!sortKey) {
                sortKey = 'submission_date';
            }
            if (!sortDir) {
                sortDir = 'desc';
            }

            const response = await gradeApi.get({
                path: `/api/regrade-requests/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            // Sort the regrades according to current sort configuration
            let regradesForTeacher = [...response.data];
            regradesForTeacher = applySorting(regradesForTeacher, { key: sortKey, direction: sortDir });

            setRegradeRequests(regradesForTeacher);

            const currentIndex = regradesForTeacher
                .map(item => item.id)
                .indexOf(Number(submission_id));

            if (currentIndex !== -1) {
                const feedback = regradesForTeacher[currentIndex]?.feedback;
                let nextFeedback = -1;
                let found = false;

                for (const feedbackItem in feedback) {
                    if (found && feedback[feedbackItem].regrade_request) {
                        nextFeedback = feedbackItem;
                        setNextRegrade(nextFeedback);
                        break;
                    }
                    if (feedbackItem == subquestion_id) {
                        found = true;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Replace the clickLeft function with this version that preserves sorting
    const clickLeft = () => {
        if (isRegradeMode && sortedRegradeRequests.length > 0) {
            const nextIndex = (currentRegradeIndex + 1) % sortedRegradeRequests.length;
            const nextItem = sortedRegradeRequests[nextIndex];

            // Create a new URL with the updated index but without the full sortedIds
            const params = new URLSearchParams(location.search);
            params.set('currentIndex', nextIndex.toString());

            // Use full URL with correct assignment and course IDs
            const targetUrl = `/tclasses/${nextItem.courseId}/tassignments/${nextItem.assignmentId}/tsubmissions/${nextItem.submissionId}/tquestiondetails/${nextItem.subquestionId}?${params.toString()}`;

            // Navigate to the URL
            window.location.href = targetUrl;
            return;
        }

        // Fall back to regular navigation if no regrade context or no next regrade found
        if (!props.submissionIds || props.submissionIds.length === 0) {
            console.error('No submissions available for navigation');
            return;
        }

        const currentIndex = props.submissionIds.indexOf(Number(submission_id));
        let nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % props.submissionIds.length;

        navigateToSubmission(nextIndex, 1);
    };
    const getInstructorFeedback = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].instructor_text;
        }
        return '';
    };
    const handleAddInstructorFeedback = (subquestionId) => {
        // Set up the editing state for this specific subquestion
        setEditableInstructorComment(prev => ({
            ...prev,
            [subquestionId]: getInstructorFeedback(subquestionId) || ''
        }));

        setIsEditingInstructorComment(prev => ({
            ...prev,
            [subquestionId]: true
        }));

        // Focus on the textarea after it renders
        setTimeout(() => {
            if (instructorCommentRefs.current[subquestionId]) {
                instructorCommentRefs.current[subquestionId].focus();
            }
        }, 0);
    };

    const handleInstructorCommentChange = (subquestionId, value) => {
        setEditableInstructorComment(prev => ({
            ...prev,
            [subquestionId]: value
        }));
    };

    // Function to handle saving instructor comment
    const handleInstructorCommentSave = (subquestionId) => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/instructor-feedback/`,
                body: {
                    subquestionId: subquestionId,
                    instructorText: editableInstructorComment[subquestionId]
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating instructor comment:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            instructor_text: editableInstructorComment[subquestionId]
                        }
                    }));

                    // Clear the editing state
                    setIsEditingInstructorComment(prev => {
                        const { [subquestionId]: _, ...rest } = prev;
                        return rest;
                    });

                    setEditableInstructorComment(prev => {
                        const { [subquestionId]: _, ...rest } = prev;
                        return rest;
                    });

                    socketSubmissionUpdate();
                }
            });
    };
    // Function to cancel instructor comment editing
    const handleInstructorCommentCancel = (subquestionId) => {
        setIsEditingInstructorComment(prev => {
            const { [subquestionId]: _, ...rest } = prev;
            return rest;
        });

        setEditableInstructorComment(prev => {
            const { [subquestionId]: _, ...rest } = prev;
            return rest;
        });
    };

    const isEditingInstructor = (subquestionId) =>
        isEditingInstructorComment.hasOwnProperty(subquestionId);


    const clearEditableFeedback = () => {
        setEditableFeedback({});
    };

    const navigateToSubmission = (startIndex, direction) => {
        if (props.submissionIds.length === 1) {
            return;
        }
        clearEditableFeedback(); // Clear editable feedback before navigation

        let currentIndex = startIndex;
        const maxAttempts = props.submissionIds.length;

        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            const nextSubmissionId = props.submissionIds[currentIndex];
            console.log(props.submissionIds);
            if (nextSubmissionId != null) {
                navigate({
                    pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${nextSubmissionId}/tquestiondetails/${subquestion_id}`,
                    search: `?${createSearchParams({
                        search,
                        pageNumber: scrollPageNumber,
                        itemsPerPage
                    })}`
                });
                window.location.reload();
                return;
            }
            currentIndex =
                (currentIndex + direction + props.submissionIds.length) % props.submissionIds.length;
        }

        console.error('No valid submissions found to navigate to');
    };

    const hasRubricItem = (subquestionId, rubricId) => {
        if (!feedbackItems) {
            return false;
        }
        if (!subquestionId) {
            return false;
        }
        if (subquestionId in feedbackItems) {
            let rubricItems = feedbackItems[subquestionId]?.rubric_items;
            for (let i = 0; i < rubricItems.length; i++) {
                if (rubricItems[i].id === rubricId) {
                    return true;
                }
            }
        }
        return false;
    };

    const getFeedback = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].feedback;
        }
        return '';
    };

    const getRR = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].regrade_request;
        }
        return '';
    };

    const getRRText = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].regrade_text;
        }
        return '';
    };

    const handleRubricItemChange = (subquestionId, rubricItem) => () => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/grade/`,
                body: {
                    subquestionId: subquestionId,
                    rubric: rubricItem
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                // console.log(error);
                setGradePending(false);
            })
            .then(response => {
                setFeedbackItems(response.data.feedback);
                setSubmissionData({ ...submissionData, grade: response.data.grade });
                setGradePending(false);
                socketSubmissionUpdate();
            });
    };

    const handleFeedbackEdit = subquestionId => {
        setEditableFeedback(prev => ({
            ...prev,
            [subquestionId]: getFeedback(subquestionId) || ''
        }));
    };

    const handleFeedbackChange = (subquestionId, value) => {
        setEditableFeedback(prev => ({
            ...prev,
            [subquestionId]: value
        }));
    };

    const handleFeedbackSave = subquestionId => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/feedback/`,
                body: {
                    subquestionId: subquestionId,
                    feedback: editableFeedback[subquestionId]
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating feedback:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            feedback: editableFeedback[subquestionId]
                        }
                    }));

                    setEditableFeedback(prev => {
                        const { [subquestionId]: _, ...rest } = prev;
                        return rest;
                    });
                    socketSubmissionUpdate();

                    // console.log('Feedback updated successfully:', response.data);
                }
            });
    };

    const isEditing = subquestionId => editableFeedback.hasOwnProperty(subquestionId);

    const handleRegradeReq = subquestionId => {
        if (subquestionId in feedbackItems) {
            handleRegradeReqDel(subquestionId);
        }
    };

    const handleRegradeReqDel = subquestionId => {
        const submissionID = submissionData.id;

        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submissionID}/regrade-request/`,
                body: {
                    subquestionId: subquestionId,
                    regradeRequest: false
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating regrade request:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            regrade_request: false
                        }
                    }));

                    // console.log('Regrade request updated successfully:', response.data);
                }
            });
    };

    const renderFeedback = feedback => {
        return <div className="mb-2">{feedback}</div>;
    };

    const findNextSubquestion = () => {
        let currentSubQuestionFound = false;
        let foundNextSubQuestionId = null;

        for (const question of examPaper) {
            for (const subQuestion of question.subQuestions) {
                if (currentSubQuestionFound) {
                    foundNextSubQuestionId = subQuestion.id;
                    break;
                }
                if (subQuestion.id === Number(subquestion_id)) {
                    currentSubQuestionFound = true;
                }
            }
            if (foundNextSubQuestionId) {
                break;
            }
        }

        setNextSubQuestionId(foundNextSubQuestionId);
        setShowNextSubquestionPopup(true);

        setTimeout(() => {
            setShowNextSubquestionPopup(false);
            if (foundNextSubQuestionId) {
                navigate({
                    pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${submission_id}/tquestiondetails/${foundNextSubQuestionId}`,
                    search: `?${createSearchParams({
                        search,
                        pageNumber: scrollPageNumber,
                        itemsPerPage
                    })}`
                });
                window.location.reload();
            } else {
                navigate(`/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions`);
                window.location.reload();
            }
        }, 1000);
    };

    const findNextUnseen = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/submissions/next-unseen/${subquestion_id}/?current_submission_id=${submission_id}`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (response.data.next_submission_id === -1) {
                let currentSubQuestionFound = false;
                let nextSubQuestionId = null;
                for (const question of examPaper) {
                    for (const subQuestion of question.subQuestions) {
                        if (currentSubQuestionFound) {
                            nextSubQuestionId = subQuestion.id;
                            break;
                        }
                        if (subQuestion.id === Number(subquestion_id)) {
                            currentSubQuestionFound = true;
                        }
                    }
                    if (nextSubQuestionId) {
                        break;
                    }
                }
                if (nextSubQuestionId) {
                    setShowAllReviewedPopup(true);
                    // If there's a next subquestion, navigate to it
                    setTimeout(() => {
                        setShowAllReviewedPopup(false);
                        navigate({
                            pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${submission_id}/tquestiondetails/${nextSubQuestionId}`,
                            search: `?${createSearchParams({
                                search,
                                pageNumber: scrollPageNumber,
                                itemsPerPage
                            })}`
                        });
                        window.location.reload();
                    }, 1000);
                } else {
                    // Show popup
                    setShowAllReviewedPopup(true);
                    // Wait 3 seconds then navigate
                    setTimeout(() => {
                        setShowAllReviewedPopup(false);
                        navigate(`/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions`);
                    }, 1500);
                }
            } else if (
                assignmentDetails?.activeSubmissionsQuestionRooms.includes(
                    String(response.data.next_submission_id)
                )
            ) {
                return findNextUnseen();
            } else {
                // Navigate to next unseen submission
                navigate({
                    pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${response.data.next_submission_id}/tquestiondetails/${subquestion_id}`,
                    search: `?${createSearchParams({
                        search,
                        pageNumber: scrollPageNumber,
                        itemsPerPage
                    })}`
                });
            }
        } catch (error) {
            console.error('Error finding next unseen submission:', error);
        }
    };

    const [isEditingEssay, setIsEditingEssay] = useState(false);
    const [editableEssayFeedback, setEditableEssayFeedback] = useState('');
    const feedbackRef = useRef(null);

    const handleEssayFeedbackChange = value => {
        setEditableEssayFeedback(value);
    };

    const handleEssayFeedbackEdit = () => {
        setEditableEssayFeedback(submissionData?.file?.filename || '');
        setIsEditingEssay(true);
    };

    const handleEssayFeedbackSave = () => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/update-feedback/${submission_id}`,
                body: {
                    feedback: editableEssayFeedback
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating feedback:', error);
            })
            .then(response => {
                if (response) {
                    submissionData.file.filename = editableEssayFeedback;
                    console.log(submissionData);

                    setIsEditingEssay(false);

                    // Optionally refetch the submission data if necessary
                    getAssignmentSubmission();

                    socketSubmissionUpdate();

                    // console.log('Feedback updated successfully:', response.data);
                }
            });
    };

    const handleRegradeClick = subQuestionId => {
        setActiveSubQuestionId(subQuestionId);
        setIsRegradeOpen(true);

        socketApi.emit('submission-question-regrade-join', {
            submissionId: submission_id,
            id: user.user.id,
            assignmentId: assignment_id,
            questionId: subQuestionId
        });
    };

    const handleRegradeClose = () => {
        setIsRegradeOpen(false);
        setActiveSubQuestionId(null);
        socketApi.emit('submission-question-regrade-leave', {
            submissionId: submission_id,
            id: user.user.id,
            assignmentId: assignment_id,
            questionId: activeSubQuestionId
        });
        window.location.reload();
    };

    const handleRegradeSubmit = (
        newRubric,
        regradeInstructions,
        regradeFeedbackInstructions,
        solution
    ) => {
        setRegradeData({ newRubric, regradeInstructions, regradeFeedbackInstructions, solution });
        setIsConfirmationOpen(true);
    };

    const handleConfirmRegrade = async () => {
        setIsConfirmationOpen(false);
        setIsLoading(true);
        try {
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/ai-regrade/`,
                body: {
                    subQuestionId: activeSubQuestionId,
                    newRubric: regradeData.newRubric,
                    regradeInstructions: regradeData.regradeInstructions,
                    regradeFeedbackInstructions: regradeData.regradeFeedbackInstructions,
                    solution: regradeData.solution
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log('AI-Regrade initiated:', response.data);
            getQuestions(); // Fetch the updated questions
            socketApi.emit('submission-question-regrade-leave', {
                submissionId: submission_id,
                id: user.user.id,
                assignmentId: assignment_id,
                questionId: activeSubQuestionId
            });
            window.location.reload();
            setMessage('AI-Regrade initiated. The system will update the grades shortly.');
        } catch (error) {
            console.error('AI-Regrade failed:', error);
            setMessage('AI-Regrade failed. Please try again.');
        } finally {
            setIsLoading(false);
            setIsRegradeOpen(false);
            setActiveSubQuestionId(null);
        }
    };

    const SortRubricItemsAndDisplay = ({ subQuestion }) => {
        subQuestion.rubricItems.sort((a, b) => {
            if (a.value == b.value) {
                return 0;
            }
            return a.value > b.value ? -1 : 1;
        });
        return (
            <div className="rounded-xl overflow-hidden border border-gray-300">
                {subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
                    <div
                        key={rubricIndex}
                        className={`
                        flex items-center px-3 py-2 cursor-pointer
                        ${hasRubricItem(subQuestion.id, rubricItem.id)
                                ? 'bg-orange-200'
                                : 'bg-white hover:bg-gray-50'
                            }
                        ${rubricIndex !== 0 ? 'border-t border-gray-300' : ''}
                    `}
                        onClick={handleRubricItemChange(subQuestion.id, rubricItem)}
                    >
                        <div className="flex items-center flex-1">
                            <div
                                className={`
                            w-5 h-5 rounded-full border border-gray-400 
                            flex items-center justify-center mr-3
                            ${hasRubricItem(subQuestion.id, rubricItem.id)
                                        ? 'bg-gray-500 border-gray-700'
                                        : ''
                                    }
                        `}
                            >
                                {hasRubricItem(subQuestion.id, rubricItem.id) && (
                                    <Check className="w-3 h-3 text-white stroke-2" />
                                )}
                            </div>
                            <div className="flex items-center">
                                <span className="text-xl font-medium mr-3">{rubricItem.value}</span>
                                <span className="text-sm">{rubricItem.descriptor}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const HasSubQuestion = questionId => {
        for (const question of examPaper) {
            if (question.id !== questionId) {
                continue;
            }
            for (const subQuestion of question.subQuestions) {
                if (subQuestion.id === Number(subquestion_id)) {
                    return true;
                }
            }
        }
        return false;
    };

    const getSubQuestionViewedCount = () => {
        for (const question of examPaper) {
            for (const subQuestion of question.subQuestions) {
                if (subQuestion.id === Number(subquestion_id)) {
                    return subQuestion.viewed_count || 0;
                }
            }
        }
        return 0;
    };

    const getPageMapping = () => {
        getQuestionMapping();
        let num = 1;
        console.log('examPaper', examPaper);
        console.log(subquestion_id);
        console.log(questionMapping);
        for (const question of examPaper) {
            for (const subQuestion of question.subQuestions) {
                console.log(subQuestion.id, subquestion_id);
                console.log(String(subQuestion.id) === String(subquestion_id))
                console.log(num)
                if (String(subQuestion.id) === String(subquestion_id)) {
                    const mappedPage = questionMapping?.[String(num)];
                    setPageNumber(mappedPage); // Set the page number when we find it
                    return mappedPage;
                }
                num++;
            }
        }
    };

    return (
        <div
            key={`${assignment_id}-${submission_id}-${refreshKey}`}
            className="flex flex-col mt-5 w-full overflow-x-hidden"
        >
            <style>{`
    @keyframes fade-in {
        0% {
            opacity: 0;
            transform: scale(0.95);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    .animate-fade-in {
        animation: fade-in 0.3s ease-out forwards;
    }
    .backdrop-blur-sm {
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
    }
`}</style>
            {/* navigation trail */}
            <div className="flex flex-col fixed w-full py-7 bg-white px-5">
                <div className="flex items-center justify-between mb-4">
                    {/* Replace the current navigation trail div with this conditional rendering */}
                    <div className="flex items-center space-x-2">
                        {isRegradeMode ? (
                            /* Regrade mode navigation trail */
                            <>
                                <Link to={`/regrade/`}
                                    className="text-black text-lg font-medium ml-4">
                                    Regrade Requests
                                </Link>
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back" />
                                <h1 className="text-black text-lg font-medium ml-4">
                                    {studentName.length > 15 ? studentName.substring(0, 12) + '...' : studentName}
                                    {' '}
                                    <span
                                        className="inline-flex items-center bg-amber-100 text-amber-700 px-2.5 py-1 rounded-md text-sm font-medium ml-2">
                                        {examPaper.flatMap((question, qIndex) =>
                                            question.subQuestions.map((subQuestion, subIndex) =>
                                                subQuestion.id === Number(subquestion_id) ?
                                                    `Q${qIndex + 1}${String.fromCharCode(97 + subIndex)}` : null
                                            )
                                        ).filter(Boolean)[0] || `Q${subquestion_id}`}
                                    </span>
                                </h1>
                            </>
                        ) : (
                            /* Regular navigation trail */
                            <>
                                <Link to="/tclasses" className="text-black text-lg font-medium ml-4">
                                    My Classes
                                </Link>
                                <Link to={`/tclasses/${assignment?.course_id}/tassignments`}>
                                    <button>
                                        <img src="/assets/back_arrow.png" className="size-6" alt="Back" />
                                    </button>
                                </Link>
                                <Link
                                    to={`/tclasses/${assignment?.course_id}/tassignments`}
                                    className="text-black text-lg font-medium ml-4"
                                >
                                    {assignment?.course?.name.length > 20
                                        ? assignment?.course?.name.substring(0, 17) + '...'
                                        : assignment?.course?.name}
                                </Link>
                                <Link
                                    to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
                                >
                                    <button>
                                        <img src="/assets/back_arrow.png" className="size-6" alt="Back" />
                                    </button>
                                </Link>
                                <Link
                                    to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
                                    className="text-black text-lg font-medium ml-4"
                                >
                                    {assignment?.title.length > 20
                                        ? assignment?.title.substring(0, 17) + '...'
                                        : assignment?.title}
                                </Link>
                                <Link
                                    to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
                                >
                                    <img src="/assets/back_arrow.png" className="size-6" alt="Back" />
                                </Link>
                                <h1 className="text-black text-lg font-medium ml-4">
                                    {studentName.length > 15 ? studentName.substring(0, 12) + '...' : studentName}
                                </h1>
                            </>
                        )}
                    </div>

                    {isReloadMessageShown && (
                        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                            Change detected, reloading...
                        </div>
                    )}

                    <div className="flex items-center justify-between">
                        {teachers &&
                            teachers.entities?.length > 0 &&
                            (teachers?.entities?.length <= 3 ? (
                                (teachers?.entities ?? []).map(t => (
                                    <div key={t.id} className="relative flex items-center space-x-2 ml-2">
                                        <span
                                            id={`teacher${t.id}`}
                                            className={`relative cursor-pointer text-lg w-7 h-7 rounded-full border border-gray-400 
                            flex items-center justify-center p-4 ${t.id === user.user.id ? 'border-orange-200' : 'border-gray-400'}`}
                                        >
                                            {t.fullName[0].toUpperCase()}
                                        </span>
                                        <Tooltip anchorSelect={`#teacher${t.id}`} place="bottom">
                                            {t.fullName}
                                        </Tooltip>
                                    </div>
                                ))
                            ) : (
                                <>
                                    {(teachers?.entities?.slice(0, 3) ?? []).map(t => (
                                        <div key={t.id} className="relative flex items-center space-x-2 ml-2">
                                            <span
                                                id={`teacher${t.id}`}
                                                className={`relative cursor-pointer text-lg w-7 h-7 rounded-full border border-gray-400 
                            flex items-center justify-center p-4 ${t.id === user.user.id ? 'border-orange-200' : 'border-gray-400'}`}
                                            >
                                                {t.fullName[0].toUpperCase()}
                                            </span>
                                            <Tooltip anchorSelect={`#teacher${t.id}`} place="bottom">
                                                {t.fullName}
                                            </Tooltip>
                                        </div>
                                    ))}
                                    <div className="relative flex items-center space-x-2 ml-2">
                                        <span
                                            id="teachers-hidden"
                                            className={`relative cursor-pointer text-lg w-7 h-7 rounded-full border border-gray-400 flex items-center justify-center p-4`}
                                        >
                                            ...
                                        </span>
                                        <Tooltip anchorSelect="#teachers-hidden" place="bottom">
                                            {(teachers?.entities?.slice(3) ?? []).map(t => (
                                                <div key={t.id} className="flex gap-2 items-center justify-center">
                                                    <p>{t.fullName}</p>
                                                    {t.role === 'owner' ? (
                                                        <span
                                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-red-100 text-red-800">
                                                            I
                                                        </span>
                                                    ) : (
                                                        <span
                                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-green-100 text-green-800">
                                                            C
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                        </Tooltip>
                                    </div>
                                </>
                            ))}
                        <div className="flex items-center space-x-2 ml-4">
                            <div className="flex items-center space-x-2 mr-5">
                                {!isRegradeMode && (
                                    <div className="text text-gray-600 mr-10">
                                        {viewedCount}/{assignment?.num_submissions || 0} manually reviewed
                                    </div>
                                )}
                                <button
                                    className="text-gray-600 text-l font-medium flex items-center"
                                    onClick={clickRight}
                                >
                                    <span>Back</span>
                                    <i className="fas fa-sort-up -rotate-90 text-2xl ml-3"></i>
                                </button>
                                <button
                                    className="text-gray-600 text-l font-medium flex items-center"
                                    onClick={clickLeft}
                                >
                                    <i className="fas fa-sort-up rotate-90 text-2xl mr-2"></i>
                                    <span>Next</span>
                                </button>
                            </div>

                            <div className="flex items-center space-xx-4">
                                {!isRegradeMode && (
                                    <button
                                        className="text-gray-600 text-md hover:bg-gray-200 hover:text-gray-800 font-medium flex items-center border border-black rounded-full p-2"
                                        onClick={findNextSubquestion}
                                    >
                                        <span>{`Next ${assignment?.type === 'flexible_problem_set' ? 'Question' : 'Sub Question'}`}</span>
                                        <div className="ml-2 flex flex-row transform rotate-90">
                                            <i className="fas fa-play text-sm"></i>
                                        </div>
                                    </button>
                                )}
                                {!isRegradeMode && (
                                    <button
                                        className="text-gray-600 text-l hover:bg-gray-200 hover:text-gray-800 font-medium flex items-center border border-black rounded-full p-2"
                                        onClick={findNextUnseen}
                                    >
                                        <span>Next Unseen</span>
                                        <div className="ml-2 flex flex-row">
                                            <i className="fas fa-play text-sm"></i>
                                            <i className="fas fa-play text-sm"></i>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* main content */}
            <div className="flex h-[calc(100vh-9rem)] mt-[7rem]">
                {/* student work */}
                <div className="w-1/2 p-4">
                    {submissionData?.submitted &&
                        (submissionData.essay_text ? (
                            <div className="w-full h-full overflow-auto bg-white border border-gray-300 p-4 rounded-xl">
                                <pre className="whitespace-pre-wrap font-sans">{submissionData.essay_text}</pre>
                            </div>
                        ) : (
                            <div
                                className="ml-5 mr-5 w-1/2 p-4 h-[calc(100vh-10rem)] min-w-full border border-gray-300 rounded-xl">
                                {' '}
                                {/* Added min-width */}
                                {submissionData?.submitted &&
                                    (submissionData.essay_text ? (
                                        <div className="w-full h-full overflow-auto bg-white p-4">
                                            <pre className="whitespace-pre-wrap font-sans">
                                                {submissionData.essay_text}
                                            </pre>
                                        </div>
                                    ) : submissionData.assignment?.type === 'flexible_problem_set' ? (
                                        <div
                                            className="bg-white h-full border border-gray-200 rounded-lg shadow-sm p-6 overflow-auto mt-[10px]">
                                            <SUnpublishedFlexibleAssignment
                                                isReadMode
                                                assignment_id={submissionData.assignment.id}
                                                assignment={submissionData.assignment}
                                                class_id={props.class_id}
                                                studentId={submissionData.user.id}
                                                questionId={subquestion_id}
                                            />
                                        </div>
                                    ) : (
                                        <div className="w-full h-full relative flex-1">
                                            {' '}
                                            {/* Added flex-1 */}
                                            <PDFView
                                                url={`${apiUrl}/api/pdf-file/${submissionData.file.filename}`}
                                                className="absolute inset-0 flex-1" /* Added flex-1 */
                                                initialPage={pageNumber}
                                            />
                                        </div>
                                    ))}
                            </div>
                        ))}
                </div>

                <div className="w-1/2 overflow-y-auto p-8">
                    {assignment?.is_exam && (
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Assigned to:</label>
                            <StudentSearchDropdown
                                search={search}
                                isLoading={isAssignmentStudentsLoading}
                                pageNumber={Number(scrollPageNumber)}
                                students={students}
                                currentStudentName={studentName}
                                updateSearchParams={updateSearchParams}
                                onStudentSelect={student => {
                                    setNewStudentAssignment(student);
                                    setIsStudentAssignmentConfirmationOpen(true);
                                }}
                            />
                            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                        </div>
                    )}
                    {groupStudents.length > 0 && (
                        <div>
                            <p>Group Members:</p>
                            <p>
                                {Array.isArray(groupStudents) && groupStudents.length > 0
                                    ? groupStudents.map((student, index) => (
                                        <span key={index}>
                                            {student[0]} ({student[1]}){index < groupStudents.length - 1 ? ', ' : ''}
                                        </span>
                                    ))
                                    : 'No students to display'}
                            </p>
                        </div>
                    )}
                    {examPaper.map((question, questionIndex) => {
                        console.log('exampaper here', examPaper);
                        return HasSubQuestion(question.id) ? (
                            <div key={questionIndex} className="mb-8">
                                {question.subQuestions.map((subQuestion, subQuestionIndex) => {
                                    return subQuestion.id == subquestion_id ? (
                                        <div
                                            key={subQuestionIndex}
                                            className={`mb-6 ml-4 ${regradeInProgress[subQuestion.id] ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''}`}
                                        >
                                            {regradeInProgress[subQuestion.id] && (
                                                <div className="mt-2 mb-4 text-xl text-orange-300">
                                                    This subquestion is being regraded...
                                                </div>
                                            )}
                                            <>
                                                <h2 className="text-xl font-bold mb-4">{`Question ${questionIndex + 1}:`}</h2>
                                                {!question.is_without_sub_questions && (
                                                    <h3 className="font-semibold mb-2">
                                                        {`${String.fromCharCode(97 + subQuestionIndex)}) ${subQuestion.question}`}
                                                    </h3>
                                                )}
                                            </>

                                            <div className="flex flex-col gap-2 mb-2 mt-3">
                                                <SortRubricItemsAndDisplay subQuestion={subQuestion} />
                                            </div>

                                            {/* Feedback section */}
                                            <div className="mt-4">
                                                {!isEditing(subQuestion.id) && (
                                                    <>
                                                        {/* AI Feedback Section */}
                                                        {getFeedback(subQuestion.id) && (
                                                            <div
                                                                className="mb-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
                                                                <div className="flex justify-between items-start mb-2">
                                                                    <h4 className="font-medium text-gray-600 text-sm uppercase tracking-wide flex items-center">
                                                                        <BrainIcon
                                                                            className="w-5 h-5 mr-2" /> {/* AI icon */}
                                                                        AI Feedback
                                                                    </h4>
                                                                    <button
                                                                        className="hover:text-red-700 transition-colors"
                                                                        title="Remove AI feedback"
                                                                        onClick={() => handleRemoveFeedback(subQuestion.id)}
                                                                    >
                                                                        <TrashIcon className="w-5 h-5" />
                                                                    </button>
                                                                </div>
                                                                <div className="text-gray-700 leading-relaxed">
                                                                    {renderFeedback(getFeedback(subQuestion.id))}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* Instructor Comment Section */}
                                                        <div
                                                            className="p-4 bg-white rounded-lg border border-gray-200 shadow-sm">
                                                            <div className="flex justify-between items-start mb-2">
                                                                <h4 className="font-medium text-gray-600 text-sm uppercase tracking-wide flex items-center">
                                                                    <UserCircleIcon
                                                                        className="w-5 h-5 mr-2 text-gray-400" /> {/* Instructor icon */}
                                                                    Instructor Comments
                                                                </h4>
                                                                <button
                                                                    className="hover:text-green-700 transition-colors"
                                                                    title="Add instructor comment"
                                                                    onClick={() => handleAddInstructorFeedback(subQuestion.id)}
                                                                >
                                                                    <PlusCircleIcon className="w-5 h-5" />
                                                                </button>
                                                            </div>

                                                            {isEditingInstructor(subQuestion.id) ? (
                                                                <div className="space-y-3">
                                                                    <textarea
                                                                        ref={el => (instructorCommentRefs.current[subQuestion.id] = el)}
                                                                        value={editableInstructorComment[subQuestion.id]}
                                                                        className="w-full p-3 border border-[rgba(228,177,121,0.2)] rounded-lg focus:ring-2 focus:ring-[rgba(228,177,121,0.3)] focus:border-[#e4b17a]"
                                                                        rows="3"
                                                                        placeholder="Add your instructor comment here..."
                                                                        onChange={e => handleInstructorCommentChange(subQuestion.id, e.target.value)}
                                                                    />
                                                                    <div className="flex justify-end gap-3 mt-8">
                                                                        <button
                                                                            type="button"
                                                                            className="px-5 py-2.5 rounded-xl border border-gray-200 text-gray-600 text-[0.875rem] font-medium hover:bg-gray-50 transition-all duration-200 hover:transform hover:-translate-y-0.5"
                                                                            onClick={() => handleInstructorCommentCancel(subQuestion.id)}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                        <button
                                                                            className="px-5 py-2.5 rounded-xl bg-gradient-to-br from-[#e4b17a] to-[#c99b69] text-white text-[0.875rem] font-medium hover:from-[#c99b69] hover:to-[#b38a5e] transition-all duration-200 hover:transform hover:-translate-y-0.5 shadow-sm hover:shadow"
                                                                            onClick={() => handleInstructorCommentSave(subQuestion.id)}
                                                                        >
                                                                            Save Comment
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="text-black">
                                                                    {getInstructorFeedback(subQuestion.id) ? (
                                                                        <div className="text-black">
                                                                            {getInstructorFeedback(subQuestion.id)}
                                                                        </div>
                                                                    ) : (
                                                                        <p className="text-black italic opacity-60 flex items-center">
                                                                            No instructor comments added yet
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        {/* Regrade Request Section */}
                                                        {getRR(subQuestion.id) && (
                                                            <div
                                                                className="mt-4 p-4 bg-gradient-to-r from-[rgba(228,177,121,0.08)] to-[rgba(228,177,121,0.04)] border-l-4 border-[#e4b17a] rounded-lg transition-all duration-200 hover:shadow-sm">
                                                                <div className="flex justify-between items-start">
                                                                    <h4 className="font-medium text-[#8c6b4a] mb-2 flex items-center gap-2">
                                                                        <ExclamationCircleIcon
                                                                            className="w-5 h-5 text-[#e4b17a]" />
                                                                        Regrade Request
                                                                    </h4>
                                                                    <button
                                                                        className="text-[#e4b17a] hover:text-[#c99b69] transition-all duration-200 hover:transform hover:-translate-y-0.5"
                                                                        onClick={() => handleRegradeReq(subQuestion.id)}
                                                                    >
                                                                        <XMarkIcon className="w-5 h-5" />
                                                                    </button>
                                                                </div>
                                                                <p className="text-[#a17c57]">{getRRText(subQuestion.id)}</p>
                                                            </div>
                                                        )}
                                                        <div className="mt-4 flex justify-end">
                                                            <button
                                                                disabled={getIsDisabledGradingButton(subQuestion.id)}
                                                                className={`px-4 py-2 flex items-center gap-2 text-orange-600 hover:bg-purple-50 rounded-lg transition-colors ${getIsDisabledGradingButton(subQuestion.id) ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                                onClick={() => handleRegradeClick(subQuestion.id)}
                                                            >
                                                                <SparklesIcon className="w-5 h-5" />
                                                                Fine Tune Grading
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                        ) : null;
                    })}
                    {assignment?.type === 'essay' && (
                        <div className="w-full bg-white border border-gray-300 p-4 rounded-xl">
                            <style>{`
                    .feedback-content h2 {
                        font-size: 20px;
                        font-weight: var(--h2-font-weight);
                        margin: var(--h2-margin);
                    }
                    .feedback-content p {
                        margin: var(--p-margin);
                    }
                    .feedback-content ul {
                        list-style-type: disc;
                        padding: var(--ul-padding);
                        margin: var(--ul-margin);
                    }
                    .feedback-content li {
                        margin: var(--li-margin);
                    }
                `}</style>
                            {isEditingEssay ? (
                                <>
                                    <div className="">
                                        <textarea
                                            ref={feedbackRef}
                                            value={editableEssayFeedback}
                                            className="w-full p-2 border-none h-[500px]"
                                            rows="10"
                                            onChange={e => handleEssayFeedbackChange(e.target.value)}
                                        />
                                        <button
                                            className="less-sexy-button w-40 h-9 hover:bg-gray-200 mt-2"
                                            onClick={handleEssayFeedbackSave}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex flex-col justify-between feedback-content">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: submissionData?.file?.filename }}
                                            className="feedback-content"
                                            style={{
                                                '--h2-font-size': '1.5em',
                                                '--h2-font-weight': 'bold',
                                                '--h2-margin': '1em 0 0.5em',
                                                '--p-margin': '0 0 1em',
                                                '--ul-padding': '0 0 0 2em',
                                                '--ul-margin': '0 0 1em',
                                                '--li-margin': '0 0 0.5em',
                                                height: 'auto', // Adjust the height property
                                                overflow: 'visible' // Ensure overflow is visible
                                            }}
                                        />
                                        <button
                                            className="less-sexy-button w-40 h-9 hover:bg-gray-200 mt-2"
                                            onClick={handleEssayFeedbackEdit}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showAllReviewedPopup && (
                <>
                    {/* Blurred background overlay */}
                    <div
                        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50"
                        style={{ backdropFilter: 'blur(4px)' }}
                    />

                    {/* Popup */}
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div
                            className="bg-white rounded-xl shadow-lg p-6 border border-gray-200 transform transition-all animate-fade-in">
                            <div className="flex items-center">
                                <div className="bg-orange-100 rounded-full p-3 mr-4">
                                    <div
                                        class="border-gray-300 h-10 w-10 animate-spin rounded-full border-4 border-t-orange-600" />
                                </div>
                                <p className="text-lg font-medium">
                                    All submissions for this subquestion reviewed. Taking you to the next subquestion.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showNextSubquestionPopup && (
                <>
                    {/* Blurred background overlay */}
                    <div
                        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50"
                        style={{ backdropFilter: 'blur(4px)' }}
                    />

                    {/* Popup */}
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div
                            className="bg-white rounded-xl shadow-lg p-6 border border-gray-200 transform transition-all animate-fade-in">
                            <div className="flex items-center">
                                <div className="bg-orange-100 rounded-full p-3 mr-4">
                                    <div
                                        class="border-gray-300 h-12 w-12 animate-spin rounded-full border-4 border-t-orange-600" />
                                </div>
                                <p className="text-lg font-medium">
                                    {nextSubQuestionId
                                        ? `Taking you to the next ${assignment?.type === 'flexible_problem_set' ? 'question' : 'subquestion'}...`
                                        : `No more ${assignment?.type === 'flexible_problem_set' ? 'questions' : 'subquestions'}. Taking you to the assignment page...`}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {showConnectionErrorPopup && (
                <>
                    <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded">
                        Connection error, reconnecting ...
                    </div>
                </>
            )}

            {isRegradeOpen && (
                <AIRegrade
                    subQuestion={examPaper
                        .flatMap(q => q.subQuestions)
                        .find(sq => sq.id === activeSubQuestionId)}
                    isEssay={assignment?.type === 'essay'}
                    onClose={handleRegradeClose}
                    onSubmit={handleRegradeSubmit}
                />
            )}

            <ConfirmationModal
                isOpen={isConfirmationOpen}
                message="Are you sure you want to regrade this subquestion for all students with the new rubric and grading instructions?"
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleConfirmRegrade}
            >
                <p>This action cannot be undone.</p>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={isStudentAssignmentConfirmationOpen}
                message={`Assigning to ${newStudentAssignment?.name} (ID: ${newStudentAssignment?.id}) will unassign any submission that may be currently assigned to them.`}
                onClose={() => setIsStudentAssignmentConfirmationOpen(false)}
                onConfirm={handleConfirmStudentAssignment}
            >
                <p>Are you sure you want to proceed?</p>
            </ConfirmationModal>

            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-xl shadow-lg">
                        <p>Regrading in progress...</p>
                        <p>Expected wait time: 30 seconds</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TQuestionDetails;