import React from 'react';
import {Cpu} from 'lucide-react';

const ProcessingOverlay = ({loadingSteps, currentStep}) => {
    return (
        <div className="processing-overlay">
            <div className="processing-header">
                <Cpu className="processing-icon"/>
                <h2>Analyzing Document (Up to 5 minutes)</h2>
                <p>
                    Our AI is carefully processing your document to create
                    precise and comprehensive grading rubrics
                </p>
            </div>

            <div className="processing-steps">
                <div className="loading-bar"/>
                {loadingSteps
                    .filter(step => step.id === currentStep)
                    .map((step) => {
                        const Icon = step.icon;
                        return (
                            <div
                                key={step.id}
                                className={`step active ${step.status === "processing" ? "processing" : ""}`}
                            >
                                <div className="step-content">
                                    <div className="step-icon">
                                        {step.icon && React.createElement(step.icon)}
                                        <div className="loading-ring"/>
                                    </div>
                                    <div className="step-info">
                                        <div className="step-label">
                                            {step.label}
                                            <div className="loading-dots">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="step-description">
                                            {step.id === 1 && "Analyzing document structure and content..."}
                                            {step.id === 2 && "Identifying questions and their components..."}
                                            {step.id === 3 && "Processing mathematical expressions and diagrams..."}
                                            {step.id === 4 && "Creating detailed grading guidelines..."}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default ProcessingOverlay;