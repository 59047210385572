import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import LTILaunchPage from './components/Teacher/LTILaunch';
import GradeIC from './demoCSS/GradeIC';
import AdminPage from './pages/Admin/AdminPage';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import TProfilePage from './pages/ProfilePage';
import Reset from './pages/Reset';
import SAssignmentsPage from './pages/Student/SAssignmentsPage';
import SClassesPage from './pages/Student/SClassesPage';
import SDetailsPage from './pages/Student/SDetailsPage';
import SupportPage from './pages/SupportPage';
import ClassSettings from './pages/Teacher/ClassSettings';
import TAssignmentsPage from './pages/Teacher/TAssignmentsPage';
import TAssignmentStatistics from './pages/Teacher/TAssignmentStatsPage';
import TClassesPage from './pages/Teacher/TClassesPage';
import TClassStatsPage from './pages/Teacher/TClassStatsPage';
import TDetailsPage from './pages/Teacher/TDetailsPage';
import TQuestionStatsPage from './pages/Teacher/TQuestionStatsPage';
import TRegradePage from './pages/Teacher/TRegradePage';
import TStatsOverviewPage from './pages/Teacher/TStatsOverviewPage';
import TSubmissionsPage from './pages/Teacher/TSubmissionsPage';
import TGroupSubmissionsPage from './pages/Teacher/TGroupSubmissionsPage';
import Verify from './pages/Verify';

import PublicRoute from './auth/PublicRoute';
import TQuestionDetailsPage from './pages/Teacher/TQuestionDetailsPage';
import PDFAssignmentHome from './components/V2PDF/src/app/page';
import PDFAssignmentTemplate from './components/V2PDF/src/app/template/page';
import PDFRubricGenerator from './components/V2PDF/src/app/generate-rubric/page';
import Visualizer from './pages/Visualizer';

export default function Routing() {
    const router = createBrowserRouter([
        {
            errorElement: <NotFound />,
            children: [
                {
                    element: <PublicRoute />,
                    children: [
                        {
                            element: <Navigate to="marketing.index.html" />,
                            path: '/'
                        },
                        {
                            element: <RedirectSite />,
                            path: 'marketing.index.html'
                        },
                        {
                            element: <Login />,
                            path: '/login'
                        },
                        {
                            element: <GradeIC />,
                            path: '/demo'
                        },
                        {
                            path: '/verify',
                            element: <Visualizer />
                        },
                        {
                            path: '/change-password/:password_change_token',
                            element: <Reset />
                        },
                        {
                            path: '/verify-email/:verification_token',
                            element: <Verify />
                        },
                        {
                            path: '/lti-launch',
                            element: <LTILaunchPage />
                        }
                    ]
                },
                {
                    element: <ProtectedRoute />,
                    children: [
                        {
                            path: '/profile',
                            element: <TProfilePage />
                        },
                        {
                            path: '/regrade',
                            element: <TRegradePage />
                        },
                        {
                            path: '/support',
                            element: <SupportPage />
                        },
                        {
                            path: '/admin',
                            children: [
                                {
                                    element: <AdminPage />,
                                    index: true
                                },
                            ],
                        },
                        {
                            path: '/sclasses',
                            children: [
                                {
                                    element: <SClassesPage />,
                                    index: true
                                },
                                {
                                    path: ':class_id/sassignments',
                                    children: [
                                        {
                                            element: <SAssignmentsPage />,
                                            index: true
                                        },
                                        {
                                            path: ':assignment_id/sdetails',
                                            element: <SDetailsPage />
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: '/csettings/:class_id',
                            element: <ClassSettings />
                        },
                        {
                            path: '/tclasses',
                            children: [
                                {
                                    element: <TClassesPage />,
                                    index: true
                                },
                                {
                                    path: ':class_id/tassignments',
                                    children: [
                                        {
                                            element: <TAssignmentsPage />,
                                            index: true
                                        },
                                        {
                                            path: ':assignment_id/create',
                                            element: <PDFAssignmentHome />,
                                        },
                                        {
                                            path: ':assignment_id/create/template',
                                            element: <PDFAssignmentTemplate />
                                        },
                                        {
                                            path: ':assignment_id/create/rubric',
                                            element: <PDFRubricGenerator />
                                        },
                                        {
                                            path: ':assignment_id/tsubmissions',
                                            children: [
                                                {
                                                    element: <TSubmissionsPage />,
                                                    index: true
                                                },
                                                {
                                                    path: 'group/:groupIndex',
                                                    element: <TGroupSubmissionsPage />
                                                },
                                                {
                                                    path: ':submission_id/tdetails/:regrade_question_id?',
                                                    element: <TDetailsPage />
                                                },
                                                {
                                                    path: ':submission_id/tquestiondetails/:subquestion_id',
                                                    element: <TQuestionDetailsPage />
                                                },
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: '/statistics',
                            children: [
                                {
                                    element: <TStatsOverviewPage />,
                                    index: true
                                },
                                {
                                    path: ':class_id',
                                    children: [
                                        {
                                            element: <TClassStatsPage />,
                                            index: true
                                        },
                                        {
                                            path: ':assignment_id',
                                            element: <TAssignmentStatistics />
                                        }
                                    ]
                                },
                                {
                                    path: 'question/:assignment_id',
                                    element: <TQuestionStatsPage />
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]);

    return <RouterProvider router={router} fallbackElement={<div>Loading</div>} />;
}

const RedirectSite = () => {
    window.location.href = 'marketing/index.html';
    return <></>;
};
