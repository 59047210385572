import React from 'react';
import { Upload, FileText, CheckCircle, ArrowRight } from 'lucide-react';

const UploadPDFsComponent = ({
    uploadHover,
    uploadStatus,
    isUploading,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleProcessDocuments,
    handleFileChange
}) => {
    return (
        <div className="upload-section">
            <div className="upload-boxes-container">
                {/* Upload box for questions template only */}
                <div
                    className={`upload-box ${uploadHover ? "hover" : ""} ${uploadStatus.withoutSolutions ? "uploaded" : ""}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        id="rubric-pdf-upload-questions"
                        accept=".pdf"
                        className="hidden"
                        onChange={(e) => handleFileChange(e, 'withoutSolutions')}
                    />
                    <label htmlFor="rubric-pdf-upload-questions" className="upload-label">
                        {isUploading.withoutSolutions ? (
                            <div className="uploading-overlay">
                                <div className="uploading-spinner"></div>
                                <span className="uploading-text">Uploading...</span>
                            </div>
                        ) : uploadStatus.withoutSolutions ? (
                            <div className="success-overlay">
                                <div className="success-checkmark">
                                    <CheckCircle />
                                </div>
                                <span className="success-text">PDF Uploaded</span>
                                <span className="success-subtext">Ready to share</span>
                            </div>
                        ) : null}
                        <FileText color="#e4b17a"
                            className={`upload-icon w-12 h-12 ${uploadHover ? "bounce" : ""}`} />
                        <span>Upload Questions Template PDF</span>
                        <p>Click to browse or drag and drop your assignment template PDF here</p>
                        <div className="upload-tip">
                            <span>Step 1: Template Only - No Solutions</span>
                        </div>
                    </label>
                </div>
            </div>

            {/* Proceed Button */}
            <div className="proceed-button-container">
                <button
                    className={`proceed-button ${uploadStatus.withoutSolutions ? 'enabled' : 'disabled'}`}
                    disabled={!uploadStatus.withoutSolutions || isUploading.withoutSolutions}
                    onClick={() => {
                        if (uploadStatus.withoutSolutions) {
                            handleProcessDocuments();
                        }
                    }}
                >
                    {isUploading.withoutSolutions ? (
                        <>
                            <div className="button-spinner"></div>
                            <span>Processing...</span>
                        </>
                    ) : (
                        <>
                            <ArrowRight className="w-5 h-5" />
                            <span>Proceed to Download PDF</span>
                        </>
                    )}
                </button>
                {(!uploadStatus.withSolutions || !uploadStatus.withoutSolutions) && (
                    <p className="proceed-hint">Please upload both documents to proceed</p>
                )}
            </div>
        </div>
    );
};

export default UploadPDFsComponent;