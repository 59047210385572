import React from 'react';
import SideBar from '../../components/SideBar';
import SClasses from '../../components/Student/SClasses';

const HomePage = () => {
  return (
    <div className="HomePageHeader">
      <div className="flex flex-row w-full min-h-screen">
        <div
          id="Left-Sidebar"
          className="sticky top-0 h-screen overflow-y-auto w-side-bar-size flex-shrink-0"
        >
          <SideBar activePage="Home" />
        </div>
        <div id="Right-content" className="flex-grow overflow-y-auto">
          <SClasses />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
