"use client";

import React from "react";
import "./styling/PDFViewer.scss";

const PDFViewer = ({
                       isDrawMode = false,
                       isDrawing = false,
                       mouseActions = {},
                       savedBoxes = [],
                       viewerRef = null,
                       overlayRef = null,
                       pdfImage = null,
                       startPoint = null,
                       currentPoint = null,
                       drawingPhase = null,
                       onBoxClick,
                       onBoxDelete,
                       selectedBoxIndex,
                       setSelectedBoxIndex,
                       setIsEditMode,
                       nameRegion = null,
                       idRegion = null,
                       examFieldMode = null,
                       alignmentMarkerBoundary = {
                           left: 3,
                           right: 97,
                           top: 5.5,
                           bottom: 97
                       },
                       onNameRegionDelete,
                       onIdRegionDelete
                   }) => {
    const handleOverlayClick = (e) => {
        if (e.target === overlayRef.current) {
            setSelectedBoxIndex(null);
            setIsEditMode(false);
        }
    };

    const formatQuestionLabel = (questionId) => {
        const [baseId, subLetter] = questionId.split("-");
        return subLetter ? `Question ${baseId}${subLetter}` : `Question ${baseId}`;
    };

    return (
        <div ref={viewerRef} className="pdf-viewer">
            <div className="pdf-content">
                {pdfImage && (
                    <img src={pdfImage} alt="PDF page" className="pdf-image"/>
                )}

                <div
                    ref={overlayRef}
                    className={`pdf-overlay ${isDrawMode ? "draw-mode" : ""}`}
                    onClick={(e) => {
                        handleOverlayClick(e);
                        mouseActions.handleClick && mouseActions.handleClick(e);
                    }}
                    onMouseMove={mouseActions.handleMouseMove}
                    onMouseUp={mouseActions.handleMouseUp}
                    onMouseLeave={mouseActions.handleMouseUp}
                >
                    {/* Gray overlay for restricted drawing areas - always visible */}
                    {(
                        <>
                            {/* Top area */}
                            <div className="restricted-area top-area" style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${alignmentMarkerBoundary.top}%`,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                border: '1px dashed rgba(255, 255, 255, 0.25)',
                                pointerEvents: 'none',
                                zIndex: 1
                            }}/>
                            {/* Bottom area */}
                            <div className="restricted-area bottom-area" style={{
                                position: 'absolute',
                                top: `${alignmentMarkerBoundary.bottom}%`,
                                left: 0,
                                width: '100%',
                                height: `${100 - alignmentMarkerBoundary.bottom}%`,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                border: '1px dashed rgba(255, 255, 255, 0.25)',
                                pointerEvents: 'none',
                                zIndex: 1
                            }}/>
                            {/* Left area */}
                            <div className="restricted-area left-area" style={{
                                position: 'absolute',
                                top: `${alignmentMarkerBoundary.top}%`,
                                left: 0,
                                width: `${alignmentMarkerBoundary.left}%`,
                                height: `${alignmentMarkerBoundary.bottom - alignmentMarkerBoundary.top}%`,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                border: '1px dashed rgba(255, 255, 255, 0.25)',
                                pointerEvents: 'none',
                                zIndex: 1
                            }}/>
                            {/* Right area */}
                            <div className="restricted-area right-area" style={{
                                position: 'absolute',
                                top: `${alignmentMarkerBoundary.top}%`,
                                left: `${alignmentMarkerBoundary.right}%`,
                                width: `${100 - alignmentMarkerBoundary.right}%`,
                                height: `${alignmentMarkerBoundary.bottom - alignmentMarkerBoundary.top}%`,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                border: '1px dashed rgba(255, 255, 255, 0.25)',
                                pointerEvents: 'none',
                                zIndex: 1
                            }}/>
                        </>
                    )}
                    {/* Drawing preview */}
                    {isDrawing && startPoint && currentPoint && (
                        <div
                            className="drawing-preview"
                            style={{
                                left: `${Math.min(startPoint.x, currentPoint.x)}%`,
                                top: `${Math.min(startPoint.y, currentPoint.y)}%`,
                                width: `${Math.abs(currentPoint.x - startPoint.x)}%`,
                                height: `${Math.abs(currentPoint.y - startPoint.y)}%`,
                                border: examFieldMode === 'name' 
                                    ? "2px dashed #4F46E5" 
                                    : examFieldMode === 'id'
                                        ? "2px dashed #4F46E5"
                                        : "2px dashed #e4b17a",
                                backgroundColor: examFieldMode === 'name'
                                    ? "rgba(79, 70, 229, 0.1)"
                                    : examFieldMode === 'id'
                                        ? "rgba(79, 70, 229, 0.1)"
                                        : "rgba(228, 177, 122, 0.1)",
                            }}
                        />
                    )}

                    {/* Name Region */}
                    {nameRegion && (
                        <div
                            className="saved-box name-region"
                            style={{
                                position: "absolute",
                                left: `${nameRegion.x}%`,
                                top: `${nameRegion.y}%`,
                                width: `${nameRegion.width}%`,
                                height: `${nameRegion.height}%`,
                                border: "2px solid #4F46E5", 
                                backgroundColor: "rgba(79, 70, 229, 0.1)",
                                zIndex: 30,
                            }}
                        >
                            <div className="box-label" style={{backgroundColor: "#4F46E5"}}>
                                Name
                            </div>
                            <button
                                className="delete-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onNameRegionDelete && onNameRegionDelete();
                                }}
                            >
                                ×
                            </button>
                        </div>
                    )}
                    
                    {/* ID Region */}
                    {idRegion && (
                        <div
                            className="saved-box id-region"
                            style={{
                                position: "absolute",
                                left: `${idRegion.x}%`,
                                top: `${idRegion.y}%`,
                                width: `${idRegion.width}%`,
                                height: `${idRegion.height}%`,
                                border: "2px solid #4F46E5", 
                                backgroundColor: "rgba(79, 70, 229, 0.1)",
                                zIndex: 30,
                            }}
                        >
                            <div className="box-label" style={{backgroundColor: "#4F46E5"}}>
                                ID
                            </div>
                            <button
                                className="delete-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onIdRegionDelete && onIdRegionDelete();
                                }}
                            >
                                ×
                            </button>
                        </div>
                    )}

                    {/* Saved boxes */}
                    {savedBoxes.map((box, index) => (
                        <div
                            key={box.id}
                            className={`saved-box ${
                                selectedBoxIndex === index ? "selected" : ""
                            }`}
                            style={{
                                left: `${box.x}%`,
                                top: `${box.y}%`,
                                width: `${box.width}%`,
                                height: `${box.height}%`,
                            }}
                            onClick={() => onBoxClick(index)}
                        >
                            <div className="box-label">
                                {formatQuestionLabel(box.questionId)}
                            </div>
                            <button
                                className="delete-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onBoxDelete(index);
                                }}
                            >
                                ×
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PDFViewer;
