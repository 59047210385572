import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAuth} from '../../../../auth/AuthContext.js';
import {useQueryParams} from '../../../../shared/hooks/use-query-params.tsx';
import {addTeachers, getTeachers, removeTeacher} from '../../../../store/teacherSlice.js';
import {DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE} from '../../../../utils/pagination.ts';
import ConfirmationModal from '../../../ConfirmationModal.js';
import Table from '../../../Table.js';
import {Plus, X, User, Loader} from 'lucide-react';

const AddDropBigTeachers = ({course, class_id}) => {
    const dispatch = useDispatch();
    const {user} = useAuth();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [selectedTeacherId, setSelectedTeacherId] = useState(null);
    const {data, isLoading: dataLoading, errorMessage} = useSelector(state => state?.teacherReducer?.teachers);
    const [teacherErrors, setTeacherErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [teacherEmail, setTeacherEmail] = useState('');

    const isOpenToManage = course?.isOpenToManage;

    const {
        params: {search, pageNumber, itemsPerPage, sortOrder},
        updateSearchParams
    } = useQueryParams({
        search: '',
        pageNumber: DEFAULT_PAGE_NUMBER,
        itemsPerPage: ITEMS_PER_PAGE.default,
        sortOrder: 'asc'
    });

    useEffect(() => {
        getAllTeachers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, pageNumber, itemsPerPage, sortOrder]);

    useEffect(() => {
        if (errorMessage) {
            setError(errorMessage);
        }
    }, [errorMessage]);

    const getAllTeachers = () =>
        dispatch(
            getTeachers({
                classId: class_id,
                token: user.session_token,
                search,
                pageNumber: Number(pageNumber),
                itemsPerPage: Number(itemsPerPage),
                sortOrder
            })
        );

    const handleRemove = async id => {
        setSelectedTeacherId(id);
        setIsConfirmationModalOpen(true);
    };

    const addTeacherByEmail = async () => {
        if (!teacherEmail) {
            setError('Please enter an instructor email');
            return;
        }

        try {
            // Clear previous messages
            setSuccess('');
            setError('');
            setTeacherErrors(null);
            setIsLoading(true);

            const response = await dispatch(
                addTeachers({
                    classId: class_id,
                    token: user.session_token,
                    emails: [teacherEmail]
                })
            );

            // Check if we got a response with payload
            if (response.payload) {
                // Check if there were any issues
                const hasIssues =
                    (response.payload?.already_added_emails?.length > 0) ||
                    (response.payload?.not_a_teacher_emails?.length > 0) ||
                    (response.payload?.teacher_not_found_emails?.length > 0) ||
                    (response.payload?.invalid_emails?.length > 0);

                if (hasIssues) {
                    // Store the specific errors
                    setTeacherErrors(response.payload);
                } else {
                    // Everything went well
                    setSuccess('Instructor added successfully');
                    setIsAddModalOpen(false);
                    setTeacherEmail('');
                }
            } else if (response.error) {
                // There was an error in the response
                setError('Failed to add instructor. Please check the email and try again.');
            } else {
                // Success case
                setSuccess('Instructor added successfully');
                setIsAddModalOpen(false);
                setTeacherEmail('');
            }

            // Always refresh the instructor list
            await getAllTeachers();
        } catch (error) {
            console.error('Error adding instructor:', error);
            setError('Failed to add instructor. Please check the email and try again.');
            setSuccess('');
        } finally {
            setIsLoading(false);
        }
    };

    const confirmRemoveTeacher = async () => {
        const deleteUser = await dispatch(
            removeTeacher({
                classId: class_id,
                token: user.session_token,
                teacherId: selectedTeacherId
            })
        );

        if (deleteUser.error) {
            setError(deleteUser.payload);
        } else {
            setSuccess('Instructor removed successfully');
            getAllTeachers();
        }

        setIsConfirmationModalOpen(false);
    };

    const columnNames = ['Name', 'Email', 'Role', 'Actions'];

    return (
        <div className="instructors-management-container">

            {/* Feedback messages */}
            {success && (
                <div
                    className="mb-4 p-3 bg-green-50 text-green-700 rounded-md border border-green-200 flex justify-between items-center">
                    <span>{success}</span>
                    <button className="text-green-700" onClick={() => setSuccess('')}>
                        <X className="w-4 h-4"/>
                    </button>
                </div>
            )}

            {error && (
                <div
                    className="mb-4 p-3 bg-red-50 text-red-700 rounded-md border border-red-200 flex justify-between items-center">
                    <span>{error}</span>
                    <button className="text-red-700" onClick={() => setError('')}>
                        <X className="w-4 h-4"/>
                    </button>
                </div>
            )}

            <div className="mb-6 bg-white rounded-lg border border-gray-200 overflow-hidden">
                <div className="p-6">
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="teacherEmail" className="block text-sm font-medium text-gray-700 mb-2">
                                Instructor Email
                            </label>
                            <form className="relative" onSubmit={(e) => {
                                e.preventDefault();
                                addTeacherByEmail();
                            }}>
                                <input
                                    type="email"
                                    id="teacherEmail"
                                    value={teacherEmail}
                                    placeholder="instructor@example.com"
                                    className="w-full px-4 py-2 pr-[130px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#e4b17a] focus:border-[#e4b17a]"
                                    onChange={(e) => setTeacherEmail(e.target.value)}
                                />
                                <button
                                    type="submit"
                                    className="absolute right-1 top-1 px-3 py-1 border border-[#e4b17a] bg-[#e4b17a] text-white rounded-md hover:bg-[#d9a064] transition-colors flex items-center gap-1 text-sm"
                                >
                                    {isLoading ? (
                                        <>
                                            <span>Processing</span>
                                            <Loader className="w-3 h-3 animate-spin"/>
                                        </>
                                    ) : (
                                        <>
                                            <User className="w-3 h-3"/>
                                            Add Instructor
                                        </>
                                    )}
                                </button>
                            </form>
                            <p className="text-xs text-gray-500 mt-1">Enter the email address of the instructor you
                                want to add</p>
                        </div>

                        {teacherErrors?.already_added_emails?.length > 0 && (
                            <div className="text-red-600 text-sm">
                                <p className="font-medium">Email already added:</p>
                                <ul className="list-disc list-inside">
                                    {teacherErrors.already_added_emails.map(email => (
                                        <li key={email}>{email}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {teacherErrors?.not_a_teacher_emails?.length > 0 && (
                            <div className="text-red-600 text-sm">
                                <p className="font-medium">Cannot add student as instructor:</p>
                                <ul className="list-disc list-inside">
                                    {teacherErrors.not_a_teacher_emails.map(email => (
                                        <li key={email}>{email}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {teacherErrors?.teacher_not_found_emails?.length > 0 && (
                            <div className="text-red-600 text-sm">
                                <p className="font-medium">Email not found:</p>
                                <ul className="list-disc list-inside">
                                    {teacherErrors.teacher_not_found_emails.map(email => (
                                        <li key={email}>{email}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {teacherErrors?.invalid_emails?.length > 0 && (
                            <div className="text-red-600 text-sm">
                                <p className="font-medium">Invalid email:</p>
                                <ul className="list-disc list-inside">
                                    {teacherErrors.invalid_emails.map(email => (
                                        <li key={email}>{email}</li>
                                    ))}
                                </ul>
                            </div>
                        )}


                    </div>
                </div>
            </div>

            {/* Instructors Table */}
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                <Table
                    search={search}
                    sortOrder={sortOrder}
                    isLoading={dataLoading || isLoading}
                    totalCount={data?.totalCount}
                    offset={data?.offset}
                    nextOffset={data?.nextOffset}
                    pageNumber={Number(pageNumber)}
                    itemsPerPage={Number(itemsPerPage)}
                    columnNames={columnNames}
                    noDataTitle="No instructors found"
                    columnBodies={(data?.entities ?? []).map(teacher => (
                        <tr key={`${teacher.fullName + ' ' + teacher.id}`} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <div className="truncate max-w-[30ch]" title={teacher.fullName}>
                                    {teacher.fullName}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <div className="truncate max-w-[30ch]" title={teacher.email}>
                                    {teacher.email}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {teacher.role === 'owner' ? (
                                    <span
                                        className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-red-100 text-red-800">
                    Lead Instructor
                  </span>
                                ) : (
                                    <span
                                        className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-green-100 text-green-800">
                    Co-Instructor
                  </span>
                                )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                {teacher.role !== 'owner' && isOpenToManage && (
                                    <button
                                        className="text-red-600 hover:text-red-900 focus:outline-none"
                                        onClick={() => handleRemove(teacher.id)}
                                    >
                                        <img
                                            src="/assets/trash.png"
                                            alt="Remove"
                                            className="w-5 h-5 z-5 opacity-70 hover:opacity-100 inline"
                                        />
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                    updateSearchParams={updateSearchParams}
                />
            </div>

            {/* Add Instructor Section - Expands Below Button */}

            {/* Confirmation Modal */}
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                message="Are you sure you want to remove this instructor? This action cannot be undone."
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmRemoveTeacher}
            />
        </div>
    );
};

export default AddDropBigTeachers;
