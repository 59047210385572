  // "use client";
import React from "react";
// import { handlePageChange } from "../utils/pageUtils";

export default function RightPanel({
  currentPage,
  totalPages,
  isPageTransitioning,
  savedBoxesByPage,
  pageThumbnails,
  setCurrentPage,
  setIsPageTransitioning,
  setBoundaryWarning,
  setIsEditMode,
  setSelectedBoxIndex,
}) {
  return (
    <div className="right-panel">
      <div className="panel-header">
        <h2>Pages</h2>
        <div className="page-info">
          <span>{currentPage}</span>
          <span className="separator">/</span>
          <span>{totalPages}</span>
        </div>
      </div>

      <div className="page-controls">
        <button
          className="page-button previous"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1 || isPageTransitioning}
        >
          <svg
            viewBox="0 0 24 24"
            width="20"
            height="20"
            stroke="currentColor"
            fill="none"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        <div className="page-input-container">
          <input
            type="number"
            value={currentPage}
            onChange={(e) => {
              const page = parseInt(e.target.value);
              if (page >= 1 && page <= totalPages) {
                setCurrentPage(page);
              }
            }}
            min="1"
            max={totalPages}
          />
        </div>

        <button
          className="page-button next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages || isPageTransitioning}
        >
          <svg
            viewBox="0 0 24 24"
            width="20"
            height="20"
            stroke="currentColor"
            fill="none"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      <div className="page-thumbnails">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            className={`page-thumbnail ${
              page === currentPage ? "active" : ""
            } ${savedBoxesByPage[page]?.length ? "has-selections" : ""}`}
            onClick={() => setCurrentPage(page)}
            disabled={isPageTransitioning}
          >
            {pageThumbnails[page] ? (
              <div className="thumbnail-container">
                <img
                  src={pageThumbnails[page]}
                  alt={`Page ${page}`}
                  className="page-preview"
                />
                {savedBoxesByPage[page]?.length > 0 && (
                  <span className="selection-indicator">
                    {savedBoxesByPage[page].length}
                  </span>
                )}
              </div>
            ) : (
              <div className="thumbnail-placeholder">
                <span className="page-number">{page}</span>
              </div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}
