import React, { useEffect } from "react";

const useKeyboardActions = ({
  setIsDrawMode,
  setIsDrawing,
  setStartPoint,
  setCurrentBox,
  selectedBoxIndex,
  setSavedBoxesByPage,
  currentPage,
  setSelectedBoxIndex,
}) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "d" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsDrawMode((currentMode) => {
          const newMode = !currentMode;
          if (newMode) {
            console.log("Auto-starting drawing mode");
            setIsDrawing(true);
            setStartPoint({
              x: 50,
              y: 50,
              currentX: 50,
              currentY: 50,
            });
          } else {
            setIsDrawing(false);
            setStartPoint(null);
          }
          return newMode;
        });
      }
      if (e.key === "Escape") {
        setIsDrawMode(false);
        setIsDrawing(false);
        setStartPoint(null);
        setCurrentBox(null);
      }
      if (
        (e.key === "Delete" || e.key === "Backspace") &&
        selectedBoxIndex !== null
      ) {
        e.preventDefault();
        setSavedBoxesByPage((prev) => ({
          ...prev,
          [currentPage]: prev[currentPage].filter(
            (_, index) => index !== selectedBoxIndex
          ),
        }));
        setSelectedBoxIndex(null);
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [selectedBoxIndex, currentPage]);
};

export default useKeyboardActions;
