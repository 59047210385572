import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {gradeApi} from '../../api';
import '../../App.css';
import {useAuth} from '../../auth/AuthContext';
import {Loading} from '../../components/Loading';
import TQuestionDetails from '../../components/Teacher/TGrading/TQuestionDetails';

function TQuestionDetailsPage() {
    let {assignment_id, class_id, submission_id, subquestion_id} = useParams();
    const [submissionIds, setSubmissionIds] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {user} = useAuth();

    useEffect(() => {
        getSubmissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment_id, subquestion_id]);

    const getSubmissions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/v2/assignments/${assignment_id}/submissions/ids`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setSubmissionIds(response.data.ids);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch submissions. Please try again.');
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading/>;
    }

    return (
        <>
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
            {submissionIds ? (
                <TQuestionDetails
                    class_id={class_id}
                    assignment_id={assignment_id}
                    submission_id={submission_id}
                    submissionIds={submissionIds}
                />
            ) : (
                <div>No submissions found.</div>
            )}
        </>
    );
}

export default TQuestionDetailsPage;
