import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {apiUrl, gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext.js';
import {formatDateTime} from '../../components/DueTimeDisplayTable';
import DragAndDropUpload from '../DragDropAnswer';
import DueTimeDisplay from '../DueTimeDisplay.js';
import AddStudentsToSubmission from './AddStudentsToSubmission';
import {SubmissionForm} from './SubmissionForm';
import {SubmissionDisplay} from './SubmissionDisplay';

import {Tooltip} from 'react-tooltip';
import {getLogs} from '../../store/teacherSlice.js';

function SUnpublishedPset({assignment, class_id}) {
    const dispatch = useDispatch();

    const {user} = useAuth();
    const [message, setMessage] = useState('');
    const [submissionData, setSubmissionData] = useState({});
    const [file, setFile] = useState();
    const [update, setUpdate] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const pollingIntervalRef = useRef(null);
    const [showHelp, setShowHelp] = useState(false);
    const [showSubmissionLog, setShowSubmissionLog] = useState(false);
    const [showSubmission, setShowSubmission] = useState(true);
    const [showAssignmentPdf, setShowAssignmentPdf] = useState(false);
    const [showSavedPopup, setShowSavedPopup] = useState(false);

    const {data: logs, isLoading: isLogsLoading} = useSelector(
        state => state?.teacherReducer?.logs
    );

    useEffect(() => {
        getAssignmentSubmission();
        return () => {
            stopPolling();
        };
    }, []);

    useEffect(() => {
        getAllLogs();

        if (assignment?.submissionId) {
            startPolling(assignment.submissionId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment]);

    const startPolling = submissionId => {
        if (isPolling) {
            return; // Prevent multiple polling intervals
        }
        setIsPolling(true);
        pollingIntervalRef.current = setInterval(() => {
            checkGradingStatus(submissionId);
        }, 5000);
    };

    const stopPolling = () => {
        setIsPolling(false);
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
        }
    };

    const checkGradingStatus = async submissionId => {
        try {
            const response = await gradeApi.get({
                path: `/api/submission/${submissionId}/grading-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            if (response.data.is_grading_done) {
                stopPolling();
                setMessage('Grading completed. Refreshing submission data...');
                setShowHelp(false);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error checking grading status:', error);
            stopPolling();
            setMessage('Error checking grading status. Please refresh the page.');
            setShowHelp(false);
        }
    };

    const getAssignmentSubmission = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/v2/assignments/${assignment.id}/user/${user.user.id}/submission/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const data = response.data;
            if (data.is_grade_in_progres) {
                setMessage('Assignment is being graded... You can leave the page.');
                setShowHelp(false);
                setIsButtonDisabled(true);
                if (data.id) {
                    startPolling(data.id);
                }
            } else {
                setIsButtonDisabled(false);
                setMessage('');
                setShowHelp(false);
            }

            setSubmissionData(data);
        } catch (error) {
            setIsButtonDisabled(false);
            setMessage(
                error.response?.data?.error || 'An error occurred while fetching submission data.'
            );
            setShowHelp(false);
        }
    };

    const getAllLogs = async () => {
        await dispatch(
            getLogs({
                userId: user.user.id,
                token: user.session_token,
                assignmentId: assignment.id
            })
        );
    };

    const submitOrResubmit = async isResubmission => {
        try {
            setMessage(
                isResubmission
                    ? 'Resubmission in progress... please wait'
                    : 'Submission in progress... please wait'
            );
            setIsButtonDisabled(true);
            setShowHelp(false);

            const formData = new FormData();
            formData.append('file_data', file);
            formData.append('user_id', user.user.id);
            formData.append('submitter_id', user.user.id);

            const response = await gradeApi.post({
                path: `/api/assignment/${assignment.id}/submit-or-resubmit/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            const submissionId = response.data.id;
            setMessage(
                isResubmission
                    ? 'Assignment resubmitted successfully! Please wait, grading...'
                    : 'Assignment submitted successfully! Please wait, grading...'
            );
            setShowHelp(false);

            const gradingResponse = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/ai-grade/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (gradingResponse.status === 200) {
                setMessage('Assignment is being graded... You can leave the page.');
                setShowSavedPopup(true);
                setTimeout(() => setShowSavedPopup(false), 5000);
                setShowHelp(false);
                startPolling(submissionId);
            } else {
                throw new Error('Grading failed. Please try again later.');
            }
        } catch (error) {
            setIsButtonDisabled(false);
            setMessage(error.response?.data?.error || 'An unknown error occurred');
            setShowHelp(true);
        }
    };

    const handleLeaveGroup = async () => {
        try {
            await gradeApi.post({
                path: `${url}/api/assignment/${assignment.id}/leave-group/`,
                body: {},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setMessage('You have left the group. Refreshing...');
            setShowHelp(false);

            getAssignmentSubmission();
        } catch (error) {
            setMessage('Error leaving group. Please try again.');
            setShowHelp(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        await submitOrResubmit(false);
    };

    const handleResubmit = async e => {
        e.preventDefault();
        await submitOrResubmit(true);
    };

    const handleCancel = async () => {
        try {
            await gradeApi.delete({
                path: `/api/assignment/${assignment.id}/delete-submissions/`,
                body: {
                    user_id: user.user.id
                },
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            setIsButtonDisabled(false);
            setMessage('Submission cancelled.');
            setShowHelp(false);

            stopPolling();
        } catch (error) {
            console.error('Error cancelling submission:', error);
            setMessage('Error cancelling submission. Please try again.');
            setShowHelp(false);
        }
    };

    return (
        <div className="flex flex-col bg-white px-5 py-7 h-full w-full">
            <div className="flex flex-col space-y-4 w-full">
                {/* Navigation breadcrumb */}
                <div className="flex flex-row items-center space-x-2 mb-4">
                    <Link to="/sclasses">
                        <div className="size-10 flex items-center justify-center bg-gray-50 rounded-full">
                            <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Home"/>
                        </div>
                    </Link>
                    <Link to="/sclasses">
                        <div className="text-black text-lg font-medium ml-4">My Classes</div>
                    </Link>
                    <Link to="/sclasses">
                        <div className="size-6 flex items-center justify-center">
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back Arrow"/>
                        </div>
                    </Link>
                    <Link to={`/sclasses/${class_id}/sassignments`}>
                        <div className="text-black text-lg font-medium ml-4">{assignment.course.name}</div>
                    </Link>
                    <Link to={`/sclasses/${class_id}/sassignments`}>
                        <div className="size-6 flex items-center justify-center">
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back Arrow"/>
                        </div>
                    </Link>
                    <h1 className="text-black text-lg font-medium ml-4">{assignment.title}</h1>
                </div>

                {/* Help Link */}
                <Link
                    to="https://drive.google.com/file/d/1y_2woeaCCz9R42i368KK4cPkylqsdw03/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-orange-300 font-bold mb-4 hover:underline"
                >
                    How do I submit?
                </Link>

                {/* Assignment Details Card */}
                <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6">
                    {assignment.description && <p className="text-gray-600 mb-4">{assignment.description}</p>}
                    <div className="flex">
                        <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
                        <DueTimeDisplay
                            dueTime={assignment.due_time}
                            dueDate={assignment.due_date}
                            timezone={assignment.timezone}
                        />
                    </div>

                    {/* Pre-grade Notice */}
                    {assignment.allow_pregrade && (
                        <div className="mt-4 mb-4">
                            {!submissionData.submission_count ? (
                                <p className="text-green-400">
                                    Professor enabled pre-grading, so you will be able to see the AI feedback for your
                                    first submission.
                                </p>
                            ) : (
                                <p className="text-red-400">
                                    You have already used your pre-grading attempt. You will see feedback for future
                                    submissions after the professor publishes grades.
                                </p>
                            )}
                        </div>
                    )}

                    {showSavedPopup && (
                        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                            Assignment submitted successfully! You can leave this page.
                        </div>
                    )}

                    {/* Submission Form */}
                    {assignment.is_group_task && submissionData.submitted ? (
                        submissionData.is_group_admin ? (
                            <>
                                <div className="mb-6">
                                    <DragAndDropUpload onFileChange={setFile}/>
                                </div>
                                <form className="flex items-center space-x-4" onSubmit={handleResubmit}>
                                    <button
                                        type="submit"
                                        disabled={isButtonDisabled}
                                        className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${
                                            isButtonDisabled
                                                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                : 'bg-orange-200 hover:bg-orange-300 text-black'
                                        }`}
                                    >
                                        {isButtonDisabled ? (
                                            <>
                                                <span className="opacity-0">Resubmit</span>
                                                <div className="absolute inset-0 flex items-center justify-center">
                                                    <div
                                                        className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                                </div>
                                            </>
                                        ) : (
                                            'Resubmit'
                                        )}
                                    </button>
                                    {isButtonDisabled && (
                                        <button
                                            type="button"
                                            className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                    )}
                                </form>
                                {submissionData.is_grade_in_progres === false && !isButtonDisabled && (
                                    <p className="text-orange-400 font-medium mt-4">
                                        Your assignment has been graded, but you can still resubmit! You will see your
                                        grade once professor publishes grades.
                                    </p>
                                )}
                            </>
                        ) : (
                            <div className="mb-6">
                                <p className="text-gray-600">
                                    You are not the group admin. Only the group admin can submit assignments.
                                </p>
                                <button
                                    className="mt-4 px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                    onClick={handleLeaveGroup}
                                >
                                    Leave Group
                                </button>
                            </div>
                        )
                    ) : (
                        <>
                            <div className="mb-6">
                                <DragAndDropUpload onFileChange={setFile}/>
                            </div>
                            <form className="flex items-center space-x-4" onSubmit={handleSubmit}>
                                <button
                                    type="submit"
                                    disabled={isButtonDisabled}
                                    className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${
                                        isButtonDisabled
                                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                            : 'bg-orange-200 hover:bg-orange-300 text-black'
                                    }`}
                                >
                                    {isButtonDisabled ? (
                                        <>
                                            <span className="opacity-0">Submit</span>
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <div
                                                    className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                            </div>
                                        </>
                                    ) : (
                                        'Submit'
                                    )}
                                </button>
                                {isButtonDisabled && (
                                    <button
                                        type="button"
                                        className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                )}
                            </form>
                            {submissionData.submitted &&
                                submissionData.is_grade_in_progres === false &&
                                !isButtonDisabled && (
                                    <p className="text-orange-400 font-medium mt-4">
                                        Your assignment has been graded, but you can still resubmit! You will see your
                                        grade once professor publishes grades.
                                    </p>
                                )}
                        </>
                    )}

                    {message && <p className="text-red-500 mt-4">{message}</p>}
                    {showHelp && (
                        <Link
                            to="https://drive.google.com/file/d/1gWYt4atdKHK_-Dt8i5Ab6VaC7EWdxcg7/view?usp=sharing"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-orange-300 font-bold mb-4 hover:underline"
                        >
                            Refer to Submission Error documentation
                        </Link>
                    )}
                </div>

                <div className="flex flex-col w-full mb-2">
                    <button
                        type="button"
                        className="flex items-center text-sm mb-2"
                        onClick={() => setShowSubmissionLog(!showSubmissionLog)}
                    >
                        <img
                            src="/assets/back_arrow.png"
                            className={`size-5 opacity-60 transform ${showSubmissionLog ? 'rotate-90' : '-rotate-90'} mr-2`}
                            alt="Toggle submission log"
                        />
                        Submission Log
                    </button>
                </div>

                {showSubmissionLog && (
                    <div className="flex flex-col w-full">
                        {logs?.entities?.length > 0 ? (
                            logs.entities.map((log, index) => (
                                <div key={index} className="mb-2 ml-4">
                                    <p className="text-sm text-gray-700">
                                        {logs.totalCount - index}. Submitted at{' '}
                                        {formatDateTime(log.submissionDate, user.user.timezone)} by{' '}
                                        <span
                                            id={`user${log.firstName + log.userId}`}
                                            className="relative cursor-pointer hover:text-gray-900"
                                        >
                      <strong>{log.fullName}</strong>
                    </span>
                                    </p>
                                    <Tooltip anchorSelect={`#user${log.firstName + log.userId}`} place="bottom">
                                        <p>ID: {log.userId}</p>
                                        <p>Email: {log.email}</p>
                                    </Tooltip>
                                </div>
                            ))
                        ) : (
                            <p className="text-sm text-gray-700 ml-4">No submissions made yet.</p>
                        )}
                    </div>
                )}

                {/* Student Submission Section */}
                <div className="flex flex-col w-full mb-2 mt-5">
                    <button
                        type="button"
                        className="flex items-center text-lg font-semibold mb-2 bg-orange-50 p-4 rounded-lg hover:bg-orange-100 transition-colors"
                        onClick={() => setShowSubmission(!showSubmission)}
                    >
                        <img
                            src="/assets/back_arrow.png"
                            className={`size-6 opacity-60 transform ${showSubmission ? 'rotate-90' : '-rotate-90'} mr-3`}
                            alt="Toggle submission"
                        />
                        Your Submission
                        {submissionData?.file?.filename && (
                            <span className="ml-2 text-sm text-gray-600">
                {`Last updated: ${formatDateTime(submissionData.submission_date, user.user.timezone)}`}
              </span>
                        )}
                    </button>
                </div>

                {showSubmission && (
                    <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6 mb-8">
                        {submissionData?.file?.filename ? (
                            <>
                                <iframe
                                    src={`${apiUrl}/api/pdf-file/${submissionData.file.filename}?mode=view`}
                                    title="PDF Viewer"
                                    className="w-full h-[600px] border border-gray-300 rounded-lg mb-6"
                                />
                                <div className="flex justify-between items-center">
                  <span className="text-gray-600">
                    Submitted file: {submissionData.file.filename}
                  </span>
                                </div>
                            </>
                        ) : (
                            <div className="text-center py-8 text-gray-600">
                                No submission yet. Upload your work using the form above.
                            </div>
                        )}
                    </div>
                )}

                {/* Group Members Section */}
                {submissionData?.file?.filename &&
                    assignment.is_group_task &&
                    submissionData.is_group_admin && <AddStudentsToSubmission aid={assignment.id}/>}

                {/* Assignment PDF Section */}
                <div className="flex flex-col w-full mb-2">
                    <button
                        type="button"
                        className="flex items-center text-lg font-semibold mb-2 bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors"
                        onClick={() => setShowAssignmentPdf(!showAssignmentPdf)}
                    >
                        <img
                            src="/assets/back_arrow.png"
                            className={`size-6 opacity-60 transform ${showAssignmentPdf ? 'rotate-90' : '-rotate-90'} mr-3`}
                            alt="Toggle assignment PDF"
                        />
                        Assignment PDF
                    </button>
                </div>

                {showAssignmentPdf && (
                    <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6">
                        {assignment.url ? (
                            <>
                                <p className="text-gray-600 mb-4">
                                    Download the assignment PDF below, fill it out and submit it as PDF. Ensure all 4
                                    corners of the page are visible if scanning or taking pictures.
                                </p>
                                <iframe
                                    id={update}
                                    src={`${apiUrl}/api/pdf-file/${assignment.url}?mode=view`}
                                    title="PDF Viewer"
                                    className="w-full h-96 border border-gray-300 rounded-lg"
                                />
                            </>
                        ) : (
                            <p className="text-gray-600">Your Professor has not created your assignment yet.</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SUnpublishedPset;
