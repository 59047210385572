import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext';
import {Loading} from '../../Loading';
import {DueTimeDisplayTable} from '../../DueTimeDisplayTable';
import Table from '../../Table.js';
import TeacherStudentUpload from './TeacherStudentUpload';
import HierarchicalQuestionView from "../HierarchicalQuestionView";

function TGrade({
                    isLoading,
                    pageNumber,
                    submissionsError,
                    submissions,
                    itemsPerPage,
                    assignment,
                    reviewMode,
                    setReviewMode,
                    updateSearchParams,
                    setUpdateTrigger
                }) {
    const {user} = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [regrades, setRegrades] = useState([]);
    const navigate = useNavigate();
    const [showSubQuestions, setShowSubQuestions] = useState(false);
    const [animatingToSubQuestions, setAnimatingToSubQuestions] = useState(false);
    const [examPaper, setExamPaper] = useState([]);


    useEffect(() => {
        getRegrades();
    }, []);


    useEffect(() => {
        if (submissionsError) {
            setErrorMessage(submissionsError);
        }
    }, [submissionsError]);

    // If the assignment is a flexible problem set, ensure we're not using Default view
    useEffect(() => {
        if (assignment?.type === 'flexible_problem_set' && reviewMode === 'Default') {
            setReviewMode('Student');
        }
    }, [assignment, reviewMode]);


    const getQuestions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setExamPaper(response.data.questions);
        } catch (error) {
            console.error('Error fetching questions:', error);
            setErrorMessage('Failed to fetch questions');
        }
    };


    useEffect(() => {
        if (assignment?.id) {
            getQuestions();
        }
    }, [assignment])


    const handleStartGrading = (subQuestionId) => {
        navigate(`group/0`, {
            state: {
                students: (submissions?.entities ?? []).slice(0, 10),
                allStudents: submissions?.entities ?? [],
                initialSubQuestion: subQuestionId
            }
        });
    };


    const getRegrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/regrade-requests/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const regradesForTeacher = response.data;
            console.log(regradesForTeacher);
            setRegrades(regradesForTeacher);
        } catch (error) {
            console.log(error);
        }
    };

    const hasRegrade = submission => {
        for (var i = 0; i < regrades.length; i++) {
            if (regrades[i].id === submission.id) {
                return true;
            }
        }
        return false;
    };


    const handleUploadClick = studentId => {
        setSelectedStudentId(studentId);
        setShowUploadModal(true);
    };


    const handleCloseModal = () => {
        setShowUploadModal(false);
        setSelectedStudentId(null);
    };


    function process_string(input_) {
        if (!Array.isArray(input_)) {
            return '';
        }
        const filteredInput = input_.filter(item => item != null);
        return filteredInput.join(', ');
    }


    const columnNames = ['Student', 'Status', 'Date Submitted', 'Grade', ' '];


    const handleGroupClick = (groupIndex, students, subQuestion = null) => {
        if (subQuestion) {
            // If a specific subquestion was selected
            navigate(`group/${groupIndex}`, {
                state: {
                    students,
                    allStudents: submissions?.entities ?? [],
                    initialSubQuestion: subQuestion.subQuestionId
                }
            });
        } else {
            // Original behavior - just go to the group
            navigate(`group/${groupIndex}`, {
                state: {
                    students,
                    allStudents: submissions?.entities ?? []
                }
            });
        }
    };


    if (!assignment || !submissions) {
        return <Loading/>;
    }


    return (
        <div id="HomeTeacher" className="flex flex-row">
            <div id="Main-Content-container" className="flex flex-col space-y-8 w-full">
                <div className="flex flex-col w-full">
                    <h2 className="text-xl font-bold mb-1">
                        Step {assignment ? (assignment.type === 'essay' ? 2 : assignment.is_exam ? 4 : 2) : 2}:
                        Review grades
                    </h2>
                    <p className="text-sm mb-4">
                        After submission upload, suggested grades will be available within two minutes.
                    </p>
                </div>


                {assignment.type !== 'essay' && (
                    <div className="flex justify-start mb-4">
                        <div className="flex space-x-4">
                            {assignment.type !== 'flexible_problem_set' && (
                                <button
                                    className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-300 ${reviewMode === 'Default'
                                        ? 'bg-orange-200 border-orange-300 text-black shadow-md'
                                        : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50'
                                    } border`}
                                    onClick={() => setReviewMode('Default')}
                                >
                                    Default
                                </button>
                            )}

                            <button
                                className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-300 ${reviewMode === 'Student'
                                    ? 'bg-orange-200 border-orange-300 text-black shadow-md'
                                    : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50'
                                } border`}
                                onClick={() => setReviewMode('Student')}
                            >
                                View by Student
                            </button>

                            <button
                                className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-300 ${reviewMode === 'Question'
                                    ? 'bg-orange-200 border-orange-300 text-black shadow-md'
                                    : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50'
                                } border`}
                                onClick={() => setReviewMode('Question')}
                            >
                                {`View by ${assignment.type === 'flexible_problem_set' ? 'Question' : 'Sub-Question'}`}
                            </button>
                        </div>
                    </div>
                )}

                {reviewMode === 'Default' && (
                    <div className="mx-4 mb-4 p-4 bg-amber-50 border border-amber-300 rounded-lg shadow-sm">
                        <div className="flex items-start">
                            <div className="flex-shrink-0 mt-0.5">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-amber-500"
                                     viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                          clipRule="evenodd"/>
                                </svg>
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-amber-800">Important Notice</h3>
                                <div className="mt-1 text-sm text-amber-700">
                                    Default reviewing is a new feature and might NOT be functional for assignments
                                    created prior to Mar 15, 2025. Use "View by Student" or "View by Sub-Question"
                                    instead.
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="ml-4 bg-white">
                    {errorMessage && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {errorMessage}
                        </div>
                    )}
                    {reviewMode === 'Default' && assignment.type !== 'flexible_problem_set' ? (
                        <div className="w-full rounded-xl overflow-hidden border border-gray-200">
                            {/* Replace the table with our new hierarchical component */}
                            <HierarchicalQuestionView
                                examPaper={examPaper}
                                submissions={submissions}
                                handleStartGrading={handleStartGrading}
                            />
                        </div>
                    ) : (
                        <Table
                            isLoading={isLoading}
                            noDataTitle="No submissions found"
                            totalCount={submissions?.totalCount}
                            itemsPerPage={Number(itemsPerPage)}
                            offset={submissions?.offset}
                            nextOffset={submissions?.nextOffset}
                            pageNumber={Number(pageNumber)}
                            columnNames={columnNames}
                            columnBodies={(submissions?.entities ?? []).map(value => (
                                <tr
                                    key={`${value.full_name + ' ' + value.studentId}`}
                                    className="border-b border-border"
                                >
                                    <td className="text-left text-gray-500 custom-16-semi px-6 py-3">
                                        <div className="flex items-center">
                                            {value.is_reviewed && (
                                                <div className="w-2 h-2 bg-orange-400 rounded-full mr-2"></div>
                                            )}
                                            {hasRegrade(value) && (
                                                <div
                                                    className={`size-9 flex items-center justify-center rounded-full transition-all duration-300`}
                                                >
                                                    <img
                                                        src="/assets/sidebar/quote-request.png"
                                                        alt="Regrade Request"
                                                        className="size-5"
                                                    />
                                                </div>
                                            )}
                                            {value.status !== 'Not submitted' && value.status !== 'Grading' ? (
                                                <Link
                                                    to={`/tclasses/${assignment.course_id}/tassignments/${assignment.id}/tsubmissions/${value.id}/tdetails`}
                                                    className="hover:underline text-black relative group"
                                                >
                                                    {value.full_name ?? value.email}
                                                    {assignment.is_group_task ? (
                                                        <div
                                                            className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap max-w-xs overflow-hidden text-ellipsis">
                                                            Group: {process_string(value.team_members)}
                                                        </div>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </Link>
                                            ) : (
                                                <span>
                                                   {value.full_name ?? value.email}
                                                    <div
                                                        className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap max-w-xs overflow-hidden text-ellipsis">
                                                       Group: {process_string(value.team_members)}
                                                   </div>
                                               </span>
                                            )}
                                        </div>
                                    </td>
                                    <td className="text-left text-black custom-16-med px-6 py-3">{value.status}</td>
                                    <td className="text-left text-black custom-16-med px-6 py-3">
                                        {value.id !== null && value.id !== undefined && !!value.submission_date ? (
                                            <DueTimeDisplayTable
                                                datetimeString={value.submission_date}
                                                timezone={user.user.timezone}
                                            />
                                        ) : (
                                            <span className="text-gray-500">N/A</span>
                                        )}
                                    </td>
                                    <td className="text-left text-black custom-16-med px-6 py-3">
                                        {value.grade !== null && value.grade !== undefined ? (
                                            `${value.grade}/${assignment.max_score}`
                                        ) : (
                                            <span className="text-gray-500">N/A</span>
                                        )}
                                    </td>
                                    {(assignment.type === 'pdf_problem_set' || assignment.type === 'byob_pdf_problem_set') &&
                                        !assignment.is_group_task &&
                                        assignment.is_assignment_published && (
                                            <td className="text-left text-black custom-16-med px-6 py-3">
                                                <button
                                                    className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                                    onClick={() => handleUploadClick(value.studentId)}
                                                >
                                                    Upload
                                                </button>
                                            </td>
                                        )}
                                </tr>
                            ))}
                            updateSearchParams={updateSearchParams}
                        />
                    )}
                    {showUploadModal && (
                        <TeacherStudentUpload
                            assignmentId={assignment.id}
                            studentId={selectedStudentId}
                            setUpdateTrigger={setUpdateTrigger}
                            onClose={handleCloseModal}
                        />
                    )}
                </div>
            </div>
            {showSubQuestions && selectedGroup && (
                <div className="fixed inset-0 z-50 bg-white bg-opacity-95 flex flex-col items-center justify-center">
                    <div
                        className={`w-full max-w-3xl mx-auto transition-all duration-500 transform ${
                            animatingToSubQuestions ? 'translate-x-full opacity-0' : 'translate-x-0 opacity-100'
                        }`}
                    >
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold">Select a subquestion to grade</h2>
                            <button
                                className="p-2 rounded-full hover:bg-gray-100"
                                onClick={() => {
                                    setAnimatingToSubQuestions(true);
                                    setTimeout(() => {
                                        setShowSubQuestions(false);
                                        setAnimatingToSubQuestions(false);
                                    }, 400);
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>


                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-[70vh] overflow-y-auto p-4">
                            {assignment && assignment.questions && assignment.questions.map((question, qIndex) => (
                                <React.Fragment key={qIndex}>
                                    {question.subQuestions && question.subQuestions.map((subQuestion, sqIndex) => (
                                        <div
                                            key={`${qIndex}-${sqIndex}`}
                                            className="bg-white border border-gray-200 hover:border-orange-300 hover:shadow-md rounded-xl p-4 cursor-pointer transition-all duration-200"
                                            onClick={() => {
                                                handleGroupClick(selectedGroupIndex, selectedGroup, {
                                                    questionId: question.id,
                                                    subQuestionId: subQuestion.id
                                                });
                                            }}
                                        >
                                            <div className="flex items-center gap-3 mb-2">
                                                <div
                                                    className="w-10 h-10 bg-orange-100 text-orange-800 rounded-full flex items-center justify-center font-medium">
                                                    {qIndex + 1}{String.fromCharCode(97 + sqIndex)}
                                                </div>
                                                <h3 className="font-semibold text-gray-800">
                                                    Question {qIndex + 1}{String.fromCharCode(97 + sqIndex)}
                                                </h3>
                                            </div>
                                            <p className="text-gray-600 line-clamp-2">{subQuestion.question}</p>
                                        </div>
                                    ))}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


export default TGrade;