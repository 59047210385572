import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {gradeApi} from '../../api';
import '../../App.css';
import {useAuth} from '../../auth/AuthContext';
import {Loading} from '../../components/Loading';
import TDetails from '../../components/Teacher/TGrading/TDetails';

function TDetailsPage() {
    let {assignment_id, submission_id, class_id, regrade_question_id} = useParams();
    const [submissionIds, setSubmissionIds] = useState(null);
    const [submissionUnseenIds, setSubmissionUnseenIds] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {user} = useAuth();

    useEffect(() => {
        if (regrade_question_id) {
            getRegrades();
        } else {
            getSubmissions();
            getUnseenSubmissions();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment_id, regrade_question_id]);

    const getSubmissions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/v2/assignments/${assignment_id}/submissions/ids`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            console.log(response.data);

            setSubmissionIds(response.data.ids); // Ensure the correct property
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch submissions. Please try again.');
            setLoading(false);
        }
    };

    const getUnseenSubmissions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/v2/assignments/${assignment_id}/submissions/ids`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    },
                    params: {
                        isUnseenOnly: true
                    }
                }
            });

            setSubmissionUnseenIds(response.data.ids);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch unseen submissions. Please try again.');
            setLoading(false);
        }
    };

    const getRegrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/regrade-requests/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setSubmissionIds(response.data.map(regrade => regrade.id));
            setLoading(false);
        } catch (error) {
            setError('Failed regrade requests. Please try again.');
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading/>;
    }

    return (
        <>
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
            {submissionIds ? (
                <TDetails
                    class_id={class_id}
                    assignment_id={assignment_id}
                    submission_id={submission_id}
                    submissionIds={submissionIds}
                    submissionUnseenIds={submissionUnseenIds}
                    regrade_question_id={regrade_question_id}
                />
            ) : (
                <div>No submissions found.</div>
            )}
        </>
    );
}

export default TDetailsPage;
