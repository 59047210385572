import React from 'react';
import { Upload, CheckCircle, ArrowRight } from 'lucide-react';

const SolutionUploadComponent = ({
    uploadHover,
    uploadStatus,
    isUploading,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleProcessSolutions,
    handleFileChange
}) => {
    return (
        <div className="upload-section">
            <div className="upload-boxes-container">
                {/* Upload box for solutions */}
                <div
                    className={`upload-box ${uploadHover ? "hover" : ""} ${uploadStatus.withSolutions ? "uploaded" : ""}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        id="rubric-pdf-upload-solutions"
                        accept=".pdf"
                        className="hidden"
                        onChange={(e) => handleFileChange(e, 'withSolutions')}
                    />
                    <label htmlFor="rubric-pdf-upload-solutions" className="upload-label">
                        {isUploading.withSolutions ? (
                            <div className="uploading-overlay">
                                <div className="uploading-spinner"></div>
                                <span className="uploading-text">Uploading...</span>
                            </div>
                        ) : uploadStatus.withSolutions ? (
                            <div className="success-overlay">
                                <div className="success-checkmark">
                                    <CheckCircle />
                                </div>
                                <span className="success-text">PDF Uploaded</span>
                                <span className="success-subtext">Ready for processing</span>
                            </div>
                        ) : null}
                        <Upload color="#e4b17a"
                            className={`upload-icon w-12 h-12 ${uploadHover ? "bounce" : ""}`} />
                        <span>Upload Solved Questions PDF</span>
                        <p>Click to browse or drag and drop the completed assignment PDF with solutions</p>
                        <div className="upload-tip">
                            <span>Step 3: Upload PDF with solutions</span>
                        </div>
                    </label>
                </div>
            </div>

            {/* Proceed Button */}
            <div className="proceed-button-container">
                <button
                    className={`proceed-button ${uploadStatus.withSolutions ? 'enabled' : 'disabled'}`}
                    disabled={!uploadStatus.withSolutions}
                    onClick={() => {
                        if (uploadStatus.withSolutions) {
                            handleProcessSolutions();
                        }
                    }}
                >
                    <ArrowRight className="w-5 h-5" />
                    <span>Proceed to Analysis</span>
                </button>
                {!uploadStatus.withSolutions && (
                    <p className="proceed-hint">Please upload the completed assignment PDF to proceed</p>
                )}
            </div>
        </div>
    );
};

export default SolutionUploadComponent;
