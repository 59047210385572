// "use client";
import React from "react";
import {useState, useEffect, useRef,} from "react";
// import { useRouter } from "next/navigation";
import {useNavigate, useParams} from "react-router-dom";
import PDFViewer from "../../components/PDFViewer";
import {FileText, Box, ArrowRight, Check, CheckCircle, ArrowLeft} from "lucide-react";
import {pdfToImage, pdfToSingleImage} from "../../utils/pdfToImage";
import "./page.scss";
import RightPanel from "../../components/RightPanel";
import "../../components/styling/RightPanel.scss";
import {getDocument} from "pdfjs-dist";
import {generateThumbnail} from "../../utils/generateThumbnail";
import {convertPdfToBase64} from "../../utils/convertFileToBase64";
import PDFPreviewModal from "../../components/PDFPreviewModal";
import {gradeApi} from '../../../../../api.js';
import {useAuth} from "../../../../../auth/AuthContext";
import PointsModal from "./PointsModal";
import ConfirmationModal from "../../../../../components/ConfirmationModal";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {transformJSON} from "../../components/QuestionsView";
import {toast} from "react-hot-toast";

// import Link from "next/link";

export default function PDFAssignmentTemplate() {
    const navigate = useNavigate();
    const {user} = useAuth();
    const dispatch = useDispatch();
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [questions, setQuestions] = useState({});
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfImage, setPdfImage] = useState(null);
    const [isDrawMode, setIsDrawMode] = useState(false);
    const [savedBoxes, setSavedBoxes] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [drawingPhase, setDrawingPhase] = useState(null); // null, 'first-click', or 'second-click'
    const [startPoint, setStartPoint] = useState(null);
    const [currentPoint, setCurrentPoint] = useState(null);
    const viewerRef = useRef(null);
    const overlayRef = useRef(null);
    const [saveDialog, setSaveDialog] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isPageTransitioning, setIsPageTransitioning] = useState(false);
    const [savedBoxesByPage, setSavedBoxesByPage] = useState({});
    const [pageThumbnails, setPageThumbnails] = useState({});
    const [boundaryWarning, setBoundaryWarning] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
    const [generatedPdfUrl, setGeneratedPdfUrl] = useState(null);
    const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
    const [isPointsModalOpen, setIsPointsModalOpen] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isExam, setIsExam] = useState(false);
    const [alignmentMarkerBoundary, setAlignmentMarkerBoundary] = useState({
        left: 3, // H_MARGIN_PERCENT from backend
        right: 97, // 100 - H_MARGIN_PERCENT
        top: 5.5, // V_MARGIN_PERCENT + TOP_MARKER_SHIFT from backend
        bottom: 97, // 100 - V_MARGIN_PERCENT
    });
    // Store name and ID regions with their page numbers
    const [nameRegion, setNameRegion] = useState(null);
    const [idRegion, setIdRegion] = useState(null);
    const [examFieldMode, setExamFieldMode] = useState(null);

    let {class_id} = useParams();
    let {assignment_id} = useParams();

    useEffect(() => {
        localStorage.setItem('axis', JSON.stringify(savedBoxesByPage))
    }, [savedBoxesByPage]);

    const handleExamFieldSelect = (fieldType) => {
        // First cancel any question selection
        setSelectedQuestion(null);

        // Set the active exam field mode and enable drawing just like question selection
        setExamFieldMode(fieldType);
        setIsDrawMode(true);

        // Reset drawing phase to prepare for new selection
        setDrawingPhase(null);
        setStartPoint(null);
        setCurrentPoint(null);
        setIsDrawing(false);
    };


    const getBlobFromBase64 = (base64String) => {
        const byteCharacters = atob(base64String.split(",")[1]); // Remove the data URL prefix
        const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], {type: "application/pdf"});
    };


    useEffect(() => {
        if (typeof window !== "undefined") {
            // Use the marked PDF with alignment markers instead of the blank one
            const markedPdf = localStorage.getItem("markedPdfUrl");
            const savedPdf = markedPdf || localStorage.getItem("questionsUrl");

            if (!savedPdf) {
                navigate("/");
                return;
            }

            // Create function to fetch questions
            const fetchQuestions = async () => {
                try {
                    const response = await gradeApi.get({
                        path: `/api/assignment/${assignment_id}/byob-questions/`,
                        config: {
                            headers: {
                                Authorization: `Bearer ${user.session_token}`
                            }
                        }
                    });

                    // Parse and process questions
                    const allQuestions = response.data;
                    setPdfFile(savedPdf);
                    setQuestions(allQuestions);

                } catch (error) {
                    console.error('Error fetching questions:', error);
                }
            };

            const fetchAssignmentOnly = async (assignmentId) => {
                try {
                    const response = await gradeApi.get({
                        path: `/api/assignment/${assignment_id}/only/`,
                        config: {
                            headers: {
                                Authorization: `Bearer ${user.session_token}`
                            }
                        }
                    });

                    // Return the assignment data
                    setIsExam(response.data.is_exam);
                } catch (error) {
                    console.error('Error fetching assignment:', error);
                    throw error;
                }
            };

            fetchQuestions();
            fetchAssignmentOnly();
        }
    }, [assignment_id, user.session_token, navigate]);

    // Add effect to load PDF and generate thumbnails
    useEffect(() => {
        if (pdfFile) {
            loadPdfAndGenerateThumbnails();
        }
    }, [pdfFile]);

    const loadPdfAndGenerateThumbnails = async () => {
        try {
            // Load the PDF document
            const pdf = await getDocument(pdfFile).promise;
            setTotalPages(pdf.numPages);

            // Generate thumbnails for all pages
            const thumbnails = {};
            for (let i = 1; i <= pdf.numPages; i++) {
                const thumbnail = await generateThumbnail({
                    pdfUrl: pdfFile,
                    pageNumber: i,
                    width: 150,
                });
                thumbnails[i] = thumbnail;
            }
            setPageThumbnails(thumbnails);

            // Convert first page to image for the viewer
            const firstPageImage = await pdfToSingleImage({
                file: pdfFile,
                pageNumber: 1,
            });
            setPdfImage(firstPageImage);
        } catch (error) {
            console.error("Error loading PDF:", error);
            // Redirect to root on any PDF loading error
            // navigate("/");
        }
    };

    // Update pdfToImage effect to handle page changes
    useEffect(() => {
        if (pdfFile && currentPage) {
            convertPdfToImage();
        }
    }, [pdfFile, currentPage]);

    const convertPdfToImage = async () => {
        try {
            const image = await pdfToSingleImage({
                file: pdfFile,
                pageNumber: currentPage,
            });
            setPdfImage(image);
            setIsPageTransitioning(false);
        } catch (error) {
            console.error("Error converting PDF to scrollable images:", error);
            setIsPageTransitioning(false);
            // Redirect to root if PDF fails to load
            // navigate("/");
        }
    };

    const [confirmationDialog, setConfirmationDialog] = useState(null);

    const handleQuestionSelect = (questionId) => {
        // Check if this question or any of its subquestions already has a box on ANY page
        const questionHasBoxOnAnyPage = (qId) => {
            // Check across all pages in savedBoxesByPage
            return Object.values(savedBoxesByPage).some(pageBoxes =>
                pageBoxes.some(box => box.questionId === qId)
            );
        };

        // Check if this is a main question (doesn't contain a hyphen)
        if (!questionId.includes('-')) {
            const mainQuestion = questions[questionId];

            // Check if the main question already has a box anywhere
            const mainQuestionHasBox = questionHasBoxOnAnyPage(questionId) || (
                mainQuestion?.answerBox &&
                typeof mainQuestion.answerBox === 'object' &&
                Object.keys(mainQuestion.answerBox).length > 0
            );

            if (mainQuestionHasBox) {
                // If the main question already has a box, just select it without confirmation
                setSelectedQuestion(questionId);
                setIsDrawMode(true);
                return;
            }

            // Check if any subquestion of this main question has a box anywhere
            const hasSubquestionBoxes = mainQuestion?.subquestions.some(sub => {
                const subQuestionId = `${questionId}-${sub.letter}`;
                return questionHasBoxOnAnyPage(subQuestionId) || (
                    sub.answerBox &&
                    typeof sub.answerBox === 'object' &&
                    Object.keys(sub.answerBox).length > 0
                );
            });

            // Debug log to check what's happening
            console.log('Question ID:', questionId);
            console.log('Has subquestion boxes?', hasSubquestionBoxes);
            console.log('Subquestions:', mainQuestion?.subquestions);

            if (hasSubquestionBoxes) {
                // Show warning that subquestion boxes exist
                setConfirmationDialog({
                    questionId,
                    message: "Some subquestions already have answer boxes. Drawing a box for the entire question will remove these boxes and merge all subquestions. Do you want to continue?",
                    onConfirm: () => {
                        // Immediately remove all subquestion boxes when user confirms
                        const updatedQuestions = {...questions};
                        updatedQuestions[questionId] = {
                            ...updatedQuestions[questionId],
                            subquestions: updatedQuestions[questionId].subquestions.map(sub => ({
                                ...sub,
                                answerBox: null // Remove all subquestion boxes
                            }))
                        };

                        // Remove subquestion boxes from ALL pages
                        const subQuestionIds = updatedQuestions[questionId].subquestions.map(
                            sub => `${questionId}-${sub.letter}`
                        );

                        // Create a copy of savedBoxesByPage to update
                        const updatedBoxesByPage = {...savedBoxesByPage};

                        // Remove all subquestion boxes from every page
                        Object.keys(updatedBoxesByPage).forEach(pageNum => {
                            if (updatedBoxesByPage[pageNum]) {
                                updatedBoxesByPage[pageNum] = updatedBoxesByPage[pageNum].filter(
                                    box => !subQuestionIds.includes(box.questionId)
                                );
                            }
                        });

                        // Update the current page's boxes for the UI
                        const filteredBoxes = updatedBoxesByPage[currentPage] || [];
                        setSavedBoxes(filteredBoxes);
                        setSavedBoxesByPage(updatedBoxesByPage);

                        setQuestions(updatedQuestions);
                        setSelectedQuestion(questionId);
                        setIsDrawMode(true);
                        setConfirmationDialog(null);
                    }
                });
                return;
            }

            // Show confirmation dialog for main questions
            setConfirmationDialog({
                questionId,
                message: "Drawing a box for the entire question will merge all subquestions into a single answer box. Do you want to continue?",
                onConfirm: () => {
                    setSelectedQuestion(questionId);
                    setIsDrawMode(true);
                    setConfirmationDialog(null);
                }
                // onClose is handled by the ConfirmationModal component directly
            });
            return;
        }

        // For subquestions, check if main question already has a box on any page
        const baseQuestionId = questionId.split('-')[0];
        // Check if this main question has a box on any page
        const mainQuestionHasBox = questionHasBoxOnAnyPage(baseQuestionId) || (
            questions[baseQuestionId]?.answerBox &&
            typeof questions[baseQuestionId].answerBox === 'object' &&
            Object.keys(questions[baseQuestionId].answerBox).length > 0
        );

        if (mainQuestionHasBox) {
            setConfirmationDialog({
                questionId,
                message: "The main question already has an answer box. Drawing boxes for individual subquestions will remove the main question box. Do you want to continue?",
                onConfirm: () => {
                    // Immediately remove the main question box when user confirms
                    const updatedQuestions = {...questions};
                    updatedQuestions[baseQuestionId] = {
                        ...updatedQuestions[baseQuestionId],
                        answerBox: null // Remove main question box
                    };

                    // Remove the main question box from ALL pages
                    // Create a copy of savedBoxesByPage to update
                    const updatedBoxesByPage = {...savedBoxesByPage};

                    // Remove the main question box from every page
                    Object.keys(updatedBoxesByPage).forEach(pageNum => {
                        if (updatedBoxesByPage[pageNum]) {
                            updatedBoxesByPage[pageNum] = updatedBoxesByPage[pageNum].filter(
                                box => box.questionId !== baseQuestionId
                            );
                        }
                    });

                    // Update the current page's boxes for the UI
                    const filteredBoxes = updatedBoxesByPage[currentPage] || [];
                    setSavedBoxes(filteredBoxes);
                    setSavedBoxesByPage(updatedBoxesByPage);

                    setQuestions(updatedQuestions);
                    setSelectedQuestion(questionId);
                    setIsDrawMode(true);
                    setConfirmationDialog(null);
                }
            });
            return;
        }

        // Proceed as normal
        setSelectedQuestion(questionId);
        setIsDrawMode(true);
    };

    // Add escape key handler
    useEffect(() => {
        const handleEscapeKey = (e) => {
            if (e.key === "Escape") {
                setSelectedQuestion(null);
                setIsDrawMode(false);
                setExamFieldMode(null);
            }
        };

        window.addEventListener("keydown", handleEscapeKey);
        return () => window.removeEventListener("keydown", handleEscapeKey);
    }, []);

    // Add cursor indicator class based on draw mode
    const cursorClass = isDrawMode || examFieldMode !== null
        ? "cursor-crosshair"
        : selectedQuestion
            ? "cursor-not-allowed"
            : "cursor-default";

    const handlePreviewClick = () => {
        if (!areAllQuestionsBoxed(questions)) {
            const message = getMissingBoxesMessage(questions);
            toast.error(message, {
                duration: 5000,
                style: {
                    background: '#F3F4F6',
                    color: '#374151',
                    border: '1px solid #E5E7EB',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                },
                icon: '⚠️',
            });
            return;
        }

        setIsPointsModalOpen(true);
    };

    const saveTemplate = async () => {
        setBoundaryWarning(null);
        console.log('Saving template...');

        // First check if questions and their positions are all valid
        // (There should be at least one question assigned)
        const hasAssignedQuestions = Object.values(savedBoxesByPage).some(
            (pageBoxes) => pageBoxes.length > 0
        );

        if (!hasAssignedQuestions) {
            toast.error("You must assign at least one question before saving the template.");
            return;
        }

        // If it's an exam, check that name and ID regions are defined
        if (isExam && (!nameRegion || !idRegion)) {
            toast.error("For exams, you must define both Name and ID regions before saving.");
            return;
        }

        // Show confirmation dialog before generating
        setSaveDialog({
            message: "Are you sure you want to create the assignment template? Once created, you cannot edit the template further.",
            onConfirm: async () => {
                try {
                    setIsGenerating(true);
                    // Transform the data structure to match what the backend expects
                    const transformedQuestions = transformJSON({
                        questions,
                        savedBoxesByPage
                    });

                    // Now format the data for sending to the backend
                    const fullTemplate = {
                        questions: transformedQuestions,
                        // Include exam field regions if it's an exam
                        ...(isExam && {
                            name_region: nameRegion ? {
                                page: nameRegion.page, // Use page stored in nameRegion
                                x: nameRegion.x,
                                y: nameRegion.y,
                                width: nameRegion.width,
                                height: nameRegion.height
                            } : null,
                            id_region: idRegion ? {
                                page: idRegion.page, // Use page stored in idRegion
                                x: idRegion.x,
                                y: idRegion.y,
                                width: idRegion.width,
                                height: idRegion.height
                            } : null
                        })
                    };

                    // Send the data to the backend
                    const response = await gradeApi.post({
                        path: `/api/assignment/${assignment_id}/template/`,
                        data: fullTemplate,
                        config: {
                            headers: {
                                Authorization: `Bearer ${user.session_token}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    });

                    if (response.status === 200 || response.status === 201) {
                        // Show success and redirect
                        toast.success("Assignment template created successfully!");
                        setSaveDialog(null);

                        // Redirect to class details page
                        navigate(`/dashboard/${class_id}/assignments`);
                    } else {
                        // Handle unexpected status
                        toast.error(`Error saving template: Unexpected status ${response.status}`);
                    }
                } catch (error) {
                    console.error("Error saving template:", error);
                    toast.error(error.response?.data?.message || "Error saving the template. Please try again.");
                } finally {
                    setIsGenerating(false);
                }
            }
        });
    };

    const areAllQuestionsBoxed = (questions) => {
        if (!questions || Object.keys(questions).length === 0) {
            return false;
        }

        return Object.entries(questions).every(([id, question]) => {
            // If the main question has an answer box, it counts as all subquestions being boxed
            if (question.answerBox) {
                return true;
            }
            // Otherwise, check if all subquestions have answer boxes
            return question.subquestions && question.subquestions.every(sub => sub.answerBox);
        });
    };

    const areAllRequiredFieldsSelected = () => {
        // First check if all questions have boxes
        const questionsBoxed = areAllQuestionsBoxed(questions);

        // If it's an exam, also check that name and ID regions are defined
        if (isExam) {
            return questionsBoxed && nameRegion && idRegion;
        }

        // For non-exam assignments, just check questions
        return questionsBoxed;
    };

    const getMissingBoxesMessage = (questions) => {
        if (!questions || Object.keys(questions).length === 0) {
            return "No questions available";
        }

        const missingBoxes = [];

        Object.entries(questions).forEach(([id, question]) => {
            if (!question.answerBox) {
                // If main question doesn't have a box, check if any subquestions are missing boxes
                const missingSubquestions = question.subquestions
                    .filter(sub => !sub.answerBox)
                    .map(sub => `${id}-${sub.letter}`);

                if (missingSubquestions.length > 0) {
                    missingBoxes.push(...missingSubquestions);
                } else if (question.subquestions.length === 0) {
                    // If there are no subquestions and no main box
                    missingBoxes.push(id);
                }
            }
        });

        if (missingBoxes.length === 0) {
            // If all questions have boxes but name/ID fields are missing on an exam
            if (isExam && (!nameRegion || !idRegion)) {
                const missing = [];
                if (!nameRegion) {
                    missing.push("Name field");
                }
                if (!idRegion) {
                    missing.push("ID field");
                }
                return `Please select the following regions: ${missing.join(', ')}`;
            }
            return "";
        }

        // If we're in exam mode and missing name/ID regions, add those to the message
        if (isExam && (!nameRegion || !idRegion)) {
            const missing = [];
            if (!nameRegion) {
                missing.push("Name field");
            }
            if (!idRegion) {
                missing.push("ID field");
            }
            return `Please draw boxes for the following questions: ${missingBoxes.join(', ')} and select the following regions: ${missing.join(', ')}`;
        }

        return `Please draw boxes for the following questions: ${missingBoxes.join(', ')}`;
    };

    const handleProceedClick = () => {
        if (!areAllRequiredFieldsSelected()) {
            const message = getMissingBoxesMessage(questions);
            toast.error(message, {
                duration: 5000,
                style: {
                    background: '#F3F4F6',
                    color: '#374151',
                    border: '1px solid #E5E7EB',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                },
                icon: '⚠️',
            });
            return;
        }

        setIsPointsModalOpen(true);
    };

    const mouseActions = {
        handleClick: (e) => {
            if (e.target.closest('.saved-box')) {
                return;
            }

            // Only proceed if we're in draw mode for questions or have an exam field mode active
            if (!isDrawMode && !examFieldMode) {
                return;
            }

            const rect = overlayRef.current.getBoundingClientRect();
            const x = ((e.clientX - rect.left) / rect.width) * 100;
            const y = ((e.clientY - rect.top) / rect.height) * 100;

            // Check if the click is within the alignment marker boundaries
            if (x < alignmentMarkerBoundary.left || x > alignmentMarkerBoundary.right ||
                y < alignmentMarkerBoundary.top || y > alignmentMarkerBoundary.bottom) {
                // Click is outside the valid area
                return;
            }

            // First click to start drawing
            if (!drawingPhase || drawingPhase === 'completed') {
                setStartPoint({x, y});
                setCurrentPoint({x, y});
                setDrawingPhase('first-click');
                setIsDrawing(true);
                return;
            }

            // Second click to complete the drawing
            if (drawingPhase === 'first-click') {
                setDrawingPhase('completed');
                completeBoxDrawing(x, y);
            }
        },

        handleMouseMove: (e) => {
            if (drawingPhase !== 'first-click') {
                return;
            }

            const rect = overlayRef.current.getBoundingClientRect();
            const x = ((e.clientX - rect.left) / rect.width) * 100;
            const y = ((e.clientY - rect.top) / rect.height) * 100;

            // Constrain the cursor position within the alignment marker boundaries
            const constrainedX = Math.min(Math.max(x, alignmentMarkerBoundary.left), alignmentMarkerBoundary.right);
            const constrainedY = Math.min(Math.max(y, alignmentMarkerBoundary.top), alignmentMarkerBoundary.bottom);

            setCurrentPoint({x: constrainedX, y: constrainedY});
        },

        // We don't need mouseUp anymore, but keeping an empty function for compatibility
        handleMouseUp: () => {
        }
    };

    // Function to handle completing the box drawing
    const completeBoxDrawing = (endX, endY) => {
        if (!startPoint) {
            return;
        }

        // Constrain the end points within the alignment marker boundaries
        const constrainedEndX = Math.min(Math.max(endX, alignmentMarkerBoundary.left), alignmentMarkerBoundary.right);
        const constrainedEndY = Math.min(Math.max(endY, alignmentMarkerBoundary.top), alignmentMarkerBoundary.bottom);

        // Create a box with the coordinates
        const box = {
            id: selectedQuestion || examFieldMode,
            x: Math.min(startPoint.x, constrainedEndX),
            y: Math.min(startPoint.y, constrainedEndY),
            width: Math.abs(constrainedEndX - startPoint.x),
            height: Math.abs(constrainedEndY - startPoint.y),
            questionId: selectedQuestion
        };

        // If in exam field mode, handle name or ID region
        if (examFieldMode) {
            if (examFieldMode === 'name') {
                // Store the current page with the name region
                setNameRegion({...box, page: currentPage});
            } else if (examFieldMode === 'id') {
                // Store the current page with the ID region
                setIdRegion({...box, page: currentPage});
            }

            // Reset exam field mode and drawing state after completion
            setIsDrawing(false);
            setDrawingPhase(null);
            setExamFieldMode(null);
            setIsDrawMode(false);
            return;
        }

        // Continue with question box drawing logic if not in exam field mode
        if (!selectedQuestion) {
            return;
        }

        // Find and remove any existing boxes for this question on ANY page
        // Create a copy of the savedBoxesByPage state to modify
        const updatedBoxesByPage = {...savedBoxesByPage};

        // Remove this question's box from all pages
        Object.keys(updatedBoxesByPage).forEach(pageNum => {
            updatedBoxesByPage[pageNum] = updatedBoxesByPage[pageNum].filter(box =>
                box.questionId !== selectedQuestion
            );
        });

        // Update the current page's boxes
        const currentPageBoxes = updatedBoxesByPage[currentPage] || [];
        const filteredBoxes = currentPageBoxes;

        // Update savedBoxes and savedBoxesByPage
        const newPageBoxes = [...filteredBoxes, box];
        setSavedBoxes(newPageBoxes);

        // Update with our clean copy that has removed this question's box from all pages
        updatedBoxesByPage[currentPage] = newPageBoxes;
        setSavedBoxesByPage(updatedBoxesByPage);

        // Update question state
        const baseQuestionId = selectedQuestion.split("-")[0];
        const subQuestionLetter = selectedQuestion.split("-")[1];


        let updatedQuestions = {...questions};

        if (subQuestionLetter) {
            // If a subquestion was selected, remove any main question box
            // to ensure exclusivity (either main question box OR subquestion boxes)
            updatedQuestions[baseQuestionId] = {
                ...updatedQuestions[baseQuestionId],
                answerBox: null, // Remove main question box if it exists
                subquestions: updatedQuestions[baseQuestionId].subquestions.map(sub =>
                    sub.letter === subQuestionLetter
                        ? {...sub, answerBox: box}
                        : sub
                ),
            };
        } else {
            // If a main question was selected, remove any subquestion boxes
            // to ensure exclusivity
            updatedQuestions[selectedQuestion] = {
                ...updatedQuestions[selectedQuestion],
                answerBox: box,
                subquestions: updatedQuestions[selectedQuestion].subquestions.map(sub => ({
                    ...sub,
                    answerBox: null // Remove all subquestion boxes
                })),
            };

            // Remove boxes from ALL pages for all subquestions of this question
            const subQuestionIds = updatedQuestions[selectedQuestion].subquestions.map(
                sub => `${selectedQuestion}-${sub.letter}`
            );

            // Remove subquestion boxes from every page
            Object.keys(updatedBoxesByPage).forEach(pageNum => {
                if (updatedBoxesByPage[pageNum] && pageNum != currentPage) {  // Skip current page as we've already updated it
                    updatedBoxesByPage[pageNum] = updatedBoxesByPage[pageNum].filter(
                        box => !subQuestionIds.includes(box.questionId)
                    );
                }
            });

            // Update the boxes for the current page
            const updatedPageBoxes = newPageBoxes.filter(
                box => !subQuestionIds.includes(box.questionId)
            );

            setSavedBoxes(updatedPageBoxes);
            updatedBoxesByPage[currentPage] = updatedPageBoxes;
            setSavedBoxesByPage(updatedBoxesByPage);
        }

        setQuestions(updatedQuestions);

        // Reset drawing state completely after completing a box (this fixes the blue highlight issue)
        setIsDrawing(false);
        setDrawingPhase(null);
        setIsDrawMode(false);
        setSelectedQuestion(null);

        let nextQuestion = null;
        const questionEntries = Object.entries(updatedQuestions);

        if (selectedQuestion) {
            const baseQuestionId = selectedQuestion.split("-")[0];
            const subQuestionLetter = selectedQuestion.split("-")[1];

            // Find current question index
            const currentQuestionIndex = questionEntries.findIndex(([id]) => id === baseQuestionId);

            if (currentQuestionIndex !== -1) {
                const currentQuestion = questionEntries[currentQuestionIndex][1];

                if (currentQuestion.subquestions && subQuestionLetter) {
                    // If we're on a subquestion, try to move to next subquestion
                    const currentSubIndex = currentQuestion.subquestions.findIndex(
                        sub => sub.letter === subQuestionLetter
                    );

                    if (currentSubIndex < currentQuestion.subquestions.length - 1) {
                        // Move to next subquestion
                        const nextSub = currentQuestion.subquestions[currentSubIndex + 1];
                        nextQuestion = `${baseQuestionId}-${nextSub.letter}`;
                    } else if (currentQuestionIndex < questionEntries.length - 1) {
                        // Move to next main question or its first subquestion
                        const [nextId, nextQ] = questionEntries[currentQuestionIndex + 1];
                        if (nextQ.subquestions && nextQ.subquestions.length > 0) {
                            nextQuestion = `${nextId}-${nextQ.subquestions[0].letter}`;
                        } else {
                            nextQuestion = nextId;
                        }
                    }
                } else {
                    // If we're on a main question, move to the next question's first subquestion
                    if (currentQuestionIndex < questionEntries.length - 1) {
                        // Move to next question's first subquestion
                        const [nextId, nextQ] = questionEntries[currentQuestionIndex + 1];
                        if (nextQ.subquestions && nextQ.subquestions.length > 0) {
                            nextQuestion = `${nextId}-${nextQ.subquestions[0].letter}`;
                        } else {
                            nextQuestion = nextId;
                        }
                    }
                }
            }
        }

        if (nextQuestion) {
            setSelectedQuestion(nextQuestion);
            setIsDrawMode(true);
        } else {
            setSelectedQuestion(null);
            setIsDrawMode(false);
        }

        setIsDrawing(false);
        setStartPoint(null);
        setCurrentPoint(null);
        setDrawingPhase(null);
    };


    // Update handlePageChange to handle box storage
    const handlePageChange = (newPage) => {
        if (newPage < 1 || newPage > totalPages) {
            setBoundaryWarning(`Page ${newPage} does not exist`);
            return;
        }

        // Save current page boxes
        if (savedBoxes.length > 0) {
            setSavedBoxesByPage((prev) => ({
                ...prev,
                [currentPage]: savedBoxes,
            }));
        }

        setIsPageTransitioning(true);
        setCurrentPage(newPage);

        // Load new page boxes
        setSavedBoxes(savedBoxesByPage[newPage] || []);
        setSelectedBoxIndex(null);
        setIsEditMode(false);
        setBoundaryWarning(null);
    };

    const handleBoxClick = (index) => {
        setSelectedBoxIndex(index);
        setIsEditMode(true);
    };

    const handleBoxDelete = (index) => {
        if (index < 0 || index >= savedBoxes.length) {
            return;
        }

        const boxToDelete = savedBoxes[index];
        if (!boxToDelete.questionId) {
            return;
        }

        // Create a copy of the savedBoxesByPage state to update
        const updatedBoxesByPage = {...savedBoxesByPage};

        // Remove the box with this question ID from ALL pages
        Object.keys(updatedBoxesByPage).forEach(pageNum => {
            updatedBoxesByPage[pageNum] = updatedBoxesByPage[pageNum].filter(box =>
                box.questionId !== boxToDelete.questionId
            );
        });

        // Update the state
        setSavedBoxes(updatedBoxesByPage[currentPage] || []);
        setSavedBoxesByPage(updatedBoxesByPage);

        // Also clear this question's answerBox in the questions state
        const questionId = boxToDelete.questionId;
        const baseQuestionId = questionId.split("-")[0];
        const subQuestionLetter = questionId.split("-")[1];

        let updatedQuestions = {...questions};
        if (subQuestionLetter) {
            // Update subquestion
            updatedQuestions[baseQuestionId] = {
                ...updatedQuestions[baseQuestionId],
                subquestions: updatedQuestions[baseQuestionId].subquestions.map(sub =>
                    sub.letter === subQuestionLetter
                        ? {...sub, answerBox: null}
                        : sub
                )
            };
        } else {
            // Update main question
            updatedQuestions[questionId] = {
                ...updatedQuestions[questionId],
                answerBox: null
            };
        }
        setQuestions(updatedQuestions);
    };

    // Handler for deleting name region
    const handleNameRegionDelete = () => {
        setNameRegion(null);
    };

    // Handler for deleting ID region
    const handleIdRegionDelete = () => {
        setIdRegion(null);
    };

    // Create async thunk for uploading questions
    const uploadQuestionsAction = createAsyncThunk(
        'tpset/uploadQuestions',
        async ({assignment_id, token, body}, thunkApi) => {
            try {
                return await gradeApi.post({
                    path: `/api/assignment/${assignment_id}/constructor/`,
                    body,
                    config: {headers: {Authorization: `Bearer ${token}`}}
                });
            } catch (error) {
                return thunkApi.rejectWithValue(
                    error.response?.data?.error || error.message || 'An error occurred. Please try again.'
                );
            }
        }
    );

    // Function to transform and upload questions to the backend
    const uploadQuestions = async (questions) => {
        // Check if we have constructor data from localStorage (from merged questions)
        const constructorDataStr = localStorage.getItem('constructorData');
        let questionsToTransform = questions;

        if (constructorDataStr) {
            try {
                const constructorData = JSON.parse(constructorDataStr);
                // Use the constructor data if available
                questionsToTransform = constructorData;
                // Clear the localStorage item after using it
                localStorage.removeItem('constructorData');
            } catch (error) {
                console.error('Error parsing constructor data:', error);
                // Fall back to the original questions if there's an error
                questionsToTransform = questions;
            }
        }

        const questions2 = transformJSON(questionsToTransform);
        console.log(questions2);

        const payload = {
            exam_paper: questions2,
            instructions: "Complete this assignment",
            model_instructions: "Grade the student's work carefully. Pay attention to their problem-solving approach, clarity of explanations, and correctness of calculations. Select the appropriate rubric item, based on the demonstrated understanding and effort.",
            feedback_instructions: 'detailed',
            show_points: true,
            hide_first_page: false,
            page: 0,
            allow_pregrade: false,
            bubble_sheet: false
        };

        await dispatch(
            uploadQuestionsAction({assignment_id, token: user.session_token, body: payload})
        );
    };

    const handleGeneratePdf = async () => {
        try {
            // First validate all questions and exam fields have boxes
            if (isExam && (!nameRegion || !idRegion)) {
                const message = getMissingBoxesMessage(questions);
                toast.error(message, {
                    duration: 5000,
                    style: {
                        background: '#F3F4F6',
                        color: '#374151',
                        border: '1px solid #E5E7EB',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                    },
                    icon: '⚠️',
                });
                return;
            }

            setIsGenerating(true);

            // Get the PDF document to access its dimensions
            const pdf = await getDocument(pdfFile).promise;
            const page = await pdf.getPage(1);
            const viewport = page.getViewport({scale: 1.0});

            // Process questions to merge subquestions for main questions with answer boxes
            const processedQuestions = {};

            Object.entries(questions).forEach(([id, question]) => {
                // If the main question has an answer box, merge all subquestions
                if (question.answerBox) {
                    // Merge all subquestion text and solutions
                    let mergedSubquestionText = '';
                    let mergedSolutionText = '';

                    question.subquestions.forEach(sub => {
                        // Add subquestion text with letter
                        mergedSubquestionText += `${sub.letter}) ${sub.text}\n`;

                        // Add solution text with letter
                        mergedSolutionText += `${sub.letter}) ${sub.solution || ''}\n`;
                    });

                    // Create a new merged question
                    processedQuestions[id] = {
                        ...question,
                        mergedSubquestions: true,
                        mergedSubquestionText: mergedSubquestionText.trim(),
                        mergedSolutionText: mergedSolutionText.trim()
                    };
                } else {
                    // Keep the question as is
                    processedQuestions[id] = question;
                }
            });

            // Store the processed questions for later use
            localStorage.setItem('processedQuestions', JSON.stringify(processedQuestions));

            // Also update the constructor endpoint to handle merged questions
            const constructorData = {};

            Object.entries(processedQuestions).forEach(([id, question]) => {
                if (question.mergedSubquestions) {
                    // For merged questions, create a special format for the constructor
                    constructorData[id] = {
                        questionText: question.questionText,
                        type: 'freeform',
                        subquestions: [
                            {
                                letter: 'a',
                                text: question.mergedSubquestionText,
                                type: 'freeform',
                                solution: question.mergedSolutionText,
                                options: [],
                                correctAnswer: null
                            }
                        ]
                    };
                } else {
                    constructorData[id] = question;
                }
            });

            await uploadQuestions(constructorData);

            localStorage.setItem('constructorData', JSON.stringify(constructorData));

            // Prepare request data with optional name and ID regions for exams
            const requestData = {
                axis: savedBoxesByPage,
                base64_pdf: await convertPdfToBase64(pdfFile),
                processedQuestions: processedQuestions,
                constructorData: constructorData
            };

            // Add name and ID region data if this is an exam
            if (isExam) {
                if (nameRegion) {
                    requestData.name_region = {
                        page: nameRegion.page, // Use page stored in nameRegion
                        x: nameRegion.x,
                        y: nameRegion.y,
                        width: nameRegion.width,
                        height: nameRegion.height
                    };
                }

                if (idRegion) {
                    requestData.id_region = {
                        page: idRegion.page, // Use page stored in idRegion
                        x: idRegion.x,
                        y: idRegion.y,
                        width: idRegion.width,
                        height: idRegion.height
                    };
                }
            }

            const response = await gradeApi.post({
                path: `api/assignment/${assignment_id}/byob-problem-set/generate-pdf/`,
                body: requestData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            });

            // Clear the rubricsGenerated flag so the rubric page will start fresh
            localStorage.removeItem('rubricsGenerated');

            // First wait for the PDF generation to complete
            console.log('PDF generation complete, now uploading questions');
            // Upload the questions to the backend to update the question structure

        } catch (error) {
            console.error("Error generating PDF:", error);
            toast.error("Error generating PDF. Please try again.");
        } finally {
            setIsGenerating(false);
        }
    };


    if (!pdfFile || !pdfImage) {
        return null;
    }

    return (
        <div className="app template-editor">
            <div className="page-header">
                <div className="header-left">
                    <a href={`/tclasses/${class_id}/tassignments/${assignment_id}/create/`}
                       className="back-button">
                        <ArrowLeft size={20}/>
                        <span>Back</span>
                    </a>
                    <div className="gradewiz-logo-container">
                        <img src="/gradewiz_logo.png" alt="GradeWiz Logo" className="gradewiz-logo"/>
                        <h1>PDF Answer Section Editor</h1>
                    </div>
                </div>
                <div className="header-actions">
                    <div className="relative">
                        <button
                            className={`proceed-btn ${!areAllRequiredFieldsSelected() ? 'disabled' : ''} ${isGenerating ? 'loading' : ''}`}
                            data-tooltip={!areAllRequiredFieldsSelected() ? getMissingBoxesMessage(questions) : ''}
                            disabled={isGenerating}
                            onClick={handleProceedClick}
                        >
                            {isGenerating ? (
                                <>
                                    <span className="spinner"></span>
                                    <span>Generating Rubric...</span>
                                </>
                            ) : (
                                <>
                                    <CheckCircle className="w-5 h-5"/>
                                    <span>Generate Solution Rubric</span>
                                    <ArrowRight className="w-5 h-5"/>
                                </>
                            )}
                        </button>
                        {!areAllRequiredFieldsSelected() && (
                            <div className="tooltip absolute right-0 mt-2">
                                {getMissingBoxesMessage(questions)}
                            </div>
                        )}
                    </div>
                </div>
                {selectedQuestion && (
                    <div
                        className={`selected-question-indicator ${drawingPhase === 'first-click' ? 'active-drawing' : ''}`}>
                        {drawingPhase === 'first-click' ? (
                            <span><strong>Click to finish</strong> drawing box</span>
                        ) : (
                            <span><strong>Click to start</strong> drawing box for Question {selectedQuestion}</span>
                        )}
                        <button
                            className="deselect-btn"
                            onClick={() => {
                                setSelectedQuestion(null);
                                setIsDrawMode(false);
                                setDrawingPhase(null);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>

            <div className={`editor-content ${cursorClass}`}>
                <div className="questions-panel">
                    <div className="panel-header">
                        <FileText color="#e4b17a" className="w-5 h-5"/>
                        <h2>Questions</h2>
                    </div>

                    {/* Add Exam Field Selection UI */}
                    {isExam && (
                        <div className="exam-field-selection">
                            <div className="questions-list">
                                <div
                                    className={`question-item ${
                                        examFieldMode === 'name' ? 'active' : ''
                                    }`}
                                    onClick={() => handleExamFieldSelect('name')}
                                >
                                    <span className="letter">Name</span>
                                    {nameRegion && (
                                        <Box className="w-3 h-3 text-green-500"/>
                                    )}
                                </div>
                                <div
                                    className={`question-item ${
                                        examFieldMode === 'id' ? 'active' : ''
                                    }`}
                                    onClick={() => handleExamFieldSelect('id')}
                                >
                                    <span className="letter">ID</span>
                                    {idRegion && (
                                        <Box className="w-3 h-3 text-green-500"/>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="questions-list">
                        {Object.entries(questions).map(([id, question], index) => (

                            <div
                                key={id}
                                className={`question-item ${
                                    selectedQuestion === id ? "active" : ""
                                }`}
                                onClick={() => handleQuestionSelect(id)}
                            >
                                <span className="letter">{index + 1}</span>
                                <p className="truncate-text">{question.questionText}</p>
                                {question.answerBox && (
                                    <Box className="w-3 h-3 text-green-500"/>
                                )}

                                {question.subquestions && (
                                    <div className="subquestions-list">
                                        {question.subquestions.map((sub) => (
                                            <div
                                                key={sub.letter}
                                                className={`subquestion-item ${
                                                    selectedQuestion === `${id}-${sub.letter}`
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleQuestionSelect(`${id}-${sub.letter}`);
                                                }}
                                            >
                                                <span className="letter">{sub.letter})</span>
                                                <span className="text truncate-text">{sub.text}</span>
                                                {sub.answerBox && (
                                                    <Box className="w-3 h-3 text-green-500"/>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="pdf-workspace">
                    <PDFViewer
                        file={pdfFile}
                        pdfImage={pdfImage}
                        isDrawMode={isDrawMode || examFieldMode !== null}
                        isDrawing={isDrawing}
                        startPoint={startPoint}
                        currentPoint={currentPoint}
                        drawingPhase={drawingPhase}
                        savedBoxes={savedBoxes}
                        nameRegion={nameRegion && nameRegion.page === currentPage ? nameRegion : null}
                        idRegion={idRegion && idRegion.page === currentPage ? idRegion : null}
                        examFieldMode={examFieldMode}
                        viewerRef={viewerRef}
                        overlayRef={overlayRef}
                        mouseActions={mouseActions}
                        selectedBoxIndex={selectedBoxIndex}
                        setSelectedBoxIndex={setSelectedBoxIndex}
                        setIsEditMode={setIsEditMode}
                        alignmentMarkerBoundary={alignmentMarkerBoundary}
                        onBoxClick={handleBoxClick}
                        onBoxDelete={handleBoxDelete}
                        onNameRegionDelete={handleNameRegionDelete}
                        onIdRegionDelete={handleIdRegionDelete}
                    />
                </div>

                <RightPanel
                    currentPage={currentPage}
                    totalPages={totalPages}
                    isPageTransitioning={isPageTransitioning}
                    savedBoxesByPage={savedBoxesByPage}
                    pageThumbnails={pageThumbnails}
                    setCurrentPage={handlePageChange}
                    setIsPageTransitioning={setIsPageTransitioning}
                    setBoundaryWarning={setBoundaryWarning}
                    setIsEditMode={setIsEditMode}
                    setSelectedBoxIndex={setSelectedBoxIndex}
                />
            </div>

            <ConfirmationModal
                isOpen={confirmationDialog !== null}
                message={confirmationDialog ? confirmationDialog.message : ""}
                onClose={() => setConfirmationDialog(null)}
                onConfirm={() => confirmationDialog && confirmationDialog.onConfirm()}
            />

            <PDFPreviewModal
                isOpen={isPdfModalOpen}
                pdfUrl={generatedPdfUrl}
                onClose={() => setIsPdfModalOpen(false)}
            />

            <PointsModal
                isOpen={isPointsModalOpen}
                onClose={() => setIsPointsModalOpen(false)}
                onSubmit={async (points) => {
                    localStorage.setItem('assignmentPoints', points);
                    // Reset the rubricsGenerated flag
                    localStorage.removeItem('rubricsGenerated');
                    await handleGeneratePdf();
                    navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/create/rubric`);
                }}
            />

            {/* Confirmation dialog for saving template */}
            <ConfirmationModal
                isOpen={saveDialog !== null}
                message={saveDialog ? saveDialog.message : ""}
                onClose={() => setSaveDialog(null)}
                onConfirm={() => saveDialog && saveDialog.onConfirm()}
            />
        </div>
    );
}