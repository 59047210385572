import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../api.js';
import {useAuth} from '../auth/AuthContext.js';
import ConfirmationModal from './ConfirmationModal.js';
import {useDispatch, useSelector} from 'react-redux';
import {editQuestions} from '../store/flexibleAssignmentSlice.js';

import '../App.css';

function TopBar({
                    assignment_in,
                    submissions_in,
                    topErrorMessages,
                    setTopErrorMessages,
                    section,
                    setSection,
                    publishAssignment,
                    isEditMode,
                    toggleEditMode
                }) {
    const [assignment, setAssignment] = useState(null);
    const [submissions, setSubmissions] = useState([]);
    const [gradesPublished, setGradesPublished] = useState(false);
    const {user} = useAuth();
    const [dueDateError, setDueDateError] = useState(false);
    const [dueTimeError, setDueTimeError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dateFocused, setDateFocused] = useState(false);
    const [timeFocused, setTimeFocused] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [dueTime, setDueTime] = useState('');
    const [isExportGradesModalOpen, setIsExportGradesModalOpen] = useState(false);
    const [exportScoreType, setExportScoreType] = useState('percentage');
    const [allowRegrades, setAllowRegrades] = useState(false);
    const [allowPregrade, setAllowPregrade] = useState(false);
    const {data: draftData} = useSelector(state => state?.flexibleAssignmentReducer?.draftData);
    const isAssignmentPublished = assignment?.is_assignment_published;
    const dispatch = useDispatch();
    const isEssay = assignment_in?.type === 'essay';
    const isFlexibleSet = assignment?.type === 'flexible_problem_set';
    const stopDueDateError = () => {
        if (dueDateError) {
            setDueDateError(false);
            setTopErrorMessages(['']);
        }
    };

    const stopDueTimeError = () => {
        if (dueTimeError) {
            setDueTimeError(false);
            setTopErrorMessages(['']);
        }
    };

    const handleSaveChanges = async () => {
        if (!isEditMode || !draftData.examPaper) {
            return;
        }

        try {
            // Transform the exam paper to include only editable fields
            // and ensure we're using the database IDs, not frontend sequential IDs
            const questions = draftData.examPaper.map(question => {
                // For the main question, include basic fields with the real ID
                const questionData = {
                    id: question.id, // This is the ID from the database that was loaded
                    description: question.question,
                    image_s3_key: question.image_s3_key || question.imageS3Key
                };

                // If the question has subquestions, include them
                if (question.subQuestions && question.subQuestions.length > 0) {
                    questionData.subQuestions = question.subQuestions.map(subq => ({
                        id: subq.id, // This is the real subquestion ID from the database
                        question: subq.question,
                        image_s3_key: subq.image_s3_key || subq.imageS3Key,
                        question_type: subq.questionType || subq.question_type
                    }));
                } else {
                    // For questions without subquestions, include the question type
                    questionData.question_type = question.questionType || question.question_type;
                }

                return questionData;
            });

            await dispatch(editQuestions({
                assignment_id: assignment_in.id,
                token: user.session_token,
                questions
            }));

            // After successful save, toggle edit mode back to view mode
            toggleEditMode();
            setTopErrorMessages([]);
        } catch (error) {
            setTopErrorMessages([
                error.response?.data?.error || 'Failed to save changes. Please try again.'
            ]);
        }
    };

    const handleEditSaveButtonClick = () => {
        if (isEditMode) {
            // If in edit mode, save changes
            handleSaveChanges();
        } else {
            // If not in edit mode, toggle to edit mode
            toggleEditMode();
        }
    };

    const handleChangeAllowRegrades = async () => {
        try {
            await gradeApi.patch({
                path: `/api/assignment/${assignment_in.id}/allow-regrades`,
                body: {allowRegrades: !allowRegrades},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAllowRegrades(!allowRegrades);
            setTopErrorMessages(['']);

        } catch (error) {
            console.error('Error updating allow regrades:', error);
            setTopErrorMessages(['Failed to update allow regrades']);
        }
    };

    const handleAllowPregradeChange = async () => {
        try {
            console.log("Updating pre-grade setting to:", !allowPregrade);
            
            // Use PATCH method just like the regrade switch
            const response = await gradeApi.patch({
                path: `/api/assignment/${assignment_in.id}/allow-pregrade`,
                body: {
                    allowPregrade: !allowPregrade
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            
            console.log("Pre-grade update response:", response);
            setAllowPregrade(!allowPregrade);
            setTopErrorMessages(['']);
            
        } catch (error) {
            console.error('Error updating pre-grade setting:', error);
            setTopErrorMessages(['Failed to update pre-grade setting']);
        }
    };

    useEffect(() => {
        if (assignment_in) {
            console.log(assignment_in);
            setAssignment(assignment_in);
            setGradesPublished(assignment_in.is_grades_published);
            setDueDate(assignment_in.due_date);
            setDueTime(assignment_in.due_time);
            setAllowRegrades(assignment_in.allow_regrade);
            setAllowPregrade(assignment_in.allow_pregrade || false);

            // Only set section to review if URL doesn't already have a section specified
            if (assignment_in.is_assignment_published && !isEssay && !section) {
                setSection('review');
            }
        }
        if (submissions_in) {
            setSubmissions(submissions_in);
        }
    }, [assignment_in, submissions_in]);

    const handlePublishClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmPublish = () => {
        publishAssignment();
        setIsModalOpen(false);
    };


    const publishGrades = async () => {
        if (!assignment.is_assignment_published) {
            setTopErrorMessages(['You must publish this assignment before you can publish grades.']);
            return;
        }

        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/publish-grades/`,
                body: {
                    is_grades_published: !gradesPublished
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setGradesPublished(!gradesPublished);
            setTopErrorMessages(['']);
            window.location.reload();
        } catch (error) {
            setTopErrorMessages([error.response.data.error]);
            return;
        }
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/store-agg-feedback/`,
                body: {
                    is_grades_published: !gradesPublished
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
        } catch (error) {
            setTopErrorMessages(['Failed to generate aggregate feedback']);
            return;
        }
    };

    const downloadCSV = () => {
        setIsExportGradesModalOpen(true);
    };

    const confirmExportGrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/export/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    },
                    params: {
                        score_type: exportScoreType
                    },
                    responseType: 'blob'
                }
            });

            const blob = new Blob([response.data], {type: 'text/csv'});
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = `assignment_grades_${assignment.id}.csv`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            setIsExportGradesModalOpen(false);
        } catch (error) {
            setTopErrorMessages(['Failed to export grades. Please try again.']);
        }
    };

    const updateDueDate = async () => {
        if (dueDateError) {
            setDueDateError(false);
            setTopErrorMessages(['']);
        }

        try {
            await gradeApi.patch({
                path: `/api/assignment/${assignment.id}/due-date`,
                body: {
                    due_date: dueDate
                },
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            setTopErrorMessages(['']);
            // console.log('Due date updated successfully');
        } catch (error) {
            const errorMessage =
                error.response?.data?.error || 'Failed to update due date. Please try again.';
            setTopErrorMessages([errorMessage]);
        }
    };

    const updateDueTime = async () => {
        try {
            await gradeApi.patch({
                path: `/api/assignment/${assignment.id}/due-time`,
                body: {
                    due_time: dueTime
                },
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            setTopErrorMessages(['']);
            // console.log('Due time updated successfully');
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || 'Failed to update due time. Please try again.';

            setTopErrorMessages([errorMessage]);
        }
    };

    const handleDueDateChange = e => {
        setDueDate(e.target.value);
    };

    const handleDueTimeChange = e => {
        setDueTime(e.target.value);
    };

    const handleDueDateBlur = () => {
        updateDueDate();
        setDateFocused(false);
    };

    const handleDueTimeBlur = () => {
        updateDueTime();
        setTimeFocused(false);
    };

    const message = assignment?.is_exam
        ? 'Publishing this assignment will prevent you from making changes to it. The ' +
        'questions will NOT be revealed to students. Once published, you will get access to a PDF you ' +
        'can print for your students.'
        : isEssay
            ? 'Publishing this essay assignment will prevent you from making changes to it. The assignment will become visible to students and allow their submissions.'
            : 'Publishing this assignment will prevent you from making changes to it. ' +
            'The assignment will become visible to students and allow their submissions.';

    return (
        <div
            id="HomeTeacher"
            className={`flex flex-col gap-2 fixed z-40 bg-white topbar-padding border-b border-gray-200 ${
                section === 'review' || isFlexibleSet || isEssay
                    ? 'ml-3 left-side-bar-size w-[calc(100%-20vw)] left-[270px] pr-8'
                    : 'w-full px-10'
            }`}
        >
            <div id="Main-Content-container" className="flex flex-col space-y-4 w-full">
                <div className="flex flex-row items-center space-x-3 h-topbar-font-box-size">
                    <Link to={`/tclasses`}>
                        <div className="text-black topbar-text-size font-medium">My Classes</div>
                    </Link>
                    <Link to={`/tclasses`}>
                        <div className="size-6 flex items-center justify-center">
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6"/>
                            </button>
                        </div>
                    </Link>
                    <Link to={`/tclasses/${assignment_in.course_id}/tassignments`}>
                        <div className="text-black topbar-text-size font-medium">
                            {assignment_in.course.name.length > 40
                                ? assignment_in.course.name.substring(0, 39) + '...'
                                : assignment_in.course.name}
                        </div>
                    </Link>
                    <Link to={`/tclasses/${assignment_in.course_id}/tassignments`}>
                        <div className="size-6 flex items-center justify-center">
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6"/>
                            </button>
                        </div>
                    </Link>
                    <div className="text-black topbar-text-size font-medium">
                        {assignment_in
                            ? assignment_in.title.length > 40
                                ? assignment_in.title.substring(0, 39) + '...'
                                : assignment_in.title
                            : ''}
                    </div>
                    {assignment_in.is_group_task ? <div>(Group) </div> : <div></div>}
                </div>
            </div>
            <hr className="border-b border-gray-200 border-0 border-dashed"/>
            <div className="flex flex-row text-smallish items-center w-full">
                {!assignment_in.is_exam && section != 'review' && (
                    <div className={`flex space-x-4`}>
                        <div
                            className={`relative flex items-center font-normal bg-white input-entry-box text-black w-[200px] ${dueDateError ? 'border-red-500' : ''}`}
                            onFocus={() => {
                                setDateFocused(true);
                                stopDueDateError();
                            }}
                            onBlur={handleDueDateBlur}
                        >
                            <div className={`flex items-center w-full ${dueDateError ? 'border-red-500' : ''}`}>
                                {!dateFocused && dueDate === '' && (
                                    <div
                                        className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                        style={{userSelect: 'none'}}
                                    >
                                        Due Date
                                    </div>
                                )}
                                <input
                                    type="date"
                                    value={dueDate}
                                    className={`actual-input ml-2.5 mr-2.5 w-full ${dueDateError ? 'text-red-500' : ''}`}
                                    onChange={handleDueDateChange}
                                    onFocus={() => stopDueDateError()}
                                />
                            </div>
                            <div
                                className="size-6 flex items-center justify-center pointer-events-none absolute right-0 bg-white mr-2">
                                <img
                                    src={dueDateError ? '/assets/red-calendar.png' : '/assets/calendar.png'}
                                    className="size-5"
                                    alt="Calendar icon"
                                />
                            </div>
                        </div>
                        <div
                            className={`relative flex items-center font-normal bg-white input-entry-box text-black w-[200px] ${dueTimeError ? 'border-red-500' : ''}`}
                            onFocus={() => {
                                setTimeFocused(true);
                                stopDueTimeError();
                            }}
                            onBlur={handleDueTimeBlur}
                        >
                            <div className={`flex items-center w-full ${dueTimeError ? 'border-red-500' : ''}`}>
                                {!timeFocused && dueTime === '' && (
                                    <div
                                        className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                        style={{userSelect: 'none'}}
                                    >
                                        Due Time
                                    </div>
                                )}
                                <input
                                    type="time"
                                    value={dueTime}
                                    className={`actual-input ml-2.5 mr-2.5 w-full ${dueTimeError ? 'text-red-500' : ''}`}
                                    onChange={handleDueTimeChange}
                                    onFocus={() => stopDueTimeError()}
                                />
                            </div>
                            <div
                                className="size-6 flex items-center justify-center pointer-events-none absolute right-0 bg-white mr-2">
                                <img
                                    src={dueTimeError ? '/assets/red-clock.png' : '/assets/clock.png'}
                                    className="size-5"
                                    alt="Clock icon"
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="m-0 flex gap-12 order-2 ml-auto">
                    {(section == 'review' || isEssay) && isAssignmentPublished && (
                        <div className="flex items-center gap-3">
                            <label htmlFor="allowRegradesSwitch" className="text-sm text-gray-600">
                                Allow Regrade Requests:
                            </label>
                            <input
                                type="checkbox"
                                id="allowRegradesSwitch"
                                checked={allowRegrades}
                                className="sr-only peer"
                                onChange={handleChangeAllowRegrades}
                            />
                            <label
                                htmlFor="allowRegradesSwitch"
                                className={`
                    relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out cursor-pointer
                    ${allowRegrades ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}
                `}
                            >
                <span
                    className={`
                        inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                        ${allowRegrades ? 'translate-x-6' : 'translate-x-1'}
                    `}
                />
                            </label>
                            
                            {(assignment?.type === 'flexible_problem_set' || assignment?.type === 'byob_pdf_problem_set') && (
                                <>
                                    <div className="w-4"></div>
                                    <label htmlFor="allowPregradeSwitch" className="text-sm text-gray-600">
                                        Allow Pre-Grading:
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="allowPregradeSwitch"
                                        checked={allowPregrade}
                                        className="sr-only peer"
                                        onChange={handleAllowPregradeChange}
                                    />
                                    <label
                                        htmlFor="allowPregradeSwitch"
                                        className={`
                    relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out cursor-pointer
                    ${allowPregrade ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}
                `}
                                    >
                                        <span
                                            className={`
                        inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                        ${allowPregrade ? 'translate-x-6' : 'translate-x-1'}
                    `}
                                        />
                                    </label>
                                </>
                            )}
                        </div>
                    )}

                    {!isEssay && assignment_in.type !== 'byob_pdf_problem_set' && (
                        <div className="flex flex-row text-smallish items-center h-10">
                            <div className="flex border border-black rounded-3xl overflow-hidden">
                                <button
                                    className={`px-4 py-2 text-smallish font-medium transition-all duration-300 ${
                                        section === 'create' ? 'bg-gray-200 cursor-default' : 'hover:bg-gray-100'
                                    }`}
                                    disabled={section === 'create'}
                                    onClick={() => {
                                        setSection('create');
                                    }}
                                >
                                    Create
                                </button>
                                <div className="w-[1px] bg-black"></div>
                                <button
                                    className={`px-4 py-2 text-smallish font-medium transition-all duration-300 ${
                                        section === 'review' ? 'bg-gray-200 cursor-default' : 'hover:bg-gray-100'
                                    }`}
                                    disabled={section === 'review'}
                                    onClick={() => {
                                        setSection('review');
                                    }}
                                >
                                    Review
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-row text-smallish items-center h-10">
                        {isAssignmentPublished && section === 'create' && isFlexibleSet && (
                            <div className="relative mr-4">
                                <button
                                    className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow"
                                    onClick={handleEditSaveButtonClick}
                                >
                                    {isEditMode ? 'Save Changes' : 'Edit Assignment'}
                                </button>
                            </div>
                        )}

                        {(section == 'create' || isEssay) && (
                            <div className="relative">
                                {topErrorMessages.length > 0 && (
                                    <div className="absolute top-full right-0 mt-2 w-max">
                                        <div className="bg-white border border-red-200 rounded-lg p-3 shadow-lg">
                                            {topErrorMessages.map((error, index) => (
                                                <>
                                                    <div key={index} className="text-red-500 text-sm whitespace-nowrap">
                                                        {error}
                                                    </div>
                                                    {assignment_in.is_group_task ? <div>(Group) </div> : <div></div>}
                                                </>
                                            ))}
                                        </div>
                                        <div
                                            className="absolute -top-2 right-6 w-4 h-4 transform -rotate-[135deg] bg-white border-l border-t border-red-200"></div>
                                    </div>
                                )}
                                {/* Only show Publish button if not published or if in edit mode */}
                                {(!isAssignmentPublished) && (
                                    <button
                                        className={`px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow ${
                                            (isAssignmentPublished && !isEditMode) || topErrorMessages.length > 0
                                                ? 'bg-gray-300 cursor-not-allowed'
                                                : 'hover:bg-gray-300'
                                        }`}
                                        disabled={(isAssignmentPublished && !isEditMode) || topErrorMessages.length > 0}
                                        onClick={handlePublishClick}
                                    >
                                        Publish Assignment
                                    </button>
                                )}
                            </div>
                        )}

                        {(section == 'review' || isEssay) && (
                            <div className="ml-4 flex gap-4 items-center">
                                <button
                                    className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow"
                                    onClick={downloadCSV}
                                >
                                    Download Grades
                                </button>

                                <button
                                    className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                    onClick={publishGrades}
                                >
                                    {gradesPublished ? 'Unpublish Grades' : 'Publish Grades'}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ConfirmationModal
                isOpen={isExportGradesModalOpen}
                message="Are you sure you want to export the grades for this assignment? Download will start automatically."
                onClose={() => setIsExportGradesModalOpen(false)}
                onConfirm={confirmExportGrades}
            >
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Select score type:</label>
                    <select
                        value={exportScoreType}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        onChange={e => setExportScoreType(e.target.value)}
                    >
                        <option value="percentage">Percentage Scores</option>
                        <option value="raw">Raw Scores</option>
                    </select>
                </div>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={isModalOpen}
                message={message}
                onClose={handleCloseModal}
                onConfirm={handleConfirmPublish}
            />
        </div>
    );
}

export default TopBar;
