import React from 'react';

function MoreBefore({toggleBool}) {
    return (
        <div className="grid grid-cols-3 items-center gap-5 border border-white rounded-3xl p-2 max-w-28">
            <div className="size-5 flex items-center justify-center"></div>
            <div className="size-5 flex items-center justify-center"></div>
            <div className="size-5 flex items-center justify-center">
                <button onClick={toggleBool}>
                    <img src="/assets/more.png" className="opacity-70 size-3" alt="More"/>
                </button>
            </div>
        </div>
    );
}

function MoreAfter({
                       toggleBool,
                       id,
                       handleDeleteAssignment,
                       handleCloneAssignment,
                       handleRenameAssignment,
                       handleShareAssignment
                   }) {
    return (
        <div className="grid grid-cols-5 items-center gap-5 border border-gray-250 rounded-3xl p-2">
            <div className="size-5 flex items-center justify-center">
                <button onClick={() => handleShareAssignment(id)}>
                    <img
                        src="/assets/tassignments/network.png"
                        className="opacity-70 size-5"
                        alt="Share Assignment"
                    />
                </button>
            </div>
            <div className="size-5 flex items-center justify-center">
                <button onClick={() => handleRenameAssignment(id)}>
                    <img
                        src="/assets/tclasses/edit.png"
                        className="opacity-60 size-5"
                        alt="Edit Assignment"
                    />
                </button>
            </div>
            <div className="size-5 flex items-center justify-center">
                <button onClick={() => handleDeleteAssignment(id)}>
                    <img
                        src="/assets/tclasses/delete_class.png"
                        className="opacity-70 size-5"
                        alt="Delete Class"
                    />
                </button>
            </div>
            <div className="size-5 flex items-center justify-center">
                <button onClick={() => handleCloneAssignment(id)}>
                    <img
                        src="/assets/tclasses/duplicate.png"
                        className="opacity-50 size-5"
                        alt="Clone Assignment"
                    />
                </button>
            </div>
            <div className="size-5 flex items-center justify-center">
                <button onClick={toggleBool}>
                    <img src="/assets/more.png" className="opacity-70 size-3" alt="More"/>
                </button>
            </div>
        </div>
    );
}

function MoreButton({
                        myBool,
                        isOpenToManage,
                        toggleBool,
                        id,
                        handleCloneAssignment,
                        handleDeleteAssignment,
                        handleRenameAssignment,
                        handleShareAssignment
                    }) {
    // console.log(isOpenToManage);
    // if (!isOpenToManage) {
    //     return null;
    // }

    return myBool ? (
        <MoreBefore toggleBool={toggleBool}/>
    ) : (
        <MoreAfter
            toggleBool={toggleBool}
            id={id}
            handleCloneAssignment={handleCloneAssignment}
            handleDeleteAssignment={handleDeleteAssignment}
            handleRenameAssignment={handleRenameAssignment}
            handleShareAssignment={handleShareAssignment}
        />
    );
}

export default MoreButton;
