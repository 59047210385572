export const getSubmissionTypeText = (is_exam, type, is_group_task) => {
    let text;
    if (type === 'essay') {
        text = 'Essay';
    }

    if (type === 'flexible_problem_set') {
        text = 'Flexible Submission';
    }

    if (type === 'pdf_problem_set') {
        text = is_exam ? 'Teacher Submission' : 'Student Submission';
    }

    if (is_group_task) {
        text += ' (Group)';
    }

    return text;
};
