import React, {useEffect, useState, useMemo} from 'react';

import {Loading} from '../components/Loading';
import {useDebounce} from '../shared/hooks/use-debounce.tsx';
import {ITEMS_PER_PAGE, PAGINATION_ROWS_PER_PAGE_OPTIONS} from '../utils/pagination.ts';
import {FaSortUp, FaSortDown, FaSort} from 'react-icons/fa';

const Table = ({
                   search,
                   columnNames,
                   columnBodies,
                   isLoading,
                   totalCount,
                   offset,
                   nextOffset,
                   noDataTitle,
                   pageNumber,
                   itemsPerPage = ITEMS_PER_PAGE.default,
                   updateSearchParams
               }) => {
    const [searchValue, setSearchValue] = useState(search);
    const [sortConfig, setSortConfig] = useState({
        key: '',
        direction: 'asc'
    });
    const totalPages = Math.ceil(totalCount / itemsPerPage);

    const debounceValue = useDebounce(searchValue, 500);

    // Function to sort data on the frontend
    const sortData = (data, sortConfig) => {
        // If no sorting is configured, return the original data
        if (!sortConfig.key || !data || !Array.isArray(data)) {
            return data;
        }

        // Create a copy of the data to avoid mutating the original
        const sortableData = [...data];

        // Get the column index that corresponds to the sortConfig.key
        const columnIndex = columnNames.findIndex(name => name === sortConfig.key);

        // If column not found, return original data
        if (columnIndex === -1) {
            return data;
        }

        return sortableData.sort((a, b) => {
            // Extract the text content from the cell for comparison
            // This assumes each row is a <tr> element with <td> children
            const aValue = extractSortValue(a, columnIndex);
            const bValue = extractSortValue(b, columnIndex);

            // Compare the values
            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    // Helper function to extract sortable value from a row
    const extractSortValue = (row, columnIndex) => {
        try {
            // Check if row is a React element
            if (row && row.props && row.props.children) {
                // Get the cells (td elements)
                const cells = Array.isArray(row.props.children)
                    ? row.props.children
                    : [row.props.children];

                // Get the cell at the specified column index
                const cell = cells[columnIndex];

                // Extract text content from the cell
                if (cell) {
                    // If cell is a string or number, use it directly
                    if (typeof cell === 'string' || typeof cell === 'number') {
                        return cell.toString().toLowerCase();
                    }

                    // If cell is a React element, try to get its text content
                    if (cell.props && cell.props.children) {
                        const cellContent = extractTextContent(cell.props.children);
                        return cellContent.toLowerCase();
                    }
                }
            }
            return '';
        } catch (error) {
            console.error('Error extracting sort value:', error);
            return '';
        }
    };

    // Recursively extract text content from React elements
    const extractTextContent = (element) => {
        if (!element) {
            return '';
        }

        // If element is a string or number, return it
        if (typeof element === 'string' || typeof element === 'number') {
            return element.toString();
        }

        // If element is an array, concatenate text from all elements
        if (Array.isArray(element)) {
            return element.map(extractTextContent).join('');
        }

        // If element is a React element with children, extract from children
        if (element.props && element.props.children) {
            return extractTextContent(element.props.children);
        }

        return '';
    };

    useEffect(() => {
        updateSearchParams({search: searchValue, pageNumber: 1});
    }, [debounceValue]);

    const noData = totalCount === 0;

    const showItemNumbers = () => {
        console.log(columnBodies);
        if (totalCount <= itemsPerPage) {
            return `1-${totalCount}`;
        }

        if (!nextOffset) {
            return `${offset + 1}-${totalCount}`;
        }

        return `${offset + 1}-${offset + itemsPerPage}`;
    };

    const pagesToShow = () => {
        const pageToShow = [];
        for (let i = 1; i <= totalPages; i++) {
            pageToShow.push(i);
        }

        if (totalPages < 3) {
            return pageToShow;
        }
        if (pageNumber === totalPages) {
            return pageToShow.slice(pageNumber - 3, totalPages);
        }
        if (pageNumber + 1 >= totalPages) {
            return pageToShow.slice(pageNumber - 2, totalPages);
        }
        return pageToShow.slice(pageNumber - 1, pageNumber + 2);
    };

    const handleSearchChange = ({target}) => {
        setSearchValue(target.value);
    };

    const currentPageOption = PAGINATION_ROWS_PER_PAGE_OPTIONS.find(
        option => Number(option.value) === itemsPerPage
    );

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <div className="flex-1">
            {/* Header Controls */}
            <div className="flex justify-between items-center mb-5">

                <div className="w-full max-w-sm min-w-[200px]">
                    <div
                        className="flex items-center bg-white rounded-lg border border-slate-200 hover:border-slate-400 transition-colors duration-200">
                        <div className="size-9 mr-0.5 flex items-center justify-center">
                            <img src="/assets/search.png" className="size-5 opacity-30" alt="Search Icon"/>
                        </div>
                        <input
                            type="text"
                            value={searchValue}
                            className="w-full py-2 pr-4 text-sm text-slate-700 bg-transparent border-none focus:outline-none"
                            placeholder="Search"
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
            </div>

            {/* Table Container - Removed overflow and height constraints */}
            <div className="bg-white rounded-lg border border-slate-200">
                <div>
                    <table className="min-w-full divide-y divide-slate-200">
                        <thead className="bg-slate-50">
                        <tr>
                            {columnNames.map((name, i) => (
                                <th
                                    key={`${name} ${i}`}
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wider bg-slate-50 cursor-pointer hover:bg-slate-100 transition-colors duration-200"
                                    onClick={() => {
                                        setSortConfig(prevConfig => ({
                                            key: name,
                                            direction: prevConfig.key === name && prevConfig.direction === 'asc' ? 'desc' : 'asc'
                                        }));
                                    }}
                                >
                    <span className="flex items-center justify-between">
                      <span>{name}</span>
                      <span className="text-slate-400 ml-1">
                        {sortConfig.key === name ? (
                            sortConfig.direction === 'asc' ? <FaSortUp className="inline"/> :
                                <FaSortDown className="inline"/>
                        ) : (
                            <FaSort className="inline opacity-50"/>
                        )}
                      </span>
                    </span>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-slate-200">
                        {noData ? (
                            <tr>
                                <td
                                    colSpan={columnNames.length}
                                    className="px-6 py-4 whitespace-nowrap text-sm text-slate-500 text-center"
                                >
                                    {noDataTitle}
                                </td>
                            </tr>
                        ) : (
                            sortData(columnBodies, sortConfig)
                        )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                {!noData && (
                    <div className="flex justify-between items-center px-4 py-3 border-t border-slate-200">
                        <div className="text-sm text-slate-500">
                            Showing <span className="font-medium">{showItemNumbers()}</span> of{' '}
                            <span className="font-medium">{totalCount}</span>
                        </div>

                        <div className="flex space-x-1">
                            <button
                                className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200
                                    ${
                                    pageNumber === 1
                                        ? 'text-slate-300 border-slate-200 cursor-not-allowed'
                                        : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                }`}
                                disabled={pageNumber === 1}
                                onClick={() => updateSearchParams({pageNumber: pageNumber - 1})}
                            >
                                Prev
                            </button>

                            {pagesToShow().map(page => (
                                <button
                                    key={`page-${page}`}
                                    className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200
                                        ${
                                        page === pageNumber
                                            ? 'bg-orange-50 border-orange-200 hover:bg-orange-100'
                                            : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                    }`}
                                    onClick={() => updateSearchParams({pageNumber: page})}
                                >
                                    {page}
                                </button>
                            ))}

                            <button
                                className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200
                                    ${
                                    pageNumber === totalPages
                                        ? 'text-slate-300 border-slate-200 cursor-not-allowed'
                                        : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                }`}
                                disabled={pageNumber === totalPages}
                                onClick={() => updateSearchParams({pageNumber: pageNumber + 1})}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Table;