import React, {useState} from 'react';
import {AlertCircle, ArrowLeft, Laptop, FileText, PenTool} from 'lucide-react';
import {Alert, AlertDescription} from './Alert';
import {useNavigate} from 'react-router-dom';

const AssignmentTypeSelector = ({onSelectType}) => {
    const [hoveredOption, setHoveredOption] = useState(null);

    const handleInitialSelection = (selection) => {
        if (selection === 'pdf') {
            // Directly use BYOB PDF without intermediate step
            onSelectType('byob_pdf_problem_set');
        } else if (selection === 'flexible') {
            onSelectType('flexible_problem_set');
        }
    };

    const OptionCard = ({icon: Icon, title, description, onClick, disabled = false, comingSoon = false}) => (
        <button
            disabled={disabled}
            className={`
                w-full p-6 text-left rounded-xl transition-all duration-300 transform
                ${disabled ?
                'bg-gray-50 cursor-not-allowed' :
                'hover:scale-[1.02] hover:shadow-lg bg-white'
            }
                ${hoveredOption === title && !disabled ?
                'border-2 border-orange-200 shadow-orange-100' :
                'border border-gray-200'
            }
            `}
            onClick={onClick}
            onMouseEnter={() => setHoveredOption(title)}
            onMouseLeave={() => setHoveredOption(null)}
        >
            <div className="flex items-start space-x-4">
                <div className={`
                    p-3 rounded-lg 
                    ${disabled ? 'bg-gray-100' : 'bg-orange-50'}
                    ${hoveredOption === title && !disabled ? 'bg-orange-100' : ''}
                `}>
                    <Icon className={`w-6 h-6 ${disabled ? 'text-gray-400' : 'text-orange-500'}`}/>
                </div>
                <div className="flex-1">
                    <div className={`font-medium text-lg ${disabled ? 'text-gray-400' : 'text-gray-900'}`}>
                        {title}
                        {comingSoon && <span className="ml-2 text-sm text-orange-500 font-normal">Coming Soon!</span>}
                    </div>
                    <div className={`mt-1 text-sm ${disabled ? 'text-gray-400' : 'text-gray-500'}`}>
                        {description}
                    </div>
                </div>
            </div>
        </button>
    );

    return (
        <div className="w-full">
            <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-900 mb-6">
                    How will your students submit the assignment?
                </h3>

                <OptionCard
                    icon={Laptop}
                    title="Online Assignment"
                    description="Students can type answers and upload images directly in GradeWiz"
                    onClick={() => handleInitialSelection('flexible')}
                />

                <OptionCard
                    icon={FileText}
                    title="Structured PDF"
                    description="Upload and use your own structured PDF"
                    onClick={() => handleInitialSelection('pdf')}
                />
            </div>
        </div>
    );
};

export default AssignmentTypeSelector;