import React, {useCallback, useState} from 'react';

function DragAndDropUpload({handleImageUpload, isPublished = false}) {
    const [dragging, setDragging] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const validateFileType = (file) => {
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        return validTypes.includes(file.type);
    };

    const handleDrop = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            setErrorMessage('');

            if (!isPublished && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                const file = e.dataTransfer.files[0];

                if (!validateFileType(file)) {
                    setErrorMessage('Only PNG and JPEG files are accepted');
                    setDragging(false);
                    return;
                }

                const newEvent = {
                    target: {
                        files: e.dataTransfer.files
                    }
                };
                handleImageUpload(newEvent);
                e.dataTransfer.clearData();
                setDragging(false);
            }
        },
        [handleImageUpload, isPublished]
    );

    const handleDrag = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragIn = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (!isPublished) {
                setDragging(true);
            }
        },
        [isPublished]
    );

    const handleDragOut = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }, []);

    const handleFileChange = useCallback(
        (event) => {
            setErrorMessage('');

            if (event.target.files && event.target.files.length > 0) {
                const file = event.target.files[0];

                if (!validateFileType(file)) {
                    setErrorMessage('Only PNG and JPEG files are accepted');
                    return;
                }

                handleImageUpload(event);
            }
        },
        [handleImageUpload]
    );

    return (
        <div className="">
            <label
                className={`flex justify-center w-full h-16 px-4 transition bg-white border-2 
                    ${dragging && !isPublished ? 'border-blue-300' : 'border-gray-500'}
                    ${isPublished ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:border-gray-900'}
                    border-dashed border-[1px] rounded-md appearance-none focus:outline-none`}
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragEnter={handleDragIn}
                onDragLeave={handleDragOut}
            >
                <span className="flex items-center space-x-2">
                    <span
                        className={`text-gray-600 italic text-smallish ${isPublished ? 'line-through' : ''}`}
                    >
                        {isPublished ? 'Image upload disabled' : 'Drag & drop PNG/JPEG file here or '}
                        {!isPublished && <span className="underline">Browse</span>}
                    </span>
                </span>

                <input
                    type="file"
                    disabled={isPublished}
                    accept="image/png,image/jpeg"
                    name="file_upload"
                    className="hidden"
                    onChange={handleFileChange}
                />
            </label>

            {errorMessage && (
                <div className="mt-2 text-red-500 text-sm">{errorMessage}</div>
            )}
        </div>
    );
}

export default DragAndDropUpload;