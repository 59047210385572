import React, {useState} from 'react';
import {ChevronDown, ChevronRight} from 'lucide-react';

const HierarchicalQuestionView = ({examPaper, submissions, handleStartGrading}) => {
    const [expandedQuestions, setExpandedQuestions] = useState(new Set());

    const toggleQuestion = (questionIndex) => {
        setExpandedQuestions(prev => {
            const newSet = new Set(prev);
            if (newSet.has(questionIndex)) {
                newSet.delete(questionIndex);
            } else {
                newSet.add(questionIndex);
            }
            return newSet;
        });
    };

    // Count only submitted assignments
    const getSubmittedCount = () => {
        console.log('entities', submissions?.entities);
        if (!submissions?.entities) {
            return 0;
        }

        return submissions.entities.filter(student =>
            student.status === 'On Time' ||
            student.status === 'Late'
        ).length;
    };

    // Group subquestions progress for each question
    const getQuestionProgress = (question) => {
        if (!question.subQuestions || question.subQuestions.length === 0) {
            return {reviewed: 0, total: 0};
        }

        const reviewed = question.subQuestions.reduce(
            (sum, subQ) => sum + (subQ.viewed_count || 0),
            0
        );

        const submittedCount = getSubmittedCount();
        const total = question.subQuestions.length * submittedCount;

        return {reviewed, total};
    };

    return (
        <div className="w-full bg-white rounded-lg shadow-sm">
            {/* Main questions */}
            {examPaper.map((question, questionIndex) => {
                const hasSubQuestions = question.subQuestions && question.subQuestions.length > 0;
                const isExpanded = expandedQuestions.has(questionIndex);
                const progress = getQuestionProgress(question);
                const progressPercentage = progress.total > 0
                    ? Math.round((progress.reviewed / progress.total) * 100)
                    : 0;

                return (
                    <div key={questionIndex} className="border-b border-gray-200 last:border-b-0">
                        {/* Question header row */}
                        <div
                            className={`py-4 px-6 flex items-center justify-between hover:bg-gray-50 cursor-pointer transition-colors duration-150 ${isExpanded ? 'bg-gray-50' : ''}`}
                            onClick={() => hasSubQuestions && toggleQuestion(questionIndex)}
                        >
                            <div className="flex items-center space-x-4">
                                {hasSubQuestions ? (
                                    isExpanded ? <ChevronDown className="text-gray-500" size={20}/> :
                                        <ChevronRight className="text-gray-500" size={20}/>
                                ) : (
                                    <div className="w-5"/> // Spacer when no chevron
                                )}

                                <div
                                    className="w-8 h-8 bg-orange-100 text-orange-800 rounded-full flex items-center justify-center font-medium">
                                    {questionIndex + 1}
                                </div>

                                <div>
                                    <div className="font-medium text-gray-900">
                                        Question {questionIndex + 1}
                                    </div>
                                    <div className="text-sm text-gray-600 line-clamp-1 max-w-md">
                                        {question.description || ""}
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center space-x-6">
                                {hasSubQuestions && (
                                    <div className="flex items-center">
                                        <div className="w-32 bg-gray-200 rounded-full h-2.5 mr-3">
                                            <div
                                                className="bg-green-500 h-2.5 rounded-full"
                                                style={{width: `${progressPercentage}%`}}
                                            ></div>
                                        </div>
                                        <span className="text-sm text-gray-600">
                      {progress.reviewed}/{progress.total}
                    </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Subquestions (collapsible) */}
                        {hasSubQuestions && isExpanded && (
                            <div className="pl-14 bg-gray-50 border-t border-gray-100">
                                {question.subQuestions.map((subQuestion, subQuestionIndex) => (
                                    <div
                                        key={`${questionIndex}-${subQuestionIndex}`}
                                        className="py-3 px-6 border-b border-gray-100 last:border-b-0 flex items-center justify-between hover:bg-gray-100 cursor-pointer transition-all duration-200"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleStartGrading(subQuestion.id);
                                        }}
                                    >
                                        <div className="flex items-center space-x-3">
                                            <div
                                                className="w-7 h-7 bg-orange-50 text-orange-800 rounded-full flex items-center justify-center font-medium text-sm">
                                                {String.fromCharCode(97 + subQuestionIndex)}
                                            </div>
                                            <div>
                                                <div className="font-medium text-gray-800">
                                                    {questionIndex + 1}{String.fromCharCode(97 + subQuestionIndex)}
                                                </div>
                                                <div className="text-sm text-gray-600 line-clamp-1 max-w-md">
                                                    {subQuestion.question_text || subQuestion.question || "No question text available"}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center space-x-6">
                                            <div className="flex items-center space-x-2">
                                                <div
                                                    className={`w-2.5 h-2.5 rounded-full ${subQuestion.viewed_count > 0 ? 'bg-green-500' : 'bg-gray-300'}`}></div>
                                                <span className="text-sm text-gray-600">
                          {subQuestion.viewed_count || 0}/{getSubmittedCount()} reviewed
                        </span>
                                            </div>


                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default HierarchicalQuestionView;