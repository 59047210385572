import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {Loading} from '../../components/Loading.js';
import SideBar from '../../components/SideBar';
import CourseManagement from '../../components/Teacher/TeacherAssignments/TeacherAssignmentSettings/CourseManagement';

const ClassSettings = () => {
    const {class_id} = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchCourse = async () => {
            try {
                setLoading(true);
                const response = await gradeApi.get({
                    path: `/api/courses/${class_id}/`
                });
                setCourse(response.data);
            } catch (error) {
                console.error('Error fetching course:', error);
                setError('Failed to load course data');
            } finally {
                setLoading(false);
            }
        };

        fetchCourse();
    }, [class_id]);

    return (
        <div className="flex">
            <div className="w-side-bar-size flex-shrink-0">
                <div className="fixed h-screen">
                    <SideBar activePage="Settings"/>
                </div>
            </div>

            <div className="flex-1">
                <div className="flex items-center space-x-2 p-8 pb-4">
                    <Link to="/tclasses" className="text-black text-lg font-medium">
                        My Classes
                    </Link>
                    <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                    <Link
                        to={`/tclasses/${class_id}/tassignments`}
                        className="text-black text-lg font-medium"
                    >
    <span className="text-black text-lg font-medium">
        {course?.name
            ? (course.name.length > 15
                ? `${course.name.substring(0, 15)}...`
                : course.name)
            : 'Class'}
    </span>
                    </Link>
                    <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                    <Link
                        to={`/tclasses/${class_id}/tassignments`}
                        className="text-black text-lg font-medium"
                    >
    <span className="text-black text-lg font-medium">
        {course?.name
            ? (course.name.length > 15
                ? `${course.name.substring(0, 15)}...`
                : course.name)
            : 'Class'} Settings
    </span>
                    </Link>
                </div>

                <div className="px-8">
                    {error && <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-md">{error}</div>}

                    {loading ? (
                        <Loading/>
                    ) : (
                        <div className="bg-white rounded-lg border border-gray-200 p-4">
                            <CourseManagement class_id={class_id} course={course}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ClassSettings;
