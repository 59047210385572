import React from 'react';
import {
    ArrowUp,
    BookOpen,
    ListChecks,
    Lightbulb,
    X
} from "lucide-react";

const GradingControls = ({
                             gradingInstructions,
                             handleInstructionsChange,
                             renderInstructionsText,
                             textareaHeight,
                             isRegrading,
                             allStudents,
                             currentBatchIndex,
                             handleApplyGradingInstructions,
                             currentQuestion,
                             data,
                             setSelectedSubQuestion,
                             showSolutionOverlay,
                             setShowSolutionOverlay,
                             setShowAIRegradeOverlay,
                             selectedSubmissions,
                             setSelectedSubmissions,
                             updateTextareaHeight
                         }) => {
    const remainingStudentsCount = Math.max(0, allStudents.length - ((currentBatchIndex + 1) * 10));

    // Function to find and set the current subquestion
    const setCurrentSubQuestion = () => {
        const currentSubQuestion = data.examPaper
            .flatMap(q => q.subQuestions)
            .find(sq => sq.id === currentQuestion);
        setSelectedSubQuestion(currentSubQuestion);
    };

    return (
        <div className="fixed bottom-5 left-0 right-0 px-20 z-10">
            <div
                className="bg-white shadow-lg p-4 max-w-screen-xl mx-auto rounded-2xl"
                style={{boxShadow: '0 0 40px rgba(251, 146, 60, 0.3)'}}
            >
                <div className="relative">
          <textarea
              type="text"
              value={gradingInstructions}
              placeholder="Enter your grading instructions here..."
              className="w-full px-3 pt-2 pb-2 rounded-lg text-base resize-none outline-none focus:outline-none focus:ring-0 border-none transition-all duration-300"
              style={{height: textareaHeight}}
              disabled={isRegrading}
              onChange={handleInstructionsChange}
              onPaste={() => {
                  // Schedule height update after the paste completes
                  setTimeout(() => updateTextareaHeight(gradingInstructions), 0);
              }}
          />
                    <div
                        className="absolute inset-0 pointer-events-none px-3 pt-2 pb-2 text-base whitespace-pre-wrap"
                        style={{color: 'transparent', height: textareaHeight}}
                    >
                        {renderInstructionsText()}
                    </div>
                </div>
                <div className="flex items-center justify-between mt-4">
                    <div className="flex items-center space-x-2">
                        <button
                            className={`transition-all px-4 py-2 text-sm border rounded-full flex items-center space-x-1 hover:text-[rgb(270,140,122)] hover:shadow-xl duration-200
              ${isRegrading
                                ? 'opacity-50 cursor-not-allowed bg-gray-100 border-gray-200 text-gray-400'
                                : 'active:border-orange-700 active:bg-orange-100 active:text-orange-600'}`}
                            disabled={isRegrading}
                            onClick={() => handleApplyGradingInstructions('remaining')}
                        >
                            <ListChecks size={16}/>
                            <span>
                This is good, grade the rest ({remainingStudentsCount})
              </span>
                        </button>
                    </div>
                    <div className="flex items-center gap-3">
                        {/* Selection Counter and Clear Button - Matching Style */}
                        {selectedSubmissions && selectedSubmissions.length > 0 && (
                            <div
                                className="flex items-center border rounded-full px-3 py-1.5 transition-all duration-200 hover:shadow-md">
                                {/* Counter with Text */}
                                <div className="flex items-center gap-2 text-sm">
                                    <span className="text-orange-500 font-medium">
                                        {selectedSubmissions.length} selected
                                    </span>
                                </div>

                                {/* Clear Button */}
                                <button
                                    className="ml-2 text-gray-500 hover:text-custom-orange transition-colors rounded-full flex items-center justify-center"
                                    title="Clear selection"
                                    onClick={() => setSelectedSubmissions([])}
                                >
                                    <X size={18}/>
                                </button>
                            </div>
                        )}

                        {/* Solution button */}
                        <button
                            className={`w-[34px] h-[34px] flex items-center justify-center ${showSolutionOverlay ? 'text-[rgb(228,177,122)]' : 'text-gray-500'} hover:text-[rgb(228,177,122)] transition-colors duration-200`}
                            title={showSolutionOverlay ? "Hide solution" : "View solution"}
                            onClick={() => {
                                setCurrentSubQuestion();
                                setShowSolutionOverlay(!showSolutionOverlay);
                            }}
                        >
                            <Lightbulb size={24}/>
                        </button>

                        {/* AI Regrade button */}
                        <button
                            className="w-[34px] h-[34px] flex items-center justify-center text-gray-500 hover:text-[rgb(228,177,122)] transition-colors duration-200"
                            title="View grading instructions"
                            onClick={() => {
                                setCurrentSubQuestion();
                                setShowAIRegradeOverlay(true);
                            }}
                        >
                            <BookOpen size={24}/>
                        </button>

                        {/* Grade current batch button */}
                        <button
                            disabled={isRegrading}
                            className={`w-[34px] h-[34px] flex items-center justify-center text-white rounded-full text-sm ${isRegrading ? 'opacity-50 cursor-not-allowed bg-gray-400' : 'bg-custom-orange hover:bg-amber-600'}`}
                            title="Grade current batch"
                            onClick={() => handleApplyGradingInstructions('current')}
                        >
                            {isRegrading ? (
                                <div
                                    className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                            ) : (
                                <ArrowUp/>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GradingControls;