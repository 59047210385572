import React from 'react';
import {Check, Pencil, X, ListChecks} from "lucide-react";
import {Formik, Form} from 'formik';
import LaTeXPreviewField from "../../LaTeXPreviewField";
import TGroupAIRegrade from './TGroupAIRegrade';

// Navigation tips popup
export const NavigationTips = ({onClose}) => (
    <div
        className="fixed bottom-40 right-5 bg-white shadow-lg rounded-xl border border-orange-200 p-4 max-w-xs animate-fade-in z-50">
        <div className="flex justify-between items-center mb-2">
            <h4 className="text-sm font-medium text-gray-800">Quick Navigation Tips</h4>
            <button
                className="text-gray-400 hover:text-gray-600"
                onClick={onClose}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
        <div className="space-y-2 text-sm">
            <div className="flex items-center space-x-3">
                <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
                    </svg>
                </div>
                <span className="text-gray-600">Previous batch</span>
            </div>
            <div className="flex items-center space-x-3">
                <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                    </svg>
                </div>
                <span className="text-gray-600">Next batch</span>
            </div>
            <div className="flex items-center space-x-3">
                <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7"/>
                    </svg>
                </div>
                <span className="text-gray-600">Previous subquestion</span>
            </div>
            <div className="flex items-center space-x-3">
                <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"/>
                    </svg>
                </div>
                <span className="text-gray-600">Next subquestion</span>
            </div>
            <div className="flex items-center space-x-3">
                <div className="flex items-center justify-center w-18 h-8 bg-gray-100 rounded">
                    <div className="flex items-center">
                        <span className="text-xs font-mono px-1">Shift</span>
                        <span className="mx-1">+</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-600" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                        </svg>
                    </div>
                </div>
                <span className="text-gray-600">Mark as viewed & next batch</span>
            </div>
        </div>
        <div className="mt-3 text-xs text-gray-500">
            Press these arrow keys on your keyboard for quick navigation
        </div>
    </div>
);

// Notification popup
export const NotificationOverlay = ({notification, onClose}) => (
    <div
        className={`fixed top-5 right-5 p-4 rounded-lg shadow-lg ${
            notification.type === 'success'
                ? 'bg-green-100 text-green-800'
                : notification.type === 'warning'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-red-100 text-red-800'
        } transition-all duration-500 transform translate-y-0 opacity-100`}
    >
        <div className="flex items-center">
            {notification.type === 'success' ? (
                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"/>
                </svg>
            ) : (
                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clipRule="evenodd"/>
                </svg>
            )}
            <span>{notification.message}</span>
        </div>
    </div>
);

// Next subquestion popup
export const NextSubquestionPopup = ({nextSubQuestionId, assignment}) => (
    <>
        <div className="fixed inset-0 z-50"/>
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
                className="bg-white rounded-xl shadow-lg p-6 border border-gray-200 transform transition-all animate-fade-in">
                <div className="flex items-center">
                    <div className="bg-orange-100 rounded-full p-3 mr-4">
                        <div
                            className="w-6 h-6 border-2 border-custom-orange border-t-transparent rounded-full animate-spin"/>
                    </div>
                    <p className="text-lg font-medium">
                        {nextSubQuestionId
                            ? `Taking you to the next ${assignment?.type === 'flexible_problem_set' ? 'question' : 'subquestion'}...`
                            : `No more ${assignment?.type === 'flexible_problem_set' ? 'questions' : 'subquestions'}. Taking you to the assignment page...`}
                    </p>
                </div>
            </div>
        </div>
    </>
);

// Rubric Overlay Component
export const RubricOverlay = ({
                                  activeSubmission,
                                  activeOverlay,
                                  currentDisplaySubmissions,
                                  currentQuestion,
                                  data,
                                  isRegrading,
                                  handleRubricItemChange,
                                  editingFeedback,
                                  feedbackText,
                                  setFeedbackText,
                                  handleEditFeedback,
                                  handleSaveFeedback,
                                  getFeedbackForSubmission,
                                  setActiveOverlay
                              }) => {
    if (!activeSubmission) {
        return null;
    }

    // Find the submission index to determine position
    const index = currentDisplaySubmissions.findIndex(sub => sub.id === activeOverlay);

    return (
        <>
            {/* Backdrop */}
            <div
                className="fixed inset-0 z-[90]"
                onClick={() => {
                    setActiveOverlay(null);
                    document.body.classList.remove('overflow-hidden');
                }}
            />
            <div
                className="fixed bg-white shadow-xl rounded-2xl border border-gray-200 z-[100] overflow-hidden"
                style={{
                    width: '560px',
                    height: '560px',
                    top: '180px',
                    ...(index % 2 === 0
                            ? {right: 'calc(50% - 580px)'}
                            : {left: 'calc(50% - 580px)'}
                    )
                }}
                onClick={(e) => e.stopPropagation()}
            >
                {/* Overlay content */}
                <div className="h-full flex flex-col">
                    <div className="flex justify-between items-center p-6 border-b">
                        <h3 className="text-lg font-semibold">Grading Criteria</h3>
                        <button
                            className="text-gray-500 hover:text-gray-700"
                            onClick={() => {
                                setActiveOverlay(null);
                                document.body.classList.remove('overflow-hidden');
                            }}
                        >
                            <X size={24}/>
                        </button>
                    </div>
                    <div className="flex-1 overflow-y-auto p-6">
                        <div className="space-y-2 mb-4">
                            <h4 className="text-sm font-medium text-gray-700 mb-2 flex items-center space-x-1.5">
                                <ListChecks size={14} className="text-gray-500"/>
                                <span>All Grading Criteria</span>
                            </h4>
                            {(() => {
                                const currentSubQuestion = data.examPaper
                                    .flatMap(q => q.subQuestions)
                                    .find(sq => sq.id === currentQuestion);

                                if (!currentSubQuestion?.rubricItems) {
                                    return (
                                        <div className="text-sm text-gray-500 italic">
                                            No rubric items available
                                        </div>
                                    );
                                }

                                return (
                                    <div className="space-y-2">
                                        {[...currentSubQuestion.rubricItems]
                                            .sort((a, b) => b.value - a.value)
                                            .map((rubricItem, index) => {
                                                const feedbackEntry = activeSubmission?.feedback?.[currentQuestion];

                                                // Check for selected rubric item
                                                const isSelected = feedbackEntry?.rubric_items?.some(
                                                    item => (item.id === rubricItem.id)
                                                );

                                                return (
                                                    <button
                                                        key={rubricItem.id}
                                                        className={`w-full flex items-center px-3 py-2 rounded-lg transition-all duration-200
                              ${isSelected ?
                                                            rubricItem.id % 10 === 0 ? 'bg-gradient-to-r from-[rgb(253,248,243)] to-[rgb(249,237,226)] border-[rgb(228,177,122)]' :
                                                                rubricItem.id % 10 === 1 ? 'bg-gradient-to-r from-[rgb(243,248,253)] to-[rgb(226,237,249)] border-[rgb(122,177,228)]' :
                                                                    rubricItem.id % 10 === 2 ? 'bg-gradient-to-r from-[rgb(248,253,243)] to-[rgb(237,249,226)] border-[rgb(177,228,122)]' :
                                                                        rubricItem.id % 10 === 3 ? 'bg-gradient-to-r from-[rgb(253,243,248)] to-[rgb(249,226,237)] border-[rgb(228,122,177)]' :
                                                                            rubricItem.id % 10 === 4 ? 'bg-gradient-to-r from-[rgb(248,243,253)] to-[rgb(237,226,249)] border-[rgb(177,122,228)]' :
                                                                                rubricItem.id % 10 === 5 ? 'bg-gradient-to-r from-[rgb(243,253,248)] to-[rgb(226,249,237)] border-[rgb(122,228,177)]' :
                                                                                    rubricItem.id % 10 === 6 ? 'bg-gradient-to-r from-[rgb(253,243,253)] to-[rgb(249,226,249)] border-[rgb(228,122,228)]' :
                                                                                        rubricItem.id % 10 === 7 ? 'bg-gradient-to-r from-[rgb(243,253,253)] to-[rgb(226,249,249)] border-[rgb(122,228,228)]' :
                                                                                            rubricItem.id % 10 === 8 ? 'bg-gradient-to-r from-[rgb(253,253,243)] to-[rgb(249,249,226)] border-[rgb(228,228,122)]' :
                                                                                                'bg-gradient-to-r from-[rgb(235,245,255)] to-[rgb(220,235,250)] border-[rgb(100,150,220)]' :
                                                            'bg-white hover:bg-gray-50 border-gray-200'
                                                        } border text-left ${isRegrading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                        disabled={isRegrading}
                                                        onClick={() => !isRegrading && handleRubricItemChange(activeSubmission.id, currentQuestion, rubricItem)}
                                                    >
                                                        <div className="flex items-center flex-1">
                                                            <div className={`w-5 h-5 rounded-full border flex items-center justify-center mr-3
                                ${isSelected ?
                                                                rubricItem.id % 10 === 0 ? 'border-[rgb(228,177,122)]' :
                                                                    rubricItem.id % 10 === 1 ? 'border-[rgb(122,177,228)]' :
                                                                        rubricItem.id % 10 === 2 ? 'border-[rgb(177,228,122)]' :
                                                                            rubricItem.id % 10 === 3 ? 'border-[rgb(228,122,177)]' :
                                                                                rubricItem.id % 10 === 4 ? 'border-[rgb(177,122,228)]' :
                                                                                    rubricItem.id % 10 === 5 ? 'border-[rgb(122,228,177)]' :
                                                                                        rubricItem.id % 10 === 6 ? 'border-[rgb(228,122,228)]' :
                                                                                            rubricItem.id % 10 === 7 ? 'border-[rgb(122,228,228)]' :
                                                                                                rubricItem.id % 10 === 8 ? 'border-[rgb(228,228,122)]' :
                                                                                                    'border-[rgb(100,150,220)]' : 'border-gray-300'}`}
                                                            >
                                                                {isSelected && (
                                                                    <Check
                                                                        className={`w-3 h-3 ${
                                                                            rubricItem.id % 10 === 0 ? 'text-[rgb(228,177,122)]' :
                                                                                rubricItem.id % 10 === 1 ? 'text-[rgb(122,177,228)]' :
                                                                                    rubricItem.id % 10 === 2 ? 'text-[rgb(177,228,122)]' :
                                                                                        rubricItem.id % 10 === 3 ? 'text-[rgb(228,122,177)]' :
                                                                                            rubricItem.id % 10 === 4 ? 'text-[rgb(177,122,228)]' :
                                                                                                rubricItem.id % 10 === 5 ? 'text-[rgb(122,228,177)]' :
                                                                                                    rubricItem.id % 10 === 6 ? 'text-[rgb(228,122,228)]' :
                                                                                                        rubricItem.id % 10 === 7 ? 'text-[rgb(122,228,228)]' :
                                                                                                            rubricItem.id % 10 === 8 ? 'text-[rgb(228,228,122)]' :
                                                                                                                'text-[rgb(100,150,220)]'} stroke-2`}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="flex items-center">
                                <span className={`text-xl font-medium mr-3 ${
                                    isSelected ?
                                        rubricItem.id % 10 === 0 ? 'text-orange-800' :
                                            rubricItem.id % 10 === 1 ? 'text-blue-800' :
                                                rubricItem.id % 10 === 2 ? 'text-green-800' :
                                                    rubricItem.id % 10 === 3 ? 'text-pink-800' :
                                                        rubricItem.id % 10 === 4 ? 'text-purple-800' :
                                                            rubricItem.id % 10 === 5 ? 'text-teal-800' :
                                                                rubricItem.id % 10 === 6 ? 'text-rose-800' :
                                                                    rubricItem.id % 10 === 7 ? 'text-cyan-800' :
                                                                        rubricItem.id % 10 === 8 ? 'text-amber-800' :
                                                                            'text-indigo-800' : 'text-gray-700'}`}
                                >
                                  {rubricItem.value}
                                </span>
                                                                <span className={`text-sm text-left ${
                                                                    isSelected ?
                                                                        rubricItem.id % 10 === 0 ? 'text-orange-800' :
                                                                            rubricItem.id % 10 === 1 ? 'text-blue-800' :
                                                                                rubricItem.id % 10 === 2 ? 'text-green-800' :
                                                                                    rubricItem.id % 10 === 3 ? 'text-pink-800' :
                                                                                        rubricItem.id % 10 === 4 ? 'text-purple-800' :
                                                                                            rubricItem.id % 10 === 5 ? 'text-teal-800' :
                                                                                                rubricItem.id % 10 === 6 ? 'text-rose-800' :
                                                                                                    rubricItem.id % 10 === 7 ? 'text-cyan-800' :
                                                                                                        rubricItem.id % 10 === 8 ? 'text-amber-800' :
                                                                                                            'text-indigo-800' : 'text-gray-600'}`}
                                                                >
                                  {rubricItem.descriptor}
                                </span>
                                                            </div>
                                                        </div>
                                                    </button>
                                                );
                                            })}
                                    </div>
                                );
                            })()}
                        </div>
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <h4 className="text-sm font-medium text-gray-700 flex items-center space-x-1.5">
                                    <svg className="w-3.5 h-3.5 text-gray-500" fill="none" viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                                    </svg>
                                    <span>Feedback</span>
                                </h4>
                                {editingFeedback !== activeSubmission.id && (
                                    <button
                                        className="text-xs px-2 py-1 rounded-full bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-700 transition-colors duration-200 flex items-center space-x-1"
                                        onClick={() => handleEditFeedback(activeSubmission)}
                                    >
                                        <Pencil size={12}/>
                                        <span>Edit</span>
                                    </button>
                                )}
                            </div>
                            {editingFeedback === activeSubmission.id ? (
                                <div className="flex flex-col space-y-2">
                  <textarea
                      value={feedbackText}
                      className="w-full h-24 px-3 py-2 text-sm border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500/20 focus:border-orange-500/30 transition-all duration-200"
                      placeholder="Enter feedback..."
                      onChange={(e) => setFeedbackText(e.target.value)}
                  />
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            className="text-xs px-2 py-1 rounded-full bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-700 transition-colors duration-200"
                                            onClick={() => setEditingFeedback(null)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="text-xs px-2 py-1 rounded-full bg-orange-50 text-orange-600 hover:bg-orange-100 hover:text-orange-700 transition-colors duration-200"
                                            onClick={() => handleSaveFeedback(activeSubmission.id)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="text-sm text-gray-600 bg-gray-50/50 rounded-lg p-3 border border-gray-100">
                                    {activeSubmission?.feedback?.[currentQuestion]?.feedback || 'No feedback provided'}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// Solution Overlay Component
export const SolutionOverlay = ({selectedSubQuestion, onClose}) => (
    <div
        className="fixed bottom-40 right-5 bg-white shadow-lg rounded-xl border border-orange-200 p-4 max-w-xs animate-fade-in z-50">
        <div className="flex justify-between items-center mb-2">
            <h4 className="text-sm font-medium text-gray-800">Solution</h4>
            <button
                className="text-gray-400 hover:text-gray-600"
                onClick={onClose}
            >
                <X size={16}/>
            </button>
        </div>
        <div className="space-y-2 text-sm max-h-60 overflow-y-auto">
            {selectedSubQuestion?.solution ? (
                <div className="text-gray-600">
                    <Formik
                        initialValues={{solution: selectedSubQuestion.solution}}
                        enableReinitialize={true}
                    >
                        {() => (
                            <Form>
                                <LaTeXPreviewField
                                    name="solution"
                                    disabled={true}
                                    className="bg-transparent border-none p-0"
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <div className="text-gray-500 italic">
                    No solution available for this question.
                </div>
            )}
        </div>
    </div>
);

// AI Regrade Overlay with Confirmation Dialog
export const AIRegradeOverlay = ({
                                     subQuestion,
                                     question,
                                     isEssay,
                                     onClose,
                                     onSubmit,
                                     isConfirmationOpen,
                                     handleConfirmRegrade,
                                     setIsConfirmationOpen
                                 }) => (
    <>
        <TGroupAIRegrade
            subQuestion={subQuestion}
            question={question}
            isEssay={isEssay}
            onClose={onClose}
            onSubmit={onSubmit}
        />

        {/* Confirmation Dialog */}
        {isConfirmationOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[60]">
                <div className="bg-white p-6 rounded-xl shadow-lg">
                    <h3 className="text-lg font-semibold mb-4">Confirm Regrade</h3>
                    <p>Are you sure you want to regrade this subquestion for all students with the new rubric and
                        grading instructions?</p>
                    <p className="text-sm text-gray-500 mt-2">This action cannot be undone.</p>
                    <div className="flex justify-end space-x-2 mt-4">
                        <button
                            className="less-sexy-button"
                            onClick={() => setIsConfirmationOpen(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="sexy-button"
                            onClick={handleConfirmRegrade}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        )}
    </>
);