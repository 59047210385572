import React from 'react';
import './Loading.scss';

export const Loading = ({title = "Loading", description = "Please wait..."}) => {
    return (
        <div className="flex-grow h-screen flex items-center justify-center bg-white">
            <div className="flex flex-col items-center max-w-sm text-center">
                {/* Title */}
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                    {title}
                </h2>

                {/* Description */}
                <p className="text-gray-600 mb-8">
                    {description}
                </p>

                {/* Loading Bar */}
                <div className="loading-container">
                    <div className="loading-bar">
                        <div className="bar default"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const GroupLoading = () => {
    return (
        <div className="flex-grow h-screen flex items-center justify-center bg-white">
            <div className="flex flex-col items-center max-w-sm text-center">
                {/* Document/Grading Icon */}
                <div className="w-12 h-12 mb-6">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgb(228,177,122)"
                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <line x1="10" y1="9" x2="8" y2="9"></line>
                    </svg>
                </div>

                {/* Title */}
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                    Loading Student Submissions
                </h2>

                {/* Description */}
                <p className="text-gray-600 mb-8">
                    Fetching student answers and grading data...
                </p>

                {/* Loading Bar */}
                <div className="loading-container">
                    <div className="loading-bar">
                        <div className="bar group"></div>
                    </div>
                </div>

                {/* Loading Steps */}
                <div className="mt-6 text-sm text-gray-500 flex items-center space-x-2">
                    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    <span>Preparing grading interface...</span>
                </div>
            </div>
        </div>
    );
};