import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../api.js';
import {useAuth} from '../auth/AuthContext';
import ConfirmationModal from './ConfirmationModal';

const SideBar = ({activePage}) => {
    const [selectedTab, setSelectedTab] = useState(null);
    const {user, isLoading, logout} = useAuth();
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const videoModalRef = useRef();
    const [isRosterImportOpen, setIsRosterImportOpen] = useState(false);
    const [regrades, setRegrades] = useState(false);

    const getRegrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/has-regrade-requests/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setRegrades(response.data['has_regrade_requests']);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getRegrades()
    }, []);

    const isActiveTab = tabName => tabName === activePage;

    const handleLogout = () => {
        setIsLogoutModalOpen(true);
    };

    const confirmLogout = () => {
        logout();
        setIsLogoutModalOpen(false);
    };

    const handleVideoModal = () => {
        setIsVideoModalOpen(true);
    };

    const closeVideoModal = () => {
        setIsVideoModalOpen(false);
    };

    const [myBool, setmyBool] = useState(true);

    function toggleBool() {
        setmyBool(!myBool);
    }

    const SidebarItem = ({to, icon, label, tabName, exact, onClick}) => (
        <div
            className={`relative rounded transition-all duration-300 flex items-center space-x-2 p-3 cursor-pointer 
                hover:-translate-y-0.5`}
            onClick={() => {
                setSelectedTab(tabName);
                if (onClick) {
                    onClick();
                }
            }}
        >
            <div
                className={`size-9 flex items-center justify-center rounded-full transition-all duration-300 
                    ${isActiveTab(tabName) ? 'bg-custom-orange' : 'hover:bg-orange-100'}`}
            >
                <img src={icon} alt={label} className="size-5"/>
            </div>
            <div className="text-sm">{label}</div>
            {tabName === "Regrade" && regrades && (
                <div className="w-2 h-2 bg-orange-400 rounded-full mr-2"></div>
            )}
        </div>
    );

    function MoreAfter(props) {
        return (
            <div
                className="flex items-center space-x-5 rounded-3xl p-2 transition-all duration-300">
                <Link to="/profile">
                    <div className="size-5 flex items-center justify-center">
                        <button className="hover:-translate-y-0.5 transition-transform duration-300">
                            <img src="/assets/sidebar/account_settings.png" className="opacity-70 size-5"
                                 alt="Account Settings"/>
                        </button>
                    </div>
                </Link>
                <div className="size-5 flex items-center justify-center">
                    <button
                        className="hover:-translate-y-0.5 transition-transform duration-300"
                        onClick={handleLogout}
                    >
                        <img src="/assets/sidebar/logout.png" className="opacity-70 size-5" alt="Logout"/>
                    </button>
                </div>
            </div>
        );
    }

    if (isLoading) {
        return <div>Loading</div>;
    }

    return (
        <>
            <ConfirmationModal
                isOpen={isLogoutModalOpen}
                message="Are you sure you want to log out?"
                onClose={() => setIsLogoutModalOpen(false)}
                onConfirm={confirmLogout}
            />
            <div id="sidebar"
                 className="w-side-bar-size h-screen pt-8 pl-5 pr-7 p-4 flex flex-col justify-between bg-gray-50">
                <div id="Top-container" className="flex-col flex space-y-0">
                    {/* Keep existing JSX structure but add hover effects to clickable elements */}
                    <Link to={user.user.is_teacher ? '/tclasses' : '/sclasses'}>
                        <div
                            className="logo transition-all duration-300 rounded-lg p-2 hover:-translate-y-0.5"
                            onClick={() => setSelectedTab('Homepage')}
                        >
                            <div className="flex items-center space-x-2 pb-3">
                                <div className="flex items-center justify-center">
                                    <img
                                        src="/assets/logo_icon.png"
                                        alt="GradeWiz Icon"
                                        style={{height: '60px', width: 'auto', maxWidth: 'none'}}
                                    />
                                </div>
                                <span className="text-xl font-medium text-gray-950">GradeWiz</span>
                                <div
                                    style={{
                                        background: '#e4b17a',
                                        color: 'white',
                                        fontSize: '1rem',
                                        fontWeight: 600,
                                        padding: '0.35rem 0.6rem',
                                        borderRadius: '0.375rem',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        marginLeft: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 'max-content'
                                    }}
                                >
                                    Beta
                                </div>
                            </div>
                        </div>
                    </Link>

                    {/* Keep existing navigation items */}
                    <Link to={user.user.is_teacher ? '/tclasses' : '/sclasses'}>
                        <SidebarItem
                            icon={isActiveTab('Home') ? `/assets/sidebar/menu-home-white.png` : `/assets/sidebar/menu-home.png`}
                            label="My Classes"
                            tabName="Home"
                        />
                    </Link>

                    {user.user.is_teacher && (
                        <>
                            {/*<Link to="/statistics">*/}
                            {/*    <SidebarItem*/}
                            {/*        icon={*/}
                            {/*            isActiveTab('Statistics')*/}
                            {/*                ? `/assets/sidebar/analytics-white.png`*/}
                            {/*                : `/assets/sidebar/analytics.png`*/}
                            {/*        }*/}
                            {/*        label="Statistics"*/}
                            {/*        tabName="Statistics"*/}
                            {/*    />*/}
                            {/*</Link>*/}
                            <Link to="/regrade">
                                <SidebarItem
                                    icon={isActiveTab('Regrade') ? `/assets/sidebar/quote-request-white.png` : `/assets/sidebar/quote-request.png`}
                                    label="Regrade Requests"
                                    tabName="Regrade"
                                />
                            </Link>
                        </>
                    )}

                    <Link to="/support">
                        <SidebarItem
                            icon={isActiveTab('Support') ? `/assets/sidebar/support-white.png` : `/assets/sidebar/support.png`}
                            label="Support & Feedback"
                            tabName="Support"
                        />
                    </Link>
                </div>

                <div
                    className="bg-white rounded-full shadow-sm p-2 flex items-center justify-between space-x-3 transition-all duration-300">
                    <div className="flex items-center space-x-3">
                        <img src="/assets/sidebar/Profile.png" alt="ProfileIcon"
                             className="w-[20px] h-[20px] opacity-30"/>
                        <div>
                            <div className="text-sm leading-[1]">ID: {user.user.id}</div>
                            <div className="text-xxs">
                                {user.user.first_name} {user.user.last_name}
                            </div>
                        </div>
                    </div>
                    <div>
                        <MoreAfter/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SideBar;