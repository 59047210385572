import axios from 'axios';
import Cookies from 'js-cookie';
// import layup from 'layup-js';
// import 'layup-js/style.css';

export const apiUrl =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_BASE_URL_DEV
        : process.env.REACT_APP_API_BASE_URL_PROD;

const instance = axios.create({
    baseURL: apiUrl
});

// c

// layup.init(process.env.REACT_APP_LAYUP_API_KEY, {
//
//     guidance_endpoint: "https://api.layuplabs.org", // Set the guidance API endpoint
//
//     // todo: define group and layup know
//     group_id: "", // ID for the group in your Layup configuration
//
//     user_id: "cbaf9ae2f6e47a2f68c4b6ffc06da7e2", // Unique user ID for tracking
//
// });

// Configure request interceptor
instance.interceptors.request.use(
    async config => {
        const sessionToken = Cookies.get('session_token');
        if (sessionToken) {
            config.headers.Authorization = `Bearer ${sessionToken}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// Configure response interceptor
instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Handle unauthorized access (e.g., redirect to login)
            Cookies.remove('session_token');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

const API = () => {
    return {
        get: ({ path, config = {} }) => instance.get(path, config),
        post: ({ path, body, config = {} }) => instance.post(path, body, config),
        put: ({ path, body, config = {} }) => instance.put(path, body, config),
        patch: ({ path, body, headers = {} }) => instance.patch(path, body, headers),
        delete: ({ path, body, headers = {} }) => instance.delete(path, { data: body, headers })
    };
};

export const gradeApi = API();
