import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';

const RegradeRequests = () => {
    const [regradeRequests, setRegradeRequests] = useState([]);
    const [error, setError] = useState(null);
    const {assignment_id} = useParams();
    const [sortConfig, setSortConfig] = useState({
        key: 'submission_date',
        direction: 'desc'
    });
    const {user} = useAuth();

    // Store the current sort configuration in localStorage when it changes
    useEffect(() => {
        localStorage.setItem('regradeRequestsSortConfig', JSON.stringify(sortConfig));
    }, [sortConfig]);

    const truncateText = (text, maxLength) => {
        if (!text) {
            return '';
        }
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    };

    // Format the date to be more user-friendly
    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);

        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).format(date);
    };

    // Handle sorting
    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});
    };

    // Get sorted items
    const getSortedItems = (items) => {
        const sortableItems = [...items];
        if (sortConfig.key) {
            sortableItems.sort((a, b) => {
                // Handle different data types based on the column
                if (sortConfig.key === 'submission_date') {
                    // Date comparison
                    const dateA = new Date(a.submission_date);
                    const dateB = new Date(b.submission_date);
                    if (dateA < dateB) {
                        return sortConfig.direction === 'asc' ? -1 : 1;
                    }
                    if (dateA > dateB) {
                        return sortConfig.direction === 'asc' ? 1 : -1;
                    }
                    return 0;
                } else if (sortConfig.key === 'grade') {
                    // Numeric comparison
                    const numA = parseFloat(a.grade) || 0;
                    const numB = parseFloat(b.grade) || 0;
                    return sortConfig.direction === 'asc' ? numA - numB : numB - numA;
                } else {
                    // String comparison (for student name, assignment, pending regrades)
                    const valueA = a[sortConfig.key] || '';
                    const valueB = b[sortConfig.key] || '';
                    if (valueA < valueB) {
                        return sortConfig.direction === 'asc' ? -1 : 1;
                    }
                    if (valueA > valueB) {
                        return sortConfig.direction === 'asc' ? 1 : -1;
                    }
                    return 0;
                }
            });
        }
        return sortableItems;
    };

    // Get sort indicator for column headers
    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? ' ↑' : ' ↓';
        }
        return '';
    };

    useEffect(() => {
        const fetchRegradeRequests = async () => {
            try {
                const response = await gradeApi.get({
                    path: `/api/regrade-requests/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });
                // Process the data to create separate entries for each subquestion
                const processedRequests = [];

                response.data.forEach(submission => {
                    // Get all subquestions with regrade requests
                    const regradeSubquestions = [];

                    submission.assignment.questions.forEach((question, questionIndex) => {
                        question.subQuestions.forEach((subQuestion, subQuestionIndex) => {
                            const subId = subQuestion.id.toString();
                            if (submission.feedback[subId]?.regrade_request) {
                                const questionNumber = questionIndex + 1;
                                const subquestionLetter = String.fromCharCode(97 + subQuestionIndex);

                                regradeSubquestions.push({
                                    id: subId,
                                    label: `Q${questionNumber}${subquestionLetter}`,
                                    questionIndex,
                                    subQuestionIndex
                                });
                            }
                        });
                    });

                    // If there are regrade requests, create a separate entry for each
                    if (regradeSubquestions.length > 0) {
                        regradeSubquestions.forEach(subq => {
                            processedRequests.push({
                                ...submission,
                                subquestionId: subq.id,
                                pendingRegrade: subq.label,
                                questionIndex: subq.questionIndex,
                                subQuestionIndex: subq.subQuestionIndex,
                                // Add full student name as a sortable field
                                studentName: `${submission.user.first_name} ${submission.user.last_name}`
                            });
                        });
                    } else {
                        // Fallback if no regrade requests found but the submission is returned
                        processedRequests.push({
                            ...submission,
                            subquestionId: null,
                            pendingRegrade: 'N/A',
                            studentName: `${submission.user.first_name} ${submission.user.last_name}`
                        });
                    }
                });

                setRegradeRequests(processedRequests);
            } catch (err) {
                console.log(err);
                setError('Error fetching regrade requests');
            }
        };
        fetchRegradeRequests();
    }, [user]);

    // Modified to include sort parameters in the URL
    const LinkToTQuestionDetails = ({submission, allRequests}) => {
        // Generate a unique key for this set of sorted IDs
        const storageKey = `sortedRegradeIds_${assignment_id || 'global'}`;

        // Store the full list in localStorage instead of the URL
        localStorage.setItem(storageKey, JSON.stringify(
            allRequests.map(req => ({
                submissionId: req.id,
                subquestionId: req.subquestionId,
                assignmentId: req.assignment.id,
                courseId: req.assignment.course_id
            }))
        ));

        console.log(localStorage.getItem(storageKey));
        console.log(storageKey);

        if (submission.subquestionId) {
            // Find current index in the sorted list for reference
            const currentIndex = allRequests.findIndex(req =>
                req.id === submission.id && req.subquestionId === submission.subquestionId
            );

            return (
                <Link
                    to={`/tclasses/${submission.assignment.course_id}/tassignments/${submission.assignment.id}/tsubmissions/${submission.id}/tquestiondetails/${submission.subquestionId}?regradeMode=true&sortKey=${sortConfig.key}&sortDir=${sortConfig.direction}&currentIndex=${currentIndex}`}
                    className="hover:underline font-semibold text-gray-900"
                >
                    {`${submission.user.first_name} ${submission.user.last_name}`}
                </Link>
            );
        }

        // Fallback if no specific subquestion
        return (
            <Link
                to={`/tclasses/${submission.assignment.course_id}/tassignments/${submission.assignment.id}/tsubmissions/${submission.id}/tdetails?sortKey=${sortConfig.key}&sortDir=${sortConfig.direction}`}
                className="hover:underline font-semibold text-gray-900"
            >
                {`${submission.user.first_name} ${submission.user.last_name}`}
            </Link>
        );
    };

    if (error) {
        return <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">{error}</div>;
    }

    const sortedRegradeRequests = getSortedItems(regradeRequests);

    return (
        <div className="flex flex-col space-y-4 w-full p-8">
            <div className="flex flex-row w-full justify-between">
                <h1 className="text-gray-900 text-xl font-medium">Regrade Requests</h1>
            </div>
            <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-6">
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}
                <table className="w-full border-collapse">
                    <thead>
                    <tr className="border-b border-gray-200">
                        <th
                            className="py-4 text-left text-gray-500 font-medium text-sm cursor-pointer hover:text-amber-500 transition-colors duration-200"
                            onClick={() => requestSort('studentName')}
                        >
                            Student{getSortIndicator('studentName')}
                        </th>
                        <th
                            className="py-4 text-left text-gray-500 font-medium text-sm cursor-pointer hover:text-amber-500 transition-colors duration-200"
                            onClick={() => requestSort('submission_date')}
                        >
                            Submission Date{getSortIndicator('submission_date')}
                        </th>
                        <th
                            className="py-4 text-left text-gray-500 font-medium text-sm cursor-pointer hover:text-amber-500 transition-colors duration-200"
                            onClick={() => requestSort('grade')}
                        >
                            Grade{getSortIndicator('grade')}
                        </th>
                        <th
                            className="py-4 text-left text-gray-500 font-medium text-sm cursor-pointer hover:text-amber-500 transition-colors duration-200"
                            onClick={() => requestSort('assignment.title')}
                        >
                            Assignment{getSortIndicator('assignment.title')}
                        </th>
                        <th
                            className="py-4 text-left text-gray-500 font-medium text-sm cursor-pointer hover:text-amber-500 transition-colors duration-200"
                            onClick={() => requestSort('pendingRegrade')}
                        >
                            Pending Regrades{getSortIndicator('pendingRegrade')}
                        </th>
                    </tr>
                    </thead>
                    {sortedRegradeRequests.length > 0 ? (
                        <tbody>
                        {sortedRegradeRequests.map((submission, index) => (
                            <tr
                                key={`${submission.id}-${submission.subquestionId || index}`}
                                className="border-b border-gray-200 transition-colors duration-200"
                            >
                                <td className="py-4 text-left">
                                    <LinkToTQuestionDetails submission={submission}
                                                            allRequests={sortedRegradeRequests}/>
                                </td>
                                <td className="py-4 text-left text-gray-700 font-medium text-sm">
                                    {formatDate(submission.submission_date)}
                                </td>
                                <td className="py-4 text-left text-gray-700 font-medium text-sm">{submission.grade}</td>
                                <td className="py-4 text-left text-gray-700 font-medium text-sm">
                                    {truncateText(submission.assignment.title, 20)}
                                </td>
                                <td className="py-4 text-left">
                                    <span
                                        className="inline-flex items-center bg-amber-100 text-amber-700 px-2.5 py-1 rounded-md text-sm font-medium">
                                        {submission.pendingRegrade}
                                    </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    ) : (
                        <tbody>
                        <tr>
                            <td colSpan="5" className="text-center py-6 text-gray-500">
                                No regrade requests found
                            </td>
                        </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
};

export default RegradeRequests;