import React, {useState} from 'react';

const PointsModal = ({isOpen, onClose, onSubmit}) => {
    const [points, setPoints] = useState('100');
    const [error, setError] = useState('');

    if (!isOpen) {
        return null;
    }

    const handleSubmit = () => {
        const numPoints = Number(points);
        if (!points || isNaN(numPoints) || numPoints < 50 || numPoints > 1000) {
            setError('Please enter a number between 50 and 1000');
            return;
        }

        onSubmit(numPoints);
        setPoints('100');
        setError('');
        onClose();
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50 flex justify-center items-center">
            <div
                className="relative mx-auto p-8 bg-white w-full max-w-[400px] rounded-2xl shadow-xl border border-gray-100">
                {/* Header */}
                <div className="flex flex-row justify-between items-center mb-6">
                    <h2 className="text-[1.125rem] font-semibold text-gray-900 tracking-[-0.01em]">
                        Assignment Points
                    </h2>
                    <button
                        type="button"
                        className="hover:opacity-70 transition-opacity"
                        onClick={onClose}
                    >
                        <img src="/assets/X.png" alt="Exit" className="w-5 h-5"/>
                    </button>
                </div>

                <div className="space-y-4">
                    <p className="text-[0.9375rem] text-gray-600 leading-relaxed">
                        How many points would you like this assignment to be worth?
                    </p>

                    {/* Input */}
                    <div className="mt-4">
                        <div className={`relative flex items-center ${error ? 'border-red-500' : 'border-gray-200'}`}>
                            <input
                                type="number"
                                value={points}
                                min="50"
                                max="1000"
                                className={`w-full px-4 py-3 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-[#e4b17a] focus:ring-2 focus:ring-[#e4b17a] focus:ring-opacity-20 transition-all duration-200
                                    ${error ? 'border-red-500 text-red-500' : 'text-gray-900'}`}
                                placeholder="Enter points (50-1000)"
                                onChange={(e) => {
                                    setPoints(e.target.value);
                                    setError('');
                                }}
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                        {error && (
                            <div className="text-red-500 text-sm mt-2 font-medium">{error}</div>
                        )}
                    </div>
                </div>

                {/* Footer */}
                <div className="flex justify-end gap-3 mt-8">
                    <button
                        type="button"
                        className="px-5 py-2.5 rounded-xl border border-gray-200 text-gray-600 text-[0.875rem] font-medium hover:bg-gray-50 transition-all duration-200 hover:transform hover:-translate-y-0.5"
                        onClick={() => {
                            setPoints('100');
                            setError('');
                            onClose();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-5 py-2.5 rounded-xl bg-gradient-to-br from-[#e4b17a] to-[#c99b69] text-white text-[0.875rem] font-medium hover:from-[#c99b69] hover:to-[#b38a5e] transition-all duration-200 hover:transform hover:-translate-y-0.5 shadow-sm hover:shadow"
                        onClick={handleSubmit}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PointsModal;