import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react';
import {Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext';
import {GroupLoading} from '../../Loading';
import './TGroupDetails.scss';

// Import our extracted components
import BatchNavigation from './BatchNavigation';
import SubmissionCard from './SubmissionCard';
import GradingControls from './GradingControls';
import {
    RubricOverlay,
    AIRegradeOverlay,
    SolutionOverlay,
    NotificationOverlay,
    NextSubquestionPopup,
    NavigationTips
} from './Overlays';

function TGroupDetails({groupIndex, students, allStudents, assignment}) {
    const {user} = useAuth();
    const navigate = useNavigate();
    const {class_id, assignment_id} = useParams();
    const location = useLocation();
    const contentContainerRef = useRef(null);

    // Core state
    const [data, setData] = useState({
        submissions: [],
        examPaper: [],
        availableStudents: [],
        loading: true,
        error: null
    });

    // UI and navigation state
    const [showNavTip, setShowNavTip] = useState(true);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [currentBatchIndex, setCurrentBatchIndex] = useState(parseInt(groupIndex) || 0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [slideDirection, setSlideDirection] = useState(null);
    const [showTopBar, setShowTopBar] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const [markingBatchAsViewed, setMarkingBatchAsViewed] = useState(false);
    const [allCurrentBatchViewed, setAllCurrentBatchViewed] = useState(false);
    const [reviewMode, setReviewMode] = useState('Default');

    // Grading state
    const [gradingInstructions, setGradingInstructions] = useState('');
    const [lastUsedInstructions, setLastUsedInstructions] = useState('');
    const [glowingSlashPositions, setGlowingSlashPositions] = useState([]);
    const [textareaHeight, setTextareaHeight] = useState('40px');
    const [isRegrading, setIsRegrading] = useState(false);
    const [subQuestionsRegrading, setSubQuestionsRegrading] = useState({});
    const [submissionsRegrading, setSubmissionsRegrading] = useState({});

    // Selection state
    const [selectedSubmissions, setSelectedSubmissions] = useState([]);
    const [expandedSubmissions, setExpandedSubmissions] = useState({});
    // Track submissions that have been graded but not yet tucked
    const [gradedSubmissions, setGradedSubmissions] = useState([]);
    const [showStudentNames, setShowStudentNames] = useState({});

    // Overlay states
    const [activeOverlay, setActiveOverlay] = useState(null);
    const [showAIRegradeOverlay, setShowAIRegradeOverlay] = useState(false);
    const [showSolutionOverlay, setShowSolutionOverlay] = useState(false);
    const [selectedSubQuestion, setSelectedSubQuestion] = useState(null);
    const [assigningStudent, setAssigningStudent] = useState(null);
    const [editingFeedback, setEditingFeedback] = useState(null);
    const [feedbackText, setFeedbackText] = useState('');
    const [notification, setNotification] = useState(null);
    const [showNextSubquestionPopup, setShowNextSubquestionPopup] = useState(false);
    const [nextSubQuestionId, setNextSubQuestionId] = useState(null);

    // Dropdown state
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Batch loading state
    const [loadedBatches, setLoadedBatches] = useState({});
    const [batchLoading, setBatchLoading] = useState(false);
    const [prefetchingBatch, setPrefetchingBatch] = useState(null);

    // Regrading state
    const [regradeData, setRegradeData] = useState(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    // Update selected subquestion when current question changes
    useEffect(() => {
        if (currentQuestion && data?.examPaper) {
            const currentSubQuestion = data.examPaper
                .flatMap(q => q.subQuestions)
                .find(sq => sq.id === Number(currentQuestion));
            if (currentSubQuestion) {
                setSelectedSubQuestion(currentSubQuestion);
            }
        }
    }, [currentQuestion, data?.examPaper]);


    // State to track if batch input is focused
    const [isBatchInputFocused, setIsBatchInputFocused] = useState(false);

    // Top bar visibility handler
    useEffect(() => {
        const handleMouseMove = (e) => {
            // Always show topbar if dropdown is open or batch input is focused, otherwise show based on mouse position
            if (isDropdownOpen || isBatchInputFocused || e.clientY < 160) {
                setShowTopBar(true);
            } else {
                setShowTopBar(false);
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        // Set topbar visible when dropdown opens or batch input is focused
        if (isDropdownOpen || isBatchInputFocused) {
            setShowTopBar(true);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isDropdownOpen, isBatchInputFocused]);

    // Handle click outside for dropdown
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }

        // Add event listener when dropdown is open
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Clean up the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    // Helper for textarea height calculation
    const updateTextareaHeight = useCallback((text) => {
        // Count lines by counting newlines plus 1
        const lines = text.split('\n');
        const lineCount = Math.min(5, lines.length);

        // Calculate additional lines needed for wrapped text
        const extraLines = lines.reduce((total, line) => {
            // Rough estimate: 1 line can fit ~80 characters
            return total + Math.floor(line.length / 80);
        }, 0);

        const totalLines = Math.min(5, lineCount + extraLines);
        const height = Math.max(40, totalLines * 24); // 24px line height, minimum 40px

        setTextareaHeight(`${height}px`);
    }, []);

    // Instructions change handler with slash detection
    const handleInstructionsChange = useCallback((e) => {
        const value = e.target.value;
        setGradingInstructions(value);

        // Find all positions of '/' in the text
        const newPositions = [];
        for (let i = 0; i < value.length; i++) {
            if (value[i] === '/') {
                newPositions.push(i);
            }
        }
        setGlowingSlashPositions(newPositions);

        // Calculate textarea height based on content
        updateTextareaHeight(value);
    }, [updateTextareaHeight]);

    // Helper to render text with glowing slashes
    const renderInstructionsText = useCallback(() => {
        if (!gradingInstructions) {
            return '';
        }

        const parts = [];
        let lastIndex = 0;

        glowingSlashPositions.forEach((position, index) => {
            // Add text before the slash
            if (position > lastIndex) {
                parts.push(gradingInstructions.slice(lastIndex, position));
            }
            // Add the glowing slash
            parts.push(<span key={`slash-${index}`} className="animate-glow-text">/</span>);
            lastIndex = position + 1;
        });

        // Add remaining text
        if (lastIndex < gradingInstructions.length) {
            parts.push(gradingInstructions.slice(lastIndex));
        }

        return <>{parts}</>;
    }, [gradingInstructions, glowingSlashPositions]);

    // Check if a specific subquestion is being regraded
    const isSubQuestionRegrading = useCallback((subQuestionId) => {
        // Find the current subquestion in the exam paper
        const currentSubQuestion = data.examPaper
            ?.flatMap(question => question.subQuestions)
            ?.find(sq => sq.id === Number(subQuestionId));

        // Check if it's being regraded
        return currentSubQuestion?.regrade_in_progress === true;
    }, [data.examPaper]);

    // Update regrading status whenever the current question or assignmentDetails changes
    useEffect(() => {
        if (currentQuestion === null) {
            return;
        }

        // Check if current subquestion is being regraded by someone else
        const isCurrentSubQuestionRegrading = isSubQuestionRegrading(Number(currentQuestion));

        // Update the subQuestionsRegrading state
        setSubQuestionsRegrading(prev => ({
            ...prev,
            [currentQuestion]: isCurrentSubQuestionRegrading
        }));

        // Update the isRegrading state based on the current subquestion
        setIsRegrading(isCurrentSubQuestionRegrading);

    }, [currentQuestion, isSubQuestionRegrading]);

    const currentBatchSubmissions = useMemo(() => {
        if (!data.submissions.length) {
            return [];
        }

        return data.submissions;
    }, [data.submissions, reviewMode]);


    // Function to refetch submissions for the current batch
    const refetchCurrentBatchSubmissions = useCallback(async () => {
        try {
            // Get the students for the current batch
            const startIdx = currentBatchIndex * 10;
            const endIdx = Math.min(startIdx + 10, allStudents.length);
            const batchStudents = allStudents.slice(startIdx, endIdx).filter(student => student && student.id);

            if (batchStudents.length === 0) {
                return;
            }

            // Fetch updated submissions for each student
            const submissionPromises = batchStudents.map(student =>
                gradeApi.get({
                    path: `/api/assignment/${assignment_id}/submission/${student.id}/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                })
            );

            const results = await Promise.all(submissionPromises);
            const batchSubmissions = results.map(r => r.data);

            // Update state with the new submissions
            setData(prev => {
                // Create a map of submissions by id
                const submissionsMap = {};
                prev.submissions.forEach(sub => {
                    submissionsMap[sub.id] = sub;
                });

                // Replace with updated submissions
                batchSubmissions.forEach(sub => {
                    submissionsMap[sub.id] = sub;
                });

                return {
                    ...prev,
                    submissions: Object.values(submissionsMap)
                };
            });

            // Force the loadedBatches to update
            setLoadedBatches(prev => ({
                ...prev,
                [currentBatchIndex]: true
            }));

        } catch (error) {
            console.error('Error refetching batch submissions:', error);
            setNotification({
                type: 'error',
                message: 'Failed to update submissions after regrading.'
            });
            setTimeout(() => setNotification(null), 10000);
        }
    }, [assignment_id, currentBatchIndex, allStudents, user.session_token]);

    // Helper for finding next subquestion
    const findNextSubquestion = useCallback(() => {
        // Mark graded submissions as viewed when navigating to next subquestion
        if (gradedSubmissions.length > 0) {
            // Mark all graded submissions as viewed
            const markViewedPromises = gradedSubmissions.map(submissionId =>
                gradeApi.post({
                    path: `/api/assignment/${assignment_id}/submissions/${submissionId}/feedback/${currentQuestion}/mark-viewed/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                })
            );

            // Execute all promises
            Promise.all(markViewedPromises).then(() => {
                // Update submissions in state to show they've been viewed
                setData(prev => {
                    const updatedSubmissions = prev.submissions.map(sub => {
                        if (gradedSubmissions.includes(sub.id)) {
                            // Make sure the feedback property exists
                            const updatedFeedback = sub.feedback || {};

                            // Create or update the entry for the current question
                            updatedFeedback[currentQuestion] = {
                                ...(updatedFeedback[currentQuestion] || {}),
                                is_viewed: true
                            };

                            return {
                                ...sub,
                                is_reviewed: true,
                                feedback: updatedFeedback
                            };
                        }
                        return sub;
                    });

                    return {
                        ...prev,
                        submissions: updatedSubmissions
                    };
                });

                // Clear the graded submissions list
                setGradedSubmissions([]);
            }).catch(error => {
                console.error('Error marking submissions as viewed during navigation:', error);
            });
        }
        let currentSubQuestionFound = false;
        let foundNextSubQuestionId = null;
        let foundNextSubQuestion = null;

        for (const question of data.examPaper) {
            for (const subQuestion of question.subQuestions) {
                if (currentSubQuestionFound) {
                    foundNextSubQuestionId = subQuestion.id;
                    foundNextSubQuestion = subQuestion;
                    break;
                }
                if (subQuestion.id === Number(currentQuestion)) {
                    currentSubQuestionFound = true;
                }
            }
            if (foundNextSubQuestionId) {
                break;
            }
        }

        setNextSubQuestionId(foundNextSubQuestionId);
        setShowNextSubquestionPopup(true);

        setTimeout(() => {
            setShowNextSubquestionPopup(false);
            if (foundNextSubQuestionId) {
                // Update the URL with the new subquestion ID
                navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions/group/0`, {
                    state: {
                        students: students,
                        allStudents: allStudents,
                        initialSubQuestion: foundNextSubQuestionId
                    },
                    replace: true // Replace current history entry instead of adding a new one
                });

                setCurrentQuestion(foundNextSubQuestionId);
                setCurrentBatchIndex(0); // Reset to first batch when changing subquestion
                setExpandedSubmissions({}); // Reset expanded state when changing subquestion

                // Set instructions if available
                if (foundNextSubQuestion?.regrade_instructions) {
                    setLastUsedInstructions(foundNextSubQuestion.regrade_instructions);
                    setGradingInstructions(foundNextSubQuestion.regrade_instructions);
                } else {
                    setGradingInstructions('');
                }
            } else {
                navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions`);
            }
        }, 1000);
    }, [currentQuestion, data.examPaper, navigate, class_id, students, allStudents, assignment_id]);

    // Helper for finding previous subquestion
    const findPreviousSubquestion = useCallback(() => {
        // Mark graded submissions as viewed when navigating to previous subquestion
        if (gradedSubmissions.length > 0) {
            // Mark all graded submissions as viewed
            const markViewedPromises = gradedSubmissions.map(submissionId =>
                gradeApi.post({
                    path: `/api/assignment/${assignment_id}/submissions/${submissionId}/feedback/${currentQuestion}/mark-viewed/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                })
            );

            // Execute all promises
            Promise.all(markViewedPromises).then(() => {
                // Update submissions in state to show they've been viewed
                setData(prev => {
                    const updatedSubmissions = prev.submissions.map(sub => {
                        if (gradedSubmissions.includes(sub.id)) {
                            // Make sure the feedback property exists
                            const updatedFeedback = sub.feedback || {};

                            // Create or update the entry for the current question
                            updatedFeedback[currentQuestion] = {
                                ...(updatedFeedback[currentQuestion] || {}),
                                is_viewed: true
                            };

                            return {
                                ...sub,
                                is_reviewed: true,
                                feedback: updatedFeedback
                            };
                        }
                        return sub;
                    });

                    return {
                        ...prev,
                        submissions: updatedSubmissions
                    };
                });

                // Clear the graded submissions list
                setGradedSubmissions([]);
            }).catch(error => {
                console.error('Error marking submissions as viewed during navigation:', error);
            });
        }
        // Flatten all subquestions into a single array
        const allSubQuestions = data.examPaper.flatMap(question =>
            question.subQuestions
        );

        // Find the index of the current question
        const currentIndex = allSubQuestions.findIndex(sq => sq.id === Number(currentQuestion));

        // Get the previous question's ID if it exists
        const previousSubQuestionId = currentIndex > 0 ? allSubQuestions[currentIndex - 1].id : null;
        const previousSubQuestion = currentIndex > 0 ? allSubQuestions[currentIndex - 1] : null;

        setNextSubQuestionId(previousSubQuestionId);
        setShowNextSubquestionPopup(true);

        setTimeout(() => {
            setShowNextSubquestionPopup(false);
            if (previousSubQuestionId) {
                // Update the URL with the new subquestion ID
                navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions/group/0`, {
                    state: {
                        students: students,
                        allStudents: allStudents,
                        initialSubQuestion: previousSubQuestionId
                    },
                    replace: true // Replace current history entry instead of adding a new one
                });

                setCurrentQuestion(previousSubQuestionId);
                setCurrentBatchIndex(0); // Reset to first batch when changing subquestion
                setExpandedSubmissions({}); // Reset expanded state when changing subquestion

                // Set instructions if available
                if (previousSubQuestion?.regrade_instructions) {
                    setLastUsedInstructions(previousSubQuestion.regrade_instructions);
                    setGradingInstructions(previousSubQuestion.regrade_instructions);
                } else {
                    setGradingInstructions('');
                }
            } else {
                navigate(`/tclasses/${class_id}/tassignments/${assignment_id}/tsubmissions`);
            }
        }, 1000);
    }, [currentQuestion, data.examPaper, navigate, class_id, students, allStudents, assignment_id]);

    // Navigation between batches
    const navigateToBatch = useCallback((direction, targetBatchIndex = null) => {
        if (isTransitioning) {
            return;
        }

        // Mark graded submissions as viewed when navigating
        if (gradedSubmissions.length > 0) {
            // Mark all graded submissions as viewed
            const markViewedPromises = gradedSubmissions.map(submissionId =>
                gradeApi.post({
                    path: `/api/assignment/${assignment_id}/submissions/${submissionId}/feedback/${currentQuestion}/mark-viewed/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                })
            );

            // Execute all promises
            Promise.all(markViewedPromises).then(() => {
                // Update submissions in state to show they've been viewed
                setData(prev => {
                    const updatedSubmissions = prev.submissions.map(sub => {
                        if (gradedSubmissions.includes(sub.id)) {
                            // Make sure the feedback property exists
                            const updatedFeedback = sub.feedback || {};

                            // Create or update the entry for the current question
                            updatedFeedback[currentQuestion] = {
                                ...(updatedFeedback[currentQuestion] || {}),
                                is_viewed: true
                            };

                            return {
                                ...sub,
                                is_reviewed: true,
                                feedback: updatedFeedback
                            };
                        }
                        return sub;
                    });

                    return {
                        ...prev,
                        submissions: updatedSubmissions
                    };
                });

                // Clear the graded submissions list
                setGradedSubmissions([]);
            }).catch(error => {
                console.error('Error marking submissions as viewed during navigation:', error);
            });
        }

        // Determine the new batch index
        let newIndex;
        if (targetBatchIndex !== null) {
            // If a specific target batch is provided, use it
            newIndex = targetBatchIndex;
        } else {
            // Otherwise calculate based on direction
            newIndex = direction === 'next' ? currentBatchIndex + 1 : currentBatchIndex - 1;
        }

        const maxBatches = Math.ceil(allStudents.length / 10);

        if (newIndex >= 0 && newIndex < maxBatches) {
            // Show the top bar temporarily when navigating between batches
            setShowTopBar(true);
            setTimeout(() => {
                // Keep top bar visible if textarea or batch input is focused
                const activeElement = document.activeElement;
                if (activeElement.tagName !== 'TEXTAREA' &&
                    !(activeElement.tagName === 'INPUT' && activeElement.type === 'text' && activeElement.classList.contains('w-12'))) {
                    setShowTopBar(false);
                }
            }, 1000);

            setIsTransitioning(true);
            // Determine slide direction based on batch indices
            const slideDir = newIndex > currentBatchIndex ? 'right' : 'left';
            setSlideDirection(slideDir);

            // Update batch index after animation
            setTimeout(() => {
                setCurrentBatchIndex(newIndex);

                // Reset states after animation completes
                setTimeout(() => {
                    setSlideDirection(null);
                    setIsTransitioning(false);

                    // Reset expanded state for all submissions when changing batches
                    setExpandedSubmissions({});

                    // Scroll to the top of the content container after changing batches
                    if (contentContainerRef.current) {
                        contentContainerRef.current.scrollTop = 0;
                    }
                }, 50);
            }, 400);
        }
    }, [currentBatchIndex, isTransitioning, allStudents.length, gradedSubmissions, currentQuestion, assignment_id, user.session_token]);

    // Mark batch as viewed
    const markBatchAsViewed = useCallback(async () => {
        // Don't do anything if all are already viewed
        if (allCurrentBatchViewed) {
            return;
        }

        if (markingBatchAsViewed) {
            return;
        }

        setMarkingBatchAsViewed(true);
        try {
            // Get the student IDs for the current batch
            const startIdx = currentBatchIndex * 10;
            const endIdx = Math.min(startIdx + 10, allStudents.length);
            const batchStudents = allStudents.slice(startIdx, endIdx).filter(student => student && student.id);
            const batchStudentIds = batchStudents.map(student => student.id);

            // Filter submissions to only include those from students in the current batch
            const currentPageSubmissions = currentBatchSubmissions.filter(submission => {
                // For matched submissions, check if the student ID is in the current batch
                return batchStudentIds.includes(submission.id);
            });

            const submissionIds = currentPageSubmissions
                .filter(submission => submission && submission.id)
                .map(submission => submission.id);

            if (submissionIds.length === 0) {
                throw new Error('No valid submissions to mark as viewed');
            }

            // Store the currentQuestion value in a local variable to ensure it's not changed during API calls
            const subQuestionId = currentQuestion;

            // Call API to mark each submission as viewed
            const markViewedPromises = submissionIds.map(submissionId =>
                gradeApi.post({
                    path: `/api/assignment/${assignment_id}/submissions/${submissionId}/feedback/${subQuestionId}/mark-viewed/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                })
            );

            // Wait for all requests to complete
            await Promise.all(markViewedPromises);

            // Update submissions in state to show they've been viewed
            setData(prev => {
                const updatedSubmissions = prev.submissions.map(sub => {
                    if (submissionIds.includes(sub.id)) {
                        // Make sure the feedback property exists
                        const updatedFeedback = sub.feedback || {};

                        // Create or update the entry for the current question
                        updatedFeedback[subQuestionId] = {
                            ...(updatedFeedback[subQuestionId] || {}),
                            is_viewed: true
                        };

                        return {
                            ...sub,
                            is_reviewed: true,
                            feedback: updatedFeedback
                        };
                    }
                    return sub;
                });

                return {
                    ...prev,
                    submissions: updatedSubmissions
                };
            });

            // Update the exam paper to reflect the new viewed count
            setData(prev => ({
                ...prev,
                examPaper: prev.examPaper.map(question => ({
                    ...question,
                    subQuestions: question.subQuestions.map(sq => {
                        if (sq.id === Number(subQuestionId)) {
                            return {
                                ...sq,
                                viewed_count: (sq.viewed_count || 0) + submissionIds.length
                            };
                        }
                        return sq;
                    })
                }))
            }));

            // Force the allCurrentBatchViewed state to update immediately
            setAllCurrentBatchViewed(true);

            setNotification({
                type: 'success',
                message: `Marked ${submissionIds.length} submissions as viewed`
            });
            setTimeout(() => setNotification(null), 10000);
        } catch (error) {
            console.error('Error marking batch as viewed:', error);
            setNotification({
                type: 'error',
                message: error.message || 'Failed to mark submissions as viewed'
            });
            setTimeout(() => setNotification(null), 10000);
        } finally {
            setMarkingBatchAsViewed(false);
        }
    }, [
        currentQuestion,
        currentBatchIndex,
        currentBatchSubmissions,
        user.session_token,
        assignment_id,
        allCurrentBatchViewed,
        markingBatchAsViewed,
        allStudents
    ]);

    const markViewedAndNext = useCallback(() => {
        // First mark any graded submissions as viewed
        const markGradedPromises = [];

        if (gradedSubmissions.length > 0) {
            // Mark all graded submissions as viewed
            gradedSubmissions.forEach(submissionId => {
                markGradedPromises.push(
                    gradeApi.post({
                        path: `/api/assignment/${assignment_id}/submissions/${submissionId}/feedback/${currentQuestion}/mark-viewed/`,
                        config: {
                            headers: {
                                Authorization: `Bearer ${user.session_token}`
                            }
                        }
                    })
                );
            });

            // Update submissions in state
            setData(prev => {
                const updatedSubmissions = prev.submissions.map(sub => {
                    if (gradedSubmissions.includes(sub.id)) {
                        // Make sure the feedback property exists
                        const updatedFeedback = sub.feedback || {};

                        // Create or update the entry for the current question
                        updatedFeedback[currentQuestion] = {
                            ...(updatedFeedback[currentQuestion] || {}),
                            is_viewed: true
                        };

                        return {
                            ...sub,
                            is_reviewed: true,
                            feedback: updatedFeedback
                        };
                    }
                    return sub;
                });

                return {
                    ...prev,
                    submissions: updatedSubmissions
                };
            });

            // Clear the graded submissions list
            setGradedSubmissions([]);
        }

        // Then mark the batch as viewed and navigate
        Promise.all(markGradedPromises).then(() => {
            markBatchAsViewed().then(() => {
                // After marking as viewed, navigate to next batch
                if (currentBatchIndex < Math.ceil(allStudents.length / 10) - 1) {
                    navigateToBatch('next');
                } else {
                    // If this is the last batch, go to next subquestion
                    findNextSubquestion();
                }
            });
        });
    }, [markBatchAsViewed, currentBatchIndex, allStudents.length, navigateToBatch, findNextSubquestion, gradedSubmissions, currentQuestion, assignment_id, user.session_token]);

    // Handle keyboard navigation
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Only handle navigation if not typing in the textarea
            if (document.activeElement.tagName !== 'TEXTAREA') {
                if (e.key === 'ArrowLeft' && currentBatchIndex > 0) {
                    navigateToBatch('prev');
                } else if (e.key === 'ArrowRight') {
                    if (e.shiftKey) {
                        // Shift + Right Arrow: Mark current batch as viewed and go to next
                        markViewedAndNext();
                    } else if (currentBatchIndex < Math.ceil(allStudents.length / 10) - 1) {
                        // Regular Right Arrow: Just navigate to next batch
                        navigateToBatch('next');
                    }
                } else if (e.key === 'ArrowUp') {
                    findPreviousSubquestion();
                } else if (e.key === 'ArrowDown') {
                    findNextSubquestion();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [
        currentBatchIndex,
        allStudents,
        isTransitioning,
        findPreviousSubquestion,
        findNextSubquestion,
        markViewedAndNext,
        navigateToBatch
    ]);

    // Get the submissions for the current batch
    const currentDisplaySubmissions = useMemo(() => {

        if (!currentBatchSubmissions || currentBatchSubmissions.length === 0) {
            return [];
        }

        // Get the student IDs for the current batch
        const startIdx = currentBatchIndex * 10;
        const endIdx = Math.min(startIdx + 10, allStudents.length);
        const batchStudents = allStudents.slice(startIdx, endIdx).filter(student => student && student.id);
        const batchStudentIds = batchStudents.map(student => student.id);
        console.log(batchStudentIds, 'batchStudentIds');
        // Filter submissions to only include those from students in the current batch
        const batchSubmissions = currentBatchSubmissions.filter(submission => {

            // For matched submissions, check if the student ID is in the current batch
            return batchStudentIds.includes(submission.id);
        });

        console.log(batchSubmissions, 'batchSubmissions');

        return batchSubmissions;
    }, [currentBatchIndex, currentBatchSubmissions, allStudents]);


    // Check if all submissions in the current batch have been viewed
    useEffect(() => {
        if (currentQuestion === null || currentBatchIndex === null) {
            setAllCurrentBatchViewed(false);
            return;
        }

        // Check if all submissions in the current batch have been viewed for the current question
        const allViewed = currentDisplaySubmissions.every(submission => {
            const feedbackEntry = submission?.feedback?.[currentQuestion];
            return feedbackEntry?.is_viewed === true;
        });

        setAllCurrentBatchViewed(allViewed);
    }, [currentQuestion, currentBatchIndex, currentDisplaySubmissions]);

    // Data fetching useEffect
    useEffect(() => {
        const fetchStaticData = async () => {
            if (!students?.length || !assignment || !assignment_id) {
                return;
            }

            setData(prev => ({...prev, loading: true, error: null}));

            try {

                // 2. Fetch questions
                const questionsResponse = await gradeApi.get({
                    path: `/api/assignment/${assignment_id}/questions/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });
                const examPaper = questionsResponse.data.questions;

                // 3. Initialize current question if not set
                let currentSubQuestionId = currentQuestion;

                // Check if there's an initialSubQuestion in the location state
                const initialSubQuestion = location.state?.initialSubQuestion;

                // Check if we need to jump to a specific batch
                const jumpToBatch = location.state?.jumpToBatch;
                const targetBatchIndex = parseInt(location.pathname.split('/').pop());

                if (jumpToBatch && !isNaN(targetBatchIndex) && targetBatchIndex >= 0) {
                    // If we're jumping to a specific batch, update the batch index
                    setCurrentBatchIndex(targetBatchIndex);
                }

                if (initialSubQuestion) {
                    currentSubQuestionId = initialSubQuestion;
                    setCurrentQuestion(initialSubQuestion);

                    // Find the subquestion to get its instructions if available
                    for (const question of examPaper) {
                        for (const subQuestion of question.subQuestions) {
                            if (subQuestion.id === Number(initialSubQuestion)) {
                                if (subQuestion.regrade_instructions) {
                                    setLastUsedInstructions(subQuestion.regrade_instructions);
                                    setGradingInstructions(subQuestion.regrade_instructions);
                                }
                                break;
                            }
                        }
                    }
                } else if (!currentSubQuestionId && examPaper.length > 0) {
                    const firstSubQuestion = examPaper[0].subQuestions[0];
                    if (firstSubQuestion) {
                        currentSubQuestionId = firstSubQuestion.id;
                        setCurrentQuestion(firstSubQuestion.id);

                        // Set instructions if available
                        if (firstSubQuestion.regrade_instructions) {
                            setLastUsedInstructions(firstSubQuestion.regrade_instructions);
                            setGradingInstructions(firstSubQuestion.regrade_instructions);
                        }
                    }
                }

                // Set the static data
                setData(prev => ({
                    ...prev,
                    examPaper: examPaper,
                    loading: false,
                    error: null
                }));

            } catch (error) {
                console.error('Error fetching static data:', error);
                setData(prev => ({
                    ...prev,
                    loading: false,
                    error: 'Failed to load data. Please try again.'
                }));

                setNotification({
                    type: 'error',
                    message: 'Failed to load data. Please try again.'
                });
                setTimeout(() => setNotification(null), 10000);
            }
        };

        fetchStaticData();
    }, [assignment_id, reviewMode, students, user.session_token, assignment, currentQuestion, location.state, location.pathname]);

    // Helper function to fetch submissions for a specific batch
    const fetchBatchSubmissions = useCallback(async (batchIndex, isPrefetch = false) => {
        // Skip if we don't have necessary data
        if (!assignment_id || !allStudents?.length) {
            return;
        }

        // If we've already loaded this batch, don't fetch it again
        if (loadedBatches[batchIndex]) {
            return;
        }

        // Set loading state only if this is not a prefetch
        if (!isPrefetch) {
            setBatchLoading(true);
        } else {
            setPrefetchingBatch(batchIndex);
        }

        try {
            // Fetch only the submissions for the specified batch
            const startIdx = batchIndex * 10;
            const endIdx = Math.min(startIdx + 10, allStudents.length);
            const batchStudents = allStudents.slice(startIdx, endIdx).filter(student => student && student.id);

            if (batchStudents.length === 0) {
                setData(prev => ({...prev, batchLoading: false}));
                return;
            }

            // Get the student IDs for this batch
            const studentIds = batchStudents.map(student => student.id);

            // Fetch submissions for each student in the batch
            const submissionPromises = studentIds.map(studentId =>
                gradeApi.get({
                    path: `/api/assignment/${assignment_id}/submission/${studentId}/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                })
            );

            const results = await Promise.all(submissionPromises);
            const batchSubmissions = results.map(r => r.data);

            // Update state with the new batch of submissions
            setData(prev => {
                // Create a map of submissions by id for easy lookup
                const submissionsMap = {};
                prev.submissions.forEach(sub => {
                    submissionsMap[sub.id] = sub;
                });

                // Add new submissions to the map
                batchSubmissions.forEach(sub => {
                    submissionsMap[sub.id] = sub;
                });

                return {
                    ...prev,
                    submissions: Object.values(submissionsMap)
                };
            });

            // Mark this batch as loaded
            setLoadedBatches(prev => ({
                ...prev,
                [batchIndex]: true
            }));

            // Clear loading states
            if (!isPrefetch) {
                setBatchLoading(false);
            } else {
                setPrefetchingBatch(null);
            }

        } catch (error) {
            console.error('Error fetching batch submissions:', error);
            setBatchLoading(false);
            setData(prev => ({
                ...prev,
                error: 'Failed to load submissions for this batch.'
            }));

            setNotification({
                type: 'error',
                message: 'Failed to load submissions for this batch.'
            });
            setTimeout(() => setNotification(null), 10000);
        }


    }, [assignment_id, user.session_token, allStudents, loadedBatches]);

    // Effect to fetch current batch submissions when the batch changes
    useEffect(() => {
        // Skip if we're transitioning between batches - this prevents duplicate API calls
        if (isTransitioning) {
            return;
        }

        // Check if we need to fetch this batch
        if (!loadedBatches[currentBatchIndex]) {
            fetchBatchSubmissions(currentBatchIndex, false);
        }
    }, [currentBatchIndex, loadedBatches, fetchBatchSubmissions, isTransitioning]);

    // Effect to pre-fetch the next batch
    useEffect(() => {
        // Don't pre-fetch if we're already loading the current batch or transitioning
        if (batchLoading || isTransitioning) {
            return;
        }

        // Calculate the next batch index
        const nextBatchIndex = currentBatchIndex + 1;
        const maxBatches = Math.ceil(allStudents.length / 10);

        // Check if next batch exists and hasn't been loaded yet
        if (nextBatchIndex < maxBatches && !loadedBatches[nextBatchIndex] && prefetchingBatch !== nextBatchIndex) {
            // Use a small delay to ensure current batch is fully processed first
            const prefetchTimer = setTimeout(() => {
                fetchBatchSubmissions(nextBatchIndex, true);
            }, 1000);

            return () => clearTimeout(prefetchTimer);
        }
    }, [currentBatchIndex, loadedBatches, batchLoading, isTransitioning, allStudents, fetchBatchSubmissions, prefetchingBatch]);

    // Simple polling interval for regrade status
    const POLLING_INTERVAL = 3000; // 3 seconds
    const INITIAL_DELAY = 5000; // 5 seconds initial delay

    // Polling effect for regrade status
    useEffect(() => {
        let pollingInterval = null;

        const checkRegradeStatus = async () => {
            if (!assignment_id || !currentQuestion) {
                return;
            }

            try {
                const questionsResponse = await gradeApi.get({
                    path: `/api/assignment/${assignment_id}/questions/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });

                const examPaper = questionsResponse.data.questions;

                // Update the subQuestionsRegrading state with all subquestions that are being regraded
                const updatedSubQuestionsRegrading = {};
                examPaper.forEach(question => {
                    question.subQuestions.forEach(sq => {
                        if (sq.regrade_in_progress) {
                            updatedSubQuestionsRegrading[sq.id] = true;
                        }
                    });
                });

                // If we're currently regrading but the backend doesn't show it yet,
                // keep the current question in the regrading state
                if (isRegrading && !updatedSubQuestionsRegrading[currentQuestion]) {
                    updatedSubQuestionsRegrading[currentQuestion] = true;
                }

                setSubQuestionsRegrading(updatedSubQuestionsRegrading);

                // Check if the current subquestion is being regraded
                const currentSubQuestion = examPaper
                    .flatMap(q => q.subQuestions)
                    .find(sq => sq.id === Number(currentQuestion));

                const isCurrentSubQuestionRegrading = currentSubQuestion?.regrade_in_progress === true;

                if (isCurrentSubQuestionRegrading) {
                    // If we weren't already regrading, update the state
                    if (!isRegrading) {
                        setIsRegrading(true);
                    }
                } else {
                    // If we were regrading and now it's done
                    if (isRegrading) {
                        // Stop polling
                        if (pollingInterval) {
                            clearInterval(pollingInterval);
                            pollingInterval = null;
                        }

                        // Update state
                        setIsRegrading(false);

                        // Refetch questions data to get updated rubric items
                        try {
                            const questionsResponse = await gradeApi.get({
                                path: `/api/assignment/${assignment_id}/questions/`,
                                config: {
                                    headers: {
                                        Authorization: `Bearer ${user.session_token}`
                                    }
                                }
                            });
                            const examPaper = questionsResponse.data.questions;

                            // Update the exam paper in state
                            setData(prev => ({
                                ...prev,
                                examPaper: examPaper
                            }));
                        } catch (error) {
                            console.error('Error refetching questions data:', error);
                        }

                        // Refetch all submissions for the current batch to get updated grades
                        await refetchCurrentBatchSubmissions();

                        // Show success notification
                        setNotification({
                            type: 'success',
                            message: "Subquestion regrade completed successfully!"
                        });
                        setTimeout(() => setNotification(null), 10000);
                    }
                }
            } catch (error) {
                console.error('Error checking regrade status:', error);
            }
        };

        // Start polling when isRegrading is true
        if (isRegrading) {
            // Initial check after a delay
            const initialCheckTimer = setTimeout(() => {
                checkRegradeStatus();

                // Then set up regular polling
                pollingInterval = setInterval(checkRegradeStatus, POLLING_INTERVAL);
            }, INITIAL_DELAY);

            return () => {
                clearTimeout(initialCheckTimer);
                if (pollingInterval) {
                    clearInterval(pollingInterval);
                }
            };
        }

        return () => {
        };
    }, [assignment_id, currentQuestion, isRegrading, user.session_token, refetchCurrentBatchSubmissions]);

    // Handle AI regrading with simplified approach
    const handleApplyGradingInstructions = useCallback(async (mode = 'current') => {
        // mode can be: 'current', 'next', or 'remaining'
        const instructionsToUse = gradingInstructions || lastUsedInstructions;

        if (!instructionsToUse.trim()) {
            setNotification({
                type: 'error',
                message: 'Please enter grading instructions before applying.'
            });
            setTimeout(() => setNotification(null), 10000);
            return;
        }

        try {
            const currentSubQuestion = data.examPaper
                .flatMap(q => q.subQuestions)
                .find(sq => sq.id === Number(currentQuestion));

            if (!currentSubQuestion) {
                throw new Error('Current subquestion not found');
            }

            // Determine which students/submissions to target based on mode
            let targetSubmissionIds = [];

            if (mode === 'current') {
                // Get the student IDs for the current batch
                const startIdx = currentBatchIndex * 10;
                const endIdx = Math.min(startIdx + 10, allStudents.length);
                const batchStudents = allStudents.slice(startIdx, endIdx).filter(student => student && student.id);
                const batchStudentIds = batchStudents.map(student => student.id);

                // Filter submissions to only include those from students in the current batch
                const currentPageSubmissions = currentBatchSubmissions.filter(submission => {
                    // For matched submissions, check if the student ID is in the current batch
                    return batchStudentIds.includes(submission.id);
                });

                targetSubmissionIds = currentPageSubmissions
                    .filter(submission => submission && submission.id)
                    .map(submission => submission.id);

                if (targetSubmissionIds.length === 0) {
                    throw new Error('No valid submissions to grade in current batch');
                }

                const updatedRegrading = {};
                currentPageSubmissions.forEach(submission => {
                    updatedRegrading[submission.id] = true;
                });
                setSubmissionsRegrading(updatedRegrading);
            } else if (mode === 'next') {
                // Next batch only
                const nextBatchIndex = currentBatchIndex + 1;
                const startIdx = nextBatchIndex * 10;
                const endIdx = Math.min(startIdx + 10, allStudents.length);

                // Since student.id is actually the submission id, we can use it directly
                // without making individual API calls for each student
                const targetStudents = allStudents
                    .slice(startIdx, endIdx)
                    .filter(student => student && student.id);

                // Extract submission IDs directly from the student objects
                // (student.id is actually the submission id)
                targetSubmissionIds = targetStudents.map(student => student.id);

                if (targetSubmissionIds.length === 0) {
                    throw new Error('No valid submissions to grade in next batch');
                }
            } else if (mode === 'remaining') {
                // Get students after the current batch
                const startIdx = (currentBatchIndex + 1) * 10;
                const endIdx = allStudents.length;

                if (startIdx >= endIdx) {
                    throw new Error('No remaining students to grade');
                }

                // Since student.id is actually the submission id, we can use it directly
                // without making individual API calls for each student
                const targetStudents = allStudents
                    .slice(startIdx, endIdx)
                    .filter(student => student && student.id);

                if (targetStudents.length === 0) {
                    throw new Error('No valid students to grade in remaining batches');
                }

                // Extract submission IDs directly from the student objects
                // (student.id is actually the submission id)
                targetSubmissionIds = targetStudents.map(student => student.id);

                if (targetSubmissionIds.length === 0) {
                    throw new Error('No valid submissions to grade in remaining batches');
                }
            }

            // Store the instructions for future use
            setLastUsedInstructions(instructionsToUse);

            // If applying to next group, navigate first
            if (mode === 'next') {
                navigateToBatch('next');
            }

            // Start the regrade request
            setIsRegrading(true);

            // Build API request body according to the backend expectations
            const requestBody = {
                subQuestionId: Number(currentQuestion),
                regradeInstructions: instructionsToUse,
                regradeFeedbackInstructions: "detailed",
                newRubric: currentSubQuestion.rubricItems,
            };

            // For current and next modes, use submission IDs
            requestBody.submissionIds = targetSubmissionIds;

            // Make the API request
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/ai-regrade/`,
                body: requestBody,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            });

            // Update the exam paper with the new instructions
            setData(prev => ({
                ...prev,
                examPaper: prev.examPaper.map(question => ({
                    ...question,
                    subQuestions: question.subQuestions.map(sq =>
                        sq.id === Number(currentQuestion)
                            ? {...sq, regrade_instructions: instructionsToUse}
                            : sq
                    )
                }))
            }));

            // Show appropriate notification based on mode
            let notificationMessage = '';
            if (mode === 'remaining') {
                const remainingCount = allStudents.length - ((currentBatchIndex + 1) * 10);
                notificationMessage = `Regrading subquestion for the remaining ${remainingCount} students...`;

                // For 'remaining' mode, automatically go to the next subquestion after a delay
                setTimeout(() => {
                    findNextSubquestion();
                }, 2000);
            } else if (mode === 'next') {
                notificationMessage = `Regrading subquestion for next batch: ${targetSubmissionIds.length} students...`;
            } else {
                notificationMessage = `Regrading subquestion for current batch: ${targetSubmissionIds.length} submissions...`;
            }

            setNotification({
                type: 'success',
                message: notificationMessage
            });
            setTimeout(() => setNotification(null), 10000);

            // Start regrading - this will trigger the polling effect
            setIsRegrading(true);

            // Also immediately update the subQuestionsRegrading state to show the animation
            // without waiting for the first poll
            setSubQuestionsRegrading(prev => ({
                ...prev,
                [currentQuestion]: true
            }));

            // Force an immediate UI update
            setTimeout(() => {
                // This empty setState forces a re-render
                setIsRegrading(true);
            }, 50);

        } catch (error) {
            console.error('AI-Regrade failed:', error);
            setNotification({
                type: 'error',
                message: error.message || 'Failed to initiate AI-Regrade. Please try again.'
            });
            setTimeout(() => setNotification(null), 10000);
            setIsRegrading(false);
        }
    }, [
        gradingInstructions,
        lastUsedInstructions,
        data.examPaper,
        currentQuestion,
        currentBatchIndex,
        currentBatchSubmissions,
        allStudents,
        navigateToBatch,
        assignment_id,
        user.session_token,
        findNextSubquestion
    ]);

    // Helper functions for submission data
    const getAnswerImageUrl = useCallback((submission) => {
        if (!submission?.feedback || !currentQuestion) {
            return null;
        }

        const feedbackEntry = submission.feedback[currentQuestion];

        if (!feedbackEntry?.answer) {
            return null;
        }

        // Check if the answer is a base64 data URL
        if (feedbackEntry.answer.startsWith('data:image')) {
            return feedbackEntry.answer;
        }

        // Otherwise, treat it as an S3 key
        return `${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${feedbackEntry.answer}`;
    }, [currentQuestion]);

    const getMaxPoints = useCallback((subQuestionId) => {
        // Find the current subquestion in the exam paper
        const currentSubQuestion = data.examPaper
            .flatMap(question => question.subQuestions)
            .find(sq => sq.id === Number(subQuestionId));

        if (!currentSubQuestion || !currentSubQuestion.rubricItems || currentSubQuestion.rubricItems.length === 0) {
            return assignment?.max_score || 0; // Fallback to assignment max_score if no rubric items
        }

        // Find the highest value among the rubric items
        const highestValue = Math.max(...currentSubQuestion.rubricItems.map(item => item.value));
        return highestValue;
    }, [data.examPaper, assignment]);

    const getCurrentGrade = useCallback((submission) => {
        if (!submission?.feedback || !currentQuestion) {
            return 0;
        }
        const feedbackEntry = submission.feedback[currentQuestion];
        if (!feedbackEntry) {
            return 0;
        }
        // Get the selected rubric item value
        const selectedRubricItem = feedbackEntry.rubric_items?.[0]; // Use [0] to get the first item
        return selectedRubricItem ? selectedRubricItem.value : 0;
    }, [currentQuestion]);

    // Feedback editing
    const getFeedbackForSubmission = useCallback((submission) => {
        if (!submission?.feedback || !currentQuestion) {
            return null;
        }
        const feedbackEntry = submission.feedback[currentQuestion];
        if (!feedbackEntry) {
            return null;
        }

        // Get selected rubric items
        const selectedRubricItems = feedbackEntry.rubric_items?.filter(item => item.selected) || [];
        const totalPoints = selectedRubricItems.reduce((sum, item) => sum + item.value, 0);
        const maxPoints = getMaxPoints(currentQuestion);

        return {
            feedback: feedbackEntry.feedback || "No feedback available",
            rubricItems: selectedRubricItems,
            points: totalPoints,
            maxPoints: maxPoints
        };
    }, [currentQuestion, getMaxPoints]);

    // Feedback editing
    const handleEditFeedback = useCallback((submission) => {
        const feedbackData = getFeedbackForSubmission(submission);
        setFeedbackText(feedbackData?.feedback || '');
        setEditingFeedback(submission.id);
    }, [getFeedbackForSubmission]);

    // Feedback saving
    const handleSaveFeedback = useCallback(async (submissionId) => {
        try {
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/${submissionId}/feedback/`,
                body: {
                    subquestionId: currentQuestion,
                    feedback: feedbackText
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            });

            // Update only the specific submission in our data
            setData(prev => ({
                ...prev,
                submissions: prev.submissions.map(sub =>
                    sub.id === submissionId
                        ? {
                            ...sub,
                            feedback: {
                                ...sub.feedback,
                                [currentQuestion]: {...sub.feedback[currentQuestion], feedback: feedbackText}
                            }
                        }
                        : sub
                )
            }));

            setEditingFeedback(null);

            setNotification({
                type: 'success',
                message: 'Feedback saved successfully'
            });
            setTimeout(() => setNotification(null), 10000);
        } catch (error) {
            console.error('Error saving feedback:', error);
            setNotification({
                type: 'error',
                message: 'Failed to save feedback. Please try again.'
            });
            setTimeout(() => setNotification(null), 10000);
        }
    }, [assignment_id, currentQuestion, feedbackText, user.session_token]);

    // Handle rubric item change
    const handleRubricItemChange = useCallback(async (submissionId, subquestionId, rubricItem) => {
        try {
            // If there are selected submissions and the current submission is among them,
            // apply the rubric to all selected submissions
            if (selectedSubmissions.length > 0 && selectedSubmissions.includes(submissionId)) {
                // Create an array of promises for each selected submission
                const updatePromises = selectedSubmissions.map(selectedId =>
                    gradeApi.post({
                        path: `/api/assignment/${assignment_id}/submissions/${selectedId}/grade/`,
                        body: {
                            subquestionId: subquestionId,
                            rubric: rubricItem
                        },
                        config: {
                            headers: {
                                Authorization: `Bearer ${user.session_token}`
                            }
                        }
                    })
                );

                // Wait for all requests to complete
                const responses = await Promise.all(updatePromises);

                // Add selected submissions to gradedSubmissions instead of marking as viewed immediately
                setGradedSubmissions(prev => {
                    const newGradedSubmissions = [...prev];
                    selectedSubmissions.forEach(selectedId => {
                        if (!newGradedSubmissions.includes(selectedId)) {
                            newGradedSubmissions.push(selectedId);
                        }
                    });
                    return newGradedSubmissions;
                });

                // Update the state to reflect the changes for all selected submissions
                setData(prev => {
                    const updatedSubmissions = prev.submissions.map(sub => {
                        if (selectedSubmissions.includes(sub.id)) {
                            // Find the corresponding response for this submission
                            const response = responses.find(r => {
                                // The response might contain the submission ID or we need to infer it
                                // This depends on your API response structure
                                return r.data && r.data.submissionId === sub.id;
                            });

                            // If we found a response, use it to update the feedback
                            if (response && response.data && response.data.feedback) {
                                // Create a copy of the feedback with is_viewed set to true
                                const updatedFeedback = {...response.data.feedback};
                                if (updatedFeedback[subquestionId]) {
                                    // Mark as viewed immediately
                                    updatedFeedback[subquestionId].is_viewed = true;
                                }

                                return {
                                    ...sub,
                                    feedback: updatedFeedback,
                                    is_reviewed: true
                                };
                            }

                            // Otherwise, manually update the feedback with the rubric item
                            const updatedFeedback = {...(sub.feedback || {})};
                            if (!updatedFeedback[subquestionId]) {
                                updatedFeedback[subquestionId] = {};
                            }
                            updatedFeedback[subquestionId].rubric_items = [rubricItem];
                            // Mark as viewed immediately
                            updatedFeedback[subquestionId].is_viewed = true;

                            return {
                                ...sub,
                                feedback: updatedFeedback,
                                is_reviewed: true
                            };
                        }
                        return sub;
                    });

                    return {
                        ...prev,
                        submissions: updatedSubmissions
                    };
                });

                // Clear selection after applying the rubric
                setSelectedSubmissions([]);

                // Ensure all selected submissions are expanded after grading
                const expandedUpdates = {};
                selectedSubmissions.forEach(id => {
                    expandedUpdates[id] = true;
                });
                setExpandedSubmissions(prev => ({
                    ...prev,
                    ...expandedUpdates
                }));

                setNotification({
                    type: 'success',
                    message: `Applied grade to ${selectedSubmissions.length} submissions`
                });
                setTimeout(() => setNotification(null), 10000);
            } else {
                // Otherwise, just apply to the current submission
                const response = await gradeApi.post({
                    path: `/api/assignment/${assignment_id}/submissions/${submissionId}/grade/`,
                    body: {
                        subquestionId: subquestionId,
                        rubric: rubricItem
                    },
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });

                // Add to gradedSubmissions instead of marking as viewed immediately
                setGradedSubmissions(prev => {
                    if (!prev.includes(submissionId)) {
                        return [...prev, submissionId];
                    }
                    return prev;
                });

                // Ensure the submission card is expanded after grading
                setExpandedSubmissions(prev => ({
                    ...prev,
                    [submissionId]: true
                }));

                // Update only the specific submission in our data
                setData(prev => ({
                    ...prev,
                    submissions: prev.submissions.map(sub =>
                        sub.id === submissionId
                            ? {
                                ...sub,
                                feedback: {
                                    ...response.data.feedback,
                                    [subquestionId]: {
                                        ...(response.data.feedback?.[subquestionId] || {}),
                                        // Mark as viewed immediately
                                        is_viewed: true
                                    }
                                },
                                is_reviewed: true  // Mark as reviewed to remove red dot
                            }
                            : sub
                    )
                }));

                setNotification({
                    type: 'success',
                    message: 'Rubric item updated successfully'
                });
            }

            // Update the exam paper to reflect the new viewed count
            setData(prev => ({
                ...prev,
                examPaper: prev.examPaper.map(question => ({
                    ...question,
                    subQuestions: question.subQuestions.map(sq => {
                        if (sq.id === Number(subquestionId)) {
                            return {
                                ...sq,
                                viewed_count: (sq.viewed_count || 0) + 1
                            };
                        }
                        return sq;
                    })
                }))
            }));

            setTimeout(() => setNotification(null), 10000);
        } catch (error) {
            console.error('Error updating rubric:', error);
            setNotification({
                type: 'error',
                message: 'Failed to update rubric. Please try again.'
            });
            setTimeout(() => setNotification(null), 10000);
        }
    }, [assignment_id, user.session_token, selectedSubmissions, setGradedSubmissions]);

    // Handle selecting next or previous rubric item
    const handleSelectAdjacentRubricItem = useCallback((submissionId, direction) => {
        const submission = data.submissions.find(sub => sub.id === submissionId);
        if (!submission) {
            return;
        }

        // Get the current subquestion's rubric items
        const currentSubQuestion = data.examPaper
            .flatMap(q => q.subQuestions)
            .find(sq => sq.id === currentQuestion);

        if (!currentSubQuestion || !currentSubQuestion.rubricItems || currentSubQuestion.rubricItems.length === 0) {
            setNotification({
                type: 'warning',
                message: 'No rubric items available for this question'
            });
            setTimeout(() => setNotification(null), 3000);
            return;
        }

        // Sort the rubric items by value in descending order (highest to lowest)
        const sortedRubricItems = [...currentSubQuestion.rubricItems].sort((a, b) => b.value - a.value);

        // Get current feedback for this submission and subquestion
        const currentFeedback = submission.feedback?.[currentQuestion];
        const currentRubricItem = currentFeedback?.rubric_items?.[0];

        if (!currentRubricItem) {
            // If no rubric item is selected, select the first one (highest value)
            handleRubricItemChange(submissionId, currentQuestion, sortedRubricItems[0]);
            setNotification({
                type: 'info',
                message: `Selected first rubric item: ${sortedRubricItems[0].descriptor}`
            });
            setTimeout(() => setNotification(null), 3000);
            return;
        }

        // Find the index of the current rubric item in the sorted array
        const currentIndex = sortedRubricItems.findIndex(item =>
            item.value === currentRubricItem.value &&
            item.descriptor === currentRubricItem.descriptor);

        if (currentIndex === -1) {
            // If current item not found in rubric items, select the first one
            handleRubricItemChange(submissionId, currentQuestion, sortedRubricItems[0]);
            setNotification({
                type: 'info',
                message: `Selected first rubric item: ${sortedRubricItems[0].descriptor}`
            });
            setTimeout(() => setNotification(null), 3000);
            return;
        }

        // Calculate the new index based on direction
        let newIndex;
        if (direction === 'up') {
            newIndex = currentIndex > 0 ? currentIndex - 1 : 0;
            if (newIndex === currentIndex) {
                setNotification({
                    type: 'info',
                    message: 'Already at the highest rubric item'
                });
                setTimeout(() => setNotification(null), 3000);
                return;
            }
        } else { // direction === 'down'
            newIndex = currentIndex < sortedRubricItems.length - 1 ?
                currentIndex + 1 : sortedRubricItems.length - 1;
            if (newIndex === currentIndex) {
                setNotification({
                    type: 'info',
                    message: 'Already at the lowest rubric item'
                });
                setTimeout(() => setNotification(null), 3000);
                return;
            }
        }

        // Select the new rubric item from the sorted array
        const newRubricItem = sortedRubricItems[newIndex];
        handleRubricItemChange(submissionId, currentQuestion, newRubricItem);

        setTimeout(() => setNotification(null), 3000);
    }, [data, currentQuestion, handleRubricItemChange, setGradedSubmissions]);

    // Handle overlay toggle
    const toggleOverlay = useCallback((submissionId) => {
        if (activeOverlay === submissionId) {
            setActiveOverlay(null);
            document.body.classList.remove('overflow-hidden');
        } else {
            setActiveOverlay(submissionId);
            document.body.classList.add('overflow-hidden');
        }
    }, [activeOverlay]);

    // AI Regrade overlay handlers
    const handleRegradeSubmit = useCallback((newRubric, regradeInstructions, regradeFeedbackInstructions, solution) => {
        setRegradeData({newRubric, regradeInstructions, regradeFeedbackInstructions, solution});
        setIsConfirmationOpen(true);
    }, []);

    const handleRegradeClose = useCallback(() => {
        setShowAIRegradeOverlay(false);
        setSelectedSubQuestion(null);
    }, []);

    // Handle student assignment
    const handleAssignStudent = useCallback(async (submissionId, studentId) => {
        try {
            await gradeApi.post({
                path: `/api/v2/assignments/${assignment_id}/submissions/${submissionId}/assign-student/`,
                body: {student_id: studentId},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            // Refresh only the affected submission
            const submissionResponse = await gradeApi.get({
                path: `/api/v2/assignments/${assignment_id}/submissions/${studentId}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            // Update our submissions data
            setData(prev => ({
                ...prev,
                submissions: prev.submissions.map(sub =>
                    sub.id === submissionId
                        ? submissionResponse.data
                        : sub
                )
            }));

            setData(prev => ({
                ...prev,
                availableStudents: studentsResponse.data.students
            }));

            setAssigningStudent(null);
            setNotification({
                type: 'success',
                message: 'Student assigned successfully'
            });
            setTimeout(() => setNotification(null), 10000);
        } catch (error) {
            console.error('Error assigning student:', error);
            setNotification({
                type: 'error',
                message: 'Failed to assign student. Please try again.'
            });
            setTimeout(() => setNotification(null), 10000);
        }
    }, [assignment_id, reviewMode, user.session_token]);

    const handleConfirmRegrade = useCallback(async () => {
        setIsConfirmationOpen(false);
        setIsRegrading(true);

        // Immediately update the subQuestionsRegrading state to show the animation
        // without waiting for the first poll
        setSubQuestionsRegrading(prev => ({
            ...prev,
            [currentQuestion]: true
        }));

        // Force an immediate UI update for the animation
        setTimeout(() => {
            // This empty setState forces a re-render
            setIsRegrading(true);
        }, 50);

        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/ai-regrade/`,
                body: {
                    subQuestionId: currentQuestion,
                    newRubric: regradeData.newRubric,
                    regradeInstructions: regradeData.regradeInstructions,
                    regradeFeedbackInstructions: regradeData.regradeFeedbackInstructions,
                    solution: regradeData.solution
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            setShowAIRegradeOverlay(false);
            setNotification({
                type: 'success',
                message: 'AI-Regrade initiated. The system will update the grades shortly.'
            });
            setTimeout(() => setNotification(null), 10000);

        } catch (error) {
            console.error('AI-Regrade failed:', error);
            setNotification({
                type: 'error',
                message: 'AI-Regrade failed. Please try again.'
            });
            setTimeout(() => setNotification(null), 10000);

            // Only set isRegrading to false if there was an error
            setIsRegrading(false);
            setSubQuestionsRegrading(prev => ({
                ...prev,
                [currentQuestion]: false
            }));
        }
    }, [assignment_id, currentQuestion, regradeData, user.session_token]);

    if (data.loading) {
        return <GroupLoading/>;
    }

    return (
        <div className="h-screen flex flex-col bg-white">
            {/* Navigation Tips */}
            {showNavTip && (
                <NavigationTips onClose={() => setShowNavTip(false)}/>
            )}

            {/* Fixed Header with Batch Navigation */}
            <BatchNavigation
                showTopBar={showTopBar}
                currentBatchIndex={currentBatchIndex}
                currentQuestion={currentQuestion}
                allStudents={allStudents}
                data={data}
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen}
                dropdownRef={dropdownRef}
                setIsBatchInputFocused={setIsBatchInputFocused}
                navigate={navigate}
                class_id={class_id}
                assignment_id={assignment_id}
                students={students}
                allCurrentBatchViewed={allCurrentBatchViewed}
                markingBatchAsViewed={markingBatchAsViewed}
                markBatchAsViewed={markBatchAsViewed}
                navigateToBatch={navigateToBatch}
                setCurrentQuestion={setCurrentQuestion}
                setCurrentBatchIndex={setCurrentBatchIndex}
                setGradingInstructions={setGradingInstructions}
                setLastUsedInstructions={setLastUsedInstructions}
                isRegrading={isRegrading}
            />

            {/* Selection counter is now in GradingControls */}

            {/* Main Content */}
            <div className="flex-1 overflow-hidden pt-0">
                <div ref={contentContainerRef} className="h-full overflow-y-auto px-10 py-4">
                    <div
                        className={`grid grid-cols-2 mt-16 gap-8 pb-64 ${slideDirection === 'right' ? 'slide-in-right' : slideDirection === 'left' ? 'slide-in-left' : 'fade-in'}`}>
                        {batchLoading && (
                            <div className="col-span-2 flex justify-center items-center py-20">
                                <div className="flex flex-col items-center">
                                    <div className="w-12 h-12 mb-4">
                                        <svg className="animate-spin" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 24 24" fill="none" stroke="rgb(228,177,122)" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <circle cx="12" cy="12" r="10" strokeOpacity="0.25"></circle>
                                            <path d="M12 2a10 10 0 0 1 10 10" strokeOpacity="0.75"></path>
                                        </svg>
                                    </div>
                                    <p className="text-gray-600 font-medium">Loading submissions...</p>
                                </div>
                            </div>
                        )}
                        {!batchLoading && currentDisplaySubmissions.length === 0 && (
                            <div className="col-span-2 flex justify-center items-center py-20">
                                <p className="text-gray-600 font-medium">No submissions found for this batch</p>
                            </div>
                        )}
                        {!batchLoading && currentDisplaySubmissions.map((submission, index) => (
                            <SubmissionCard
                                key={submission.id || index}
                                submission={submission}
                                index={index}
                                currentBatchIndex={currentBatchIndex}
                                currentQuestion={currentQuestion}
                                assignment={assignment}
                                isRegrading={isRegrading}
                                selectedSubmissions={selectedSubmissions}
                                setSelectedSubmissions={setSelectedSubmissions}
                                expandedSubmissions={expandedSubmissions}
                                setExpandedSubmissions={setExpandedSubmissions}
                                showStudentNames={showStudentNames}
                                setShowStudentNames={setShowStudentNames}
                                assigningStudent={assigningStudent}
                                setAssigningStudent={setAssigningStudent}
                                availableStudents={data.availableStudents}
                                handleAssignStudent={handleAssignStudent}
                                getAnswerImageUrl={getAnswerImageUrl}
                                getCurrentGrade={getCurrentGrade}
                                getMaxPoints={getMaxPoints}
                                toggleOverlay={toggleOverlay}
                                handleSelectAdjacentRubricItem={handleSelectAdjacentRubricItem}
                                reviewMode={reviewMode}
                            />
                        ))}
                        {prefetchingBatch !== null && (
                            <div
                                className="fixed bottom-2 left-4 px-3 py-1.5 bg-gray-100 text-gray-600 text-xs font-medium rounded-md shadow-sm flex items-center">
                                <svg className="w-3 h-3 mr-1 animate-spin" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                     strokeLinecap="round" strokeLinejoin="round">
                                    <circle cx="12" cy="12" r="10" strokeOpacity="0.25"></circle>
                                    <path d="M12 2a10 10 0 0 1 10 10" strokeOpacity="0.75"></path>
                                </svg>
                                Pre-loading next batch
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <GradingControls
                gradingInstructions={gradingInstructions}
                handleInstructionsChange={handleInstructionsChange}
                renderInstructionsText={renderInstructionsText}
                textareaHeight={textareaHeight}
                isRegrading={isRegrading}
                allStudents={allStudents}
                currentBatchIndex={currentBatchIndex}
                handleApplyGradingInstructions={handleApplyGradingInstructions}
                currentQuestion={currentQuestion}
                data={data}
                setSelectedSubQuestion={setSelectedSubQuestion}
                showSolutionOverlay={showSolutionOverlay}
                setShowSolutionOverlay={setShowSolutionOverlay}
                setShowAIRegradeOverlay={setShowAIRegradeOverlay}
                selectedSubmissions={selectedSubmissions}
                setSelectedSubmissions={setSelectedSubmissions}
                updateTextareaHeight={updateTextareaHeight}
            />

            {/* Overlays */}
            {notification && (
                <NotificationOverlay
                    notification={notification}
                    onClose={() => setNotification(null)}
                />
            )}

            {showNextSubquestionPopup && (
                <NextSubquestionPopup
                    nextSubQuestionId={nextSubQuestionId}
                    assignment={assignment}
                />
            )}

            {activeOverlay && (
                <RubricOverlay
                    activeSubmission={currentDisplaySubmissions.find(sub => sub.id === activeOverlay)}
                    activeOverlay={activeOverlay}
                    currentDisplaySubmissions={currentDisplaySubmissions}
                    currentQuestion={currentQuestion}
                    data={data}
                    isRegrading={isRegrading}
                    handleRubricItemChange={handleRubricItemChange}
                    editingFeedback={editingFeedback}
                    feedbackText={feedbackText}
                    setFeedbackText={setFeedbackText}
                    handleEditFeedback={handleEditFeedback}
                    handleSaveFeedback={handleSaveFeedback}
                    getFeedbackForSubmission={getFeedbackForSubmission}
                    setActiveOverlay={setActiveOverlay}
                />
            )}

            {/* Solution Overlay */}
            {showSolutionOverlay && selectedSubQuestion && (
                <SolutionOverlay
                    selectedSubQuestion={selectedSubQuestion}
                    onClose={() => setShowSolutionOverlay(false)}
                />
            )}

            {/* AI Regrade Overlay */}
            {showAIRegradeOverlay && selectedSubQuestion && (
                <AIRegradeOverlay
                    subQuestion={selectedSubQuestion}
                    question={data.examPaper.find(q =>
                        q.subQuestions.some(sq => sq.id === currentQuestion)
                    )}
                    isEssay={assignment?.type === 'essay'}
                    isConfirmationOpen={isConfirmationOpen}
                    handleConfirmRegrade={handleConfirmRegrade}
                    setIsConfirmationOpen={setIsConfirmationOpen}
                    onClose={handleRegradeClose}
                    onSubmit={handleRegradeSubmit}
                />
            )}
        </div>
    );
}

export default TGroupDetails;