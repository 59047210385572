import React, {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {useDispatch, useSelector} from 'react-redux';
import {Settings} from 'lucide-react'; // Import the Settings icon
import {Link} from 'react-router-dom';
import {useAuth} from '../../../../auth/AuthContext';
import {
    clearInfiniteStudents,
    clearInfiniteTeachers,
    getStudents,
    getTeachers
} from '../../../../store/teacherSlice.js';
import {DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE} from '../../../../utils/pagination.ts';
import {Loading} from '../../../Loading.js';

const AddDrop = props => {
    const {user} = useAuth();
    const dispatch = useDispatch();

    const [section, setSection] = useState('students');

    const [hovered, setHovered] = useState(null);
    const [pagingPage, setPagingPage] = useState(DEFAULT_PAGE_NUMBER);

    const {
        data: students,
        infiniteStudents,
        isLoading: isStudentsLoading
    } = useSelector(state => state?.teacherReducer?.students);

    const {
        data: teachers,
        infiniteTeachers,
        isLoading: isTeachersLoading
    } = useSelector(state => state?.teacherReducer?.teachers);

    useEffect(() => {
        getAllStudents(pagingPage);
        getAllTeachers(pagingPage);

        return () => {
            dispatch(clearInfiniteStudents());
            dispatch(clearInfiniteTeachers());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPagingPage(1);
    }, [section]);

    const getAllStudents = page =>
        dispatch(
            getStudents({
                classId: props.class_id,
                token: user.session_token,
                search: '',
                pageNumber: page,
                itemsPerPage: ITEMS_PER_PAGE.default
            })
        );

    const getAllTeachers = page =>
        dispatch(
            getTeachers({
                classId: props.class_id,
                token: user.session_token,
                search: '',
                pageNumber: page,
                itemsPerPage: ITEMS_PER_PAGE.default
            })
        );

    return (
        <div className="add-drop-container min-h-screen flex flex-col bg-white border-l border-gray-200">
            <div className="pt-6 pl-6 pr-6 pb-4 border-b border-gray-200">
                <div className="flex gap-2">
                    {[
                        {label: 'Students', value: 'students'},
                        {label: 'Teachers', value: 'teachers'}
                    ].map(({label, value}) => (
                        <button
                            key={value}
                            type="button"
                            className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 ${
                                section === value
                                    ? 'bg-orange-50 border-orange-200 hover:bg-orange-100'
                                    : 'text-slate-700 border-slate-200 hover:border-slate-400'
                            }`}
                            onClick={() => setSection(value)}
                        >
                            {label}
                        </button>
                    ))}
                </div>
                <div className="flex items-center justify-between mt-4">
                    <h3 className="font-semibold text-gray-900">
                        {section === 'students' ? 'Students' : 'Teachers'}
                    </h3>
                    <Link to={`/csettings/${props.class_id}`}>
                        <button
                            className="group flex items-center justify-center p-3 bg-white rounded-full
                      text-sm text-gray-700 border border-gray-200 hover:border-orange-200
                      hover:bg-orange-50 transition-all duration-200"
                        >
                            <Settings className="w-5 h-5 text-gray-500 group-hover:text-orange-500 transition-colors"/>
                        </button>
                    </Link>
                </div>
            </div>

            {section === 'students' ? (
                <div className="max-h-[705px] overflow-y-auto px-6">
                    <InfiniteScroll
                        hasMore={!!students?.nextOffset}
                        initialLoad={false}
                        loader={!!isStudentsLoading ? <Loading/> : null}
                        loadMore={async () => {
                            if (!!students?.nextOffset && students?.nextOffset !== 0) {
                                getAllStudents(pagingPage + 1);
                                setPagingPage(pagingPage + 1);
                            }
                        }}
                        useWindow={false}
                        threshold={Number(ITEMS_PER_PAGE.default)}
                    >
                        {/* Replace the existing students mapping code with this */}
                        {[...new Map((infiniteStudents ?? []).map(student => [student.id, student])).values()].map((student, index) => (
                            <div key={index} className="student-item flex justify-between items-center py-3">
                                <div
                                    className="relative cursor-pointer"
                                    onMouseEnter={() => setHovered(student)}
                                    onMouseLeave={() => setHovered(null)}
                                >
      <span className="text-gray-700">
        {student.first_name} {student.last_name}
      </span>

                                    {hovered === student && (
                                        <div
                                            className="absolute z-10 bg-black text-white p-2 rounded text-sm mt-1 left-[110%] top-[-50%]">
                                            ID: {student.id}
                                            <br/>
                                            Email: {student.email}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div>
            ) : (
                <div className="flex-1 overflow-y-auto px-6">
                    <InfiniteScroll
                        hasMore={!!teachers?.nextOffset}
                        initialLoad={false}
                        loader={!!isTeachersLoading ? <Loading/> : null}
                        loadMore={async () => {
                            if (!!teachers?.nextOffset && teachers?.nextOffset !== 0) {
                                getAllTeachers(pagingPage + 1);
                                setPagingPage(pagingPage + 1);
                            }
                        }}
                        useWindow={false}
                        threshold={Number(ITEMS_PER_PAGE.default)}
                    >
                        {(infiniteTeachers ?? []).map((teacher, index) => (
                            <li key={index} className="student-item flex justify-between items-center py-3">
                                <div
                                    className="relative cursor-pointer"
                                    onMouseEnter={() => setHovered(teacher)}
                                    onMouseLeave={() => setHovered(null)}
                                >
                                    <span className="text-gray-700">{teacher.fullName}</span>

                                    {hovered === teacher && (
                                        <div
                                            className="absolute z-10 bg-black text-white p-2 rounded text-sm mt-1 left-[110%] top-[-50%]">
                                            ID: {teacher.id}
                                            <br/>
                                            Email: {teacher.email}
                                        </div>
                                    )}
                                </div>
                            </li>
                        ))}
                    </InfiniteScroll>
                </div>
            )}
        </div>
    );
};

export default AddDrop;
