import React, { useCallback, useEffect } from "react";

const useMouseActions = ({
  isDrawMode,
  isDrawing,
  startPoint,
  setIsDrawing,
  setStartPoint,
  setCurrentBox,
  setSaveDialog,
  setIsDragging,
  boxMouseActions,
  isDragging,
  isResizing,
  overlayRef,
}) => {
  const handleMouseUp = useCallback(() => {
    if (isDrawing && startPoint) {
      const box = {
        x: Math.min(startPoint.x, startPoint.currentX),
        y: Math.min(startPoint.y, startPoint.currentY),
        width: Math.abs(startPoint.currentX - startPoint.x),
        height: Math.abs(startPoint.currentY - startPoint.y),
      };

      if (box.width > 1 && box.height > 1) {
        const rect = overlayRef.current.getBoundingClientRect();
        const dialogX =
          rect.left +
          (box.x * rect.width) / 100 +
          (box.width * rect.width) / 200;
        const dialogY = rect.top + (box.y * rect.height) / 100 - 60;

        setSaveDialog({
          box,
          position: { x: dialogX, y: dialogY },
        });
      }
    }
    setIsDrawing(false);
    setStartPoint(null);
    setCurrentBox(null);
  }, [isDrawing, startPoint]);

  const handleMouseDown = useCallback(
    (e) => {
      if (!isDrawMode) {
        console.log("Mouse down ignored - not in draw mode");
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      const container = e.currentTarget;
      const rect = container.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100;
      const y = ((e.clientY - rect.top) / rect.height) * 100;

      console.log("Mouse Down Coordinates:", {
        clientX: e.clientX,
        clientY: e.clientY,
        containerRect: rect,
        calculatedX: x,
        calculatedY: y,
      });

      setIsDrawing(true);
      setStartPoint({
        x,
        y,
        currentX: x,
        currentY: y,
      });
    },
    [isDrawMode]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDrawing || !startPoint) {
        console.log('not drawing or start point');
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      const container = e.currentTarget;
      const rect = container.getBoundingClientRect();
      let currentX = ((e.clientX - rect.left) / rect.width) * 100;
      let currentY = ((e.clientY - rect.top) / rect.height) * 100;

      // Constrain to PDF boundaries
      currentX = Math.max(0, Math.min(currentX, 100));
      currentY = Math.max(0, Math.min(currentY, 100));

      setStartPoint((prev) => ({
        ...prev,
        currentX,
        currentY,
      }));

      setCurrentBox({
        x: Math.min(startPoint.x, currentX),
        y: Math.min(startPoint.y, currentY),
        width: Math.abs(currentX - startPoint.x),
        height: Math.abs(currentY - startPoint.y),
      });
    },
    [isDrawing, startPoint]
  );

  // Add cleanup for mouse events
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isResizing || isDragging) {
        setIsResizing(false);
        setIsDragging(false);
        setResizeHandle(null);
      }
    };

    const handleGlobalMouseMove = (e) => {
      if (isResizing || isDragging) {
        e.preventDefault();
        if (overlayRef.current) {
          boxMouseActions.handleBoxMouseMove(e);
        }
      }
    };

    if (isResizing || isDragging) {
      window.addEventListener("mousemove", handleGlobalMouseMove, {
        passive: false,
      });
      window.addEventListener("mouseup", handleGlobalMouseUp);

      return () => {
        window.removeEventListener("mousemove", handleGlobalMouseMove);
        window.removeEventListener("mouseup", handleGlobalMouseUp);
      };
    }
  }, [isResizing, isDragging, boxMouseActions.handleBoxMouseMove]);

  return {
    handleMouseUp,
    handleMouseDown,
    handleMouseMove,
  };
};

export default useMouseActions;
