export const convertPdfToBase64 = async (pdfUrl) => {
  try {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // Return the base64 string only
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob); // Read the blob as a data URL
    });
  } catch (error) {
    console.error("Error converting PDF to Base64:", error);
    return null;
  }
};
